import React from 'react'
import { ReactComponent as IconCosentinoC } from '../../../assets/img/icons/cosentino-global-icon-c.svg'
import { injectIntl } from 'react-intl'

const NaturalStoneIndicator = ({ idMessage, defaultMessage, intl, isCheckout = false }) => {

    const checkoutStyles = {
        indicatorHeight: '18px',
        iconSize: '18px',
        iconBorder: '1px solid #232323',
        svgWidth: '8px',
        textFontSize: '8.5px',
        textPadding: '2px',
        letterSpacing: '0.2px'
    }

    const styles = {
        naturalStoneIndicator: {
            display: 'flex',
            alignItems: 'center',
            height: isCheckout ? checkoutStyles.indicatorHeight : '20px',
        },
        cosentinoIconC: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: isCheckout ? checkoutStyles.iconSize : '20px',
            height: isCheckout ? checkoutStyles.iconSize : '20px',
            border: checkoutStyles.iconBorder,
            backgroundColor: '#000'
        },
        svg: {
            width: isCheckout ? checkoutStyles.svgWidth : '10px',
        },
        cosentinoNaturalStoneText: {
            backgroundColor: '#FFF',
            height: isCheckout ? checkoutStyles.indicatorHeight : '20px',
            padding: isCheckout ? checkoutStyles.textPadding : '2px',
            display: 'flex',
            alignItems: 'center',
            color: '#000',
            fontSize: isCheckout ? checkoutStyles.textFontSize : '8.8px',
            fontWeight: 500,
            border: '1px solid #232323',
            fontFamily: 'Roboto',
            lineHeight: '16px',
            borderLeft: 0
        }
    }

    return (
        <div style={styles.naturalStoneIndicator}>
            <div style={styles.cosentinoIconC}>
                <IconCosentinoC style={styles.svg} />
            </div>
            <div style={styles.cosentinoNaturalStoneText}>
                <span>
                    {intl.formatMessage({
                        id: idMessage,
                        defaultMessage: defaultMessage
                    })}
                </span>
            </div>
        </div>
    )
}

export default injectIntl(NaturalStoneIndicator)
