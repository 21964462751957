import React from 'react'
import {injectIntl, defineMessages, FormattedHTMLMessage} from 'react-intl'
import ProfileContainer from '../../../profile/ProfileContainer'
import Spinner from '../../../global/Spinner'
import LocalizedLink from '../../../global/LocalizedLink'
import Translation from '../../../global/Translation'
import ProductImage from '../../../catalog/ProductImage'
import { addForwardSlash, DisplayShippingAddress, CUT_TO_SIZE_MATERIALS, getConfigLineName } from '../Utils'
import ProductDisplayName from '../../../global/ProductDisplayName'
import { ReactComponent as ShippingTruckIcon } from '../../../../assets/img/order-history/shipping-truck.svg'
import { ReactComponent as PrintIcon } from '../../../../assets/img/order-history/print-icon.svg'
import confirm from '../../../global/extendedConfirm'
import moment from 'moment'
import Modal from '../../../global/Modal'
import ReactTable from 'react-table'
import DisplayOutletIndicator from '../../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import ReactTablePaginationCount from '../../../promotions/discounts/ReactTablePaginationCount'
import NewOrderDetailsEditMode from './NewOrderDetailsEditMode'
import * as CutToSizeMaps from '../../../cut-to-size/CutToSizeMaps'
import DisplayThickness from '../../../catalog/rectangleResult/displays/DisplayThickness'
import Timeline from "../../Timeline";
import { OrderLineStatus } from '../Enums/OrderLineStatus'
import { OrderStatus } from '../Enums/OrderStatus'
import Alerts from '../../../alerts/Alerts'
import OrderDetailsWalkthrough from '../../../walkthroughs/order/OrderDetailsWalkthrough'
import {capitalizeFirstLetter} from '../../../../helpers'
import {ElaborationOptionsMapById} from "../../../cut-to-size/CutToSizeMaps";
import LineSegmentation from "../../../cart-line/LineSegmentation";
import ModalConfigurationDetails from '../../../cut-to-size/configurable-baths/ModalConfigurationDetails'
import ConfigBathIndicator from '../indicators/ConfigBathIndicator'
import {ReactComponent as QuickShipIcon} from "../../../../assets/img/cut-to-size/quickShipIcon.svg";

// Internal and external component
export const ModifiedLineIndicator = props => {
    const { orderLine } = props

    if (orderLine) {
        let lineIndicator = null
        let orderLineStatus = orderLine.status && orderLine.status.status

        if (parseInt(orderLine.reservation_quantity) !== parseInt(orderLine.quantity)) {
            lineIndicator = (
                <div className="product__modified">
                    <div className="dot-icon"></div>
                    <span>
                        <Translation id="product_modified" defaultMessage="Product modified" />
                    </span>
                </div>
            )
        }

        if (parseInt(orderLine.reservation_quantity) === 0 && parseInt(orderLine.quantity) > 0) {
            lineIndicator = (
                <div className="product__added">
                    <div className="dot-icon"></div>
                    <span>
                        <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                    </span>
                </div>
            )
        }

        if (orderLineStatus === OrderLineStatus.Order_cancelled && orderLine.reject_reason === 'D1') {
            lineIndicator = (
                <div className="product__cancelled__basic">
                    <div className="dot-icon"></div>
                    <span>
                        <Translation id="product_canceled_by_user" defaultMessage="Product canceled by user" />
                    </span>
                </div>
            )
        }

        if (orderLineStatus === OrderLineStatus.Order_cancelled && orderLine.reject_reason !== 'D1') {
            lineIndicator = (
                <div className="product__cancelled">
                    <div className="product__cancelled--icon-text">
                        <div className="dot-icon"></div>
                        <span>
                            <Translation id="product_canceled_by_center" defaultMessage="Product cancelled by center" />
                        </span>
                    </div>
                    <div className="product__cancelled--comment">
                        <span>
                            <Translation id="product_canceled_by_center_comment" defaultMessage="This product has been cancelled by the center at your request." />
                        </span>
                    </div>
                </div>
            )
        }

        return lineIndicator
    }
    return false
}

defineMessages({
    CONFIRM_REJECT_ORDER: {
        id: 'CONFIRM_REJECT_ORDER',
        description: 'CONFIRM_REJECT_ORDER',
        defaultMessage: 'Are you sure you want to cancel this order completely?'
    },
    CONFIRM_REJECT_ORDER_SECOND_MSG: {
        id: 'CONFIRM_REJECT_ORDER_SECOND_MSG',
        description: 'CONFIRM_REJECT_ORDER_SECOND_MSG',
        defaultMessage: 'By clicking on “Yes, cancel the order” your order will be canceled once we have reviewed your request'
    },
    CONFIRM_REJECT_ORDER_CANCEL_BUTTON: {
        id: 'CONFIRM_REJECT_ORDER_CANCEL_BUTTON',
        description: 'CONFIRM_REJECT_ORDER_CANCEL_BUTTON',
        defaultMessage: 'No, keep the order'
    },
    CONFIRM_REJECT_ORDER_SAVE_BUTTON: {
        id: 'CONFIRM_REJECT_ORDER_SAVE_BUTTON',
        description: 'CONFIRM_REJECT_ORDER_SAVE_BUTTON',
        defaultMessage: 'Yes, cancel the order'
    },

    CONFIRM_DISCARD_CHANGES_EDIT_ORDER: {
        id: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER',
        description: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER',
        defaultMessage: 'Are you sure you want to discard the changes?'
    },
    CONFIRM_DISCARD_CHANGES_EDIT_ORDER_CANCEL_BUTTON: {
        id: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER_CANCEL_BUTTON',
        description: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER_CANCEL_BUTTON',
        defaultMessage: 'No, keep changes'
    },
    CONFIRM_DISCARD_CHANGES_EDIT_ORDER_SAVE_BUTTON: {
        id: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER_SAVE_BUTTON',
        description: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER_SAVE_BUTTON',
        defaultMessage: 'Yes, discard changes'
    },
    CONFIRM_SAVE_CHANGES: {
        id: 'CONFIRM_SAVE_CHANGES',
        description: 'CONFIRM_SAVE_CHANGES',
        defaultMessage: 'Are you sure you want to save the changes?'
    },
    CONFIRM_SAVE_CHANGES_SECOND_MSG: {
        id: 'CONFIRM_SAVE_CHANGES_SECOND_MSG',
        description: 'CONFIRM_SAVE_CHANGES_SECOND_MSG',
        defaultMessage: 'By clicking "Yes, save changes" these changes will be applied once we have reviewed your request.'
    },
    CONFIRM_SAVE_CHANGES_EDIT_ORDER_CANCEL_BUTTON: {
        id: 'CONFIRM_SAVE_CHANGES_EDIT_ORDER_CANCEL_BUTTON',
        description: 'CONFIRM_SAVE_CHANGES_EDIT_ORDER_CANCEL_BUTTON',
        defaultMessage: 'No, keep editing'
    },
    CONFIRM_SAVE_CHANGES_EDIT_ORDER_SAVE_BUTTON: {
        id: 'CONFIRM_SAVE_CHANGES_EDIT_ORDER_SAVE_BUTTON',
        description: 'CONFIRM_SAVE_CHANGES_EDIT_ORDER_SAVE_BUTTON',
        defaultMessage: 'Yes, save changes'
    },
    order_in_process_of_modification_warning: {
        id: 'order_in_process_of_modification_warning',
        description: 'order_in_process_of_modification_warning',
        defaultMessage: 'Order in process of modification. Information on dates and status of materials may not be accurate.'
    },
    select_global_date: {
        id: 'select_global_date',
        description: 'select_global_date',
        defaultMessage: 'Global date'
    },
    select_individual_date: {
        id: 'select_individual_date',
        description: 'select_individual_date',
        defaultMessage: 'Individual date'
    }
})

class NewOrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showInvoiceModal: false,
            editOrderMode: false,
            orderWeightError: false,
            showShippingsModal: false,
            shouldRenderWalkthrough: false,
            showConfigBathModal: false,
            currentOrderLine: null,
        }
        this.getOrderInvoiceDownloadUrl = this.getOrderInvoiceDownloadUrl.bind(this)
    }

    componentDidMount() {
        this.fetchOrderDetailsFn()
        this.props.setIsOrderHistoryScreen(true)
    }

    fetchOrderDetailsFn() {
        this.props.fetchOrderDetails(this.props.reservationId).then(() => {
            this.setState({ shouldRenderWalkthrough: true })
            this.props.fetchOrderToEdit(this.props.order.orderId)
        })
    }

    componentWillUnmount() {
        this.props.setIsOrderHistoryScreen(false)
    }

    getOrderInvoiceDownloadUrl(invoiceNum) {
        // return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum + '/?authToken=' + this.props.token
        return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum
    }

    showShippingsModal = () => {
        this.setState({showShippingsModal: true})
    }

    closeShippingsModal = () => {
        this.setState({showShippingsModal: false})
    }

    showInvoiceModal = () => {
        this.setState({ showInvoiceModal: true })
    }

    closeInvoiceModal = () => {
        this.setState({ showInvoiceModal: false })
    }

    writeOrderBody = printWindow => {
        printWindow.document.write('<html><head>')
        printWindow.document.write(document.getElementsByTagName('head')[0].innerHTML)
        printWindow.document.write('</head><body class="order__shipping__details change-elements-print p-4">')
        printWindow.document.write(this.orderScreenBody.innerHTML)
        printWindow.document.write('</body></html>')

        return Promise.resolve(printWindow)
    }

    // Download or print order
    downloadPdfOrderBody = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600')
        setTimeout(() => {
            this.writeOrderBody(printWindow).then(printWindow => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                    printWindow.close()
                }, 2000)
            })
        }, 1000)

        return false
    }

    // Confirm reject the whole order
    confirmRejectOrder = (reservationId, orderId, onRejectOrder, intl) => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }), {
            isV2: true,
            advise: intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER_SECOND_MSG' }),
            textCancel: intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER_CANCEL_BUTTON' }),
            textOk: intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER_SAVE_BUTTON' })
        }).then(
            confirm => {
                return onRejectOrder(reservationId, orderId).then(() => {
                    this.fetchOrderDetailsFn()
                })
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    showPrice = (isEmserUser, order) => !isEmserUser && !CUT_TO_SIZE_MATERIALS.includes(String(order.orderLines.material_id))

    displayPrice = (isEmserUser, order, orderLine) => {
        if (this.showPrice(isEmserUser, order)) {
            return (
                parseInt(orderLine.import) === 0 ? <Translation id="not_available" defaultMessage="Not available" /> : orderLine.import + ' ' +  orderLine.coin
            )
        }
        return <Translation id="not_available" defaultMessage="Not available" />
    }

    resetChangeInPositions() {
        this.props.changeOrderInEdition(this.props.orderToEdit)
    }

    confirmDiscardChanges = intl => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER' }), {
            isV2: true,
            textCancel: intl.formatMessage({ id: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER_CANCEL_BUTTON' }),
            textOk: intl.formatMessage({ id: 'CONFIRM_DISCARD_CHANGES_EDIT_ORDER_SAVE_BUTTON' })
        }).then(
            (confirm) => {
                this.resetChangeInPositions()
                return this.setState({ editOrderMode: !this.state.editOrderMode })
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    confirmSaveChanges = (orderInEdition, orderId, reservationId, onSaveChange, intl) => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_SAVE_CHANGES' }), {
            isV2: true,
            advise: intl.formatMessage({ id: 'CONFIRM_SAVE_CHANGES_SECOND_MSG' }),
            textCancel: intl.formatMessage({ id: 'CONFIRM_SAVE_CHANGES_EDIT_ORDER_CANCEL_BUTTON' }),
            textOk: intl.formatMessage({ id: 'CONFIRM_SAVE_CHANGES_EDIT_ORDER_SAVE_BUTTON' })
        }).then(
            confirm => {
                return onSaveChange(orderInEdition, orderId, reservationId).then(() => {
                    this.fetchOrderDetailsFn()
                    this.setState({ editOrderMode: !this.state.editOrderMode })
                    }
                )
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    onChangeValue = (positionId, input, value) => {
        let changedOrderInEdition = this.props.orderInEdition.map(item => {
            if (item.position === positionId) {
                switch (input) {
                    case 'rejected':
                        return { ...this.props.orderInEdition.find(ele => ele.position === item.position), rejected: !value }
                    case 'qty':
                        if (isNaN(value) || value === '') value = 0
                        return { ...item, quantity: value }
                    case 'preferredDeliveryDate':
                        return { ...item, preferredDeliveryDate: value }
                    default:
                        return item
                }
            } else return item
        })

        this.props.changeOrderInEdition(changedOrderInEdition)

        if (this.props.canEditOrderMinMax()) {
            this.setState({ orderWeightError: false })
        } else {
            this.setState({ orderWeightError: true })
        }
    }

    canEditPositon = position => {
        if (!this.props.orderToEdit) {
            return false
        }

        let positionToEdit = this.props.orderToEdit.find(ele => ele.position === position)

        if (!positionToEdit) {
            return false
        }

        return !positionToEdit.rejected
    }

    thicknessFormatIntl = orderLine => {
        if (!orderLine.product.thickness) return null
        return this.props.replaceDotWithComma(orderLine.product.thickness, this.props.locale) + ' cm'
    }

    finishFormatIntl = (orderLine, intl) => {
        if (!orderLine.product.finish) return null
        return intl.formatMessage({ id: orderLine.product.finish })
    }

    zaddonFormatIntl = (orderLine, intl) => {
        if (orderLine.product.zaddon !== 'MAL') return null
        return intl.formatMessage({ id: 'MAL' })
    }

    productFormatIntl = orderLine => {
        if (!orderLine.product.format) return null
        return this.props.adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })
    }

    showBtnToDetails = orderLine => {
        if (orderLine.delivery_id && !this.props.isIkeaCenter) return true
        return false
    }

    handleBtnToDetailsOnClick = orderLine => {
        this.props.redirect(
            this.props.intl
                .formatMessage({ id: 'ROUTE_SHIPPING_DETAILS' })
                .replace(':filter', 'view-shipping')
                .replace(':deliveryId', orderLine.delivery_id)
        )
    }

    deliveryStatusLinksEstimatedOn = orderLine => {
        return (
            <div className="shipping__date__url">
                <div className="shipping__date__url--content">
                    <div className="date-column">
                        <span>
                            <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                        </span>
                        :&nbsp;
                        <span>
                            {moment(orderLine.status.date).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(orderLine.status.date).format('L')}
                        </span>
                    </div>
                    {this.showBtnToDetails(orderLine) && (
                        <div className="delivery-id-column">
                            <span>
                                <Translation id="shipment" defaultMessage="Shipment" />
                            </span>
                            :&nbsp;
                            <span>
                                <button
                                    onClick={() => {
                                        this.handleBtnToDetailsOnClick(orderLine)
                                    }}
                                >
                                    {orderLine.delivery_id}
                                </button>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    deliveryStatusLinksDeliveredOn = orderLine => {
        return (
            <div className="shipping__date__url">
                <div className="shipping__date__url--content">
                    <div className="date-column">
                        <span>
                            <Translation id="delivered_on" defaultMessage="Delivered on" />
                        </span>
                        :&nbsp;
                        <span>
                            {moment(orderLine.status.date).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(orderLine.status.date).format('L')}
                        </span>
                    </div>
                    {this.showBtnToDetails(orderLine) && (
                        <div className="delivery-id-column">
                            <span>
                                <Translation id="shipment" defaultMessage="Shipment" />
                            </span>
                            :&nbsp;
                            <span>
                                <button
                                    onClick={() => {
                                        this.handleBtnToDetailsOnClick(orderLine)
                                    }}
                                >
                                    {orderLine.delivery_id}
                                </button>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    deliveryStatusLinksPartially = (orderLine, limitDeliveries = 0) => {
        let deliveries = orderLine.status.deliveries
        let deliveriesEntries = deliveries && Object.entries(deliveries)

        return (
            <div className="shipping__date__url__partially">
                {(limitDeliveries ? deliveriesEntries.slice(0 , limitDeliveries) : deliveriesEntries).map((delivery, index) => {
                    return (
                        <div className="shipping__date__url__partially--container">
                            <div>
                                {delivery[1].status === 'TREATED_COMPLETELLY' ? (
                                    <React.Fragment>
                                        <span>
                                            <Translation id="delivered_on" defaultMessage="Delivered on" />
                                        </span>
                                        :&nbsp;
                                        <span>
                                            {moment(delivery[1].date).isSame(moment(), 'day') ? (
                                                <Translation id="today" defaultMessage="Today" />
                                            ) : (
                                                moment(delivery[1].date).format('L')
                                            )}
                                        </span>
                                    </React.Fragment>
                                ) : delivery[1].status === 'TREATED_PARTIALLY' ? (
                                    <React.Fragment>
                                        <span>
                                            <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                                        </span>
                                        :&nbsp;
                                        <span>
                                            {moment(delivery[1].date).isSame(moment(), 'day') ? (
                                                <Translation id="today" defaultMessage="Today" />
                                            ) : (
                                                moment(delivery[1].date).format('L')
                                            )}
                                        </span>
                                    </React.Fragment>
                                ) : delivery[1].status === 'NOT_TREATED' && delivery[1].date && delivery[1].date !== null ? (
                                    <React.Fragment>
                                        <span>
                                            <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                                        </span>
                                        :&nbsp;
                                        <span>
                                            {moment(delivery[1].date).isSame(moment(), 'day') ? (
                                                <Translation id="today" defaultMessage="Today" />
                                            ) : (
                                                moment(delivery[1].date).format('L')
                                            )}
                                        </span>
                                    </React.Fragment>
                                ) : delivery[1].status === 'NOT_TREATED' && (!delivery[1].date || delivery[1].date === null) ? (
                                    <div className="shipping__status__gray">
                                        <div>
                                            <ShippingTruckIcon />
                                        </div>
                                        <div>
                                            <span>
                                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {delivery[0] && (
                                <div className="shipping__date__url__partially--shipment">
                                    <span>
                                        <Translation id="shipment" defaultMessage="Shipment" />
                                    </span>
                                    :&nbsp;
                                    <span>
                                        <button
                                            onClick={() => {
                                                if (!this.props.isIkeaCenter) {
                                                    this.props.redirect(
                                                        this.props.intl
                                                        .formatMessage({ id: 'ROUTE_SHIPPING_DETAILS' })
                                                        .replace(':filter', 'view-shipping')
                                                        .replace(':deliveryId', delivery[0])
                                                    )
                                                }
                                            }}
                                        >
                                            {delivery[0]}
                                        </button>                                     
                                    </span>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }

    deliveryStatusLogic = (orderLine) => {
        let status = orderLine.status && orderLine.status.status
        let date = orderLine.status && orderLine.status.date
        let deliveryId = orderLine.delivery_id
        let deliveries = orderLine.status && orderLine.status.deliveries
        let deliveriesEntries = deliveries && Object.entries(deliveries)
        let limitDeliveries = 3
        const isQuickShip = !!(orderLine.cut_to_size && orderLine.cut_to_size.configurable && orderLine.cut_to_size.configurable.thirdParty)



        // if (isQuickShip && date == null) {
        if (isQuickShip
            && status != OrderLineStatus.Order_cancelled
            && status != OrderLineStatus.Delivered
            && status != OrderLineStatus.Partially_delivered
        ) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                                <span>
                                    <FormattedHTMLMessage
                                        id="estimated_delivery_on_4_weeks"
                                        defaultMessage="Estimated delivery on <b>2-4 weeks</b>"
                                    />
                                </span>
                        </div>
                    </div>
                </div>
            )
        }

        if (status === OrderLineStatus.Created && date == null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        if (status === OrderLineStatus.Created && date !== null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(date).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(date).format('L')}</span>
                        </div>
                    </div>
                    {date && deliveryId && this.deliveryStatusLinksEstimatedOn(orderLine)}
                </div>
            )
        }

        if (status === OrderLineStatus.Approved && date == null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        if (status === OrderLineStatus.Approved && date !== null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(date).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(date).format('L')}</span>
                        </div>
                    </div>
                    {date && deliveryId && this.deliveryStatusLinksEstimatedOn(orderLine)}
                </div>
            )
        }

        if (status === OrderLineStatus.Pending && date == null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        if (status === OrderLineStatus.Pending && date !== null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(date).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(date).format('L')}</span>
                        </div>
                    </div>
                    {date && deliveryId && this.deliveryStatusLinksEstimatedOn(orderLine)}
                </div>
            )
        }

        if (status === OrderLineStatus.In_process && date == null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__gray">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="no_planned_delivery" defaultMessage="No planned delivery" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        if (status === OrderLineStatus.In_process && date !== null) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>{moment(date).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(date).format('L')}</span>
                        </div>
                    </div>
                    {date && deliveryId && this.deliveryStatusLinksEstimatedOn(orderLine)}
                </div>
            )
        }

        if (status ===  OrderLineStatus.Delivered) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__green">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="entregado" defaultMessage="Delivered" />
                            </span>
                        </div>
                    </div>
                    {date && deliveryId && this.deliveryStatusLinksDeliveredOn(orderLine)}
                </div>
            )
        }

        if (status === OrderLineStatus.Partially_delivered) {
            return (
                <div className="shipping__status__content">
                    <div className="shipping__status__blue">
                        <div>
                            <ShippingTruckIcon />
                        </div>
                        <div>
                            <span>
                                <Translation id="order_status_partially_delivered" defaultMessage="Partially delivered" />
                            </span>
                        </div>
                    </div>
                    {this.deliveryStatusLinksPartially(orderLine, limitDeliveries)}

                    {deliveriesEntries.length > limitDeliveries && (
                        <div className="partially__see__shippings">
                            <button onClick={this.showShippingsModal}>
                                <span>
                                    <Translation id="view_all_deliveries" defaultMessage="View all deliveries" />
                                </span>
                            </button>
                        </div>
                    )}

                    {/* View all shippings Modal */}
                    {this.state.showShippingsModal && (
                        <Modal
                            title={<Translation id="delivery_information" defaultMessage="Delivery information" />}
                            onClose={this.closeShippingsModal}
                            customClass="delivery__info__modal"
                        >
                            <div>
                                <div>
                                    <span className="brand">{orderLine.product && orderLine.product.brand ? capitalizeFirstLetter(orderLine.product.brand) + '&reg;' : ''}</span>
                                </div>
                                <div>
                                    <span className="product">
                                        {orderLine.product ? (
                                            <ProductDisplayName product={orderLine.product} />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="delivery-divider-x"></div>
                            {this.deliveryStatusLinksPartially(orderLine)}
                        </Modal>
                    )}
                </div>
            )
        }
    }

    cutToSizeDescriptionBlock = orderLine => {
        const cts = orderLine.cut_to_size
        const measures = this.props.getMeasure(cts)
        const materialId =  ElaborationOptionsMapById[orderLine.material_id] ? this.props.intl.formatMessage(ElaborationOptionsMapById[orderLine.material_id].name) : orderLine.material_id


        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <img src={cts.thumb} alt="Cut to size" />
                    </div>

                    <div className="product-description-table">

                        <div>
                            <span className="brand">{capitalizeFirstLetter(cts.brand)} &reg;</span>
                        </div>

                        <div className="product">
                            <span>
                                {capitalizeFirstLetter(cts.colorName)}
                            </span>
                        </div>

                        <div>
                            <span className="technical">{materialId}</span>
                        </div>

                        {cts.elaboration === 'CUSTOM_MOBILIARIO' && (
                            <div className="cts-custom-mobiliario">
                                <span>{this.props.intl.formatMessage(CutToSizeMaps.Messages[cts.elaboration])}</span>&nbsp; (
                                <span>
                                    {!!cts.samePattern ? <Translation id="extension" defaultMessage="Extension" /> : <Translation id="main_piece" defaultMessage="Main piece" />}
                                </span>
                                )
                            </div>
                        )}

                        {measures.format && (
                            <div className="cts-format">
                                <span>
                                    <Translation id="format" defaultMessage="Format" />
                                </span>
                                :&nbsp;
                                <span>{measures.format}</span>
                            </div>
                        )}

                        <div className="cts-finish">
                            <span>
                                <Translation id="finish" defaultMessage="Finish" />
                            </span>
                            :&nbsp;
                            <span>{this.props.intl.formatMessage({ id: cts.finish })}</span>
                        </div>

                        {cts.addon === 'MAL' && (
                            <div className="cts-addon">
                                <span>
                                    {this.props.intl.formatMessage({id:'MAL'})}
                                </span>
                            </div>
                        )}

                        <div className="cts-thickness">
                            <span>
                                <Translation id="thickness" defaultMessage="Thickness" />
                            </span>
                            :&nbsp;
                            <span>
                                <DisplayThickness thickness={cts.thickness} string />
                            </span>
                        </div>

                        {measures.radius && (
                            <div className="cts-radius">
                                <span>
                                    <Translation id="radius" defaultMessage="Radius" />
                                </span>
                                :&nbsp;
                                <span>{measures.radius}</span>
                            </div>
                        )}

                        {cts.edge && (
                            <div className="cts-edge">
                                <span>
                                    <Translation id="edge" defaultMessage="Edge" />
                                </span>
                                :&nbsp;
                                <span>{this.props.intl.formatMessage(CutToSizeMaps.Messages[cts.edge])}</span>
                            </div>
                        )}

                        {cts.bevel && (
                            <div className="cts-bevel">
                                <span>
                                    <Translation id="bevel" defaultMessage="Bevel" />
                                </span>
                                :&nbsp;
                                <span>{this.props.intl.formatMessage(CutToSizeMaps.Messages[cts.bevel])}</span>
                            </div>
                        )}

                        {cts.packaging && (
                            <div className="cts-packaging">
                                <span>
                                    <Translation id="packaging" defaultMessage="Packaging" />
                                </span>
                                :&nbsp;
                                <span>{this.props.intl.formatMessage(CutToSizeMaps.Messages[cts.packaging])}</span>
                            </div>
                        )}

                        {cts.samePattern === 'YES' && (
                            <div className="cts-pattern">
                                <span>
                                    <Translation id="same_pattern" />
                                </span>
                            </div>
                        )}
                       
                        {cts.shape && (
                            <div className="cts-shape">
                                <span>
                                    <Translation id="shape" defaultMessage="Shape" />
                                </span>
                                :&nbsp;
                                <span>{this.props.intl.formatMessage(CutToSizeMaps.Messages[cts.shape])}</span>
                            </div>
                        )}

                        {measures.diameter && (
                            <div className="cts-diameter">
                                <span>
                                    <Translation id="diameter" defaultMessage="Diameter" />
                                </span>
                                :&nbsp;
                                <span>{measures.diameter}</span>
                            </div>
                        )}
                    
                        <div>
                            <span className="qty">
                                <Translation id="quantity" defaultMessage="Quantity" />:&nbsp;
                                {orderLine.reservation_quantity ? (
                                    parseInt(orderLine.reservation_quantity) === 0 ? (
                                        orderLine.quantity ? (
                                            <React.Fragment>{parseInt(orderLine.quantity)}</React.Fragment>
                                        ) : (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        )
                                    ) : (
                                        <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                    )
                                ) : (
                                    <Translation id="not_available" defaultMessage="Not available" />
                                )}
                            </span>
                        </div>
                        <div className="price">
                            <span>
                                <Translation id="price" defaultMessage="Price" />
                            </span>
                            :&nbsp;
                            <span>
                                {this.displayPrice(this.props.isEmserUser, this.props.order, orderLine)}
                            </span>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {this.additionalInfoBlock(orderLine)}
                    <div className="order-divider-x"></div>
                    {this.deliveryInformationBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    handleOpenConfigBathModal(orderLine) {
        this.setState({ showConfigBathModal: true })
        this.setState({ currentOrderLine: orderLine })
    }

    handleCloseConfigBathModal() {
        this.setState({ showConfigBathModal: false })
        this.setState({ currentOrderLine: null })
    }

    descriptionBlockConfigBath = orderLine => {
        const cts = orderLine.cut_to_size
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        {!!cts.configurable.thirdParty && <QuickShipIcon style={{position:"absolute", top:"10%"}}/>}
                        <img src={cts.thumb} alt={getConfigLineName(cts.configurable)} className="is-img-configurable"/>
                        <ConfigBathIndicator />
                    </div>
                    <div className="product-description-table">
                        <div>
                            <span className="brand">{capitalizeFirstLetter(cts.configurable.material.brand)} &reg;</span>
                        </div>
                        <div className="product">
                            <span className="product-title">
                                {getConfigLineName(cts.configurable)}
                            </span>
                        </div>
                        <div>
                            <span className="technical">
                                <Translation id="customized" defaultMessage="Custom" />
                            </span>
                        </div>
                        <div>
                            <span className="qty">
                                <Translation id="quantity" defaultMessage="Quantity" />:&nbsp;
                                {orderLine.reservation_quantity ? (
                                    parseInt(orderLine.reservation_quantity) === 0 ? (
                                        orderLine.quantity ? (
                                            <React.Fragment>{parseInt(orderLine.quantity)}</React.Fragment>
                                        ) : (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        )
                                    ) : (
                                        <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                    )
                                ) : (
                                    <Translation id="not_available" defaultMessage="Not available" />
                                )}
                            </span>
                        </div>
                        <div className="price">
                            <span>
                                <Translation id="price" defaultMessage="Price" />
                            </span>
                            :&nbsp;
                            <span>
                                {this.displayPrice(this.props.isEmserUser, this.props.order, orderLine)}
                            </span>
                        </div>
                        <div className="configurable-bath-btn-orders">
                            <button onClick={() => this.handleOpenConfigBathModal(orderLine)}>
                                <Translation id="view_customization_details" defaultMessage="View customization details" />
                            </button>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {this.additionalInfoBlock(orderLine)}
                    <div className="order-divider-x"></div>
                    {this.deliveryInformationBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    descriptionBlockException = orderLine => {
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <ProductImage product={null} />
                    </div>
                    <div className="product-description-table">
                        {orderLine.short_text && (
                            <div>
                                <span className="product">
                                    <span>{capitalizeFirstLetter(orderLine.short_text)}</span>
                                </span>
                            </div>
                        )}
                        {orderLine.material_id && (
                            <div>
                                <span className="technical">{orderLine.material_id}</span>
                            </div>
                        )}
                        {orderLine.lot_id && (
                            <div className="lot-id">
                                <span>
                                    <Translation id="lot_id" defaultMessage="Lot ID" />
                                    :&nbsp;
                                </span>
                                <span>{orderLine.lot_id}</span>
                            </div>
                        )}
                        <div>
                            <span className="qty">
                                <Translation id="quantity" defaultMessage="Quantity" />:&nbsp;
                                {orderLine.reservation_quantity ? (
                                    parseInt(orderLine.reservation_quantity) === 0 ? (
                                        orderLine.quantity ? (
                                            <React.Fragment>{parseInt(orderLine.quantity)}</React.Fragment>
                                        ) : (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        )
                                    ) : (
                                        <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                    )
                                ) : (
                                    <Translation id="not_available" defaultMessage="Not available" />
                                )}
                            </span>
                        </div>
                        <div className="price">
                            <span>
                                <Translation id="price" defaultMessage="Price" />
                            </span>
                            :&nbsp;
                            <span>
                                {this.displayPrice(this.props.isEmserUser, this.props.order, orderLine)}
                            </span>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {this.additionalInfoBlock(orderLine)}
                    <div className="order-divider-x"></div>
                    {this.deliveryInformationBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    descriptionBlock = orderLine => {
        return (
            <React.Fragment>
                {orderLine.product ? (
                    <React.Fragment>
                        <div className="order__shipping__details__table--description">
                            <div className="product-img">
                                <ProductImage product={orderLine.product} />
                            </div>
                            <div className="product-description-table">
                                {orderLine.outlet_quality && <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />}
                                <div>
                                    <span className="brand">{capitalizeFirstLetter(orderLine.product.brand)} &reg;</span>
                                </div>
                                <div>
                                    <span className="product">
                                        <ProductDisplayName product={orderLine.product} />
                                    </span>
                                </div>
                                <div>
                                    <span className="technical">
                                        {addForwardSlash(
                                            orderLine.product.productId,
                                            this.productFormatIntl(orderLine),
                                            this.finishFormatIntl(orderLine, this.props.intl),
                                            this.thicknessFormatIntl(orderLine),
                                            this.zaddonFormatIntl(orderLine, this.props.intl)
                                        )}
                                    </span>
                                </div>
                                {orderLine.lot_id && (
                                    <div className="lot-id">
                                        <span>
                                            <Translation id="lot_id" defaultMessage="Lot ID" />
                                            :&nbsp;
                                        </span>
                                        <span>{orderLine.lot_id}</span>
                                    </div>
                                )}
                                <div className="qty">
                                    <span>
                                        <Translation id="quantity" defaultMessage="Quantity" />
                                        :&nbsp;
                                    </span>
                                    <span>
                                        {orderLine.reservation_quantity ? (
                                            parseInt(orderLine.reservation_quantity) === 0 ? (
                                                orderLine.quantity ? (
                                                    <React.Fragment>{parseInt(orderLine.quantity)}</React.Fragment>
                                                ) : (
                                                    <Translation id="not_available" defaultMessage="Not available" />
                                                )
                                            ) : (
                                                <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                            )
                                        ) : (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        )}
                                    </span>
                                </div>
                                <div className="price">
                                    <span>
                                        <Translation id="price" defaultMessage="Price" />
                                    </span>
                                    :&nbsp;
                                    <span>
                                        {this.displayPrice(this.props.isEmserUser, this.props.order, orderLine)}
                                    </span>
                                </div>
                                {/* For tablet screen */}
                                <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                            </div>
                        </div>
                        {/* For mobile screen */}
                        <div className="d-block-only-mobile">
                            <div className="order-divider-x"></div>
                            {this.additionalInfoBlock(orderLine)}
                            <div className="order-divider-x"></div>
                            {this.deliveryInformationBlock(orderLine)}
                        </div>
                    </React.Fragment>
                ) : (
                    this.descriptionBlockException(orderLine)
                )}
            </React.Fragment>
        )
    }

    additionalInfoBlock = orderLine => {
        return (
            <div className="order__shipping__details__table--additional">
                <div>
                    {
                        orderLine.segmentation && <LineSegmentation line={orderLine} showAsList />
                    }
                </div>
                <div className="comments">
                    <span>
                        <Translation id="comments" defaultMessage="Comments" />
                        :&nbsp;
                    </span>
                    <span>{orderLine.comments ? orderLine.comments : '--'}</span>
                </div>
                <div>
                    <span>
                        <Translation id="prefered_delivery_date" defaultMessage="Preferred delivery date" />
                        :&nbsp;
                    </span>
                    <span>
                        {orderLine.requested_delivery_date ? (
                            moment(orderLine.requested_delivery_date).isSame(moment(), 'day') ? (
                                <Translation id="today" defaultMessage="Today" />
                            ) : (
                                moment(orderLine.requested_delivery_date).format('L')
                            )
                        ) : (
                            '--'
                        )}
                    </span>
                </div>
            </div>
        )
    }

    deliveryInformationBlock = orderLine => {
        return (
            <React.Fragment>
                {orderLine && (
                    <div className="order__shipping__details__table--shippings">
                        <ModifiedLineIndicator orderLine={orderLine} />
                        {this.deliveryStatusLogic(orderLine)}
                    </div>
                )}
            </React.Fragment>
        )
    }

    render() {
        const {
            order,
            orderStatusMap,
            reservationId,
            orderInEdition,
            saveOrderToEdit,
            rejectOrder,
            orderToEdit,
            isLoading,
            shippingAddressesByUser,
            invoices,
            getFileByUrl,
            intl,
            isEmserUser,
            adaptedFormat,
            getMeasure,
            locale,
            replaceDotWithComma,
            impersonatedBy
        } = this.props

        const editOrderModeOnClick = () => {
            this.setState({ editOrderMode: !this.state.editOrderMode })
        }

        const headerButtonsGroup = (
            <React.Fragment>
                {order && order.orderLines && (
                    <div>
                        <button onClick={this.downloadPdfOrderBody} gtm-label="ec_historial_pedidos_imprimir_detalle">
                            <PrintIcon />
                            <span>
                                <Translation id="print_order_details" defaultMessage="Print my order details" />
                            </span>
                        </button>
                    </div>
                )}
                {invoices && invoices.length > 0 && (
                    <div className="download-invoice" gtm-label="ec_historial_pedidos_descargar_factura">
                        <button onClick={this.showInvoiceModal}>
                            <i class="fas fa-download"></i>
                            <span>
                                <Translation id="download_invoices" defaultMessage="Download invoices" />
                            </span>
                        </button>
                    </div>
                )}
                {order && order.ecosentino_is_deletable && order.reservationStatus !== 'PENDING_CANCELLATION' && order.reservationStatus !== 'PENDING_CHANGES' && (
                    <div>
                        <button onClick={() => this.confirmRejectOrder(order.reservationId, order.orderId, rejectOrder, intl)}>
                            <i class="fas fa-trash-alt"></i>
                            <span>
                                <Translation id="cancel_complete_order" defaultMessage="Cancel complete order" />
                            </span>
                        </button>
                    </div>
                )}
            </React.Fragment>
        )

        const timelineOrderStatusColor = () => {
            let orderStatus = order && order.orderStatusV3.status
            if (orderStatus === OrderStatus.Created) return '--position-created'
            if (orderStatus === OrderStatus.Approved) return '--position-confirmed'
            if (orderStatus === OrderStatus.Pending) return '--position-pending'
            if (orderStatus === OrderStatus.In_process) return '--position-in-process'
            if (orderStatus === OrderStatus.Partially_delivered) return '--position-partially-delivered'
            if (orderStatus === OrderStatus.Delivered) return '--position-delivered'
            if (orderStatus === OrderStatus.Order_cancelled) return '--position-cancelled'
        }

        const timelineOrderStatusMsg = (
            <div className={`timeline__order__status ${order && (order.ecosentino_status === 'PENDING_CHANGES' || order.reservationStatus === 'PENDING_CANCELLATION') ? '--position-changes' : timelineOrderStatusColor()}`}>
                <span className="order__status--dot"></span>
                <span className="order__status--status"><Translation id="status" defaultMessage="Status" /></span>:&nbsp;
                <span>
                    {order && order.reservationStatus === 'PENDING_CANCELLATION' ? (
                        <span className="order__status--text">{intl.formatMessage({ id: orderStatusMap[order.reservationStatus].textToShow })}</span>
                    ) : order && order.ecosentino_status === 'PENDING_CHANGES' ? (
                        <span className="order__status--text">{intl.formatMessage({ id: orderStatusMap[order.ecosentino_status].textToShow })}</span>
                    ) : order && order.orderStatusV3.status ? (
                        <span className="order__status--text">{intl.formatMessage({ id: orderStatusMap[order.orderStatusV3.status].textToShow })}</span>
                    ) : (
                        <span>--</span>
                    )}
                </span>
            </div>
        )

        return (
            <div className="box-profile row">
                {/* Sidebar */}
                <div className="profile-container-box col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>

                <div className="col-12 col-xl-9 order__shipping__details p-0">
                    {isLoading && <Spinner isLoading={isLoading} />}

                    {order && (
                        <div ref={element => (this.orderScreenBody = element)} className="position-relative">
                            {/* Header */}
                            <header className="order__shipping__details__header">
                                <div className="order__shipping__details__header--goback">
                                    <i class="fas fa-chevron-left"></i>
                                    <LocalizedLink routeId="ROUTE_PROFILE" params={{ filter: 'orders', open: '' }}>
                                        <span>
                                            <Translation id="return_order_history" defaultMessage="Return to order history" />
                                        </span>
                                    </LocalizedLink>
                                </div>
                                <div className="order__shipping__details__header--title">
                                    <div className="main-title">
                                        <h1>
                                            <Translation id="order_detail" defaultMessage="Order detail" />
                                            &nbsp;
                                            {order.orderId ? <span>{order.orderId}</span> : <span>--</span>}
                                        </h1>
                                    </div>
                                    {/* Desktop screen */}
                                    <div className="d-none d-xl-block details-btn-group">
                                        <div className="desktop-screen">{headerButtonsGroup}</div>
                                    </div>
                                </div>
                                <div className="d-none d-xl-block">
                                    <div className="order-divider-x"></div>
                                </div>

                                {timelineOrderStatusMsg}

                                <div>
                                    <Timeline
                                      isHorizontal={true}
                                      delivery={order.newTimeline}
                                      orderStatusMap={orderStatusMap}
                                      withDates={true}
                                    />
                                </div>

                                {/* Responsive screen */}
                                <div className="d-block d-xl-none">
                                    <div className="order-divider-x"></div>
                                </div>

                                <div className="d-block d-xl-none details-btn-group">
                                    <div className="mobile-screen">{headerButtonsGroup}</div>
                                </div>
                            </header>

                            {/* Box resume */}
                            <h3 className="order__shipping__boxresume__title">
                                <Translation id="resume" defaultMessage="Resume" />
                            </h3>
                            <section className="order__shipping__details__boxresume">
                                <div className="order__shipping__details__boxresume--title">
                                    <h4>
                                        <Translation id="order_number" defaultMessage="Order number" />
                                        :&nbsp;
                                        {order.orderId ? <span>{order.orderId}</span> : <span>--</span>}
                                    </h4>
                                </div>
                                <div className="order-divider-x"></div>
                                <div className="order__shipping__details__boxresume--content">
                                    <div>
                                        <div>
                                            <span>
                                                <Translation id="reservation_code" defaultMessage="Reservation code" />
                                                :&nbsp;
                                            </span>
                                            {reservationId ? <span>{reservationId}</span> : <span>--</span>}
                                        </div>

                                        <div>
                                            <span>
                                                <Translation id="order_date" defaultMessage="Order date" />
                                                :&nbsp;
                                            </span>
                                            {order.orderCreationDate ? (
                                                moment(order.orderCreationDate).isSame(moment(), 'day') ? (
                                                    <span>
                                                        <Translation id="today" defaultMessage="Today" />
                                                    </span>
                                                ) : (
                                                    <span>{moment(order.orderCreationDate).format('L')}</span>
                                                )
                                            ) : order.createdDate ? (
                                                moment(order.createdDate).isSame(moment(), 'day') ? (
                                                    <span>
                                                        <Translation id="today" defaultMessage="Today" />
                                                    </span>
                                                ) : (
                                                    <span>{moment(order.createdDate).format('L')}</span>
                                                )
                                            ) : (
                                                <span>--</span>
                                            )}
                                        </div>

                                        {/* <div>
                                            <span>
                                                <Translation id="prefered_delivery_date" defaultMessage="Preferred delivery date" />
                                                :&nbsp;
                                            </span>
                                            {order.preferredDeliveryDate ? (
                                                moment(order.preferredDeliveryDate).isSame(moment(), 'day') ? (
                                                    <span>
                                                        <Translation id="today" defaultMessage="Today" />
                                                    </span>
                                                ) : (
                                                    <span>{moment(order.preferredDeliveryDate).format('L')}</span>
                                                )
                                            ) : (
                                                <span>--</span>
                                            )}
                                        </div> */}
                                        <div>
                                            <span>
                                                <Translation id="shipping_address" defaultMessage="Shipping address" />
                                                :&nbsp;
                                            </span>
                                            <span>
                                                <DisplayShippingAddress
                                                    shippingAddress={order.shippingAddress}
                                                    shippingAddressId={order.shippingAddressId}
                                                    shippingAddressesByUser={shippingAddressesByUser}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <span>
                                                <Translation id="number_of_products_in_order" defaultMessage="Number of products in the order" />
                                                :&nbsp;
                                            </span>
                                            {order.orderLines ? <span>{order.orderLines.length}</span> : <span>--</span>}
                                        </div>
                                        <div>
                                            <span>
                                                <Translation id="order_type" defaultMessage="Order Type" />
                                                :&nbsp;
                                            </span>
                                            {order.shippingType ? (
                                                <span>
                                                    <Translation id={order.shippingType} />
                                                </span>
                                            ) : (
                                                <span>--</span>
                                            )}
                                        </div>
                                        <div>
                                            <span>
                                                <Translation id="supplier_center" defaultMessage="Supplier center" />
                                                :&nbsp;
                                            </span>
                                            {order.centerName && order.centerId ? (
                                                <span>
                                                    {order.centerName}&nbsp;({order.centerId})
                                                </span>
                                            ) : (
                                                <span>--</span>
                                            )}
                                        </div>
                                        <div>
                                            <span>PO:&nbsp;</span>
                                            {order.po ? <span>{order.po}</span> : <span>--</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="order-divider-x"></div>
                                <div className="order__shipping__details__boxresume--comments">
                                    <span className="title">
                                        <Translation id="comments_on_this_order" defaultMessage="Comments on this order" />
                                        :&nbsp;
                                    </span>
                                    <span className="comment">{order.comments ? order.comments : '--'}</span>
                                </div>
                            </section>

                            <div className="order__shipping__boxcontent__title">
                                <div>
                                    <h3>
                                        <Translation id="order_content" defaultMessage="Order content" />
                                    </h3>
                                </div>
                                {!this.state.editOrderMode && order.ecosentino_is_editable && order.reservationStatus !== 'PENDING_CANCELLATION' && order.reservationStatus !== 'PENDING_CHANGES' && orderToEdit !== null && (
                                    <div className="order__shipping__boxcontent__title--edit">
                                        <button
                                            className="edit-positions"
                                            gtm-label="ec_historial_pedidos_modificar"
                                            onClick={() => {
                                                editOrderModeOnClick()
                                            }}
                                        >
                                            <i class="fas fa-pen"></i>
                                            <span>
                                                <Translation id="modify" defaultMessage="Modify" />
                                            </span>
                                        </button>
                                    </div>
                                )}
                                {this.state.editOrderMode && (
                                    <div className="order__shipping__boxcontent__title--btn-actions">
                                        <button
                                            className="discard-changes"
                                            onClick={() => {
                                                this.confirmDiscardChanges(intl)
                                            }}
                                        >
                                            <Translation id="discard_changes" defaultMessage="Discard changes" />
                                        </button>
                                        <button
                                            className="save-changes"
                                            onClick={() => this.confirmSaveChanges(orderInEdition, order.orderId, reservationId, saveOrderToEdit, intl)}
                                            disabled={this.state.orderWeightError}
                                        >
                                            <Translation id="save" defaultMessage="Save" />
                                        </button>
                                    </div>
                                )}
                            </div>
                            {(order.ecosentino_status === 'PENDING_CHANGES' || order.reservationStatus === 'PENDING_CANCELLATION') && (
                                <div>
                                    <Alerts status='warning'
                                            alert='order_in_process_of_modification_warning'
                                            cssIcon='fas fa-exclamation-triangle'
                                            iconSize='16px'
                                            className='order__alert__warning'   
                                    />
                                </div>
                            )}

                            {!this.state.editOrderMode && (
                                <section className="order__shipping__details__table">
                                    <table className="order__shipping__details__table--table">
                                        <thead>
                                            <tr className="order__shipping__details__table--heading">
                                                <th className="th-description">
                                                    <Translation id="description" defaultMessage="Description" />
                                                </th>
                                                <th className="th-addional-info">
                                                    <Translation id="additional_information" defaultMessage="Additional Information" />
                                                </th>
                                                <th className="th-delivery-info">
                                                    <Translation id="delivery_information" defaultMessage="Delivery information" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.orderLines &&
                                                order.orderLines.length > 0 &&
                                                order.orderLines.map((orderLine, idx) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr key={idx}>
                                                                <div className="order-details-divider-x"></div>
                                                                <td className="td-description">
                                                                    {orderLine.cut_to_size && orderLine.cut_to_size.configurable ? (
                                                                        this.descriptionBlockConfigBath(orderLine)
                                                                    ) : orderLine.cut_to_size ? (
                                                                        this.cutToSizeDescriptionBlock(orderLine)
                                                                    ) : (
                                                                        this.descriptionBlock(orderLine)
                                                                    )}
                                                                </td>
                                                                <td className="td-additional-info">{this.additionalInfoBlock(orderLine)}</td>
                                                                <td className="td-delivery-info">{this.deliveryInformationBlock(orderLine)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                                {this.state.showConfigBathModal && (
                                                    <ModalConfigurationDetails
                                                        config={this.state.currentOrderLine.cut_to_size && this.state.currentOrderLine.cut_to_size.configurable}
                                                        showBtnEdit={false}
                                                        showBtnDownload={true}
                                                        onClose={() => this.handleCloseConfigBathModal()}
                                                    />
                                                )}
                                        </tbody>
                                    </table>
                                </section>
                            )}
                        </div>
                    )}

                    {/* Edit Order Mode */}
                    {this.state.editOrderMode && (
                        <React.Fragment>
                            {this.state.orderWeightError && (
                                <div className="weight__limit__excedeed">
                                    <div className="weight__limit__excedeed--content">
                                        <div className="dot-icon"></div>
                                        <div>
                                            <span>
                                                <Translation id="edit_order_weight_limit_excedeed" defaultMessage="Weight limits for this order type have been exceeded." />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <NewOrderDetailsEditMode
                                orderLines={order.orderLines}
                                orderToEdit={orderInEdition}
                                canEditPosition={this.canEditPositon}
                                onLineChangeValue={this.onChangeValue}
                                intl={intl}
                                adaptedFormat={adaptedFormat}
                                getMeasure={getMeasure}
                                isEmserUser={isEmserUser}
                                locale={locale}
                                replaceDotWithComma={replaceDotWithComma}
                                changeOrderInEdition={this.props.changeOrderInEdition}
                                orderShippingType={order.shippingType}
                                impersonatedBy={impersonatedBy}
                            />
                        </React.Fragment>
                    )}

                    {/* Invoices Modal */}
                    {this.state.showInvoiceModal && (
                        <Modal title={<Translation id="invoices" defaultMessage="Invoices" />} onClose={this.closeInvoiceModal} customClass="modal__invoice__orders" gtmLabel="ec_historial_pedidos_facturas_popup">
                            <ReactTable
                                data={invoices}
                                columns={[
                                    {
                                        Header: <Translation id="invoice_num" defaultMessage="Invoice Num." />,
                                        Cell: ({ original }) => {
                                            return (
                                                <span
                                                    className="invoice-number"
                                                    gtm-label="ec_historial_pedidos_facturas_popup_descargar"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        getFileByUrl(this.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                                    }}
                                                >
                                                    {original.invoiceNum}
                                                </span>
                                            )
                                        }
                                    },
                                    {
                                        Header: <Translation id="invoice_date" defaultMessage="Invoice Date" />,
                                        Cell: ({ original }) => {
                                            return <span className="invoice-date">{moment(original.invoiceDate).format('YYYY-MM-DD')}</span>
                                        }
                                    }
                                ]}
                                defaultPageSize={3}
                                pageSizeOptions={[8, 16, 32, 64, 128]}
                                previousText={<Translation id="previous" defaultMessage="Previous" />}
                                nextText={<Translation id="next" defaultMessage="Next" />}
                                noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                pageText={<Translation id="page" defaultMessage="Page" />}
                                ofText={<Translation id="of" defaultMessage="Of" />}
                                PaginationComponent={ReactTablePaginationCount}
                            />
                        </Modal>
                    )}
                </div>
                {/* Order details walkthrough */}
                {this.state.shouldRenderWalkthrough && <OrderDetailsWalkthrough /> }
            </div>
        )
    }
}

export default injectIntl(NewOrderDetails)
