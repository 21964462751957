import React from 'react'
import Translation from '../global/Translation'

import CartLineContainer from '../cart-line/CartLineContainer'
import Spinner from '../global/Spinner'
import getNumberFormat from "../getNumberFormat";

const MiniCartSummary = ({
    lines,
    totalQty,
    total,
    isIkeaCenter,
    calculatingLinesPrice,
    canViewPricesPhase2,
    totalCoin,
    lang
}) => {
    if (totalQty <= 0) {
        return (
            <div className="total cart-empty container-fluid">
                <div className="row">
                    <div className="label">
                        <Translation id="your_cart_is_empty" defaultMessage="Your cart is empty" />
                    </div>
                </div>
            </div>
        )
    }
    let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits:2 })
    
    if(totalCoin === 'JPY') {
        numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
    }
    return (
        <div>
            <div className={isIkeaCenter ? 'item-list container-fluid more-height' : 'item-list container-fluid'}>
                {lines.map(line => line && <CartLineContainer line={line} key={line.lineId} onlyView={line.extra && line.extra.itsFree} typeLine={'checkout_v2'} type={'checkout'}/>)}
            </div>
            {!isIkeaCenter && (
                <div className="total container-fluid">
                    <div className="row">
                        <div className="col-3 my-auto">
                            <div className="label">
                                <Translation id="total_items" defaultMessage="Total" />
                            </div>
                        </div>
                        <div className="col-2  my-auto">
                            <div className="quantity">{totalQty}</div>
                        </div>

                        {canViewPricesPhase2 && (
                            <React.Fragment>
                                <div className="col-2 col-md-2 my-auto text-right">
                                    <div className="label">
                                        <Translation id="total_import" defaultMessage="Total" />
                                    </div>
                                </div>

                                <div className="col-4 my-auto">
                                    <div className="quantity">
                                        {calculatingLinesPrice ? (
                                            <Spinner isLoading={true} relative small></Spinner>
                                        ) : ( (!total) || (total && total == 0) ? (
                                                <Translation id="not_available" defaultMessage="Not available" />
                                            ) : total && (
                                                <React.Fragment>
                                                    {numberFormat.format(total)} {totalCoin}
                                                </React.Fragment>
                                            )
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
export default MiniCartSummary
