import React from 'react'
import Translation from '../../../global/Translation'

import Modal from '../../../global/Modal'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../../../store/profile-configuration/reducers'
import { getPriceList, getUserPriceList, getUserType, getImpersonatedBy, getPriceListAtSelectedCenter, getUserDiscounts, getUserCenter } from '../../../../store/login/reducers'
import {
    getDRIUSACenterId,
    getFregaderosCenterId,
    getIKEACenterId,
    getIsIkeaCenter,
    getIsLowesCenter,
    getIsMkToolsCenter,
    getLowesCenterId,
    getNaturalStoneSpainCenterId,
    getSelectedCenter,
    getShowroomCenterId,
    getWarehoseCenterId
} from '../../../../store/centers/reducers'
import { canViewPrices, canViewPricesPhase3, discountsByProductId } from '../../../../store/products/reducers'
import { getLanguage } from '../../../../store/ui/reducers'
import ReactTooltip from 'react-tooltip'
import getNumberFormat from "../../../getNumberFormat";
import ReactDOM from 'react-dom'

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps && ownProps.product && ownProps.product.productId
    let outlet = ownProps && ownProps.outlet
    return {
        userPriceList: getUserPriceList(state),
        priceList: getPriceListAtSelectedCenter(state),
        centerId: getSelectedCenter(state),
        canViewPrices: canViewPrices(state),
        canViewPricesPhase3: canViewPricesPhase3(state),
        userType: getUserType(state),
        impersonatedBy: getImpersonatedBy(state),
        lang: getLanguage(state),
        userCenter: getUserCenter(state),
        productDiscounts: discountsByProductId(state, productId, outlet),
        naturalStoneSpainCenterId: getNaturalStoneSpainCenterId(),
        iKEACenterId: getIKEACenterId(),
        warehoseCenterId: getWarehoseCenterId(),
        showroomCenterId: getShowroomCenterId(),
        DRIUSACenterId: getDRIUSACenterId(),
        lowesCenterId: getLowesCenterId(),
        fregaderosCenterId: getFregaderosCenterId(),
        isIkeaCenter: getIsIkeaCenter(state),
        isMkToolsCenter: getIsMkToolsCenter(state),
        isLowesCenter: getIsLowesCenter(state),
    }
}

class DisplayPrice extends React.Component {

    static defaultProps = {
        showPriceDiscountDisclaimer: true,
    }

    state = { showHelp: false, center: null, pricesToShow: false, basePrice: null, baseMeasure: null, discounts: [], appliedDiscounts: [] }
    componentDidUpdate(prevProps) {
        //si cambia la lista de precios del usuario, el componente debe recalcular
        //si cambia los descuentos de fase 1 usuario, el componente debe recalcular
        //TODO: Magdalena añade aqui lo que consideres que debe actualizar el componente en fase 3
        //si cambia las promociones de fase 3 usuario, el componente debe recalcular
        if (prevProps.userPriceList !== this.props.userPriceList || prevProps.priceList !== this.props.priceList || prevProps.productDiscounts !== this.props.productDiscounts) {
            this.updatePrices()
        }
    }
    componentDidMount() {
        this.updatePrices()
    }

    updatePrices() {
        this.priceCondition = {
            ZT00: { condition: 'ZT00', operator: '-', description: 'BASE_PRICE' },
            ZEFP: { condition: 'ZEFP', operator: '+', description: 'FINISH_EXTRA_PERCENT' },
            ZDPR: { condition: 'ZDPR', operator: '-', description: 'PRODUCT_DISCOUNT' },
            ZDCU: { condition: 'ZDCU', operator: '-', description: 'CUSTOMER_DISCOUNT' },
            ZDLO: { condition: 'ZDLO', operator: '-', description: 'LOGISTIC_DISCOUNT' },
            Z71D: { condition: 'Z71D', operator: '-', description: 'PERCENTUAL_DISCOUNT_PROMOTION' }, //Phase3
            ZEFN: { condition: 'ZEFN', operator: '+', description: 'FINISH_EXTRA' },
            Z70D: { condition: 'Z70D', operator: '', description: 'FIXED_PRICE_PROMOTION' }, //Phase3
            ZDWB: { condition: 'ZDWB', operator: '-', description: 'ECOSENTINO_DISCOUNT' },
            ZDPP: { condition: 'ZDPP', operator: '-', description: 'PROMT_PAYMENT_DISCOUNT' }
        }
        // let productPromo = []
        // if (this.props.product && this.props.product.promotions) {
        //     productPromo = this.props.product.promotions
        // }
        //
        // let clientPromo = []
        //
        // if (this.props.userDiscounts && this.props.userDiscounts[this.props.product.productId]) {
        //     let discountsAtThisCenter = this.props.userDiscounts[this.props.product.productId].filter(promotion => {
        //         return promotion.center == this.props.centerId || !promotion.center
        //     })
        //     discountsAtThisCenter.map(validDiscount => {
        //         clientPromo = [{ ...validDiscount, discountClient: true }]
        //     })
        // }

        //current product discounts
        let allPromotions = []

        // [
        //     {conditionClass: Z71D,
        //     value: 10,
        //     promoId: 50000123
        //     promoname:promo italia,}

        //     { conditionClass: Z71D,
        //     value: 10,
        //     promoId: 50000123
        //     promoname:promo italia}
        // ]
        let sorting = {
            Z70D: { val: 0 },
            Z71D: { val: 1 },
            Z73D: { val: 2 },
            ZQ00: { val: 3 }
        }
        //new user discounts
        if (this.props.productDiscounts && Object.keys(this.props.productDiscounts).length > 0) {
            //         this.promotions = this.promotions.concat(this.validateOutletPromotions(this.props.productDiscounts))
            allPromotions = allPromotions.concat(this.props.productDiscounts)
        }
        //Validación OUTLET - NO OUTLET

        //  console.log("PROMOCIONES DE PRODUCTO")
        //  console.log( allPromotions)
        if (this.props.outlet) {
            allPromotions = this.validateOutletPromotions(allPromotions)
        }
        this.promotions = allPromotions.sort((a, b) => {
            if (sorting[b.conditionClass] && sorting[a.conditionClass]) {
                // console.log(a.conditionClass)
                // console.log(sorting[a.conditionClass].val)
                if (a.conditionClass == b.conditionClass) {
                    if (a.conditionClass == 'Z71D') {
                        //Discunt higher more favorable.
                        if (a.value > b.value) {
                            return -1
                        }
                        if (a.value < b.value) {
                            return 1
                        }
                    } else {
                        if (a.value > b.value) {
                            return 1
                        }
                        if (a.value < b.value) {
                            return -1
                        }
                    }
                }

                return sorting[a.conditionClass].val - sorting[b.conditionClass].val
            }

            // a must be equal to b
            return 1
        })
        //console.log(this.promotions)
        let notDefaultCenter = [
            this.props.naturalStoneSpainCenterId,
            this.props.iKEACenterId,
            this.props.warehoseCenterId,
            this.props.showroomCenterId,
            this.props.DRIUSACenterId,
            this.props.lowesCenterId,
            this.props.fregaderosCenterId
        ]

        if (notDefaultCenter.includes(this.props.userCenter)) {
            this.setState({ pricesToShow: false })
        }
        if (this.props.priceList && this.props.priceList[0]) {
            this.setState(
                {
                    //TODO:: PV_REPLACE_CENTERS
                    center:
                        this.props.product.centerId === '1070' ||
                        (this.props.product.centerId === '1000' && (this.props.product.type == 'FREGADERO' || this.props.product.type == 'PPCC'))
                            ? this.props.userCenter
                            : this.props.product.centerId
                },
                () => {
                    if (this.getCurrentZT00()) this.setState({ discounts: this.getDiscounts(), appliedConditions: this.getAppliedConditions(), pricesToShow: true })
                }
            )
        }
    }
    normalizeDiscount = priceCondition => {
        let m2toft2Ratio = 10.7639

        if (priceCondition.condition === 'ZT00' || priceCondition.condition === 'ZVFG') {
            return null
        }

        if (priceCondition.priceList === this.props.priceList[0].priceList) {
            if (this.getCurrentZT00().measure === priceCondition.measure || priceCondition.coin === '%') {
                return priceCondition
            } else {
                if (this.getCurrentZT00().measure === 'M2' && priceCondition.measure === 'PI2') {
                    priceCondition.price = priceCondition.price * m2toft2Ratio
                    return priceCondition
                } else if (this.getCurrentZT00().measure === 'PI2' && priceCondition.measure === 'M2') {
                    priceCondition.price = priceCondition.price / m2toft2Ratio
                    return priceCondition
                }
            }
        }
        return null
    }

    getAppliedConditions = () => {
        let priceConditionOrder = Object.keys(this.priceCondition)
        let appliedConditions = this.getDiscounts()
        let promotion = this.promotions && this.promotions[0]

        if (promotion) {
            appliedConditions.push({
                coin: promotion.coin,
                condition: promotion.conditionClass,
                price: promotion.value,
                priceList: null,
                registerCondition: promotion.promoId,
                salesOrg: promotion.salesOrg,
                measure: promotion.measure
            })

            if (promotion.condition === 'Z70D') {
                appliedConditions.map(condition => {
                    if (['ZEFP', 'ZDPR', 'ZDCU', 'ZDLO', 'ZEFN'].includes(condition.condition)) return (condition = { ...condition, annulled: true })
                })
            }
        }

        appliedConditions = appliedConditions.sort((a, b) => {
            return priceConditionOrder.indexOf(a.condition) - priceConditionOrder.indexOf(b.condition)
        })

        return appliedConditions
    }
    getDiscounts = () => {
        let priceConditionOrder = Object.keys(this.priceCondition)

        let discounts = []

        //prepara descuentos de material

        this.props.product.price &&
            this.props.product.price[this.state.center] &&
            this.props.product.price[this.state.center].forEach(priceCondition => {
                if (priceCondition.condition !== 'ZT00' && priceCondition.professionalPartner === 'PP') return
                // let discount = this.normalizeDiscount(priceCondition)
                // if (discount) discounts.push(discount)
                if (priceCondition.salesOrg === this.props.priceList[0].salesOrg) {
                    let discount = this.normalizeDiscount(priceCondition)
                    if (discount) discounts.push(discount)
                }
            })

        //prepara descuentos de cliente

        this.props.userPriceList &&
            this.props.userPriceList[this.props.product.productId] &&
            this.props.userPriceList[this.props.product.productId].forEach(priceCondition => {
                if (priceCondition.condition !== 'ZT00' && priceCondition.professionalPartner === 'PP') return

                if (priceCondition.salesOrg === this.props.priceList[0].salesOrg) {
                    let discount = this.normalizeDiscount(priceCondition)
                    if (discount) {
                        //if theres a discount with same condition , we keep the user condition, cause its more specific
                        let foundAt = discounts.map(discount => discount.condition).indexOf(discount.condition)
                        if (foundAt > -1) {
                            discounts.splice(foundAt, 1, discount)
                        } else {
                            if (discount) discounts.push(discount)
                        }
                    }
                }
            })
        //prepara promociones de cliente (nueva version FASE III)

        discounts = discounts.sort((a, b) => {
            return priceConditionOrder.indexOf(a.condition) - priceConditionOrder.indexOf(b.condition)
        })
        return discounts
    }
    getCurrentZT00 = () => {
        return (
            this.props.product.price &&
            this.props.product.price[this.state.center] &&
            this.props.product.price[this.state.center].filter(
                priceCondition => priceCondition.condition === 'ZT00' && priceCondition.priceList === this.props.priceList[0].priceList
            )[0]
        )
    }

    getPromotionMeasure = () => {
        let promotions = this.promotions
        let promotion = promotions[0]
        let result = null
        if (!this.props.canViewPricesPhase3) return this.getCurrentZT00().measure

        if (promotion && promotion.conditionClass === 'Z70D') {
            return promotion.measure
        }

        return this.getCurrentZT00().measure
    }

    applyDiscount = (discount, priceBefore) => {
        let priceAfter = priceBefore

        //TODO Remove this when ZDCU discounts are fixed
        if(discount.condition === 'ZDCU' || discount.condition === 'ZDPR' || discount.condition === 'ZDLO') {
            return priceAfter
        }

        //CAUTION: THIS METHOD WORKS BOTH WITH PROMOTION OR PRICECONDITIONS
        if (discount.condition === 'Z70D') {
            priceAfter = discount.price
            return priceAfter
        }
        if (discount.condition === 'Z71D') {
            priceAfter = priceBefore - (priceBefore * discount.price) / 100
            return priceAfter
        }

        if (discount.coin === '%') {
            if (discount.condition === 'ZEFP' || discount.condition === 'ZEFN') {
                //esto es un extra
                priceAfter = priceBefore + (priceBefore * discount.price) / 100
            } else {
                // esto es un discount
                priceAfter = priceBefore - (priceBefore * discount.price) / 100
            }
        } else {
            if (discount.condition === 'ZEFP' || discount.condition === 'ZEFN') {
                //esto es un extra
                priceAfter = priceBefore + discount.price
            } else {
                // esto es un discount
                priceAfter = priceBefore - discount.price
            }
        }

        return priceAfter
    }
    getFinalPrice = () => {
        if (!this.props.product.price && !this.props.userPriceList) return 0
        let finalPrice = this.getCurrentZT00() ? this.getCurrentZT00().price : 0;
        let discounts = this.state.discounts
        discounts.forEach(discount => {
            finalPrice = this.applyDiscount(discount, finalPrice)
        })
        return finalPrice
    }

    getFinalPriceWithPromotions = () => {
        let promotions = this.promotions
        let promotion = promotions[0]
        let result = null
        if (!this.props.canViewPricesPhase3) return null
        if (promotion && promotion.conditionClass === 'Z70D') {
            result = promotion.value
            this.state.discounts.map(discount => {
                if (discount.condition == 'ZDWB') {
                    result = this.applyDiscount(discount, result)
                }
                if (discount.condition == 'ZDPP') {
                    result = this.applyDiscount(discount, result)
                }
            })
        } else if (promotion && promotion.conditionClass === 'Z71D') {
            let priceConditionBefore = ['ZT00', 'ZEFP', 'ZDPR', 'ZDCU', 'ZDLO']
            let priceConditionAfter = ['ZEFN', 'ZDWB', 'ZDPP']
            result = this.getCurrentZT00().price
            this.state.discounts.map(discount => {
                if (priceConditionBefore.includes(discount.condition)) {
                    result = this.applyDiscount(discount, result)
                }
            })
            result = result - (result * promotion.value) / 100
            this.state.discounts.map(discount => {
                if (priceConditionAfter.includes(discount.condition)) {
                    result = this.applyDiscount(discount, result)
                }
            })
        }
        return result
    }

    getPromotionCoin = promotion => {
        if (promotion && (promotion.condition === 'Z70D' || promotion.conditionClass === 'Z70D') && promotion.coin !== null && promotion.coin !== this.getCurrentZT00().coin) {
            return promotion.coin
        }

        return this.getCurrentZT00().coin
    }

    promoPhase3DifferentCurrencies = () => {
        let ZTOO = this.getCurrentZT00()
        let promotion = this.promotions && this.promotions[0]
        if (promotion && ZTOO && promotion.coin !== ZTOO.coin) return true
        return false
    }

    transformMeasure = measure => {
        if (measure === 'PI2')
            return (
                <React.Fragment>
                    ft<sup>2</sup>
                </React.Fragment>
            )
        if (measure === 'M2')
            return (
                <React.Fragment>
                    m<sup>2</sup>
                </React.Fragment>
            )
        if (measure === 'UN')
            return (
                <React.Fragment>
                    <Translation id="unit" defaultMessage="Unit"></Translation>
                </React.Fragment>
            )
        if (measure === 'ROL')
            return (
                <React.Fragment>
                    <Translation id="roll" defaultMessage="Roll"></Translation>
                </React.Fragment>
            )
        return <React.Fragment>{measure}</React.Fragment>
    }

    validateOutletPromotions = allPromotions => {
        if (allPromotions == undefined || allPromotions == null || Object.keys(allPromotions).length == 0) return []

        //remove other outlet types promos.
        allPromotions = Object.values(allPromotions).filter(promo => {
            if (promo.outlet && promo.outlet == true && promo.outlet_types != this.props.product.outletQuality) {
                return false
            }
            return true
        })
        return allPromotions
    }

    getCanViewPriceDiscountDisclaimer() {
        if (!this.props.showPriceDiscountDisclaimer) {
            return false
        }
    
        return !(this.props.isIkeaCenter || this.props.isMkToolsCenter || this.props.isLowesCenter)
    }
    
    
    render() {
        if (!this.props.canViewPrices) return null

        if (this.props.rawPrice) {
            const currentZT00 = this.getCurrentZT00()
            const finalPrice = this.getFinalPrice();
            if (!currentZT00 || !finalPrice) {
                return null;
            }
            return <React.Fragment>{finalPrice.toFixed(2)} <small><span>{currentZT00.coin}</span><span>{`/${currentZT00.measure}`}</span></small></React.Fragment>;
        }

        if (!this.state.pricesToShow || !this.props.priceList || !this.props.priceList[0] || !this.getCurrentZT00())
            return (
                <div className="finish price v2">
                    <div className="text">
                        <div className="product_detail">
                            <span className='text price'>
                                <Translation id="price" defaultMessage="Price" />: <Translation id="not_available" defaultMessage="Not available" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        let partialPrice = this.getCurrentZT00().price
        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        
        if(this.getCurrentZT00().coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        const priceDiscountDisclaimer = (
            <div className="disclaimer-price-discount">
                <div><span><Translation id="prices_do_not_include_discounts" defaultMessage="Prices do not include discounts." /></span></div>
                <div><span><Translation id="applied_discounts_will_be_shown_in_the_shooping_cart" defaultMessage="Applied discounts will be shown in the shopping cart." /></span></div>
            </div>
        )
        
        return (
            <div className="finish price">
                <div className="text">
                    <div className="product_detail">
                        {(this.props.userType === 'ADMIN' || this.props.impersonatedBy) && (
                            <div>
                                <i
                                    className="fal fa-info-circle"
                                    style={{ margin: '5px' }}
                                    onClick={() => {
                                        this.setState({ showHelp: !this.state.showHelp })
                                    }}
                                />
                                <span className="price-info-display-price"><Translation id="price_info" defaultMessage="Price info" /></span>
                            </div>
                        )}
                        {this.getFinalPriceWithPromotions() !== null ? (
                            <React.Fragment>
                                {((this.getCurrentZT00().measure !== this.getPromotionMeasure() || this.getFinalPriceWithPromotions() < this.getFinalPrice()) || this.promoPhase3DifferentCurrencies()) && (
                                    <React.Fragment>
                                        <del>
                                            {this.state.discounts && numberFormat.format(this.getFinalPrice())}{' '}
                                            {this.getCurrentZT00().coin} / {this.transformMeasure(this.getCurrentZT00().measure)}{' '}
                                        </del>
                                        <br />
                                    </React.Fragment>
                                )}
                                <span className='text price'>
                                    {numberFormat.format(this.getFinalPriceWithPromotions())}{' '}
                                    {this.getPromotionCoin(this.promotions[0])} / {this.transformMeasure(this.getPromotionMeasure())}{' '}
                                </span>
                                {this.getCanViewPriceDiscountDisclaimer() && (
                                    priceDiscountDisclaimer
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span className='text price'>
                                    {this.state.discounts && numberFormat.format(this.getFinalPrice())} {this.getCurrentZT00().coin}{' '}
                                    / {this.transformMeasure(this.getCurrentZT00().measure)}{' '}
                                </span>
                                {this.getCanViewPriceDiscountDisclaimer() && (
                                    priceDiscountDisclaimer
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {this.state.showHelp && (
                    ReactDOM.createPortal(
                        (<Modal
                        customClass="catalog-modal-price"
                        id="general-modal-comp"
                        title={<Translation id="pricing_detail" defaultMessage="Pricing detail" />}
                        onClose={() => this.setState({ showHelp: false })}
                    >
                        <div className={`info ${!this.state.showHelp ? 'info--hidden' : ''}`}>
                            <h5>
                                <Translation id="applied_conditions" defaultMessage="Applied conditions" />
                            </h5>
                            <table class="table info info--promo">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <Translation id="condition_class" defaultMessage="Condition class" />
                                        </th>
                                        <th scope="col">
                                            <Translation id="id_condition" defaultMessage="Id condition" />
                                        </th>
                                        <th scope="col">
                                            <Translation id="price_calculation" defaultMessage="Price calculation" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            <span data-tip={this.priceCondition['ZT00'].description}>{this.getCurrentZT00().condition}</span>
                                        </th>
                                        <td>
                                            <span>
                                                {this.getCurrentZT00().registerCondition}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {this.getCurrentZT00().price} {this.getCurrentZT00().coin}
                                            </span>
                                        </td>
                                    </tr>
                                    {this.state.appliedConditions.map(discount => {
                                        partialPrice = this.applyDiscount(discount, partialPrice)
                                        //TODO Remove this when ZDCU discounts are fixed
                                        if(discount.condition === 'ZDCU' || discount.condition === 'ZDPR' || discount.condition === 'ZDLO') return null;
                                        return (
                                            <tr>
                                                <th scope="row">
                                                    <span data-tip={this.priceCondition[discount.condition].description}>
                                                        {discount.condition} ({discount.salesOrg}) {this.priceCondition[discount.condition].operator}{' '}
                                                        {numberFormat.format(discount.price)} {discount.condition !== 'Z70D' ? discount.coin : null}
                                                    </span>
                                                </th>
                                                <td>
                                                    <span>
                                                        {discount.registerCondition}
                                                    </span>
                                                </td>

                                                <td>
                                                    <span>
                                                        {numberFormat.format(partialPrice)} {this.getPromotionCoin(discount)}
                                                    </span>
                                                </td>
                                                <ReactTooltip bg-coloreffect="solid" place="bottom" />
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}></div> */}
                            {/* {this.state.appliedConditions.map(discount => {
                                partialPrice = this.applyDiscount(discount, partialPrice)
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            textDecoration: discount.annulled ? 'line-through' : ''
                                        }}
                                    ></div>
                                )
                            })} */}
                        </div>
                        {this.promotions && this.promotions.length > 0 && (
                            <div>
                                <br />
                                <div className="info info--promo">
                                    <h5>
                                        <Translation id="available_promos" defaultMessage="Available promotions" />
                                    </h5>
                                    <table class="table info info--promo">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <Translation id="promo_id" defaultMessage="Promo id" />
                                                </th>
                                                <th scope="col">
                                                    <Translation id="description" defaultMessage="Description" />
                                                </th>
                                                <th scope="col">
                                                    <Translation id="condition_class" defaultMessage="Condition class" />
                                                </th>
                                                <th scope="col">
                                                    <Translation id="value" defaultMessage="Value" />{' '}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.promotions.map(promo => (
                                                <tr>
                                                    <th scope="row">
                                                        {' '}
                                                        {promo.discountClient && <i className="fal fa-user" />}
                                                        {promo.promoId}
                                                    </th>
                                                    <td>{promo.description}</td>
                                                    <td>{promo.conditionClass}</td>
                                                    <td>{promo.value}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </Modal>), (document.getElementById('wrapper-contain'))
                    )
                )}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    null
)(DisplayPrice)
