import React, { useEffect, useState } from 'react'
import SegmentationV3 from './SegmentationV3'
import SegmentationEvidence from './SegmentationEvidence'

const SegmentationSteps = (props) => {
    const { show, setSegmentationCurrentStep } = props
    const [currentStep, setCurrentStep] = useState(1)

    useEffect(() => {
        // Set current step to 1 when modal segmentation is close
        if (!show) {
            setCurrentStep(1)
        }
    }, [show])

    const handleNextStep = () => {
        setCurrentStep(prevStep => {
            const nextStep = prevStep + 1
            setSegmentationCurrentStep(nextStep)
            return nextStep
        })
    }

    const handleBackStep = () => {
        setCurrentStep(prevStep => {
            const nextStep = prevStep - 1
            setSegmentationCurrentStep(nextStep)
            return nextStep
        })
    }

    const getStepContent = () => {
        switch (currentStep) {
            case 1:
                return <SegmentationV3 {...props} onNextStep={handleNextStep} />
            case 2:
                return <SegmentationEvidence {...props} onBackStep={handleBackStep} />
            default:
                return <SegmentationV3 {...props} onNextStep={handleNextStep} />
        }
    }

    return (
        <React.Fragment>
            {getStepContent()}
        </React.Fragment>
    )
}

export default SegmentationSteps
