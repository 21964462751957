import React from 'react'
import Button from '../../global/Button'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import RectangleResultContainer from '../../catalog/rectangleResult/RectangleResultContainer'
import ProductContext, { MallProductContext } from '../../catalog/rectangleResult/contexts/ProductContext'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getCommonParams, getPriceListAtSelectedCenter, getUserPriceList, getUserType } from '../../../store/login/reducers'
import { getSelectedCenter } from '../../../store/centers/reducers'
import { dispatchPushURL } from '../../../store/ui/actions'
import { setLastSelectedProduct } from '../../../store/selected-objects/actions'
import DisplayDiscontinued from '../../catalog/rectangleResult/displays/DisplayDiscontinued'
import { v4 as uuidv4 } from 'uuid'
import DisplaySoldOut from '../../catalog/rectangleResult/displays/DisplaySoldOut'
import DisplayTechnology from '../../catalog/rectangleResult/displays/DisplayTechnology'
import { isNewIndicator, isUpcomingIndicator } from '../../../store/products/reducers'
import DisplayNewIndicator from '../../catalog/rectangleResult/displays/DisplayNewIndicator'
import DisplayUpcomingIndicator from '../../catalog/rectangleResult/displays/DisplayUpcomingIndicator'

const SliderItem = ({ product, setProductSelectedToAdd, onZoomClick, sapLandingId, ...props }) => {
    const showValidImage = () => {
        if (product.detailImage && product.detailImage !== 'null' && product.detailImage !== undefined && product.detailImage !== 'undefined') return product.detailImage
        if (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) return product.imgLarge
        return cameraOff
    }

    const placeHolderClassName = () => {
        if (!product.detailImage || product.detailImage === 'null' || !product.imgLarge || product.imgLarge === 'null' || product.imgLarge == 'null') return '--placeholder'
        return ''
    }

    const canDisplayDiscontinued = () => {
        return !((['MK', 'SH', 'WH'].includes(product.webHeading) && product.discontinued === 'DD') || product.discontinued === 'DD' || product.discontinued === 'DF')
    }

    return (
        <div className="slide-card">
            <div className="container p-0">
                <div className="slide-card--content">
                    <div className={'col-lg-7 col-md-6 col-12 slide-card--image' + placeHolderClassName()} style={{ backgroundImage: `url(${showValidImage()})` }}>
                        <div className="indicators">
                            {product.realStock === 0 ? (
                                <React.Fragment key={uuidv4()}>
                                    <DisplaySoldOut />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {!(isNewIndicator(product.creationDate) || isUpcomingIndicator(product.creationDate)) && (
                                        <React.Fragment>
                                            {canDisplayDiscontinued() && <DisplayDiscontinued discontinued={product.discontinued} key={uuidv4()} />}
                                            {product && product.outlet && product.realStock > 0 && <DisplayOutletIndicator outletQuality={product.outletQuality} key={uuidv4()} />}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            {isNewIndicator(product.creationDate) && <DisplayNewIndicator key={uuidv4()} />}
                            {isUpcomingIndicator(product.creationDate) && <DisplayUpcomingIndicator key={uuidv4()} />}
                        </div>

                        {(product.detailImage && product.detailImage !== 'null') || (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) ? (
                            <Button className="slide-card__zoom" icon="expand" size="medium" inverted onClick={onZoomClick} />
                        ) : null}

                        <div className="wrapper_technology_indicator">
                            <div className="d-flex items-center" style={{ gap: '4px' }}>
                                <DisplayTechnology technology={product.technology} subBrand={product.subBrand} desktopWidth={'90px'} desktopHeight={'auto'} key={uuidv4()} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-12 mall-rectangle-container">
                        <ProductContext.Provider value={MallProductContext}>
                            <RectangleResultContainer product={product} key={product.productId} list={'MALL'} sapLandingId={sapLandingId} />
                        </ProductContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        landing: state.landing.landingBySlug,
        userType: getUserType(state),
        commonParamsAnalytics: getCommonParams(state),
        priceList: getPriceListAtSelectedCenter(state),
        userPriceList: getUserPriceList(state),
        selectedCenter: getSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        addProductInFetchedProducts: (product, centerId, locale) => {
            dispatch({ type: 'FETCH_PRODUCT_SUCCESS', product, centerId, locale })
        },
        setSelectedProduct: product => dispatch(setLastSelectedProduct(product))
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SliderItem))
