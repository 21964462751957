import React from 'react'

export default class DisplayBlackIndicator extends React.Component {
    render() {
        const { value } = this.props

        const styles = {
            backgroundColor: '#EE0015',
            color: '#FFFFFF',
            padding: '4px 8px',
            fontSize: '10px',
            fontWeight: '500',
            zIndex: 1,
            display: 'inline-block',
            lineHeight: '16px'
        }

        return (
            value && (
                <div className="new-indicator new-indicator--black" style={styles}>
                    {value}
                </div>
            )
        )
    }
}
