import React from 'react'

import Segmentation from './Segmentation'
import SegmentationConfiguration from "./SegmentationConfiguration";
import SegmentationSteps from './SegmentationSteps'

class SegmentationSelector extends React.Component {

    render() {
        const isInConfiguration = !!window.location.pathname.match(/segmentation/gi)

        return this.props.segmentationV3 ?
            isInConfiguration ?
                <SegmentationConfiguration {...this.props} />
                :
                <SegmentationSteps {...this.props} />
            : <Segmentation {...this.props} />
    }
}

export default SegmentationSelector
