import React from 'react'

import PropTypes from 'prop-types'
import getNumberFormat from "../../../getNumberFormat";
import {connect} from "react-redux";
import {getLanguage} from "../../../../store/ui/reducers";
class DisplayPriceMktools extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        dataCY: PropTypes.string,
        group: PropTypes.string,
        prices: PropTypes.object.isRequired,
        showCoin: PropTypes.bool
    }
    render() {
        const { prices, group, className = '', dataCY = 'display_price', showCoin = true, lang } = this.props
        if (!prices) return null
        let groupPrice = prices[group] ? prices[group] : prices.default
        if (!groupPrice) return null

        let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if(groupPrice.coin === 'JPY') {
            numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        return (
            <span className={className} data-cy={dataCY}>
                {numberFormat.format(groupPrice.price)} {showCoin && groupPrice.coin}
            </span>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        lang: getLanguage(state),
    }
}

export default connect(
    mapStateToProps,
    null
)(DisplayPriceMktools)