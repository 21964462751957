import React from 'react'
import Translation from '../../global/Translation'
import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'

//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayPrice from './displays/DisplayPrice'
import MultiProductDisplayName from '../../global/MultiProductDisplayName'

export default class SimpleDetailsDefault extends React.Component {
    state = { showHelp: false }
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    render() {
        const { product, canViewThresholdMax, isMulti } = this.props
    
        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }
        const watchingOutlet = this.props.outlet ? true : false
        let stock
        if (watchingOutlet) {
            stock = product.stock_outlet
        } else {
            if (product.threshold && product.threshold >= product.stock) {
                stock = ' < ' + product.threshold
            } else if (canViewThresholdMax && product.thresholdMax && product.thresholdMax <= product.stock) {
                stock = ' > ' + product.thresholdMax
            } else {
                stock = product.stock
            }
        }

        return (

            <DisplayProductInfo>
                {
                    !isMulti ? (
                        <div className="product-color-name">
                            <ProductDisplayName product={product} />
                        </div>
                    ) : (
                        <div className="product-color-name">
                            <MultiProductDisplayName product={product} />
                        </div>
                    )
                }                

                {this.props.isExclusiveHomeDepot && (
                    <small>
                        <span style={{ color: '#f96303' }}>
                            <Translation id="home_depot_exclusive" defaultMessage="Home Depot exclusive"></Translation>
                        </span>
                    </small>
                )}

                <span className="subtitle">
                    <DisplayBrand brand={product.brand}></DisplayBrand>
                    {!isMulti && (<span className="product-brand">&nbsp;(ID {product.productId})</span>)}
                </span>

                {
                    !isMulti && (
                        <DisplayGroupDtails>
                            { <DisplayPrice product={this.props.product} outlet={this.props.outlet} showPriceDiscountDisclaimer={false}/> }
                        </DisplayGroupDtails>
                    )
                }

                
            </DisplayProductInfo>
        )
    }
}
