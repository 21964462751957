import React from 'react'
import Translation from '../../global/Translation'

import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'
import mallLarge from '../../../assets/img/logos/mall-large.jpg'
//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'
import DisplayStock from './displays/DisplayStock'
import DisplayPrice from './displays/DisplayPrice'
import DisplayPricePhase2 from './displays/DisplayPricePhase2'
import DisplayNaturalStoneOrigin from './displays/DisplayNaturalStoneOrigin'
import LocalizedLink from '../../global/LocalizedLink'
import { connect } from 'react-redux'
import { getAdaptedFormat, getReplaceDotWithComma } from '../../../helpers'
import NaturalStoneIndicator from '../../cart-line/indicators/NaturalStoneIndicator'
import { getNaturalStoneViewingPermissions } from '../../../store/login/reducers'

class DetailsDefaultV2 extends React.Component {
    state = { showHelp: false }

    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    render() {
        const extras = this.props.getExtras()
        const { product, canViewThresholdMax, intl, userType } = this.props

        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div style={{ display: 'inline' }}>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }
        const watchingOutlet = !!(this.props.outlet || (product.fromMall && product.outlet))
        const stock = this.props.getStock()

        return (
            <DisplayProductInfo mallPromotionSection={this.props.mallPromotionSection}>
                <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ columnGap: '16px', rowGap: 0 }}>
                    <div>
                        <DisplayBrand brand={product.brand} colorSerieDesc={product.colorSerieDesc} />
                    </div>
                    <div className="d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                        {product.fromMall && (
                            <div>
                                <img style={{ width: '38px' }} src={mallLarge} alt="logo mall" />
                            </div>
                        )}
                        {extras && extras.isCosentinoGlobal && !(!!this.props.outlet && product.outlet) && this.props.naturalStoneViewingPermissions.canViewHQ && (
                            <NaturalStoneIndicator idMessage={'natural_stone_hq'} defaultMessage="Natural stone Spain" />
                        )}

                        {extras && extras.isCosentinoBrazil && this.props.naturalStoneViewingPermissions.canViewBrazil && (
                            <NaturalStoneIndicator idMessage={'natural_stone_brazil'} defaultMessage="Natural stone Brazil" />
                        )}

                        {!!this.props.outlet && product.outlet && extras && extras.isCosentinoGlobal && this.props.naturalStoneViewingPermissions.canViewHQ && (
                            <NaturalStoneIndicator idMessage={'outlet_in_hq'} defaultMessage="Outlet Spain" />
                        )}
                    </div>
                </div>

                <div className="product-color-name">
                    <ProductDisplayName product={product} />
                </div>

                {!this.props.mallPromotionSection && (
                    <div className="product-info-details_productid">
                        <div>
                            <DisplayGroupDtails>
                                <DisplayDetail class="productId" value={product.productId} label={<Translation id="material_id" defaultMessage="Material ID" />} />
                            </DisplayGroupDtails>
                        </div>
                    </div>
                )}

                {!this.props.notDescriptionProduct && !this.props.mallPromotionSection && (
                    <React.Fragment>
                        <div className="product-info-details__content">
                            <DisplayGroupDtails>
                                <DisplayDetail
                                    class="measure-unit format"
                                    value={this.props.adaptedFormat(product.format, { upper: true, showUnits: true })}
                                    label={<Translation id="format" defaultMessage="Format" />}
                                />
                            </DisplayGroupDtails>
                            <DisplayGroupDtails>
                                <DisplayDetail class="subtype" value={product.subtypeName} label={<Translation id="subtype" defaultMessage="Subtype" />} />
                            </DisplayGroupDtails>
                            <DisplayGroupDtails>
                                <DisplayDetail
                                    class="thickness"
                                    value={this.props.replaceDotWithComma(product.thickness, intl.locale)}
                                    label={<Translation id="thickness" defaultMessage="Thickness" />}
                                />
                                {product.thickness && <span className="thickness-measure">cm</span>}
                            </DisplayGroupDtails>
                            <DisplayGroupDtails>
                                <DisplayDetail class="finish" value={product.finish} label={<Translation id="finish" defaultMessage="Finish" />} />
                            </DisplayGroupDtails>

                            <DisplayGroupDtails>
                                <div className="product_detail">
                                    <div className="text">
                                        <span className="bold-text">
                                            <Translation id="MAL" defaultMessage="MAL" />
                                        </span>
                                        :&nbsp;
                                        {product.zaddon === 'MAL' ? <Translation id="yes" defaultMessage="Yes" /> : <Translation id="no" defaultMessage="No" />}
                                    </div>
                                </div>
                            </DisplayGroupDtails>

                            <DisplayNaturalStoneOrigin product={product} />
                        </div>
                        <DisplayStock
                            value={stock}
                            measure={quantity}
                            surface={this.getUnitBy()}
                            product={this.props.product}
                            hideSurface={product.fromMall}
                            watchingOutlet={this.props.outlet}
                            canViewThresholdMax={canViewThresholdMax}
                        />
                    </React.Fragment>
                )}
                {product.fromMall ? (
                    <React.Fragment>
                        {this.props.minimumOrder > 0 ? (
                            <DisplayGroupDtails>
                                <DisplayDetail class="finish minimum" value={this.props.minimumOrder} label={<Translation id="minimun_order" defaultMessage="Minimun Order" />} />
                            </DisplayGroupDtails>
                        ) : null}

                        {this.props.showLots() && !this.props.mallPromotionSection && (
                            <div className="box-buttons-lots">
                                {product.lotViewAvailable === undefined || product.lotViewAvailable ? (
                                    <div className="buttons-lots">
                                        <LocalizedLink
                                            routeId="ROUTE_LOTS"
                                            params={{ id: product.productId, outlet: product.outlet ? 'outlet' : '' }}
                                            onClick={() => {
                                                product.extra = extras
                                                if (this.props.minimumOrder > 1) product.minimumOrder = this.props.minimumOrder
                                                else product.minimumOrder = null
                                                this.props.setLastSelectedProduct(product)
                                            }}
                                        >
                                            <div className="lots">
                                                <Translation id="view_lots" defaultMessage="View lots" /> ({product.realStock})
                                            </div>
                                            {product.fromMall && (
                                                <div>
                                                    <i class="fas fa-external-link-alt"></i>
                                                </div>
                                            )}
                                        </LocalizedLink>
                                    </div>
                                ) : (
                                    <div className="buttons-lots">
                                        <React.Fragment>
                                            <Translation id="lots" defaultMessage="Lots" /> ({product.realStock})
                                        </React.Fragment>
                                    </div>
                                )}
                            </div>
                        )}
                        <DisplayGroupDtails>
                            {product && userType !== 'ADMIN' ? (
                                <DisplayPricePhase2 product={product} />
                            ) : (
                                <span className="price-not-available">
                                    <Translation id="price_not_available" defaultMessage="Price not available" />
                                </span>
                            )}
                        </DisplayGroupDtails>
                    </React.Fragment>
                ) : (
                    <DisplayGroupDtails>
                        <DisplayPrice product={product} outlet={watchingOutlet} />
                    </DisplayGroupDtails>
                )}

                {/***** SECCIÓN MALL EN LA HOME *****/}
                {this.props.mallPromotionSection && (
                    <React.Fragment>
                        <div className="promotion-progress-bar">
                            {product.realStock > 0 ? (
                                <span
                                    style={{
                                        width: `${(product.stock_mall / product.stock) * 100}%`,
                                        backgroundColor: `rgb(54, 165, 150)`
                                    }}
                                ></span>
                            ) : (
                                <span
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'red'
                                    }}
                                ></span>
                            )}
                        </div>

                        <DisplayGroupDtails>
                            <DisplayStock value={stock} measure={quantity} surface={this.getUnitBy()} product={product} hideSurface={true} />
                        </DisplayGroupDtails>
                    </React.Fragment>
                )}
                {/***** SECCIÓN MALL EN LA HOME *****/}
            </DisplayProductInfo>
        )
    }
}

const mapStateToProps = state => {
    return {
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state),
        naturalStoneViewingPermissions: getNaturalStoneViewingPermissions(state)
    }
}

export default connect(mapStateToProps, null)(DetailsDefaultV2)
