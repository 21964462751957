import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../../../global/Translation'

class DisplayDiscontinued extends React.Component {
    render() {
        const { discontinued, isCheckout = false, isSimplified = false, displayInLine = false } = this.props

        const checkoutStyles = {
            fontSize: '8px',
            lineHeight: '9px',
            padding: '2px 4px',
            minHeight: '19px',
            letterSpacing: '0.5px'
        }

        const styles = {
            backgroundColor: '#3C898A',
            color: '#FFFFFF',
            fontWeight: '500',
            fontSize: isCheckout ? checkoutStyles.fontSize : '10px',
            lineHeight: isCheckout ? checkoutStyles.lineHeight : '11px',
            padding: '4px 8px',
            textTransform: 'uppercase',
            zIndex: 1,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            position: 'relative',
            display: displayInLine ? 'inline' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            minHeight: '21px',
            textAlign: 'left'
        }

        if (discontinued) {
            return (
                <div className="discontinued-indicator" style={styles}>
                    <Translation id="discontinued" defaultMessage="Discontinued" />
                    {' - '}
                    {this.props.intl.formatMessage({ id: this.props.discontinued + '_SHORT' })}
                </div>
            )
        }

        if (isSimplified) {
            return (
                <div className="discontinued-indicator" style={styles}>
                    <Translation id="discontinued" defaultMessage="Discontinued" />
                </div>
            )
        }

        return null
    }
}
export default injectIntl(DisplayDiscontinued)
