import { weightSelector } from '../cart/reducers'
import jwtDecode from 'jwt-decode'
import * as user from '../login/reducers'
import { getShippingAddressesCenters } from '../shipping-addresses/reducers'

import bannerHome from '../../assets/img/promotions/italy/banner-home.jpg'
import bannerCatalog from '../../assets/img/promotions/italy/banner-catalog.jpg'
import flyerPromo from '../../assets/pdf/promotions/flyer-italy-promo-12mm.pdf'

import {getUserCenter, getUserName} from '../login/reducers'
import moment from 'moment'

export const initState = {
    selectedCenter: null,
    selectedCenterPreferences: {
        productsByType: {
            slab: null,
            sink: null,
            complementary: null,
            washbasin: null,
            shower: null,
            flooring: null,
            baths: null
        }
    },
    selectedShippingMethod: null,
    centers: [],
    remembered: false,
    animateChangeCenter: false,
    mktoolsShippingCenter: null,
    pricesGroup: null
}
const centers = (state = initState, action) => {
    switch (action.type) {
        case 'EMPTY_CENTERS':
            return Object.assign({}, state, { centers: [], selectedCenter: null, selectedShippingMethod: null, mktoolsShippingCenter: null })
        case 'SIMPLE_LOGIN_SUCCESS':
        case 'SIMPLE_IMPERSONATE_SUCCESS':
            const decoded = jwtDecode(action.token)
            return Object.assign({}, state, { centers: decoded.centers })
        case 'CHANGE_CENTER':
            const isBetweenCenters = state.centers.find(center => center.centerId === action.center)
            const selectedCenter = isBetweenCenters ? action.center : state.centers[0].centerId
            return Object.assign({}, state, {
                selectedCenter: selectedCenter,
                selectedShippingMethod: getCenterDataOf(selectedCenter, state).shippingTypes[0],
                animateChangeCenter: !state.animateChangeCenter,
                mktoolsShippingCenter: state.selectedCenter === selectedCenter ? state.mktoolsShippingCenter : null
            })
        case 'ONLY_CHANGE_CENTER':
            return Object.assign({}, state, {
                selectedCenter: action.center
            })
        case 'CHANGE_SHIPPING_METHOD':
            return Object.assign({}, state, { selectedShippingMethod: action.shippingMethod })
        case 'FETCH_CENTER_PREFERENCES_SUCCESS':
            return Object.assign({}, state, { selectedCenterPreferences: action.centerPreferences })
        case 'REMEMBERED_CENTER':
            return Object.assign({}, state, { remembered: true })
        case 'REMEMBER_CENTER':
            return Object.assign({}, state, { remembered: false })
        case 'SET_MKTOOLS_SHIPPING_CENTER':
            return Object.assign({}, state, { mktoolsShippingCenter: action.mktoolsShippingCenter })
        default:
            return state
    }
}

export const getIsEndiCenter = state => {
    const center = getCenterData(state)
    return center && center.shippingTypes && (center.shippingTypes.includes('ENDI_TRUCK') || center.shippingTypes.includes('ENDI_CONTAINER'))
}

export const getCenters = state => {
    return state.centers ? state.centers.centers : null
}
// export const getCentersSuppliers = state => {
//     return state.centers.centers
// }
// export const getCentersAddress = state => {
//     console.log(state.centers.centers)
//     return state.centers.centers.filter(center => {
//         console.log(center)
//         return center.mkCustomer
//     })
// }
export const getSelectedCenter = state => {
    return state.centers ? state.centers.selectedCenter : null
}
export const getShippingMethod = state => {
    if (getIsMkToolsCenter(state)) {
        return 'MKTOOLS'
    }
    // if ((getSalesOrg(state) === '2011' || getSalesOrg(state) === '2032') && isMallCartOnly(state)) {
    //     return 'CONTAINER'
    // }
    if (state.centers && state.centers.selectedShippingMethod === 'SINGLE_SLAB' && weightSelector(state) > 12000) {
        if ((getSalesOrg(state) === '2011' || getSalesOrg(state) === '2032') && weightSelector(state) < 19050) {
            return 'SINGLE_SLAB'
        }
        return 'FULL_TRUCK'
    }

    return state.centers ? state.centers.selectedShippingMethod : null
}

export const getShippingMethodWithoutWeights = state => {
    if (getIsMkToolsCenter(state)) {
        return 'MKTOOLS'
    }
    return state.centers ? state.centers.selectedShippingMethod : null
}

export const getCenterData = state => {
    if (state.centers) {
        let found = state.centers.centers.find(center => {
            return center.centerId === getSelectedCenter(state)
        })

        return found
    }
    return null
    
}

export const hasIncentives = state => {
    if (!getCenterData(state)) return null
    return getCenterData(state).hasIncentives
}
export const getPromoBanners = state => {
    const promoBanner = {
        default: {
            // banner: bannerFR,
            banner: bannerHome,
            subBanner: null,
            catalogBanner: bannerCatalog,
            catalogBannerMobile: bannerCatalog,
            homeBannerMobile: null,
            pdfLink: flyerPromo
        }
    }
    return promoBanner
}
export const hasPromotionLandingActivated = state => {
    return !!state.promotions.promotionsByCenter[getSelectedCenter(state)]
}
export const hasPromoActivated = state => {
    // return false
    if (moment() >= moment(new Date('2020-12-30 GMT+0000'))) {
        return false
    }
    let centersPromo = [7050, 7051, 7052, 7060, 7065]
    let shippingTypePromo = ['SINGLE_SLAB', 'FULL_TRUCK']
    let usersWithPromo = [
        '0000104341',
        '0000104348',
        '0000104362',
        '0000104363',
        '0000104364',
        '0000104378',
        '0000104391',
        '0000104442',
        '0000104449',
        '0000104454',
        '0000104460',
        '0000104463',
        '0000104483',
        '0000104491',
        '0000104505',
        '0000104519',
        '0000104532',
        '0000104562',
        '0000104587',
        '0000104610',
        '0000104616',
        '0000105809',
        '0000105816',
        '0000105846',
        '0000105930',
        '0000106488',
        '0000106694',
        '0000106743',
        '0000106864',
        '0000106916',
        '0000107090',
        '0000107198',
        '0000107606',
        '0000107677',
        '0000107931',
        '0000107974',
        '0000108255',
        '0000108409',
        '0000108757',
        '0000108913',
        '0000109254',
        '0000109285',
        '0000109628',
        '0000109733',
        '0000109803',
        '0000109897',
        '0000109942',
        '0000110035',
        '0000110036',
        '0000110037',
        '0000110042',
        '0000110043',
        '0000110044',
        '0000110047',
        '0000110454',
        '0000110499',
        '0000110530',
        '0000110830',
        '0000111092',
        '0000111115',
        '0000111133',
        '0000111228',
        '0000111317',
        '0000111575',
        '0000111786',
        '0000111813',
        '0000111874',
        '0000111968',
        '0000111969',
        '0000111972',
        '0000112023',
        '0000112044',
        '0000112094',
        '0000112134',
        '0000112181',
        '0000112240',
        '0000112584',
        '0000112777',
        '0000112847',
        '0000112917',
        '0000113018',
        '0000113082',
        '0000113246',
        '0000113362',
        '0000113378',
        '0000113712',
        '0000113916',
        '0000113925',
        '0000113960',
        '0000114025',
        '0000114052',
        '0000114114',
        '0000114181',
        '0000114202',
        '0000114548',
        '0000115216',
        '0000115282',
        '0000115441',
        '0000115684',
        '0000115783',
        '0000115957',
        '0000116159',
        '0000116412',
        '0000116453',
        '0000116529',
        '0000116530',
        '0000116577',
        '0000116642',
        '0000116987',
        '0000117021',
        '0000117322',
        '0000117383',
        '0000118320',
        '0000118715',
        '0000118913',
        '0000118943',
        '0000119080',
        '0000119092',
        '0000119549',
        '0000119560',
        '0000119702',
        '0000119703',
        '0000119911',
        '0000119978',
        '0000119994',
        '0000119999',
        '0000120013',
        '0000120047',
        '0000120145',
        '0000120190',
        '0000120193',
        '0000120196',
        '0000120228',
        '0000120237',
        '0000120282',
        '0000120300',
        '0000120313',
        '0000120320',
        '0000120323',
        '0000120374',
        '0000120409',
        '0000120503',
        '0000120528',
        '0000120640',
        '0000120672',
        '0000120675',
        '0000120697',
        '0000120709',
        '0000120780',
        '0000120781',
        '0000120784',
        '0000120821',
        '0000120895',
        '0000120922',
        '0000120965',
        '0000121070',
        '0000121138',
        '0000121322',
        '0000121549',
        '0000122567',
        '0000122663',
        '0000122756',
        '0000122760',
        '0000122780',
        '0000123291',
        '0000123335',
        '0000123406',
        '0000123587',
        '0000303994',
        '0000403518',
        '0000403611',
        '0000403643',
        '0000403697',
        '0000403776',
        '0000414941',
        '0000415696'
    ]

    if (!getCenterData(state)) return false

    //this trick to allow users buy with promo to center 1000 when they have available one of the promo centers
    // let includesPromoCenter = getCenters(state).find(center => {
    //     return centersPromo.includes(center.centerId)
    // })
    if (centersPromo.includes(getSelectedCenter(state))) {
        if (usersWithPromo.includes(getUserName(state))) {
            if (getCenterData(state).shippingTypes.filter(shippingType => shippingTypePromo.includes(shippingType)).length > 0) {
                return true
            }
        }
    }
    // if (getSelectedCenter(state) === 1000 && user.getSalesOrg(state) === '1000') return true
    // if (getSelectedCenter(state) === 1070 && includesPromoCenter) return true
    return false
}

export const productIsInPromo = (state, product) => {
    // return false
    // let colorPromo = ['2K', 'BZ', 'BZJ', 'DHA', 'DZE', 'BJJ', 'BJ2', '2KJ', 'M14', 'M1J']

    if (!product) return null

    if (hasPromoActivated(state) && product.promo) return true

    return false
}
export const getAccess = state => {
    if (!getCenterData(state)) return null
    return getCenterData(state).access
}
export const getSalesOrg = state => {
    if (!getCenterData(state)) return null
    return getCenterData(state).salesOrg
}
export const getHasMinWeight = state => {
    if (!getCenterData(state)) return null
    return getCenterData(state).hasMinWeight
}
export const getCenterName = state => {
    if (!getCenterData(state)) return null
    return getCenterData(state).name
}
export const canOnlyViewOutlet = state => {
    if (!user.getIsLogged(state)) return false
    if (!getCenterData(state)) return null
    // if (getSelectedCenter(state) === getHQCenterId() && getCurrentUrl(state).pathname.includes('outlet')) return true

    return !getCenterData(state).access['VIEW_CATALOG'] && !getCenterData(state).access['VIEW_MKTOOLS'] && getCenterData(state).access['VIEW_OUTLET']
}
export const canViewCatalog = state => {
    if (!user.getIsLogged(state)) return true
    if (!getCenterData(state)) return null
    if (getSelectedCenter(state) === 7510 && getIsIkeaCenter(state)) return false
    if (getSelectedCenter(state) === 7777) return false
    return getCenterData(state) && getCenterData(state).access && getCenterData(state).access['VIEW_CATALOG']
    // if (getSelectedCenter(state) === 7777) return false
    // return (
    //     getCenterData(state).access === 'BUY_ADMIN_AND_CUSTOMER' ||
    //     getCenterData(state).access === 'BUY_ADMIN' ||
    //     getCenterData(state).access === 'VIEW_ALL' ||
    //     getCenterData(state).access === 'BUY_ALL' ||
    //     getCenterData(state).access === 'BUY_ONLY_MKTOOLS'
    // )
}
export const canBuyMktools = state => {
    if (!user.getIsLogged(state)) return false
    return getCenterData(state) && getCenterData(state).access && getCenterData(state).access['BUY_MKTOOLS']
    // if (getCenterData(state) && !getCenterData(state).shippingTypes.includes('MKTOOLS')) return false
    // return (
    //     getCenterData(state).access === 'BUY_ADMIN_AND_CUSTOMER' ||
    //     getCenterData(state).access === 'BUY_ADMIN' ||
    //     getCenterData(state).access === 'VIEW_ALL' ||
    //     getCenterData(state).access === 'BUY_ALL' ||
    //     getCenterData(state).access === 'BUY_ONLY_MKTOOLS'
    // )
}
export const canViewMktools = state => {
    if (!user.getIsLogged(state)) return false
    return getCenterData(state) && getCenterData(state).access && (getCenterData(state).access['BUY_MKTOOLS'] || getCenterData(state).access['VIEW_MKTOOLS'])
    // return canBuyMktools(state)
}
export const canViewOutlet = state => {
    if (!user.getIsLogged(state)) return true
    return getCenterData(state) && getCenterData(state).access && (getCenterData(state).access['VIEW_OUTLET'] || getCenterData(state).access['BUY_OUTLET'])
    // if (getIsIkeaCenter(state)) return false
    // if (getSelectedCenter(state) === 7777) return false
    // //AFFECTED-CODE-HQ =
    // // if (getShippingMethod(state) === 'MKTOOLS') return process.env.REACT_APP_MKTOOLS_OUTLET === 'true'
    // if (getSelectedCenter(state) === getHQCenterId()) return true
    // return getShippingMethod(state) !== 'CONTAINER' && getShippingMethod(state) !== 'DIRECT_TRUCK' // en el centro 1000 nunca se puede comprar outlet.
}
export const canViewPromotions = state => {
    if (hasPromoActivated(state)) return true
    return false
}
export const canViewBaths = state => {
    return true
    // if (getSelectedCenter(state) === 7080 || getSelectedCenter(state) === 7095) return true
    // if (!user.getIsLogged(state)) return false
    // if (!getCenterData(state)) return null
    // let centerNames = ['PRICING']
    // if (centerNames.includes(getUserName(state))) return true
    // if (centerNames.includes(user.getImpersonatedBy(state))) return true
    // let centersWithBathsBefore = [
    //         '5400',
    //         '7000',
    //         '7010',
    //         '7015',
    //         '7016',
    //         '7017',
    //         '7030',
    //         '7031',
    //         '7032',
    //         '7034',
    //         '7035',
    //         '7036',
    //         '7037',
    //         '7038',
    //         '7040',
    //         '7050',
    //         '7051',
    //         '7052',
    //         '7060',
    //         '7065',
    //         '7080',
    //         '7095',
    //         '7100',
    //         '7101',
    //         '7110',
    //         '7120',
    //         '7125',
    //         '7126',
    //         '7127',
    //         '7128',
    //         '7129',
    //         '7130',
    //         '7140',
    //         '7150',
    //         '7160',
    //         '7180',
    //         '7181',
    //         '7182',
    //         '7190',
    //         '7201',
    //         '7210',
    //         '7220',
    //         '7230',
    //         '7231',
    //         '7240',
    //         '7241',
    //         '7500',
    //         '7501',
    //         '7502',
    //         '7503',
    //         '7504',
    //         '7505',
    //         '7506',
    //         '7507',
    //         '7509',
    //         '7510',
    //         '7520',
    //         '7531',
    //         '7532',
    //         '7533',
    //         '7534',
    //         '7535',
    //         '7536',
    //         '7540',
    //         '7560',
    //         '7570',
    //         '7583',
    //         '7584',
    //         '7590',
    //         '7591',
    //         '7593',
    //         '7595',
    //         '7596',
    //         '7597',
    //         '7601',
    //         '7602',
    //         '7603',
    //         '7604',
    //         '7605',
    //         '7606',
    //         '7607',
    //         '7608',
    //         '7609',
    //         '7610',
    //         '7611',
    //         '7612',
    //         '7613',
    //         '7615',
    //         '7616',
    //         '7617',
    //         '7618',
    //         '7619',
    //         '7620',
    //         '7621',
    //         '7622',
    //         '7623',
    //         '7624',
    //         '7625',
    //         '7626',
    //         '7627',
    //         '7628',
    //         '7629',
    //         '7630',
    //         '7631',
    //         '7632',
    //         '7633',
    //         '7634',
    //         '7635',
    //         '7636',
    //         '7637',
    //         '7638',
    //         '7639',
    //         '7640',
    //         '7641',
    //         '7642',
    //         '7643',
    //         '7644',
    //         '7645',
    //         '7646',
    //         '7647',
    //         '7648',
    //         '7649',
    //         '7666']
    // if (centersWithBathsBefore.includes(getSelectedCenter(state))) return true
    // return false
}
export const canBuyCatalog = state => {
    if (!user.getIsLogged(state)) return true
    if (!getCenterData(state)) return null
    // if (getSelectedCenter(state) === getHQCenterId() && getCurrentUrl(state).pathname.includes('outlet')) return false
    return getCenterData(state).access && getCenterData(state).access['BUY_CATALOG']
}
export const canBuy = state => {
    return canBuyCatalog(state) || canBuyMktools(state)
}
export const canViewCart = state => {
    if (!getCenterData(state)) return null
    return getCenterData(state).access && getCenterData(state).access['VIEW_CART']
    // return (
    //     getCenterData(state).access === 'BUY_ADMIN_AND_CUSTOMER' ||
    //     (getCenterData(state).access === 'BUY_ADMIN' && user.getIsAdmin(state)) ||
    //     getCenterData(state).access === 'BUY_ALL' ||
    //     (getCenterData(state).access === 'BUY_NO_LOTS' && !user.getIsAdmin(state)) ||
    //     getCenterData(state).access === 'BUY_ONLY_MKTOOLS'
    // )
}
export const getNeedsSegmentation = state => {
    //disable for ikea center and user of ikea mexico
    //disable for mktools
    if (
        getSelectedCenter(state) === getShowroomCenterId() ||
        getSelectedCenter(state) === getWarehoseCenterId() ||
        getSelectedCenter(state) === getDRIUSACenterId() ||
        getSelectedCenter(state) === getIKEACenterId()
    ) {
        return false
    } else {
        return true
    }
}
export const getCenterDataOf = (selectedCenter, state) => {
    let found = state.centers.find(center => {
        return center.centerId === selectedCenter
    })

    return found
}
export const getSelectedCenterPreferences = state => {
    return state.centers ? (state.centers.selectedCenterPreferences ? state.centers.selectedCenterPreferences : initState.selectedCenterPreferences) : null
}

export const getIsRemembered = state => {
    return state.centers.remembered
}
export const getIsMkToolsCenter = state => {
    return state.centers ? (state.centers.selectedCenter === getDRIUSACenterId() || state.centers.selectedCenter === getWarehoseCenterId() || state.centers.selectedCenter === getShowroomCenterId()) : false
}
export const getIsMktoolsUsaCenter = state => {
    return state.centers.selectedCenter === getDRIUSACenterId()
}
export const getIsIkeaCenter = state => {
    //exception for ikea mexico
    if (state && state.login && (state.login.username === '0000125055' || state.login.username ===  '0000121083')) { //7510
        return true
    }
    return state.centers.selectedCenter === getIKEACenterId() || state.centers.selectedCenter === getIKEACanariasCenterId()
}
export const isBetweenCenters = (centerId, state) => {
    return state.centers.centers.find(center => center.centerId === centerId)
}
export const getCenterMktool = state => {
    if (state.centers){
        if (state.centers.centers.find(center => center.centerId === getShowroomCenterId())) return getShowroomCenterId()
        else if (state.centers.centers.find(center => center.centerId === getDRIUSACenterId())) return getDRIUSACenterId()
        else return false
    }else return false
   
}
export const getCenterShowroom = state => {
    if (state.centers) {
        if (state.centers.centers.find(center => center.centerId === getShowroomCenterId())) return getShowroomCenterId()
        else if (state.centers.centers.find(center => center.centerId === getDRIUSACenterId())) return getDRIUSACenterId()
        else return false
    }else return false
   
}
export const getCenterWarehose = state => {
    if (state.centers && state.centers.centers.find(center => center.centerId === getWarehoseCenterId())) return getWarehoseCenterId()
    else return false
}
export const getAnimateChangeCenter = state => {
    return state.centers ? state.centers.animateChangeCenter : null
}
export const getIsLowesCenter = state => {
    return state.centers ? state.centers.selectedCenter === getLowesCenterId() : null
}
export const isEuropeOrIberia = state => {
    if (!user.getIsLogged(state)) return false
    let centersOfIberia = [
        '1000',
        '1010',
        '1060',
        '1070',
        '1080',
        '1090',
        '1100',
        '4037',
        '4100',
        '5000',
        '7701',
        '7702',
        '7703',
        '7704',
        '7705',
        '7706',
        '7707',
        '7708',
        '7710',
        '7711',
        '7712',
        '7713',
        '7714',
        '7715',
        '7716',
        '7717',
        '7718',
        '7719',
        '7720',
        '7798',
        '7799',
        '7080',
        '7095'
    ]
    let centersOfEuropa = [
        '7000',
        '7010',
        '7015',
        '7016',
        '7017',
        '7030',
        '7031',
        '7032',
        '7034',
        '7035',
        '7036',
        '7037',
        '7038',
        '7050',
        '7051',
        '7052',
        '7053',
        '7054',
        '7060',
        '7065',
        '7100',
        '7101',
        '7110',
        '7120',
        '7124',
        '7125',
        '7126',
        '7127',
        '7128',
        '7129',
        '7130',
        '7140',
        '7150',
        '7160',
        '7180',
        '7181',
        '7182',
        '7210',
        '7220',
        '7240',
        '7241',
        '7242',
        '7243',
        '7270',
        '7280'
    ]
    return getSelectedCenter(state) ? centersOfIberia.includes(getSelectedCenter(state).toString()) || centersOfEuropa.includes(getSelectedCenter(state).toString()) : null
}

export const isUsaOrCanada = state => {
    const salesOrg = getSalesOrg(state)
    const usaCanadaSalesOrg = getUsaCanadaSalesOrg()

    return salesOrg && usaCanadaSalesOrg.includes(salesOrg) && true
}

export const isAustralianCenter = state => {
    return getSelectedCenter(state) && getAustraliaCenters().includes(getSelectedCenter(state).toString())
}

export const isUserAustralianCenter = state => {
    return getUserCenter(state) && getAustraliaCenters().includes(getUserCenter(state).toString())
}

export const isAustraliaSalesOrg = state => {
    const salesOrg = getSalesOrg(state)
    const australiaSalesOrg = getAustraliaSalesOrg()
    return salesOrg && australiaSalesOrg.includes(salesOrg) && true
}

export const isNoSilestoneCenter = state => {
    return getSelectedCenter(state) && getNoSilestoneCenters().includes(getSelectedCenter(state).toString())
}

export const isEuropeOrIberiaSalesOrg = state => {
    const salesOrg = getSalesOrg(state)
    const europeIberiaSalesOrg = getEuropeIberiaSalesOrg()

    return salesOrg && europeIberiaSalesOrg.includes(salesOrg) && true
}

export const getMktoolsShippingCenter = state => {
    if (state.centers) {
        if (state.centers.mktoolsShippingCenter) return state.centers.mktoolsShippingCenter
        if (getSelectedCenter(state) === getShowroomCenterId()) {
            if (getShippingAddressesCenters(state) && getShippingAddressesCenters(state)[0]) return getShippingAddressesCenters(state)[0].id
        }
        return state.centers.mktoolsShippingCenter
    }else return null
    
}
export const getIsMktoolsShippingCenterSelected = state => {
    return state.centers ? state.centers.mktoolsShippingCenter !== null : false
}
export const getMktoolsBudget = state => {
    if (!getShippingAddressesCenters(state) || !getMktoolsShippingCenter(state)) return null
    return getShippingAddressesCenters(state).find(center => center.id === getMktoolsShippingCenter(state)).mktoolsBudget
}
export const getMktoolsBudgetWasted = state => {
    if (!getShippingAddressesCenters(state) || !getMktoolsShippingCenter(state)) return null
    return getShippingAddressesCenters(state).find(center => center.id === getMktoolsShippingCenter(state)).mktoolsBudgetWasted
}
export const getPricesGroup = state => {
    if (getIsMktoolsUsaCenter(state)) return '02'
    if (!getShippingAddressesCenters(state)) return 'default'
    if (getMktoolsShippingCenter(state) && getShippingAddressesCenters(state))
        return getShippingAddressesCenters(state).find(center => center.id === getMktoolsShippingCenter(state)).pricesGroup
}
export const getCoin = state => {
    if (getMktoolsShippingCenter(state) && getShippingAddressesCenters(state))
        return getShippingAddressesCenters(state).find(center => center.id === getMktoolsShippingCenter(state)).coin
}
export const canOffer = state => {
    if (!user.getIsLogged(state)) return false
    const centersCanViewOffer = [7510]
    if (centersCanViewOffer.includes(getSelectedCenter(state))) return true
    return false
}
export const canViewNewTracking = state => {
    // let centerPrototype = [2040, 7080, 7126, 7000, 7030, 7100] // Pilot 11/05

    // if (!getCenterData(state)) return false
    // let includesPilotCenter = false

    // //TODO: disable 07/05
    // includesPilotCenter = getCenters(state).find(center => {
    //     return centerPrototype.includes(center.centerId)
    // })

    // return includesPilotCenter
    return state.login.canViewNewOrders
}

//AFFECTED-CODE-HQ: El Centro nuevo HQ ¿muestra los enlaces de mktools o el de virtualVisit?
export const getIsVirtualVisitEnabled = state => {
    if (!user.getIsLogged(state)) return false
    if (getIsIkeaCenter(state) || getIsLowesCenter(state) || getSelectedCenter(state) === getDRIUSACenterId()) return false
    return true
}
export const getCanViewImageByLot = state => {
    let center = getCenters(state).find(center => center.centerId === getSelectedCenter(state))
    return center.imageLotAvailable
}

export const isOptionalBuilderProjectCenter = state => {
    const centers = getCenters(state)
    const optionalSalesOrg = [...getUsaCanadaSalesOrg(), ...getOptionalBuilderProjectsSalesOrg()]

    for (const center of centers) {
        if (optionalSalesOrg.includes(center.salesOrg)) {
            return true
        }
    }
    return false
}

export const hasSingleSlabAvailableInSelectedCenter = (state) => {
    const center = state && state.centers && state.centers.centers && state.centers.centers.find(center => center.centerId == getSelectedCenter(state))
    if (center && center.shippingTypes.includes("SINGLE_SLAB")) {
        return true
    }

    return false
}

export const isLatamSalesOrg = state => {
    const salesOrg = getSalesOrg(state)
    const latamSalesOrg = getLatamSalesOrg()

    if (salesOrg && latamSalesOrg.includes(salesOrg)) {
        return true
    }

    return false
}

export const isApacSalesOrg = state => {
    const salesOrg = getSalesOrg(state)
    const apacSalesOrg = getApacSalesOrg()

    if (salesOrg && apacSalesOrg.includes(salesOrg)) {
        return true
    }

    return false
}

/**
 * If the customer has the ENV3 (Single Slab) type of shipment available in its centers, it will be able to choose the delivery dates by position. 
 * Otherwise, it will not be possible to modify the date by position.
 * @returns {boolean}
 */
export const isDisabledPreferedDeliveryDatePicker = (state) => {
    if (hasSingleSlabAvailableInSelectedCenter(state)) {
        return false
    }

    return true
}

export const isOnlyUsaSalesOrg = state => {
    const salesOrg = getSalesOrg(state)
    const usaSalesOrg = getOnlyUsaSalesOrg()

    return salesOrg && usaSalesOrg.includes(salesOrg) && true
}

//List centers
export const getHQCenterId = () => 1100
export const getShowroomCenterId = () => 1060 //MKTOOLS
export const getNaturalStoneSpainCenterId = () => 1070
export const getFregaderosCenterId = () => 1080
export const getIKEACenterId = () => 4100
export const getIKEACanariasCenterId = () => 4037
export const getWarehoseCenterId = () => 5000 //MKTOOLS
export const getDRIUSACenterId = () => 5200 //MKTOOLS
export const getLatinaVitoriaCenterId = () => 7500
export const getLatinaDepot = () => 7507
export const getHUBSydneyCenterId = () => 7536
export const getHUBHoustonCenterId = () => 7625
export const getHUBNorfolkCenterId = () => 7626
export const getLowesCenterId = () => 7777
export const getUsaCanadaSalesOrg = () => ['2011', '2032', '2036', '2037']
export const getOptionalBuilderProjectsSalesOrg = () => ['2021', '2031', '2041']
export const isLatinaVitoria = state => state.centers.selectedCenter == 7500 && state.centers.selectedShippingMethod === 'CONTAINER'
export const getEuropeIberiaSalesOrg = () => ['2003', '2000', '2001', '2002', '2003', '2004', '2030', '2004', '2007', '2008', '2009', '2010', '2014', '2015', '2016', '2038', '2039', '2042', '2047', '1000', '1100', '3000', '2006', '2005', '1037']
export const getAustraliaCenters = () => ['7530','7531','7532','7533','7534','7535','7536','7537','7538']
export const getNoSilestoneCenters = () => ['7531','7532']
export const getAustraliaSalesOrg = () => ['2031']
export const getLatamSalesOrg = () => ['2012', '2026', '2027']
export const getApacSalesOrg = () => ['2017', '2031', '2034', '2040']
export const getOnlyUsaSalesOrg = () => ['2011', '2037', '2046']
export const getEuropeAndPortugalCenters = () => [
    '7000', '7000', '7010', '7015', '7015', '7016', '7017', '7030', '7030', 
    '7031', '7032', '7034', '7035', '7036', '7037', '7038', '7041', '7050', 
    '7053', '7054', '7060', '7065', '7100', '7101', '7110', '7110', '7120', 
    '7122', '7124', '7125', '7126', '7127', '7128', '7128', '7129', '7130', 
    '7130', '7140', '7140', '7280', '7150', '7160', '7051', '7052', '7052', 
    '7210', '7220', '7270', '7240', '7241', '7242', '7242', '7290', '7080', 
    '7095'
  ]

export default centers
