import React from 'react'
import Translation from '../../global/Translation'
import LocalizedLink from '../../global/LocalizedLink'
import Button from '../../global/Button'
import InputNumber from '../../global/InputNumber'
import AddFavourite from '../../global/AddFavourite'
import ProductImage from '../ProductImage'
import BannerResultV2 from '../banners/BannerResultV2'
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import DisplayTechnology from './displays/DisplayTechnology'
import DisplayBlackIndicator from './displays/DisplayBlackIndicator'

const RectangleResultV2UI = props => {
    const limit = props.getLimit()

    const showValidImage = () => {
        if (props.product.detailImage && props.product.detailImage !== 'null' && props.product.detailImage !== undefined && props.product.detailImage !== 'undefined')
            return props.product.detailImage
        if (props.product.imgLarge && (props.product.imgLarge !== 'null' || props.product.imgLarge != 'null')) return props.product.imgLarge
        return cameraOff
    }

    const placeHolderClassName = () => {
        if (!props.product.detailImage || props.product.detailImage === 'null' || !props.product.imgLarge || props.product.imgLarge === 'null' || props.product.imgLarge == 'null')
            return '--placeholder'
        return ''
    }

    const extras = () => {
        return props.getExtras()
    }

    const isCosentinoBrazil = () => {
        return extras() && extras().isCosentinoBrazil
    }

    return (
        <React.Fragment>
            {/********************************************* BANNERS **************************************************/}
            {props.result &&
                props.index === 4 &&
                props.location.pathname.includes(props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')) &&
                !props.isHideAllBannerCatalog &&
                !props.isIkeaCenter && (
                    <React.Fragment>
                        {props.promotionLateralBanner && props.hasPromotionLandingActivated && (
                            <div className="box-parent-banner-result-v2" style={{ background: 'white', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                {/*//TODO: Cambiar el enlace a la landing de la promoción*/}
                                <LocalizedLink routeId="ROUTE_PROMOTION_LANDING" params={{ promotionId: props.promotionLink }} gtmLabel="ec_banner_list">
                                    <div className="d-none d-md-block position-relative">
                                        {/* <div className="d-none d-md-block position-relative box-parent-banner-result-v2" style={{ backgroundImage: `url(${this.props.promotionLateralBanner})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center' }}> */}

                                        <div className="text_banner text_banner--filters ">
                                            <div dangerouslySetInnerHTML={{ __html: props.promotionBannerTitle }} />
                                            <div dangerouslySetInnerHTML={{ __html: props.promotionBannerDescription }} />
                                        </div>
                                        <img src={props.promotionLateralBanner} style={{ width: '100%' }} alt="" className=" image-slide" />
                                    </div>
                                </LocalizedLink>
                                <br />
                                <div className="m-3">
                                    <LocalizedLink routeId="ROUTE_PROMOTION_LANDING" params={{ promotionId: props.promotionLink }} gtmLabel="ec_banner_list">
                                        <Button inverted size="large">
                                            <Translation id="view_section" defaultMessage="View section" />
                                        </Button>
                                    </LocalizedLink>
                                </div>
                            </div>
                        )}
                        {!(props.hasPromotionLandingActivated && props.promotionLateralBanner) && (
                            <BannerResultV2
                                type="0"
                                isIkeaCenter={props.isIkeaCenter}
                                testAB={props.testAB}
                                typeClient={props.typeClient}
                                location={props.location}
                                showBannerPromo={props.showBannerPromo}
                            />
                        )}
                    </React.Fragment>
                )}
            {/********************************************** FIN BANNERS *********************************************/}

            <div
                className={`rectangle-result-v2 v2 ${props.size && props.size === 'S' ? 'small' : ''} ${props.mallPromotionSection ? 'mall-promo' : ''} ${
                    props.product.fromMall ? 'landing-mall' : ''
                }`}
                data-cy="catalog__product_rectangle_result"
                data-key={props.product.productId}
                data-color={props.product.colorId}
                type={props.getTypeProductForStyles()}
            >
                {(!props.product.fromMall || props.showImg) && (
                    <div className="wrapper-product-image">
                        {props.indicators}

                        {props.canOnlyViewOutlet ? (
                            props.product.lotViewAvailable === undefined || props.product.lotViewAvailable ? (
                                <LocalizedLink routeId="ROUTE_LOTS" params={{ id: props.product.productId, outlet: 'outlet' }}>
                                    <ProductImage product={props.product} state={props.state} intl={props.intl} />
                                </LocalizedLink>
                            ) : (
                                <ProductImage product={props.product} state={props.state} intl={props.intl} />
                            )
                        ) : (
                            <LocalizedLink
                                routeId="ROUTE_PRODUCT_DETAIL"
                                params={{ id: props.product.productId, outlet: props.outlet ? 'outlet' : '' }}
                                onClick={() => props.goToProductCard(props.product)}
                            >
                                {/* Show the custom upload image or normal image of Mall Campaigns */}
                                {props.product.fromMall ? (
                                    <div className={'product-image-container ' + placeHolderClassName()}>
                                        <div
                                            className="product-image"
                                            style={{
                                                backgroundColor: 'white',
                                                backgroundImage: 'url("' + showValidImage() + '")',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }}
                                        >
                                            <div className="product-image-technology">
                                                <div className="technology-content">
                                                    <DisplayTechnology technology={props.product.technology} subBrand={props.product.subBrand} />
                                                </div>

                                                {props.canViewPricesPhase3 &&
                                                    props.state &&
                                                    props.state.promotions &&
                                                    props.state.promotions.length > 0 &&
                                                    (props.state.promotions[0].dealType === 'A' ? (
                                                        <div className="d-flex">
                                                            <DisplayBlackIndicator value={props.intl.formatMessage({ id: 'new_price' })} />
                                                        </div>
                                                    ) : props.state.promotions[0].dealType === 'B' ? (
                                                        <div className="d-flex">
                                                            <DisplayBlackIndicator value={`-${props.state.promotions[0].value}%`} />
                                                        </div>
                                                    ) : null)}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <ProductImage product={props.product} state={props.state} intl={props.intl} />
                                )}
                            </LocalizedLink>
                        )}
                        {!props.product.fromMall && <AddFavourite productId={props.product.productId} />}
                    </div>
                )}

                {props.details}

                {props.canBuy && (
                    (props.isLatinaVitoria && ['SENSA', 'SCALEA', 'PREXURY'].includes(props.product.brand)) ? (
                /* {props.canBuy &&
                    (props.showLotsInPortfolio(props.product, !!props.outlet, isCosentinoBrazil()) ? ( */
                        props.getStock() > 0 ? (
                            <div className="pb-3 px-3">
                                <LocalizedLink
                                    routeId="ROUTE_LOTS"
                                    params={{ id: props.product.productId, outlet: '' }}
                                    onClick={() => {
                                        props.product.extra = props.getExtras()
                                        props.setLastSelectedProduct(props.product)
                                    }}
                                >
                                    <Button>
                                        <Translation id="view_lots" defaultMessage="View lots" />
                                    </Button>
                                </LocalizedLink>
                            </div>
                        ) : (
                            <div className="pb-3 px-3">
                                <Button disabled={true}>
                                    <Translation id="view_lots" defaultMessage="View lots" />
                                </Button>
                            </div>
                        )
                    ) : (
                        <div className={`product-actions ${props.mallPromotionSection ? 'mall' : ''}`}>
                            {!props.product.haveOptions && !props.mallPromotionSection && (
                                <div className="input-row">
                                    <div className="input">
                                        <InputNumber
                                            value={props.getValueToAdd()}
                                            change={qty => props.state.setQtyToAdd(qty)}
                                            step={props.isIkeaCenter && props.product.unit === 'M2' ? 0.01 : props.getStep()}
                                            limit={limit}
                                            min={props.getMin()}
                                            decimals={props.isIkeaCenter && props.product.unit === 'M2' ? 2 : 0}
                                        />
                                    </div>
                                </div>
                            )}
                            {props.mallPromotionSection && (
                                <LocalizedLink
                                    routeId="ROUTE_PRODUCT_DETAIL"
                                    params={{ id: props.product.productId, outlet: props.outlet ? 'outlet' : '' }}
                                    onClick={() => {
                                        if (props.indexOfList !== undefined) props.clickDetail(props.product, props.list, props.indexOfList)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <Button>
                                        <span className="btn-add-to-cart-text">
                                            <Translation id="view_more" defaultMessage="View more" />
                                        </span>
                                    </Button>
                                </LocalizedLink>
                            )}
                            <div className="bt-row">
                                {props.product.haveOptions ? (
                                    props.canOnlyViewOutlet ? (
                                        (props.product.lotViewAvailable === undefined || props.product.lotViewAvailable) && (
                                            <LocalizedLink routeId="ROUTE_LOTS" params={{ id: props.product.productId, outlet: 'outlet' }}>
                                                <Button icon="shopping-cart" size="small" customClass="cart">
                                                    <Translation id="options" defaultMessage="Options" />
                                                </Button>
                                            </LocalizedLink>
                                        )
                                    ) : (
                                        <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL" params={{ id: props.product.productId, outlet: props.outlet ? 'outlet' : '' }}>
                                            <Button icon="shopping-cart" size="small" customClass="cart">
                                                <Translation id="options" defaultMessage="Options" />
                                            </Button>
                                        </LocalizedLink>
                                    )
                                ) : (
                                    <Button
                                        far={true}
                                        icon="cart-shopping"
                                        inverted
                                        customClass="cart add-to-cart-button"
                                        disabled={props.isAdding || (limit != null ? limit : 1) <= 0 || !props.state.qtyToAdd >= 1}
                                        onClick={() => props.addToCart()}
                                        dataQas="global_product-add-to-cart-button"
                                    >
                                        <span className="btn-add-to-cart-text">
                                            <Translation id="add" defaultMessage="add" />
                                        </span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </React.Fragment>
    )
}

export default RectangleResultV2UI
