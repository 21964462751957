import React from 'react'
import Translation from '../../../global/Translation'

export default class DisplayUpcomingIndicator extends React.Component {
    render() {
        const { isCheckout = false } = this.props

        // Styles for the checkout view
        const checkoutStyles = {
            backgroundColor: '#FCD03D',
            color: '#232323',
            padding: '3px 6px',
            fontSize: '9px',
            fontWeight: '500',
            zIndex: 1,
            display: 'inline-block',
            lineHeight: '11px',
        }

        // Default styles
        const defaultStyles = {
            backgroundColor: '#FCD03D',
            color: '#232323',
            padding: '4px 8px',
            fontSize: '10px',
            fontWeight: '500',
            zIndex: 1,
            display: 'inline-block',
            lineHeight: '16px',
        }

        const styles = isCheckout ? checkoutStyles : defaultStyles

        return (
            <div style={styles}>
                <Translation id="coming_soon" defaultMessage="Coming soon" />
            </div>
        )
    }
}
