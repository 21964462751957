import React from 'react'

import PropTypes from 'prop-types'
import { confirmable } from 'react-confirm'
import Button from './Button'
import {defineMessages, injectIntl, IntlProvider, FormattedMessage} from 'react-intl'
import {messages as i18nMessages} from '../../i18n/messages'


defineMessages({
    CONFIRM: {
        id: 'CONFIRM',
        description: 'CONFIRM',
        defaultMessage: 'Are you sure?'
    },
    CHANGE_CENTER_CONFIRM: {
        id: 'CHANGE_CENTER_CONFIRM',
        description: 'CHANGE_CENTER_CONFIRM',
        defaultMessage: 'Cart is linked to center, are you sure?'
    },
    CHANGE_STORE_CONFIRM: {
        id: 'CHANGE_STORE_CONFIRM',
        description: 'CHANGE_STORE_CONFIRM',
        defaultMessage: 'Cart is linked to store, are you sure?'
    },
    CHANGE_SHIPPING_CENTER: {
        id: 'CHANGE_SHIPPING_CENTER',
        description: 'CHANGE_SHIPPING_CENTER',
        defaultMessage: 'Do you want to change the market? your selection by shopping cart will be lost'
    },
    IMPERSONATE_CONFIRM: {
        id: 'IMPERSONATE_CONFIRM',
        description: 'IMPERSONATE_CONFIRM',
        defaultMessage: 'Cart is linked to center and user, are you sure?'
    },
    REJECT_RESERVATION_CONFIRM: {
        id: 'REJECT_RESERVATION_CONFIRM',
        description: 'REJECT_RESERVATION_CONFIRM',
        defaultMessage: 'Are you sure to reject this reservation?'
    },
    DELETE_TEMPLATE_LOWES_CONFIRM: {
        id: 'DELETE_TEMPLATE_LOWES_CONFIRM',
        description: 'DELETE_TEMPLATE_LOWES_CONFIRM',
        defaultMessage: 'Are you sure to delete this template?'
    },
    DELETE_ITEM_LOWES_CONFIRM: {
        id: 'DELETE_ITEM_LOWES_CONFIRM',
        description: 'DELETE_ITEM_LOWES_CONFIRM',
        defaultMessage: 'Are you sure you want to delete this item?'
    },
    ACTIVATE_PROMOTION_MAILING_CONFIRM:{
        id: 'ACTIVATE_PROMOTION_MAILING_CONFIRM',
        description: 'ACTIVATE_PROMOTION_MAILING_CONFIRM',
        defaultMessage: 'Are you sure to activate the promotion? it will trigger the mailing on promotion start date' 
    },
    CONFIRM_REJECT_QUOTE: {
        id: 'CONFIRM_REJECT_QUOTE',
        description: 'CONFIRM_REJECT_QUOTE',
        defaultMessage: 'Are you sure to reject this quote?'
    },
    CONFIRM_ACCEPT_QUOTE: {
        id: 'CONFIRM_ACCEPT_QUOTE',
        description: 'CONFIRM_ACCEPT_QUOTE',
        defaultMessage: 'Are you sure to accept this quote?'
    }
})

function findLangFromProvider() {
    const node = document.querySelector('#root')
    let _node

    for (var key in node) {
        if (key.startsWith("__reactContainer")) {
            _node = node[key].child
        }
    }

    return  _node
        && _node.memoizedState
        && _node.memoizedState.storeState
        && _node.memoizedState.storeState.ui
        && _node.memoizedState.storeState.ui.language
}

const ConfirmDialog = (props) => {
    const lang = findLangFromProvider()
    return <IntlProvider locale={lang} messages={i18nMessages[lang]} key={lang}>
            <InjectedConfirmDialog {...props}/>
        </IntlProvider>
}

export const InnerConfirmDialog = ({ show, proceed, dismiss, cancel, confirmation, options, intl }) => {

    const textOk = options.textOk ? options.textOk : intl ? intl.formatMessage({id:'ok', defaultMessage:'Ok'}) : 'OK'
    const textCancel = options.textCancel ? options.textCancel : intl ? intl.formatMessage({id:'cancel', defaultMessage:'Cancel'}) : 'CANCEL'

    return (
        <div className={options.isV2 ? 'modal-v2 v2' : 'modal-v2 v2'}>
            <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
            <div id="confirm-container" className="confirm" style={{ display: show ? 'block' : 'none' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 container">
                            <header className="container-fluid">
                                <div>
                                    <h2>{confirmation}</h2>
                                    <div className="confirm-container-buttons">
                                        <Button className={'text-cancel-btn'} onClick={() => cancel('arguments will be passed to the callback')} datacy="confirm_dialog__cancel">
                                            {textCancel}
                                        </Button>
                                        <Button className={'text-ok-btn bt-inverted'} onClick={() => proceed('same as cancel')} datacy="confirm_dialog__ok">
                                            {textOk}
                                        </Button>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const InjectedConfirmDialog = injectIntl(InnerConfirmDialog)


InnerConfirmDialog.propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if theyarn start dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
}

export default confirmable(ConfirmDialog)
