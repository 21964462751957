import React,{useState} from 'react'

import Select from 'react-select'
import iconLocation from '../../assets/img/icons/location.svg'
import chevronDown from '../../assets/img/icons/chevron-down.svg'
import { injectIntl } from 'react-intl'
import confirm from '../global/confirm'
import Translation from '../global/Translation'
import Button from '../global/Button'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'
import Modal from '../global/Modal';

const centerOption = createClass({
    propTypes: {
        children: PropTypes.node,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isFocused: PropTypes.bool,
        isSelected: PropTypes.bool,
        onFocus: PropTypes.func,
        onSelect: PropTypes.func,
        option: PropTypes.object.isRequired
    },
    handleMouseDown(event) {
        event.preventDefault()
        event.stopPropagation()
        this.props.onSelect(this.props.option, event)
    },
    handleMouseEnter(event) {
        this.props.onFocus(this.props.option, event)
    },
    handleMouseMove(event) {
        if (this.props.isFocused) return
        this.props.onFocus(this.props.option, event)
    },
    render() {
        let imageStyle = {
            borderRadius: 3,
            display: 'inline-block',
            marginRight: 2,
            position: 'relative',
            top: -2,
            verticalAlign: 'middle',
            height: '20px',
            background: 'transparent',
            color: 'black'
        }
        const icons = { FULL_TRUCK: 'icon fal fa-truck', DIRECT_TRUCK: 'icon fal fa-truck', CONTAINER: 'icon fal fa-ship', SINGLE_SLAB: 'icon fal fa-rectangle-wide' }
        return (
            <div
                className={this.props.className}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
                title={this.props.option.title}
            >
                <span>{this.props.children}</span>
                {this.props.option.userType !== 'ADMIN'
                    ? this.props.option.shippingMethods.map((shippingMethod, index) => <i key={index} className={icons[shippingMethod]} style={imageStyle} />)
                    : null}
            </div>
        )
    }
})

const confirmChangeCenter = (center, onChangeCenter, intl, keepHere, userType, localizedUrl, reloadPage) => {
    if (keepHere) {
        onChangeCenter(parseInt(center, 10))      
        if (reloadPage) setTimeout(() => {
            window.location.reload()
        }, "1000")         
    } else {
        confirm(intl.formatMessage({ id: 'CHANGE_CENTER_CONFIRM' })).then(
            confirm => {
                if (center !== 7777) onChangeCenter(parseInt(center, 10), localizedUrl(intl, 'ROUTE_HOME'))
                else if (userType !== 'ADMIN') onChangeCenter(parseInt(center, 10), localizedUrl(intl, 'ROUTE_CATALOG_LOWES').replace(':filter', 'home'))
                else onChangeCenter(parseInt(center, 10), localizedUrl(intl, 'ROUTE_CATALOG_LOWES_ADMIN' ).replace(':filter', 'home'))
            },
            cancel => {}
        )
    }
}

const CentersV2 = ({ centers, intl, onChangeCenter, selectedCenter, keepHere, userType, dir: languageDir, employeeId, setEmployeeId, localizedUrl, reloadPage, simple, isSpanishSalesOrg, userName, naturalStoneViewingPermissions, isBetweenCenters }) => {
    const [showModal, changeShowModal] = useState(false)
    const [selectedCenterOnModal, changeSelectedCenter] = useState(selectedCenter)

    let options = centers.map(center => {
        return { value: center.centerId, label: center.name, shippingMethods: center.shippingTypes, userType: userType }
    })

    /**
     * Shown to all users except distributors, not loogged, admins, supply center 1100, distribution channel 10 and 11.
     * @param {object} state
     * @returns {boolean}
     */
    const manipulateCenterOptionsValues = () => {
        const { canViewHQ, canViewBrazil } = naturalStoneViewingPermissions
    
        // Case for LKARLSSON or when the user has both HQ and Brazil permissions
        if ((canViewHQ && isSpanishSalesOrg) || userName === 'LKARLSSON') {
            return options
        }
    
        /* if (canViewBrazil && isBetweenCenters(7500) !== null) {
            return options
        } */

        if (canViewHQ && !isSpanishSalesOrg) {
            return options.filter((center) => center.value != 1100)
        }
    
        /* if (canViewHQ && !canViewBrazil && !isSpanishSalesOrg) {
            return options.filter((center) => center.value !== 1100)
        } */

        /* if (!canViewHQ && canViewBrazil && isBetweenCenters(7500) === null) {
            return options.filter((center) => center.value !== 7500)
        } */
    
        return options
    }
    
    let currentCenter = centers.filter(center => center.centerId == selectedCenter)[0]
    let input = (
        <div className='center-name-header'>
            {currentCenter ? (
                <span>
                    <strong style={{color: "blue"}}>{currentCenter.name}</strong>{' '}<span onClick={changeShowModal} style={{ color: 'rgb(0, 123, 255)', cursor: 'pointer' }} data-qas="global_header-select-center-dropdown-open-button"><img alt="" src={chevronDown} /></span>
                </span>
            ) : (
                intl.formatMessage({ id: 'a_center_near_to_you' })
            )}{' '}
        </div>
    )

    const simpleInput = (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'end'
        }}>
            <Translation id="you_are_ordering_from" defaultMessage="You are ordering products from "/>
            <span style={{fontWeight:500}}>
                &nbsp;{currentCenter && currentCenter.name}
            </span>
            <span onClick={changeShowModal} style={{color: "#365BCA", cursor: "pointer", fontWeight:500}}>
                &nbsp;<Translation id="change" defaultMessage="Change"  />
            </span>
        </div>
    )
            
            // let options = centers.map(center => {
            //     return { value: center.centerId, label: center.name, shippingMethods: center.shippingTypes, userType: userType }
            // })
            // let input = ''
            // if (!selectedCenter) {
            //     input = <input type="text" readOnly placeholder={intl.formatMessage({ id: 'a_center_near_to_you' })} />
            // } else if (centers.length === 1) {
            //     input = <input type="text" readOnly value={centers[0].name !== 'IKEA' ? centers[0].name : ''} className={'center' + selectedCenter} />
            // } else {
            //     input = (
            //         <Select
            //             onChange={selectOption => {
            //                 if (selectOption !== null) confirmChangeCenter(selectOption.value, onChangeCenter, intl, keepHere, userType)
            //             }}
            //             value={selectedCenter}
            //             options={options}
            //             optionComponent={centerOption}
            //             clearable={false}
            //             className={'Select_center center' + selectedCenter}
            //             rtl={languageDir === 'rtl'}
            //         />
            //     )
            // }

            return (
                <div className="select-center">
                    { simple ? simpleInput : input }
                    {showModal && (
                        <Modal
                        customStyle={{'overflow-y': 'visible'}}
                        title={<Translation id="showing_catalog_of_center" defaultMessage="Showing catalog of {centerName}" values={{ centerName: currentCenter.name }} />}
                            // title={<Translation id="showing_catalog_of_center" defaultMessage="Showing catalog of "></Translation>>}
                            subtitle={<Translation id="change_center_to_view_other_catalogs" defaultMessage="Change your Cosentino center to view other products catalog"></Translation>}
                            onClose={() => changeShowModal()}
                        >
                            <div className="container" style={{padding:0}}>
                                <div className="form-group text-center" data-qas="global_header-select-center-dropdown-button">
                                    <Select
                                        onChange={selectOption => {
                                            // if (selectOption !== null) confirmChangeCenter(selectOption.value, onChangeCenter, intl, keepHere, userType)
                                            changeSelectedCenter(selectOption)
                                        }}
                                        value={selectedCenterOnModal}
                                        options={manipulateCenterOptionsValues()}
                                        optionComponent={centerOption}
                                        clearable={false}
                                        className={'Select_center center' + selectedCenter}
                                        rtl={languageDir === 'rtl'}
                                    />
                                </div>
                            </div>
                            <Button onClick={() => {
                                confirmChangeCenter(selectedCenterOnModal.value, onChangeCenter, intl, keepHere, userType, localizedUrl, reloadPage)
                                
                                console.log(selectedCenterOnModal)
                                }} inverted dataQas="global_header-select-center-load-catalog-button"><Translation id="load_catalog" defaultMessage="Load catalog" /></Button>
                        </Modal>
                    )}
                    {/* {!keepHere && (
                        <div className="icon">
                            <img
                                alt=""
                                src={iconLocation}
                                aria-hidden="true"
                            />
                        </div>
                    )} */}
                </div>
            )
        }

export default injectIntl(CentersV2)
