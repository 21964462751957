import React from 'react'
import Translation from '../../../global/Translation'

const DisplaySoldOut = () => {

    const styles = {
        backgroundColor: '#f7e671',
        color: '#000000',
        padding: '4px 8px',
        fontSize: '10px',
        fontWeight: '500',
        zIndex: 1,
        display: 'inline-block',
        lineHeight: '16px'
    }

    return (
        <div style={styles}>
            <Translation id="sold_out" defaultMessage="Sold out" />
        </div>
    )
}

export default DisplaySoldOut