import React from 'react'
import chevronDown from "../../assets/img/icons/chevron-down.svg";
import {RefinementListFilter} from "searchkit";

const FilterV2Box = props => {

  const {filteredDataName, translatedTitle, onTransform, listComponent, size, gtmLabel, operator = 'OR'} = props;

  return (
    <div className={'filtersV2-box ' + filteredDataName} onMouseLeave={() => {
      [...document.querySelectorAll("#" + filteredDataName + "-filter")].map(e => e.hidden = true);
      [...document.querySelectorAll("#" + filteredDataName + "-filter-container")].map(e => e.classList.remove('filter-active'));
    }} gtm-label={gtmLabel}>
      <div id={filteredDataName + '-filter-container'} className="filter-container">
        <div className="filters-box-items" onClick={()=> {
          [...document.querySelectorAll("#" + filteredDataName + "-filter")].map(e => e.hidden = !e.hidden);
          [...document.querySelectorAll("#" + filteredDataName + "-filter-container")].map(e =>
            e.classList.contains('filter-active') ?
              e.classList.remove('filter-active') :
              e.classList.add('filter-active')
          );
        }}
        >
          <span>{translatedTitle}</span>
          <img alt="" src={chevronDown} />
        </div>
        <div hidden={true} id={filteredDataName + '-filter'} className='filter-list'>
          <RefinementListFilter
            id={filteredDataName}
            field={filteredDataName + ".raw"}
            operator={operator}
            title={translatedTitle}
            bucketsTransform={onTransform}
            size={size}
            listComponent={listComponent}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterV2Box
