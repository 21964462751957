import React, {useEffect} from 'react'

import { Route, Switch } from 'react-router-dom'
import { injectIntl, defineMessages } from 'react-intl'
import { withRouter } from 'react-router'

import CatalogScreen from './screens/CatalogScreen'
import CheckoutScreen from './screens/CheckoutScreen'
import CartSummaryScreen from './screens/CartSummaryScreen'
import ProductDetailScreen from './screens/ProductDetailScreen'
import ReservationsScreen from './screens/ReservationsScreen'
import KpisScreen from './screens/KpisScreen'
import KpiDetailScreen from './screens/KpiDetailScreen'
import HomeScreen from './screens/HomeScreen'
import LotsScreen from './screens/LotsScreen'
import ProfileScreen from './screens/ProfileScreen'
import LotImageViewerScreen from './screens/LotImageViewerScreen'
import PromotionsScreen from './screens/PromotionsScreen'
import LandingManagerScreen from './screens/LandingManagerScreen'
import LandingCreationScreen from './screens/LandingCreationScreen'
import LandingTemplatesScreen from './screens/LandingTemplatesScreen'
import PromotionAdminScreen from './screens/PromotionAdminScreen'
import LandingPromotionsScreen from './screens/LandingPromotionsScreen'
import SuggestionScreen from './screens/SuggestionScreen'
import ChangeLogScreen from './screens/ChangeLogScreen'
import MktAdminProductsScreen from './screens/MktAdminProductsScreen'

import Error404Screen from './screens/Error404Screen'
import MaintenanceScreen from './screens/MaintenanceScreen'
import { getIsLogged, getUserType, getIsMktoolsAdmin, getUserAuthorization, getIsMallAdmin, getIsMallPilotUser, getIsCampaignsPilotUser } from './store/login/reducers'
import { fetchCart } from './store/cart/actions'
import { getSelectedCenter } from './store/centers/reducers'
import { connect } from 'react-redux'

import Layout from './components/layout/Layout'
import CutToSizeScreen from './screens/CutToSizeScreen'
import CatalogLowesScreen from './screens/CatalogLowesScreen'
import CatalogLowesAdminScreen from './screens/CatalogLowesAdminScreen'
import LowesRegister from './components/catalog-lowes/LowesRegister'
import LowesNewUserRegister from './components/catalog-lowes/LowesNewUserRegister'
import RedirectSSOScreen from './screens/RedirectSSOScreen'
import { getMaintenanceMode } from './store/maintenance-mode/reducers'
import DeviatedAdminScreen from './screens/DeviatedAdminScreen'
import RedirectJTScreen from './screens/RedirectJTScreen'
import OldLoginScreen from './screens/OldLoginScreen'
import MktAdminColorsScreen from './screens/MktAdminColorsScreen'
import CookiePolicy from './components/global/CookiePolicy'
import PrivacyPolicy from './components/global/PrivacyPolicy'
import { loginRequired } from './store/login/actions'
import PromotionsListScreen from './screens/PromotionsListScreen'
import UnsubscribeScreen from './screens/UnsubscribeScreen'
import { getLanguage, getLocalizedUrl } from './store/ui/reducers'
import PageViewRouteComponent from "./PageViewRouteComponent";
import ConfigCutToSizeScreen from "./screens/ConfigCutToSizeScreen";
import ConfigCutToSizePlanAcceptanceScreen from "./screens/ConfigCutToSizePlanAcceptanceScreen";


defineMessages({
    ROUTE_HOME: {
        id: 'ROUTE_HOME',
        description: 'ROUTE_HOME',
        defaultMessage: 'ROUTE_HOME'
    },
    ROUTE_CATALOG: {
        id: 'ROUTE_CATALOG',
        description: 'ROUTE_HOME',
        defaultMessage: 'ROUTE_HOME'
    },
    ROUTE_PRODUCT_DETAIL: {
        id: 'ROUTE_PRODUCT_DETAIL',
        description: 'ROUTE_PRODUCT_DETAIL',
        defaultMessage: 'ROUTE_PRODUCT_DETAIL'
    },
    ROUTE_FLASH_PROMOTIONS: {
        id: 'ROUTE_FLASH_PROMOTIONS',
        description: 'ROUTE_FLASH_PROMOTIONS',
        defaultMessage: 'ROUTE_FLASH_PROMOTIONS'
    },
    ROUTE_ADMIN_PROMOTIONS: {
        id: 'ROUTE_ADMIN_PROMOTIONS',
        description: 'ROUTE_ADMIN_PROMOTIONS',
        defaultMessage: '/admin-promotions'
    },
    //TODO uncomment when landing set
    // ROUTE_PROMOTIONS: {
    //     id: 'ROUTE_LANDING_PROMOTIONS',
    //     description: 'ROUTE_LANDING_PROMOTIONS',
    //     defaultMessage: 'ROUTE_LANDING_PROMOTIONS'
    // },
    ROUTE_PROMOTIONS_LIST: {
        id: 'ROUTE_PROMOTIONS_LIST',
        description: 'ROUTE_PROMOTIONS_LIST',
        defaultMessage: '/promotions-list'
    },
    ROUTE_CART_SUMMARY: {
        id: 'ROUTE_CART_SUMMARY',
        description: 'ROUTE_CART_SUMMARY',
        defaultMessage: 'ROUTE_CART_SUMMARY'
    },
    ROUTE_CHECKOUT: {
        id: 'ROUTE_CHECKOUT',
        description: 'ROUTE_CHECKOUT',
        defaultMessage: 'ROUTE_CHECKOUT'
    },
    ROUTE_CHANGELOG: {
        id: 'ROUTE_CHANGELOG',
        description: 'ROUTE_CHANGELOG',
        defaultMessage: 'ROUTE_CHANGELOG'
    },
    ROUTE_RESERVATIONS: {
        id: 'ROUTE_RESERVATIONS',
        description: 'ROUTE_RESERVATIONS',
        defaultMessage: 'ROUTE_RESERVATIONS'
    },
    ROUTE_KPI_DETAIL: {
        id: 'ROUTE_KPI_DETAIL',
        description: 'ROUTE_KPI_DETAIL',
        defaultMessage: 'ROUTE_KPI_DETAIL'
    },
    ROUTE_KPIS: {
        id: 'ROUTE_KPIS',
        description: 'ROUTE_KPIS',
        defaultMessage: 'ROUTE_KPIS'
    },
    ROUTE_LOT_IMAGE_VIEWER: {
        id: 'ROUTE_LOT_IMAGE_VIEWER',
        description: 'ROUTE_LOT_IMAGE_VIEWER',
        defaultMessage: 'ROUTE_LOT_IMAGE_VIEWER'
    },
    ROUTE_LOTS: {
        id: 'ROUTE_LOTS',
        description: 'ROUTE_LOTS',
        defaultMessage: 'ROUTE_LOTS'
    },
    ROUTE_PROFILE: {
        id: 'ROUTE_PROFILE',
        description: 'ROUTE_PROFILE',
        defaultMessage: 'ROUTE_PROFILE'
    },
    ROUTE_CUT_TO_SIZE: {
        id: 'ROUTE_CUT_TO_SIZE',
        description: 'ROUTE_CUT_TO_SIZE',
        defaultMessage: 'ROUTE_CUT_TO_SIZE'
    },
    ROUTE_CONFIG_CUT_TO_SIZE: {
        id: 'ROUTE_CONFIG_CUT_TO_SIZE',
        description: 'ROUTE_CONFIG_CUT_TO_SIZE',
        defaultMessage: '/configurables/:type/:model/:fabricator/:refcode?'
    },
    ROUTE_CONFIG_CUT_TO_SIZE_PLAN_ACCEPTANCE: {
        id: 'ROUTE_CONFIG_CUT_TO_SIZE_PLAN_ACCEPTANCE',
        description: 'ROUTE_CONFIG_CUT_TO_SIZE_PLAN_ACCEPTANCE',
        defaultMessage: '/configurable-plan-acceptance/:type/:model/:fabricator/:refcode'
    },
    ROUTE_SUGGESTIONS: {
        id: 'ROUTE_SUGGESTIONS',
        description: 'ROUTE_SUGGESTIONS',
        defaultMessage: 'ROUTE_SUGGESTIONS'
    },
    ROUTE_CATALOG_LOWES: {
        id: 'ROUTE_CATALOG_LOWES',
        description: 'ROUTE_CATALOG_LOWES',
        defaultMessage: 'ROUTE_CATALOG_LOWES'
    },
    ROUTE_CATALOG_LOWES_SHOP: {
        id: 'ROUTE_CATALOG_LOWES_SHOP',
        description: 'ROUTE_CATALOG_LOWES_SHOP',
        defaultMessage: 'ROUTE_CATALOG_LOWES_SHOP'
    },
    ROUTE_CATALOG_LOWES_ADMIN: {
        id: 'ROUTE_CATALOG_LOWES_ADMIN',
        description: 'ROUTE_CATALOG_LOWES_ADMIN',
        defaultMessage: 'ROUTE_CATALOG_LOWES_ADMIN'
    },
    ROUTE_LOWES_REGISTER: {
        id: 'ROUTE_LOWES_REGISTER',
        description: 'ROUTE_LOWES_REGISTER',
        defaultMessage: 'ROUTE_LOWES_REGISTER'
    },
    ROUTE_LOWES_NEW_USER_REGISTER: {
        id: 'ROUTE_LOWES_NEW_USER_REGISTER',
        description: 'ROUTE_LOWES_NEW_USER_REGISTER',
        defaultMessage: '/lowes-new-user'
    },
    ROUTE_MKT_ADMIN_PRODUCTS: {
        id: 'ROUTE_MKT_ADMIN_PRODUCTS',
        description: 'ROUTE_MKT_ADMIN_PRODUCTS',
        defaultMessage: '/mkt-admin-products'
    },
    ROUTE_MKT_ADMIN_COLORS: {
        id: 'ROUTE_MKT_ADMIN_COLORS',
        description: 'ROUTE_MKT_ADMIN_COLORS',
        defaultMessage: '/mkt-admin-colors'
    },
    ROUTE_DEVIATED_ADMIN_PRODUCTS: {
        id: 'ROUTE_DEVIATED_ADMIN',
        description: 'ROUTE_DEVIATED_ADMIN',
        defaultMessage: '/deviated-admin'
    },
    ROUTE_COOKIES: {
        id: 'ROUTE_COOKIES',
        description: 'ROUTE_COOKIES',
        defaultMessage: '/cookies-policy'
    },
    ROUTE_PRIVACY: {
        id: 'ROUTE_PRIVACY',
        description: 'ROUTE_PRIVACY',
        defaultMessage: '/privacy-policy'
    },
    ROUTE_LANDING_MNGR: {
        id: 'ROUTE_LANDING_MNGR',
        description: 'ROUTE_PRIVACY',
        defaultMessage: '/privacy-policy'
    },
    ROUTE_LANDING_CREATION: {
        id: 'ROUTE_LANDING_CREATION',
        description: 'ROUTE_PRIVACY',
        defaultMessage: '/privacy-policy'
    },
    ROUTE_LANDING_MANAGER: {
        id: 'ROUTE_LANDING_MANAGER',
        description: 'ROUTE_PRIVACY',
        defaultMessage: '/privacy-policy'
    },
    ROUTE_LANDING_MANAGER_DETAIL: {
        id: 'ROUTE_LANDING_MANAGER_DETAIL',
        description: 'ROUTE_PRIVACY',
        defaultMessage: '/privacy-policy'
    },
    ROUTE_PROMOTION_LANDING: {
        id: 'ROUTE_PROMOTION_LANDING',
        description: 'ROUTE_PROMOTION_LANDING',
        defaultMessage: '/landing/:promotionId?'
    },
    ROUTE_UNSUBSCRIBE: {
        id: 'ROUTE_UNSUBSCRIBE',
        description: 'ROUTE_UNSUBSCRIBE',
        defaultMessage: '/unsubscribe/:tokenOnlyLogin'
    },
    ROUTE_OLD_LOGIN: {
        id: 'ROUTE_OLD_LOGIN',
        description: 'ROUTE_OLD_LOGIN',
        defaultMessage: '/shops-login'
    },
    ROUTE_REDIRECT_JT: {
        id: 'ROUTE_REDIRECT_JT',
        description: 'ROUTE_REDIRECT_JT',
        defaultMessage: '/redirect-jt'
    },
    ROUTE_SHIPPING_DETAILS: {
        id: 'ROUTE_SHIPPING_DETAILS',
        description: 'ROUTE_SHIPPING_DETAILS',
        defaultMessage: 'ROUTE_SHIPPING_DETAILS'
    },
    ROUTE_ORDER_DETAILS: {
        id: 'ROUTE_ORDER_DETAILS',
        description: 'ROUTE_ORDER_DETAILS',
        defaultMessage: 'ROUTE_ORDER_DETAILS'
    }
})

// class PrivateRoute extends React.Component {
//     componentDidMount() {
//         if (!this.props.isLogged) {
//             this.props.loginRequired()
//         }
//     }
//     render() {
//         let { component: Component, ...rest } = this.props
//         return <PageViewRouteComponent {...rest} render={props => (rest.isLogged ? <Component {...props} /> : <Error404Screen />)}></PageViewRouteComponent>
//     }
// }


const PrivateRoute = (props) => {
    useEffect(() => {
        if (!props.isLogged) {
            props.loginRequired()
        }
    }, [])
    return <PageViewRouteComponent {...props} showErrorScreen={!props.isLogged} />
}

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (rest.isLogged && rest.userType === 'ADMIN' ? <Component {...props} /> : <Error404Screen />)} />
)
const MktoolsAdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (rest.isLogged && rest.userType === 'ADMIN' && rest.isMktoolsAdmin ? <Component {...props} /> : <Error404Screen />)} />
)
const DeviatedAdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (rest.isLogged && rest.userType === 'ADMIN' ? <Component {...props} /> : <Error404Screen />)} />
)

// const MallRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (rest.isMallPilotUser || rest.isMallAdmin ? <Component {...props} /> : <Error404Screen />)} />
// )
const MallRoute = (props) => (
    <PageViewRouteComponent {...props} showErrorScreen={!props.isMallPilotUser && !props.isMallAdmin} />
)

const MallAdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (rest.isLogged && rest.isUserMallAuthorized && rest.isMallAdmin ? <Component {...props} /> : <Error404Screen />)} />
)
const CampaignAdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (rest.isLogged && rest.isCampaignAdmin ? <Component {...props} /> : <Error404Screen />)} />
)

const mapStateToProps = (state, props) => {
    return {
        isLogged: getIsLogged(state),
        isUserMallAuthorized: getUserAuthorization(state),
        isMallAdmin: getIsMallAdmin(state),
        userType: getUserType(state),
        centerId: getSelectedCenter(state),
        isMktoolsAdmin: getIsMktoolsAdmin(state),
        isMallPilotUser: getIsMallPilotUser(state),
        maintenanceMode: getMaintenanceMode(state),
        isCampaignAdmin: getIsCampaignsPilotUser(state),
        locale: getLanguage(state),
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCart: centerId => {
            dispatch(fetchCart(centerId))
        },
        loginRequired: () => {
            dispatch(loginRequired())
        }
    }
}

class Routes extends React.Component {
    constructor(props) {
        super(props)

        document.addEventListener('visibilitychange', event => {
            if (document.visibilityState == 'visible') {
                props.fetchCart(this.props.centerId)
            } else {
            }
        })
    }

    componentDidUpdate(prevProps) {
        const regex = /(\/v2\/)|(\/v2$)/
        if (this.props.location && this.props.location.pathname && this.props.location.pathname.match(regex)) {
            const oldPathname = this.props.location.pathname
            this.props.location.pathname = this.props.location.pathname.replace(regex, '/')
            if (oldPathname !== this.props.location.pathname) this.props.history.push(this.props.location)
        }
        if (this.props.location !== prevProps.location) {

            window.scrollTo(0, 0)
        }
    }

    getRoute(routeId) {
        return this.props.localizedUrl(routeId)
    }

    render() {
        const { intl, maintenanceMode } = this.props
        //During v1+v2 you need to use getRoute or the route will not work on both versions
        return (
            <Layout>
                <Switch>
                    {maintenanceMode ? <Route component={MaintenanceScreen} /> : null}
                    <PageViewRouteComponent exact path={this.getRoute('ROUTE_HOME')} component={HomeScreen} registerAnalytics={true}/>
                    <Route path={'/redirect-sso'} component={RedirectSSOScreen} />
                    <Route path={this.getRoute('ROUTE_REDIRECT_JT')} component={RedirectJTScreen} />
                    <Route path={this.getRoute('ROUTE_OLD_LOGIN')} component={OldLoginScreen} />

                    <PageViewRouteComponent path={this.getRoute('ROUTE_COOKIES')} component={CookiePolicy} registerAnalytics={true}/>
                    <PageViewRouteComponent path={this.getRoute('ROUTE_PRIVACY')} component={PrivacyPolicy} registerAnalytics={true}/>

                    <PageViewRouteComponent path={this.getRoute('ROUTE_CATALOG')} component={CatalogScreen} registerAnalytics={true}/>
                    {/* <Route path={this.getRoute('ROUTE_PROMOTIONS')} component={PromotionsScreen} /> */}
                    <MallAdminRoute {...this.props} path={this.getRoute('ROUTE_LANDING_MANAGER')} component={LandingManagerScreen} />
                    <MallAdminRoute {...this.props} path={this.getRoute('ROUTE_LANDING_MANAGER_DETAIL')} component={LandingCreationScreen} />
                    <MallRoute {...this.props} path={this.getRoute('ROUTE_LANDING_TEMPLATE')} component={LandingTemplatesScreen} registerAnalytics={true}/>
                    <Route path={this.getRoute('ROUTE_FLASH_PROMOTIONS')} component={PromotionsScreen} />
                    {/*TODO: intl to landing*/}
                    <PageViewRouteComponent path={this.getRoute('ROUTE_PROMOTION_LANDING')} component={LandingPromotionsScreen} registerAnalytics={true}/>
                    <CampaignAdminRoute {...this.props} path={this.getRoute('ROUTE_PROMOTIONS_LIST')} component={PromotionsListScreen} />
                    <CampaignAdminRoute {...this.props} path={this.getRoute('ROUTE_ADMIN_PROMOTIONS')} component={PromotionAdminScreen} />
                    <AdminRoute {...this.props} path={this.getRoute('ROUTE_CHANGELOG')} component={ChangeLogScreen} />
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_SUGGESTIONS')} component={SuggestionScreen} />
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_CART_SUMMARY')} component={CartSummaryScreen} />
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_CHECKOUT')} component={CheckoutScreen} registerAnalytics={true}/>
                    <PrivateRoute {...this.props} exact path={this.getRoute('ROUTE_RESERVATIONS')} component={ReservationsScreen} />
                    <PrivateRoute {...this.props} exact path={this.getRoute('ROUTE_KPI_DETAIL')} component={KpiDetailScreen} intl={intl} />
                    <AdminRoute {...this.props} path={this.getRoute('ROUTE_KPIS')} component={KpisScreen} />
                    <PageViewRouteComponent {...this.props} exact path={this.getRoute('ROUTE_LOT_IMAGE_VIEWER')} component={LotImageViewerScreen} registerAnalytics={true}/>
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_LOTS')} component={LotsScreen} registerAnalytics={true}/>
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_PROFILE')} component={ProfileScreen} registerAnalytics={true}/>

                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_CUT_TO_SIZE')} component={CutToSizeScreen} registerAnalytics={true}/>
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_CONFIG_CUT_TO_SIZE')} component={ConfigCutToSizeScreen}/>
                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_CONFIG_CUT_TO_SIZE_PLAN_ACCEPTANCE')} component={ConfigCutToSizePlanAcceptanceScreen}/>

                    <PrivateRoute {...this.props} path={this.getRoute('ROUTE_CATALOG_LOWES')} component={CatalogLowesScreen} />
                    <AdminRoute {...this.props} path={this.getRoute('ROUTE_CATALOG_LOWES_ADMIN')} component={CatalogLowesAdminScreen} />
                    {/* <Route exact path={intl.formatMessage({ id: 'ROUTE_PRODUCT_DETAIL' })} component={ProductDetailScreen} /> */}
                    <PageViewRouteComponent exact path={this.getRoute('ROUTE_PRODUCT_DETAIL')} component={ProductDetailScreen} registerAnalytics={true}/>
                    <Route {...this.props} path={this.getRoute('ROUTE_LOWES_REGISTER')} component={LowesRegister} />
                    <Route {...this.props} path={this.getRoute('ROUTE_LOWES_NEW_USER_REGISTER')} component={LowesNewUserRegister} />
                    <MktoolsAdminRoute {...this.props} path={this.getRoute('ROUTE_MKT_ADMIN_PRODUCTS')} component={MktAdminProductsScreen} />
                    <MktoolsAdminRoute {...this.props} path={this.getRoute('ROUTE_MKT_ADMIN_COLORS')} component={MktAdminColorsScreen} />
                    <DeviatedAdminRoute {...this.props} path={this.getRoute('ROUTE_DEVIATED_ADMIN')} component={DeviatedAdminScreen} />
                    <Route {...this.props} path={this.getRoute('ROUTE_UNSUBSCRIBE')} component={UnsubscribeScreen} />
                    <Route component={Error404Screen} />
                </Switch>
            </Layout>
        )
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(Routes)
    )
)
