import React from 'react'
import Translation from '../../../global/Translation'

const DisplayHomeDepotIndicator = () => {
    const styles = {
        backgroundColor: '#F53000',
        color: '#FFFFFF',
        padding: '4px 8px',
        fontSize: '10px',
        fontWeight: '500',
        zIndex: 1,
        display: 'inline-block',
        lineHeight: '13px',
        maxHeight: '21px',
    }

    return (
        <div style={styles}>
            <Translation id="home_depot_exclusive" defaultMessage="Home Depot exclusive" />
        </div>
    )
}

export default DisplayHomeDepotIndicator
