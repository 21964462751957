import { injectIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import SimpleModal from '../global/SimpleModal'
import { Checkbox } from '@progress/kendo-react-inputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import MallSlider from './components/MallSlider'
import AddToCartModalItem from './components/AddToCartModalItem'
import BannerImage from './components/BannerImage'
import * as analytics from '../../analyticsEvents'
import { isFilterSelected, SORT_OPTIONS, SORT_CLEAR_OPTION } from './utils'
import Button from '../global/Button'
import Translation from '../global/Translation'
import FloatingAlert from '../alerts/FloatingAlert'
import ImpersonateBar from './components/ImpersonateBar'
import Countdown from '../global/Countdown'
import ProductItem from "./components/ProductItem";
import MallImageViewer from "./components/MallImageViewer";
import {Pagination} from "searchkit";
import InfiniteScroll from "react-infinite-scroll-component";


const LandingTemplates = ({
    landing,
    currentIso,
    filters,
    addCartLine,
    formattedProducts,
    redirect,
    intl,
    addProductInFetchedProducts,
    setSelectedProduct,
    showFilters,
    setShowFilters,
    productSelectedToAdd,
    setProductSelectedToAdd,
    sortSelected,
    selectedSort,
    selectFilter,
    unselectFilters,
    filtersSelected,
    landingData,
    commonParamsAnalytics,
    priceList,
    showToastNotification,
    lines,
    userPriceList,
    selectedCenter,
    userType,
    ...props
}) => {
    const PRODUCTS_LIMIT = 20

    const [toggleState, setToggleState] = useState(true)
    const [showImageModal, setShowImageModal] = useState(false)
    const [imageModal, setImageModal] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [currentProductList, setCurrentProductList] = useState([])
    const [productChunks, setProductChunks] = useState([[]])

    useEffect(() => {
        if (formattedProducts.length > 0) {
            nextPage()

            // let index = 0;
            // const _productChunks = []
            // for (let i = 0; i < formattedProducts.length; i += PRODUCTS_LIMIT) {
            //     _productChunks[index++] = formattedProducts.slice(i, i + PRODUCTS_LIMIT)
            // }
            // setProductChunks(_productChunks)
            // setCurrentProductList(_productChunks[0])
            // setCurrentPage(0)
        }
    }, [formattedProducts.length])

    const nextPage = () => {
        const _currentIndex = currentPage * PRODUCTS_LIMIT
        const _currentProductList = formattedProducts.slice(0, _currentIndex + PRODUCTS_LIMIT)
        setCurrentProductList(_currentProductList)
        setCurrentPage(currentPage + 1)
    }


    // useEffect(() => {
    //     if (currentPage !== undefined) {
    //         setCurrentProductList(productChunks[currentPage])
    //         analytics.sendMallImpressions(productChunks[currentPage], priceList, userPriceList, commonParamsAnalytics)
    //     }
    // }, [currentPage])




    function toggleMallContainerDiscount() {
        setToggleState(!toggleState)
    }

    const isAnyFilterActive = () => {
        let active = false

        Object.keys(filtersSelected).forEach(f => {
            if (filtersSelected[f].length > 0) {
                active = true
            }
        })

        return active
    }

    const onZoomClick = product => {
        setImageModal(product)
        setShowImageModal(true)
    }



    return (
        <div className='mall new-mall' style={{ backgroundColor: "#F9F9F9"}}>
            <div className="container">
                <div className='mall-header'>
                    <div>
                        <h1 className='mall-title'>
                            {landingData.title}
                        </h1>
                    </div>
                    <div className="timer-promo">
                        <span className="time-remaining"><Translation id="time_remaining" defaultMessage="Promotion time remaining" /></span>
                        <Countdown endDate={landingData.end_at ? landingData.end_at : "2025-02-15"} />
                    </div>
                </div>
            </div>
            <MallSlider
                products={currentProductList}
                onZoomClick={onZoomClick}
                setProductSelectedToAdd={setProductSelectedToAdd}
                sapId={landing.landing.sap_id}
            />

            {props.isUsaOrCa && toggleState && (
                <FloatingAlert
                    warningMessage={<Translation id="extra_discount_for_full_containers" defaultMessage="Extra discount for full containers"></Translation>}
                    mallContainerDiscount
                    className="catalog-position extra-discount"
                    onClose={() => toggleMallContainerDiscount()}
                />
            )}
            <ImpersonateBar />

                <hr></hr>


            <div className="container">
                <h2 className='all-materials-mall'><Translation id={"all_materials_view"} defaultMessage={"All materials view"}></Translation></h2>

                <BannerImage landingData={landingData} />
                <div>
                    <div className={`mall__filters`}>
                        <div className="mall__filters__content">
                            <div className="filter-list-mall row ml-0 mr-0">
                                {filters && filters.brand && !!filters.brand.length && (
                                    <div className="col-12 col-md-3 col-lg-2 p-0">
                                        <div className="mall__filters__content__section">
                                            <div className='filter-header-mall' onClick={()=> {
                                                [...document.querySelectorAll(`#brand-mall-filter`)].map(e => {
                                                    e.hidden = !e.hidden
                                                });
                                                [...document.querySelectorAll(`#up-brands-mall`)].map(e => {
                                                    if (e.classList.contains("d-none")) {
                                                        e.classList.remove("d-none")
                                                    } else {
                                                        e.classList.add("d-none")
                                                    }
                                                });
                                                [...document.querySelectorAll(`#down-brands-mall`)].map(e => {
                                                    if (e.classList.contains("d-none")) {
                                                        e.classList.remove("d-none")
                                                    } else {
                                                        e.classList.add("d-none")
                                                    }
                                                })
                                            }}>
                                                <span className='filter-mall-name'>{intl.formatMessage({ id: 'brands' })}</span>
                                                <i className={`fal fa-chevron-down`} id={"down-brands-mall"}></i>
                                                <i className={`fal fa-chevron-up d-none`} id={"up-brands-mall"}></i>
                                            </div>
                                            <div className="mall__filters__content__check" id='brand-mall-filter' hidden={true}>
                                                {filters.brand.map((filter, i) => {
                                                    return (
                                                        <Checkbox
                                                            onChange={selectFilter('brand', filter)}
                                                            id={filter}
                                                            checked={isFilterSelected(filtersSelected['brand'], filter)}
                                                            key={i}
                                                        >
                                                            <label htmlFor={filter} className={'k-checkbox-label'}>
                                                                {filter === 'DEKTON' && selectedCenter === 7130 ? (
                                                                    <span>DKTN</span>
                                                                ) : ( <span>{filter}</span>)}
                                                            </label>
                                                        </Checkbox>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {filters && filters.format && !!filters.format.length && (
                                    <div className="col-12 col-md-3 col-lg-2 p-0">
                                        <div className="mall__filters__content__section">
                                            <div className='filter-header-mall' onClick={() => {
                                                [...document.querySelectorAll(`#format-mall-filter`)].map(e => {
                                                    e.hidden = !e.hidden
                                                });
                                                [...document.querySelectorAll(`#up-format-mall`)].map(e => {
                                                    if (e.classList.contains("d-none")) {
                                                        e.classList.remove("d-none")
                                                    } else {
                                                        e.classList.add("d-none")
                                                    }
                                                });
                                                [...document.querySelectorAll(`#down-format-mall`)].map(e => {
                                                    if (e.classList.contains("d-none")) {
                                                        e.classList.remove("d-none")
                                                    } else {
                                                        e.classList.add("d-none")
                                                    }
                                                })
                                            }}>
                                                <span className='filter-mall-name'>{intl.formatMessage({ id: 'format' })}</span>
                                                <i className={`fal fa-chevron-down`} id={"down-format-mall"}></i>
                                                <i className={`fal fa-chevron-up d-none`} id={"up-format-mall"}></i>
                                            </div>
                                            <div className="mall__filters__content__checks" id='format-mall-filter' hidden={true}>
                                                {filters.format.map((filter, i) => (
                                                    <Checkbox
                                                        onChange={selectFilter('format', filter)}
                                                        id={filter}
                                                        key={i}
                                                        checked={isFilterSelected(filtersSelected['format'], filter)}
                                                    >
                                                        <label htmlFor={filter} className={'k-checkbox-label'}>
                                                            {filter}
                                                        </label>
                                                    </Checkbox>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {filters && filters.thickness && !!filters.thickness.length && (
                                    <div className="col-12 col-md-3 col-lg-2 p-0">
                                        <div className="mall__filters__content__section">
                                            <div className='filter-header-mall' onClick={() => {
                                                [...document.querySelectorAll(`#thickness-mall-filter`)].map(e => {
                                                    e.hidden = !e.hidden
                                                });
                                                [...document.querySelectorAll(`#up-thick-mall`)].map(e => {
                                                    if(e.classList.contains("d-none")){
                                                        e.classList.remove("d-none")
                                                    }else{
                                                        e.classList.add("d-none")
                                                    }
                                                });
                                                [...document.querySelectorAll(`#down-thick-mall`)].map(e => {
                                                    if (e.classList.contains("d-none")) {
                                                        e.classList.remove("d-none")
                                                    } else {
                                                        e.classList.add("d-none")
                                                    }
                                                })
                                            }}>
                                                <span className='filter-mall-name'>{intl.formatMessage({ id: 'thickness' })}</span>
                                                <i className={`fal fa-chevron-down`} id={"down-thick-mall"}></i>
                                                <i className={`fal fa-chevron-up d-none`} id={"up-thick-mall"}></i>
                                            </div>
                                            <div className="mall__filters__content__checks" id='thickness-mall-filter' hidden={true}>
                                                {filters.thickness.map((filter, i) => (
                                                    <Checkbox
                                                        onChange={selectFilter('thickness', filter)}
                                                        id={filter}
                                                        key={i}
                                                        checked={isFilterSelected(filtersSelected['thickness'], filter)}
                                                    >
                                                        <label htmlFor={filter} className={'k-checkbox-label'}>
                                                            {filter} cm
                                                        </label>
                                                    </Checkbox>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='all-filters-mall' style={{ justifyContent: isAnyFilterActive() ? "space-between" : "right" }}>
                    <div className={'tags-clean row'} hidden={!isAnyFilterActive()}>
                        <div className={'col-12'}>
                            <div className={'mall_filters__tags'}>
                                {Object.keys(filtersSelected).map((key) => {
                                    let output = [];
                                    if (filtersSelected[key].length !== 0) {
                                        filtersSelected[key].forEach((item) => {
                                            output.push((
                                                <div className={'mall_filters__tag'}>
                                                    <div className={'mall_filters__tag__text'}>
                                                        <span><Translation id={key} />: {item}</span>
                                                    </div>
                                                    <div className={'mall_filters__tag__close'} onClick={selectFilter(key, item)}>
                                                        X
                                                    </div>
                                                </div>
                                            ));
                                        })
                                        return (<div className={'mall_filters__tags__container'}>{output}</div>);
                                    }

                                })}

                                <span className="clean-filters" onClick={unselectFilters}><Translation id={'clear_all'} /></span>
                            </div>
                        </div>

                    </div>
                    <div className="mall__products__order">
                        <DropDownList
                            label={intl.formatMessage({ id: 'order_by' })}
                            textField="text"
                            dataItemKey="id"
                            data={sortSelected ? [...SORT_OPTIONS, SORT_CLEAR_OPTION] : SORT_OPTIONS}
                            onChange={event => selectedSort(event.target.value.id)}
                            popupSettings={{
                                className: "options-mall-select"
                            }}
                        />
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={currentProductList.length}
                    next={() => nextPage()}
                    // next={() => this.props.currentPage < this.props.lastPage && this.fetchPreOrdersPaginate()}
                    // next={() => setCurrentPage(currentPage + 1)}
                    hasMore={currentProductList.length < formattedProducts.length}
                    scrollThreshold={0.95 + (currentPage * 0.0075)}
                >
                <div className={"mall-products-container"}>
                    <div className="mall__products">
                        <div className="mall__products__content">

                                {!isAnyFilterActive() || (isAnyFilterActive() && currentProductList.length > 0) ? (
                                    currentProductList.map((product, i) => {
                                        return (
                                            <div className='position-relative'
                                                 key={'mall-item_' + i}
                                            >
                                                <ProductItem
                                                    product={product}
                                                    sapLandingId={landing.landing.sap_id}
                                                    onZoomClick={onZoomClick}
                                                    {...props}
                                                />
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="not-found">
                                        <p>
                                            <Translation id="no_materials_found" defaultMessage="No materials found according to the search parameters" />
                                        </p>
                                        <Button onClick={unselectFilters}>
                                            <Translation id="remove_filters" defaultMessage="Remove filters" />
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                </InfiniteScroll>
            </div>

            <div className="row">
                <div className="col-12 mb-4 pt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => nextPage()}
                        size="medium"
                        disabled={currentProductList.length >= formattedProducts.length}
                    >
                        {currentProductList.length < formattedProducts.length ? (
                            <Translation id="load_more" defaultMessage="Load more" />
                        ) : (
                            <Translation id="no_more_result" defaultMessage="No more results" />
                        )}
                    </Button>
                </div>
            </div>

            {/*<nav className="paginator" aria-label="Mall landing paginator">*/}
            {/*    <ul className="pagination justify-content-center">*/}
            {/*        <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>*/}
            {/*            <span className="page-link page-nav " onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))} tabIndex="-1" >*/}
            {/*                <Translation id="previous" defaultMessage="Previous" />*/}
            {/*            </span>*/}
            {/*        </li>*/}
            {/*        {*/}
            {/*            productChunks.map((v, i) => (*/}
            {/*                <li className={`page-item ${currentPage === i ? 'active' : ''}`}>*/}
            {/*                    <span className="page-link" onClick={() => setCurrentPage(i)}>{i + 1}</span>*/}
            {/*                </li>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*        <li className={`page-item ${currentPage === (productChunks.length - 1) ? 'disabled' : ''}`}>*/}
            {/*            <span className="page-link page-nav" onClick={() => setCurrentPage(Math.min(currentPage + 1, productChunks.length - 1))} >*/}
            {/*                <Translation id="next" defaultMessage="Next" />*/}
            {/*            </span>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</nav>*/}

            {productSelectedToAdd && (
                <SimpleModal customClass="mall__modal" title={''} size="large" footer={null} onClose={() => setProductSelectedToAdd(null)}>
                    <AddToCartModalItem
                        showToastNotification={showToastNotification}
                        userType={userType}
                        priceList={priceList}
                        userPriceList={userPriceList}
                        lines={lines}
                        commonParamsAnalytics={commonParamsAnalytics}
                        addCartLine={addCartLine}
                        product={productSelectedToAdd}
                        setProductSelectedToAdd={setProductSelectedToAdd}
                        addProductInFetchedProducts={addProductInFetchedProducts}
                        intl={intl}
                        selectedCenter={selectedCenter}
                        sap_landing_id={landing.landing.sap_id}
                    />
                </SimpleModal>
            )}
            {showImageModal && (
                <MallImageViewer product={imageModal} onClose={() => setShowImageModal(false)}/>
            )}
        </div>
    )
}

export default injectIntl(LandingTemplates)
