import React from 'react'

const Button = props => {
    let onClick = props.onClick
    let cssClasses = 'bt noselect'
    let title
    let style = {}
    let datacy = props.datacy
    let gtmLabel = props.gtmLabel
    let gtmUsertype = props.gtmUsertype
    let dataQas = props.dataQas
    if (props.inverted) {
        cssClasses += ' bt-inverted'
    }
    if (props.gentle) {
        cssClasses += ' bt-gentle'
    }
    if (props.danger) {
        cssClasses += ' bt-danger'
    }
    if (props.inline) {
        cssClasses += ' bt-inline'
    }
    if (props.noborder) {
        cssClasses += ' bt-noborder'
    }
    if (props.size) {
        cssClasses += ' bt-size-' + props.size
    }
    if (props.customClass) {
        cssClasses += ' ' + props.customClass
    }
    if (props.disabled) {
        cssClasses += ' bt-disabled'
        onClick = null
    }
    if (props.hidden) {
        return <div style={{ display: 'none' }} />
    }
    let iconCssClasses
    if (props.far) {
        iconCssClasses = 'icon far'
    }  else if (props.fal) {
        iconCssClasses = 'icon fal'
    } else {
        iconCssClasses = 'icon fas'
    }

    if (props.redIcon) iconCssClasses += " text-danger"
    else if (props.greenIcon) iconCssClasses += " text-success"

    if (props.offers) {
        cssClasses += ' bt-offers'
    }
    if (props.icon) {
        if (props.icon === 'custom') {
            cssClasses += ' has-custom-icon'
        } else {
            iconCssClasses += ' visible fa-' + props.icon
        }
    }
    if (props.title) {
        title = props.title
    }
    if(props.style){
        style= props.style
    }

    if (props.backgroundColor) style.backgroundColor = props.backgroundColor
    if (props.color) style.color = props.color

    cssClasses = props.className ? (cssClasses += ' ' + props.className) : cssClasses

    return (
        <div className={cssClasses}
             onClick={onClick}
             title={title}
             data-cy={datacy}
             data-tip={props['data-tip']}
             data-toggle={props['data-toggle']}
             style={style} data-qas={dataQas}
             gtm-label={gtmLabel}
             gtm-usertype={gtmUsertype}
        >
            {
                !props.iconEnd && (<i className={iconCssClasses} />)
            }
            {props.children}
            {props.iconEnd && (
                <span>
                    &nbsp;
                    <i className={iconCssClasses} />
                </span>
            )}
        </div>
    )
}

export default Button
