import { getUserName } from './../login/reducers';
import landingPreviewV1 from '../../assets/img/promotions/previews-template/landing_preview.jpg'
import landingPreviewV2 from '../../assets/img/promotions/previews-template/landing_preview_v2.jpg'
import mailPreviewV1 from '../../assets/img/promotions/previews-template/mail_preview.jpg'
import mailPreviewV2 from '../../assets/img/promotions/previews-template/mail_preview_v2.jpg'
import { getSelectedCenter } from '../centers/reducers'

export const initState = {
    isSending: false,
    status: null,
    alert: null,
    step: 1,

    currentPromotionId: null,

    //start step 1
    promotionName: null,
    languages: [],
    startDate: null,
    endDate: null,
    //end step 1

    // start step 2
    market: null,
    countries: [],
    centers: [],
    fetchedMarkets: [],
    fetchedCountries: {},
    fetchedCenters: [],
    // end step 2

    //start step3
    landingTemplateId: null,
    mailTemplateId: null,
    //end step3

    //start step4
    headLandingBannerData: {},
    homeBannerData: {},
    headEmailBannerData: {},
    lateralBanner: {},
    //end step4

    translations: {
        commercial_name: null,
        description: null,
        banner_title: null,
        banner_description: null
    },
    data: {},
    promotions: [],
    isLoading: false,
    bannersPaginatedResult: { data: [] },
    customersOfArea: [],
    customersSelected: [],
    //Excluded IKEA,MKTOOL, LOWES
    excludedPromotionsCenters: ['1010', '1060', '1080', '1090', '4000', '5000', '5200', '5400', '7777'],
    promotionsByCenter: {},
    discountMaterials: {},
    dataToResolveDiscount: [],
    acceptedDiscounts: [],
    isAmortized: false,
    discountStatus: null,
    //Step 1
    hasUnsavedChangesStepOne: {
        promotionNameDirty: false,
        promotionLanguageDirty: false,
        promotionStartDateDirty: false,
        promotionEndDateDirty: false,
        promotionCommercialNameDirty: false,
        promotionDescriptionNameDirty: false
    },
    //Step 2
    hasUnsavedChangesStepTwo: {
        promotionMarketDirty: false,
        promotionCountriesDirty: false,
        promotionCentersDirty: false
    },
    //Step 3
    hasUnsavedChangesStepThree: {
        promotionCustomersDirty: false
    },
    //Step 4
    hasUnsavedChangesStepFour: {
        promotionMaterialsDirty: false
    },
    //Step 5
    hasUnsavedChangesStepFive: {
        promotionDiscountsDirty: false
    },
    //Step 6
    hasUnsavedChangesStepSix: {
        promotionLandingTemplateDirty: false,
        promotionNewsletterTemplateDirty: false
    },
    //Step 7
    hasUnsavedChangesStepSeven: {
        promotionBannerTitleDirty: false,
        promotionBannerDescriptionDirty: false
    }
}

const promotions = (state = initState, action) => {
    switch (action.type) {
        case 'SEND_PROMOTION_HEADER_REQUEST':
        case 'SEND_TEMPLATES_SELECTED_REQUEST':
        case 'SEND_PROMOTION_CENTERS_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SEND_PROMOTION_HEADER_SUCCESS':
            return Object.assign({}, state, {
                isSending: false,
                currentPromotionId: action.promotionId ? action.promotionId : state.currentPromotionId,
                alert: action.alert,
                status: action.status,
                promotionName: action.data.name,
                startDate: action.data.start_date,
                endDate: action.data.end_date,
                translations: {
                    commercial_name: action.data.commercial_name,
                    description: action.data.description,
                    banner_title: state.translations.banner_title,
                    banner_description: state.translations.banner_description
                },
                landingTemplateId: '1',
                mailTemplateId: '1'
            })
        case 'SEND_TEMPLATES_SELECTED_SUCCESS':
            return Object.assign({}, state, {
                isSending: false,
                currentPromotionId: action.promotionId ? action.promotionId : state.currentPromotionId,
                alert: action.alert,
                status: action.status,
                landingTemplateId: action.data.landingTemplateId,
                mailTemplateId: action.data.mailTemplateId
            })
        case 'SEND_PROMOTION_CENTERS_SUCCESS':
            return Object.assign({}, state, {
                isSending: false,
                currentPromotionId: action.promotionId ? action.promotionId : state.currentPromotionId,
                alert: action.alert,
                status: action.status,
                market: action.data.market,
                countries: action.data.countries ? action.data.countries : [],
                centers: action.data.centers ? action.data.centers : []
            })
        case 'SEND_PROMOTION_HEADER_FAILURE':
        case 'SEND_TEMPLATES_SELECTED_FAILURE':
        case 'SEND_PROMOTION_CENTERS_FAILURE':
            return Object.assign({}, state, { isSending: false, alert: `${action.alert}`, status: action.status })
        case 'UPDATE_OBJECT_TRANSLATIONS':
            return Object.assign({}, state, {
                translations: { ...state.translations, [action.object]: action.translations }
            })
        case 'GET_MARKETS':
        case 'GET_COUNTRIES':
        case 'GET_CENTERS':
            return Object.assign({}, state, { status: action.status })
        case 'GET_MARKETS_FAILURE':
        case 'GET_COUNTRIES_FAILURE':
        case 'GET_CENTERS_FAILURE':
            return Object.assign({}, state, { alert: `${action.alert}`, status: action.status })
        case 'GET_MARKETS_SUCCESS':
            return Object.assign({}, state, { status: action.status, fetchedMarkets: action.fetchedMarkets })
        case 'GET_COUNTRIES_SUCCESS':
            return Object.assign({}, state, { status: action.status, fetchedCountries: action.fetchedCountries })
        case 'GET_CENTERS_SUCCESS':
            return Object.assign({}, state, { status: action.status, fetchedCenters: action.fetchedCenters })

        case 'FETCH_PROMOTION_DATA_REQUEST':
            return Object.assign({}, state, { status: action.status, error: null })
        case 'FETCH_PROMOTION_DATA_SUCCESS':
            return Object.assign({}, state, { status: action.status, data: action.data, currentPromotionId: action.data.promotionId })
        case 'FETCH_PROMOTION_DATA_FAILURE':
            return Object.assign({}, state, { status: action.status, data: {}, error: action.alert })
        case 'FETCH_PROMOTIONS_LIST_REQUEST':
            return Object.assign({}, initState, { isLoading: true })
        case 'FETCH_PROMOTIONS_LIST_SUCCESS':
            return Object.assign({}, state, { isLoading: false, status: action.status, promotions: action.promotions })
        case 'FETCH_PROMOTIONS_LIST_FAILURE':
            return Object.assign({}, state, { isLoading: false, status: action.status, promotions: [] })

        case 'SEND_ENABLE_PROMOTION_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'SEND_ENABLE_PROMOTION_SUCCESS':
            return Object.assign({}, state, {
                isLoading: false,
                promotions: state.promotions.map(promotion => {
                    if (promotion.id === action.promotionId) return { ...promotion, status: 'ACTIVE' }
                    else return promotion
                })
            })
        case 'SEND_ENABLE_PROMOTION_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'SEND_DISABLE_PROMOTION_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'SEND_DISABLE_PROMOTION_SUCCESS':
            return Object.assign({}, state, {
                isLoading: false,
                promotions: state.promotions.map(promotion => {
                    if (promotion.id === action.promotionId) return { ...promotion, status: 'INACTIVE' }
                    else return promotion
                })
            })
        case 'SEND_DISABLE_PROMOTION_FAILURE':
            return Object.assign({}, state, { isLoading: false })

        case 'SEND_BANNERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'SEND_BANNERS_SUCCESS':
            return Object.assign({}, state, {
                isLoading: false,
                status: action.status,
                // step: action.step,
                headLandingBannerData: action.bannerLanding ? action.bannerLanding : {},
                homeBannerData: action.bannerHome ? action.bannerHome : {},
                headEmailBannerData: action.bannerNewsLetter ? action.bannerNewsLetter : {},
                lateralBanner: action.sideBannerNewsLetter ? action.sideBannerNewsLetter : {},

                translations: {
                    commercial_name: state.translations.commercial_name,
                    description: state.translations.description,
                    banner_title: action.banner_title && JSON.parse(action.banner_title),
                    banner_description: action.banner_description && JSON.parse(action.banner_description)
                }
            })
        case 'SEND_BANNERS_FAILURE':
            return Object.assign({}, state, { isLoading: false, status: action.status })
        case 'FETCH_PROMOTIONS_BANNERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_PROMOTIONS_BANNERS_SUCCESS':
            return Object.assign({}, state, {
                isLoading: false,
                status: action.status,
                bannersPaginatedResult: action.bannersPaginatedResult
            })
        case 'FETCH_PROMOTIONS_BANNERS_FAILURE':
            return Object.assign({}, state, { isLoading: false, status: action.status, promotions: [] })
        case 'PROMOTIONS_ADMIN_CHANGE_STEP':
            return Object.assign({}, state, { step: action.step })
        case 'PROMOTIONS_SET_AVAILABLE_LANGUAGES':
            return Object.assign({}, state, { languages: action.languages })
        case 'FETCH_CUSTOMERS_OF_AREA_REQUEST':
            return Object.assign({}, state, { customersOfArea: [], isLoading: true })
        case 'FETCH_CUSTOMERS_OF_AREA_SUCCESS':
            return Object.assign({}, state, { customersOfArea: action.customers, isLoading: false })
        case 'FETCH_CUSTOMERS_OF_AREA_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'SEND_CUSTOMERS_SELECTED_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SEND_CUSTOMERS_SELECTED_SUCCESS':
            return Object.assign({}, state, { isSending: false, customersSelected: action.customers })
        case 'SEND_CUSTOMERS_SELECTED_FAILURE':
            return Object.assign({}, state, { isSending: false })
        case 'SEND_MATERIALS_SELECTED_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SEND_MATERIALS_SELECTED_SUCCESS':
            return Object.assign({}, state, {
                isSending: false,
                materialsSelected: action.materials,
                materialsSelectedData: action.materialsSelectedData
            })
        case 'SEND_MATERIALS_SELECTED_FAILURE':
            return Object.assign({}, state, { isSending: false })
        case 'INIT_CREATE_NEW_PROMOTION':
            return Object.assign({}, initState, {})
        //Actualizar el estado del reducer con la nueva información de la promo a editar
        case 'FETCH_PROMOTION_TO_EDIT_REQUEST':
            return Object.assign({}, initState, { isLoading: true })
        case 'FETCH_PROMOTION_TO_EDIT_SUCCESS':

            const discountMaterials = {}
            action.promotion.promotion_discounts && action.promotion.promotion_discounts.forEach(v => discountMaterials[v.material_id] ={
                materialId: v.material_id,
                requestedDiscount: v.requested_discount,
                acceptedDiscount: v.accepted_discount
            })

            return Object.assign({}, state, {
                currentPromotionId: action.promotion.id,
                alert: null,
                languages: action.languages,
                promotionName: action.promotion.name,
                startDate: action.promotion.start_date,
                endDate: action.promotion.end_date,
                step: 1,

                market: action.promotion.market,
                countries: action.countries ? action.countries : [],
                centers: action.centers ? action.centers : [],

                landingTemplateId: action.promotion.landing_template,
                mailTemplateId: action.promotion.mail_template,

                headLandingBannerData: action.promotion.head_landing_banner_data ? action.promotion.head_landing_banner_data : {},
                homeBannerData: action.promotion.home_banner_data ? action.promotion.home_banner_data : {},
                headEmailBannerData: action.promotion.head_email_banner_data ? action.promotion.head_email_banner_data : {},
                lateralBanner: action.promotion.lateral_banner_data ? action.promotion.lateral_banner_data : {},

                customersSelected: action.promotion.promotion_customers && action.promotion.promotion_customers.map(customer => customer.login),
                materialsSelected: action.promotion.promotion_materials && action.promotion.promotion_materials.map(material => material.material_id),
                materialsSelectedData: action.promotion.promotion_materials_data,

                translations: {
                    commercial_name: action.promotion.commercial_name_data && JSON.parse(action.promotion.commercial_name_data.text),
                    description: action.promotion.description_data && JSON.parse(action.promotion.description_data.text),
                    banner_title: action.promotion.banner_title_data && JSON.parse(action.promotion.banner_title_data.text),
                    banner_description: action.promotion.banner_text_data && JSON.parse(action.promotion.banner_text_data.text)
                },
                fetchedCenters: [],
                isLoading: false,
                discountMaterials: discountMaterials,
                isAmortized: action.promotion.is_amortized,
                discountStatus: action.promotion.discount_request_status
            })
        case 'FETCH_PROMOTION_TO_EDIT_FAILURE':
            return Object.assign({}, state, { isLoading: false })

        case 'FETCH_PROMOTION_ACTIVE_BY_CENTER_AND_USER_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_PROMOTION_ACTIVE_BY_CENTER_AND_USER_SUCCESS':
            return Object.assign({}, initState, {
                isLoading: false,
                promotionsByCenter: { ...state.promotionsByCenter, ...action.promotion }
            })
        case 'FETCH_PROMOTION_ACTIVE_BY_CENTER_AND_USER_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_PRODUCTS_OF_THE_LAST_QUERY_MULTI_CENTER_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_PRODUCTS_OF_THE_LAST_QUERY_MULTI_CENTER_SUCCESS':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_PRODUCTS_OF_THE_LAST_QUERY_MULTI_CENTER_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'REQUEST_MATERIALS_DISCOUNTS':
            return Object.assign({}, state, { isSending: true })
        case 'REQUEST_MATERIALS_DISCOUNTS_FAILURE':
            return Object.assign({}, state, {
                isSending: false,
                discountMaterials: {}
            })
        case 'REQUEST_MATERIALS_DISCOUNTS_SUCCESS':
            return Object.assign({}, state, {
                isSending: false,
                discountMaterials: action.discountMaterials
            })

        case 'FETCH_DATA_TO_RESOLVE_DISCOUNT_REQUEST':
            return Object.assign({}, state, {
                isLoading: true,
                dataToResolveDiscount: []
            })
        case 'FETCH_DATA_TO_RESOLVE_DISCOUNT_FAILURE':
            return Object.assign({}, state, {
                isLoading: false,
                dataToResolveDiscount: []
            })
        case 'FETCH_DATA_TO_RESOLVE_DISCOUNT_SUCCESS':
            return Object.assign([], state, {
                isLoading: false,
                dataToResolveDiscount: action.resolveDiscountData
            })
        case 'CHECK_PROMOTION_DISCOUNTS_REQUEST':
        case 'SEND_APPROVE_CAMPAIGN_DISCOUNTS_REQUEST':
        case 'SEND_DENY_CAMPAIGN_DISCOUNTS_REQUEST':
            return Object.assign([], state, {
                isLoading: true
            })
        case 'CHECK_PROMOTION_DISCOUNTS_REQUEST_SUCCESS':
        case 'SEND_APPROVE_CAMPAIGN_DISCOUNTS_SUCCESS':
        case 'SEND_DENY_CAMPAIGN_DISCOUNTS_SUCCESS':
        case 'CHECK_PROMOTION_DISCOUNTS_REQUEST_FAILURE':
        case 'SEND_APPROVE_CAMPAIGN_DISCOUNTS_FAILURE':
        case 'SEND_DENY_CAMPAIGN_DISCOUNTS_FAILURE':
            return Object.assign([], state, {
                isLoading: false
            })
        case 'SEND_CLONE_PROMOTION_REQUEST':
            return Object.assign([], state, {
                isLoading: true
            })
        case 'SEND_CLONE_PROMOTION_SUCCESS':
        case 'SEND_CLONE_PROMOTION_FAILURE':
            return Object.assign([], state, {
                isLoading: false
            })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_ONE':
            return Object.assign({}, state, {
                hasUnsavedChangesStepOne: {
                    promotionNameDirty: action.changes.promotionNameDirty,
                    promotionLanguageDirty: action.changes.promotionLanguageDirty,
                    promotionStartDateDirty: action.changes.promotionStartDateDirty,
                    promotionEndDateDirty: action.changes.promotionEndDateDirty,
                    promotionCommercialNameDirty: action.changes.promotionCommercialNameDirty,
                    promotionDescriptionNameDirty: action.changes.promotionDescriptionNameDirty,
            }
        })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_TWO':
            return Object.assign({}, state, {
                hasUnsavedChangesStepTwo: {
                    promotionMarketDirty: action.changes.promotionMarketDirty,
                    promotionCountriesDirty: action.changes.promotionCountriesDirty,
                    promotionCentersDirty: action.changes.promotionCentersDirty
            }
        })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_THREE':
            return Object.assign({}, state, {
                hasUnsavedChangesStepThree: {
                    promotionCustomersDirty: action.changes.promotionCustomersDirty
            }
        })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_FOUR':
            return Object.assign({}, state, {
                hasUnsavedChangesStepFour: {
                    promotionMaterialsDirty: action.changes.promotionMaterialsDirty
            }
        })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_FIVE':
            return Object.assign({}, state, {
                hasUnsavedChangesStepFive: {
                    promotionDiscountsDirty: action.changes.promotionDiscountsDirty
            }
        })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_SIX':
            return Object.assign({}, state, {
                hasUnsavedChangesStepSix: {
                    promotionLandingTemplateDirty: action.changes.promotionLandingTemplateDirty,
                    promotionNewsletterTemplateDirty: action.changes.promotionNewsletterTemplateDirty
            }
        })
        case 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_SEVEN':
            return Object.assign({}, state, {
                hasUnsavedChangesStepSeven: {
                    promotionBannerTitleDirty: action.changes.promotionBannerTitleDirty,
                    promotionBannerDescriptionDirty: action.changes.promotionBannerDescriptionDirty
            }
        })
        default:
            return state
    }
}
export const getPromotionData = state => {
    return state.promotions.promotionsByCenter[getSelectedCenter(state)]
}
export const getError = state => {
    return state.promotions.error
}
export const getPromotionHomeBanner = state => {
    return getPromotionData(state) && getPromotionData(state).home_banner_url
}
export const getPromotionLateralBanner = state => {
    return getPromotionData(state) && getPromotionData(state).lateral_banner_url
}
export const getPromotionLandingBanner = state => {
    return getPromotionData(state) && getPromotionData(state).head_landing_banner_url
}
export const getPromotionLink = state => {
    return getPromotionData(state) && getPromotionData(state).id
}
export const getIsSending = state => {
    return state.promotions.isSending
}
export const getStatus = state => {
    return state.promotions.status
}
export const getAlert = state => {
    return state.promotions.alert
}
export const getTranslations = state => {
    return state.promotions.translations
}
export const getStep = state => {
    return state.promotions.step
}
export const getFetchedMarkets = state => {
    return state.promotions.fetchedMarkets
}
export const getFetchedCountries = state => {
    return state.promotions.fetchedCountries
}
export const getFetchedCenters = state => {
    return state.promotions.fetchedCenters.filter(center => !state.promotions.excludedPromotionsCenters.includes(center.id))
}
export const getLandingTemplates = () => {
    return [
        /* {
            id: '1',
            name: 'Template Version 1',
            urlPreview: landingPreviewV1
        }, */
        {
            id: '2',
            name: 'Template Version 2',
            urlPreview: landingPreviewV2
        }
    ]
}
export const getNewsLetterTemplates = () => {
    return [
        /* {
            id: '1',
            name: 'Template Version 1',
            urlPreview: mailPreviewV1
        }, */
        {
            id: '2',
            name: 'Template Version 2',
            urlPreview: mailPreviewV2
        }
    ]
}
export const getCurrentPromotionId = state => state.promotions.currentPromotionId
export const getAvailableSteps = state => {
    let availableSteps = [1]
    if (!!getCurrentPromotionId(state)) {
        availableSteps.push(2)
    }
    if (!!getCurrentPromotionId(state) && !!getPromotionCenters(state).length) {
        if (canViewDiscountStep(state)) availableSteps.push(3, 4, 5, 6, 7)
        else availableSteps.push(3, 4, 5, 6)

    }
    return availableSteps
}
export const getDataLandingTemplateId = state => state.promotions.data.landingTemplateId
export const getLandingTemplateId = state => state.promotions.landingTemplateId
export const getMailTemplateId = state => state.promotions.mailTemplateId
export const getPromotionTitle = state => state.promotions.data.promotionTitle
export const getPromotionDescription = state => state.promotions.data.promotionDescription
export const getBannerTitle = state => {
    if (state.promotions.data && state.promotions.data.bannerTitle) {
        return state.promotions.data.bannerTitle
    }
    if (state.promotions.translations && state.promotions.translations['banner_title'] && state.promotions.translations['banner_title']['default_text'])
        return state.promotions.translations['banner_title']['default_text']
    return null
}
export const getBannerDescription = state => {
    if (state.promotions.data && state.promotions.data.bannerDescription) {
        return state.promotions.data.bannerDescription
    }
    if (state.promotions.translations && state.promotions.translations['banner_description'] && state.promotions.translations['banner_description']['default_text'])
        return state.promotions.translations['banner_description']['default_text']
    return null
}

export const isProductIdInPromotion = (state, productId) => {
    const product = state.promotions &&
                    state.promotions.data && 
                    state.promotions.data.products && 
                    state.promotions.data.products.find(p => p.product.productId == productId)
    return !!product
}

export const canViewDiscountStep = state => {
    // return true
    return (
        !state.promotions.isAmortized &&
        (!state.promotions.discountStatus || state.promotions.discountStatus === 'NONE' || state.promotions.discountStatus === 'DENIED')
        // && ['VALENCIA', 'SINGAPORE', 'PRICING'].includes(getUserName(state))
    )
    // return (!state.promotions.discountMaterials || Object.keys(state.promotions.discountMaterials).length === 0) && true
}

export const getPromotions = state => state.promotions.promotions
export const getIsLoading = state => state.promotions.isLoading
export const getBannersPaginatedResult = state => state.promotions.bannersPaginatedResult
export const getAvailableLanguages = state => state.promotions.languages
export const getCustomersOfArea = state => state.promotions.customersOfArea
export const getCustomersSelected = state => state.promotions.customersSelected
export const getMaterialsSelected = state => state.promotions.materialsSelected
export const getMaterialsSelectedData = state => state.promotions.materialsSelectedData
export const getPromotionName = state => state.promotions.promotionName
export const getPromotionStartDate = state => state.promotions.startDate
export const getPromotionEndDate = state => state.promotions.endDate
export const getCommercialName = state => state.promotions.translations['commercial_name']
export const getDescription = state => state.promotions.translations['description']
export const getPromotionMarket = state => state.promotions.market
export const getPromotionCountries = state => state.promotions.countries
export const getPromotionCenters = state => state.promotions.centers
export const getHeadLandingBannerData = state => state.promotions.headLandingBannerData
export const getHomeBannerData = state => state.promotions.homeBannerData
export const getHeadEmailBannerData = state => state.promotions.headEmailBannerData
export const getLateralBanner = state => state.promotions.lateralBanner
export const getPromotionDataProduct = state => state.promotions.data.products
export const getIsAmortized = state => state.promotions.isAmortized
export const getDiscountMaterials = state => state.promotions.discountMaterials
export const getDataToResolveDiscounts = state => state.promotions.dataToResolveDiscount
export const getAcceptedDiscounts = state => state.promotions.acceptedDiscounts
export const getDiscountStatus = state => state.promotions.discountStatus

//Unsaved changes
export const getUnsavedChangesStepOne = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepOne).some(item => item === true)
}

export const getUnsavedChangesStepTwo = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepTwo).some(item => item === true)
}

export const getUnsavedChangesStepThree = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepThree).some(item => item === true)
}

export const getUnsavedChangesStepFour = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepFour).some(item => item === true)
}

export const getUnsavedChangesStepFive = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepFive).some(item => item === true)
}

export const getUnsavedChangesStepSix = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepSix).some(item => item === true)
}

export const getUnsavedChangesStepSeven = (state) => {
    return Object.values(state.promotions.hasUnsavedChangesStepSeven).some(item => item === true)
}

export default promotions
