import React from 'react'
import Translation from '../../../global/Translation'
import Spinner from '../../../global/Spinner'
import { connect } from 'react-redux'
import { getPriceListAtSelectedCenter } from '../../../../store/login/reducers'
import { getSelectedCenter } from '../../../../store/centers/reducers'
import { canViewPricesPhase2 } from '../../../../store/products/reducers'
import { phase2priceByProductId } from '../../../../store/cart/reducers'
import { checkPricePhase2ByProduct } from '../../../../store/cart/actions'
import getNumberFormat from "../../../getNumberFormat";
import { getLanguage } from '../../../../store/ui/reducers'

const mapStateToProps = (state, ownProps) => {
    return {
        canViewPricesPhase2: canViewPricesPhase2(state),
        phase2priceByProductId: phase2priceByProductId(state, ownProps.product.productId),
        fetchingPhase2Price: true,
        priceList: getPriceListAtSelectedCenter(state),
        center: getSelectedCenter(state),
        lang: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        checkPricePhase2ByProduct: (productId, centerId, outletType) => {
            dispatch(checkPricePhase2ByProduct(productId, centerId, outletType))
        }
    }
}

class DisplayPricePhase2 extends React.Component {
    componentDidMount() {
        this.props.checkPricePhase2ByProduct(this.props.product.productId, this.props.center, this.props.product.outletQuality)
    }
    componentDidUpdate(prevProps) {
        //si cambia la lista de precios del usuario, el componente debe recalcular
        //si cambiar el center, tambien
        //si cambia las promociones de fase 3 usuario, el componente debe recalcular
        // console.log(prevProps.priceList)
        // console.log(this.props.priceList)
        // console.log(prevProps.center)
        // console.log(this.props.center)
        if (!this.props.phase2priceByProductId || (prevProps.priceList !== this.props.priceList && prevProps.center !== this.props.center)) {
            // console.log('it has changed!')
            this.props.checkPricePhase2ByProduct(this.props.product.productId, this.props.center, this.props.product.outletQuality)
        }
    }
    hasZT00Price() {
        let { line } = this.props
        let priceZT00 = false
        if (this.props.phase2priceByProductId) {
            priceZT00 = this.props.phase2priceByProductId.hasZT00
        }
        return priceZT00
    }

    getNewPrice() {
        let { line } = this.props
        let price = null
        if (this.props.phase2priceByProductId) {
            let priceYYYY = this.props.phase2priceByProductId.price
            price = priceYYYY ? priceYYYY : 0
        }

        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if(this.props.phase2priceByProductId.coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }
        
        return price && numberFormat.format(price)
    }
    getNewPricePerUnit() {
        let { line } = this.props
        let price = null
        if (this.props.phase2priceByProductId) {
            let priceYYYY = this.props.phase2priceByProductId.pricePerUnit
            price = priceYYYY ? priceYYYY : 0
        }
        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if(this.props.phase2priceByProductId.coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        return price && numberFormat.format(price)
    }
    render() {
        if (!this.props.canViewPricesPhase2) return null

        return (
            <div className="mini_cart_line_info__line" style={{ display: 'block' }}>
                {this.props.fetchingPhase2Price && !this.props.phase2priceByProductId ? (
                    <Spinner isLoading={true} relative small></Spinner>
                ) : (
                    <React.Fragment>
                        {(this.getNewPricePerUnit() && this.getNewPricePerUnit() == 0) || !this.getNewPricePerUnit() ? (
                            <Translation id="not_available" defaultMessage="Not available" />
                        ) : (
                            this.getNewPricePerUnit()
                        )}{' '}
                        {this.props.phase2priceByProductId && !this.getNewPricePerUnit() == 0 && (
                            <React.Fragment>
                                {this.props.phase2priceByProductId.coin} / {this.props.phase2priceByProductId.unit}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayPricePhase2)
