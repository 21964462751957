import React from 'react'
import Translation from '../../global/Translation'

import { injectIntl } from 'react-intl'

import { showingDektonGripModal, getLinkTechnicalDektonGrip, getLinkMaintenanceDektonGrip } from '../../../store/ui/reducers'
import * as ui from '../../../store/ui/actions'
import { connect } from 'react-redux'
import Modal from '../../global/Modal'
import Button from '../../global/Button'
import { submitDektonGripModal } from '../../../store/ui/actions'

const mapStateToProps = state => {
    return {
        show: showingDektonGripModal(state),
        linkTechnicalDektonGrip: getLinkTechnicalDektonGrip(state),
        linkMaintenanceDektonGrip: getLinkMaintenanceDektonGrip(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeDektonGripModal())
        },
        submitDektonGripModal: () => {
            dispatch(submitDektonGripModal())
        }
    }
}

class DektonGripModal extends React.Component {

    render() {
        const { show, onClose, submitDektonGripModal, linkTechnicalDektonGrip, linkMaintenanceDektonGrip } = this.props

        return show ? (
            <Modal
                title={
                    <h5 style={{ textAlign: 'center', flexGrow: '1' }}>
                        <Translation id="are_you_sure_add_to_cart_a_dekton_and" defaultMessage="Are you sure add to cart a dekton Grip + ?" />
                    </h5>
                }
                onClose={onClose}
                footer={
                    <div className="group_buttons" style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                        <Button onClick={onClose} size="medium" customClass="m-1">
                            <Translation id="cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button onClick={submitDektonGripModal} inverted size="medium" customClass="m-1">
                            <Translation id="add_to_cart" defaultMessage="Add to cart" />
                        </Button>
                    </div>
                }
                customClass="dekton-grip-modal"
            >
                <div className="p-2">
                    <p>
                        <Translation
                            id="dekton_grip_is_the_anti_slip_treatment_for_those_areas_that_require_a_higher_grip_due_to_local_standard_and_is_not_recommended_for_areas_where_it_is_not_necessary"
                            defaultMessage="Dekton Grip + is the anti-slip treatment for those areas that require a higher grip due to local standard, and is not recommended for areas where it is not necessary.​"
                        />
                    </p>
                    <p>
                        <Translation
                            id="for_a_succesfull_installation_we_share_with_you_all_the_information_in_these_links"
                            defaultMessage="For a succesfull installation, we share with you all the information in these links ..."
                        />
                    </p>
                    <p>
                        <a href={linkTechnicalDektonGrip} target="_blank" rel="noopener noreferrer" size="small">
                            <Translation id="technical_information" defaultMessage="Technical Information" />
                        </a>{' '}
                        <Translation id="and" defaultMessage="and" />{' '}
                        <a href={linkMaintenanceDektonGrip} target="_blank" rel="noopener noreferrer" size="small">
                            <Translation id="cleaning_&_maintenance" defaultMessage="Cleaning & Maintenance" />
                        </a>
                    </p>
                </div>
            </Modal>
        ) : null
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DektonGripModal)
)
