import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getCurrentSegmentation, getSelectedChannel, getChannelOptionsV3, getNexusOptions, getSegmentatingProductId } from '../../store/segmentation/reducers'
import { toggleSegmentation, selectChannel } from '../../store/segmentation/actions'
import SegmentationTranslation from './SegmentationTranslation'
const mapStateToProps = (state, props) => {
    return {
        show: getCurrentSegmentation(state) === 'CHANNEL',
        selected: getSelectedChannel(state),
        options: getChannelOptionsV3(state),
        nexusOptions: props.inProfile ? state.segmentation.nexusOptions : getNexusOptions(state),
        selectedProduct: getSegmentatingProductId(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('CHANNEL'))
        },
        onSelect: (value, valueName) => {
            dispatch(selectChannel(value, valueName))
        },
        onSelectRetail: value => {
            dispatch({ type: 'CLEAR_SEGMENTATION' })
        }
    }
}

const RETAIL_CHANNEL = ['A0', 'D0', 'nexus_retail']
const NON_RETAIL_CHANNEL = ['B0', 'C0', 'HE', 'DA', 'BB', 'BD', 'HO', 'nexus_non_retail']
/**
 * This is for the attribute data-qas to show the typology name to the QAS Team.
 * @type {object}
 */
const SEGMENTATION_CHANNELS = {
    A0: 'kitchen-and-bath',
    D0: 'home-center',
    B0: 'projects',
    C0: 'builder',
    HE: 'projects',
    HO: 'cosentino-home-owners',
    DA: 'direct-accounts',
    BB: 'projects',
    BD: 'projects',
    nexus: 'cosentino-nexus',
    '': 'no-channel'
}

class ChannelV4 extends React.Component {
    state = { selected: null, selectedSegment: 'retail' }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.options && prevProps.selected !== this.props.selected) {
            const channel = this.props.selected
            const retail = RETAIL_CHANNEL.includes(channel) ? 'retail' : 'no_retail'
            this.setState({ selectedSegment: retail })
        }
    }

    getBusinessSegment = () => {
        let retailOptions = []

        if (
            Object.keys(this.props.options).some(item => RETAIL_CHANNEL.includes(item)) ||
            (this.props.nexusOptions['nexus_retail'] && Object.keys(this.props.nexusOptions['nexus_retail']).length > 0)
        ) {
            retailOptions.push({
                label: 'Retail',
                value: 'retail'
            })
        }

        if (
            Object.keys(this.props.options).some(item => NON_RETAIL_CHANNEL.includes(item)) ||
            (this.props.nexusOptions['nexus_non_retail'] && Object.keys(this.props.nexusOptions['nexus_non_retail']).length > 0)
        ) {
            retailOptions.push({
                label: 'Non Retail',
                value: 'no_retail'
            })
        }

        return Object.entries(retailOptions).map((option, index) => {
            let isActive = this.state.selectedSegment === option[1].value
            return (
                <div
                    key={index}
                    className={`segmentation-v4-box ${isActive ? 'is-active' : ''}`}
                    data-qa="option"
                    data-key={option[0]}
                    onClick={() => {
                        this.setState({ selectedSegment: option[1].value }, () => this.props.onSelectRetail())
                    }}
                    data-qas={option[1].value === 'retail' ? 'global_segmentation-bussiness-segment-retail' : 'global_segmentation-bussiness-segment-non-retail'}
                >
                    <div data-qa="label" className="sk-item-list-option__text">
                        {option[1].value === 'retail' ? <Translation id="retail" defaultMessage="Retail" /> : <Translation id="non-retail" defaultMessage="Non Retail" />}
                    </div>
                </div>
            )
        })
    }

    getTypologies = () => {
        const showedOptions = {}
        Object.entries(this.props.options).forEach(([key, option]) => {
            if (!(key === 'HE' && showedOptions['B0']) && !(key === 'B0' && showedOptions['HE'])) {
                showedOptions[key] = option
            }
        })

        if (this.props.nexusOptions) {
            let channelName
            if (this.state.selectedSegment === 'retail' && this.props.nexusOptions.nexus_retail) {
                channelName = 'nexus_retail'
            }
            if (this.state.selectedSegment === 'no_retail' && this.props.nexusOptions.nexus_non_retail) {
                channelName = 'nexus_non_retail'
            }

            if (channelName) {
                showedOptions[channelName] = {
                    id: 'nexus',
                    name: 'Cosentino Nexus'
                }
            }
        }

        return Object.entries(showedOptions)
            .filter(([channel, option]) => (this.state.selectedSegment === 'retail' ? RETAIL_CHANNEL.includes(channel) : !RETAIL_CHANNEL.includes(channel)))
            .map(([channel, option], index) => {
                const isActive = this.props.selected === channel
                const refChannel = typeof channel === 'string' && channel.startsWith('nexus') ? 'nexus' : channel
                const channelName = SEGMENTATION_CHANNELS[refChannel]

                return (
                    <div
                        key={index}
                        className={`segmentation-v4-box channel-icon ${isActive ? 'is-active' : ''}`}
                        data-qa="option"
                        data-key={channel}
                        data-qas={'global_segmentation-typology-' + channelName}
                        onClick={() => {
                            this.setState({ selected: channel }, () => this.props.onSelect(channel, option.name))
                        }}
                    >
                        <div data-qa="label" className="sk-item-list-option__text" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img
                                src={require(`../../assets/img/icons/${refChannel}.svg`)}
                                style={{ background: 'black', width: '24px', height: '24px', borderRadius: '100%', marginRight: '8px' }}
                            />
                            &nbsp;
                            <SegmentationTranslation channel={refChannel} />
                        </div>
                    </div>
                )
            })
    }

    render() {
        if (this.props.options === undefined) {
            return ''
        }

        return (
            <div>
                <div>
                    <span className="segmentation-segment-title">
                        <Translation id="business_segment" defaultMessage="Bussines Segment" /> <small>{'*'}</small>
                    </span>
                    <div data-qa="options" className="sk-item-list mt-1" style={{ justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: '.5em' }}>
                        {this.getBusinessSegment()}
                    </div>
                </div>
                <div>
                    <span className="segmentation-segment-title">
                        <Translation id="typology" defaultMessage="Typology" /> <small>{'*'}</small>
                    </span>
                    <div data-qa="options" className="sk-item-list mt-1" style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                        {this.getTypologies()}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelV4)
