import React from 'react'
import DisplayOutletIndicator from '../displays/DisplayOutletIndicator'
import DisplayDiscontinued from '../displays/DisplayDiscontinued'
import DisplayNewIndicator from '../displays/DisplayNewIndicator'
import DisplayUpcomingIndicator from '../displays/DisplayUpcomingIndicator'
import { v4 as uuidv4 } from 'uuid'
import DisplayHomeDepotIndicator from '../displays/DisplayHomeDepotIndicator'
import DisplayBlackIndicator from '../displays/DisplayBlackIndicator'
import { isNewIndicator, isUpcomingIndicator } from '../../../../store/products/reducers'

const ProductIndicators = (props) => {

    const getIndicators = () => {
        const indicators = props.indicators ? props.indicators : []

        if (props.canDisplayDiscontinued()) indicators.push(
            <React.Fragment key={uuidv4()}>
                <DisplayDiscontinued discontinued={props.product.discontinued} />
            </React.Fragment>
        )
        if (props.outlet) indicators.push(
            <DisplayOutletIndicator
                key={uuidv4()}
                outletQuality={props.product.outletQuality}
                outletDeviated={props.isAdmin || props.impersonatedBy ? props.product.outletDeviated : null}
            />
        )
        if (props.isExclusiveHomeDepot) indicators.push(<DisplayHomeDepotIndicator/>)

        return indicators
    }

    const getSphereIndicators = () => {
        const sphereIndicators = props.sphereIndicators ? props.sphereIndicators : []

        if (isNewIndicator(props.product.creationDate)) sphereIndicators.push(<DisplayNewIndicator key={uuidv4()} />)
        if (isUpcomingIndicator(props.product.creationDate)) sphereIndicators.push(<DisplayUpcomingIndicator key={uuidv4()} />)

        if (props.canViewPricesPhase3 && props.tate && props.state.promotions && props.state.promotions.length > 0) {
            if (props.state.promotions[0].dealType === 'A') {
                sphereIndicators.push(<DisplayBlackIndicator key={uuidv4()}
                                                             value={props.intl.formatMessage({ id: 'new_price' })}
                                                             productDiscounts={props.productDiscounts} />)
            }
            else if (props.state.promotions[0].dealType === 'B') {
                sphereIndicators.push(<DisplayBlackIndicator key={uuidv4()}
                                                             value={`-${props.state.promotions[0].value}%`}
                                                             productDiscounts={props.productDiscounts} />)
            }
        }

        return sphereIndicators
    }

    return props.product ? (
        <div className="indicators">
            {[
                ...getIndicators(),
                ...getSphereIndicators()
            ].map((indicator, index) => (
                <div key={index} style={{ zIndex: 1, lineHeight: 0 }}>
                    {indicator}
                </div>
            ))}
        </div>
    ) : null
}

export default ProductIndicators
