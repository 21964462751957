import { connect } from 'react-redux'
import { getCenters, getSelectedCenter, canViewCatalog, getLowesCenterId, getIsMkToolsCenter, isBetweenCenters } from '../../store/centers/reducers'
import { fetchProductsDiscounts } from '../../store/products/actions'
import { changeCenter } from '../../store/centers/actions'
import { getUserType, isSpanishSalesOrg, getUserName, getNaturalStoneViewingPermissions } from '../../store/login/reducers'
import { getLanguageDir, getLocalizedUrl } from '../../store/ui/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import CentersV2 from "./CentersV2";

const mapStateToProps = (state) => {
    return {
        centers: getCenters(state),
        selectedCenter: getSelectedCenter(state),
        canViewCatalog: canViewCatalog(state),
        key: getSelectedCenter(state),
        userType: getUserType(state),
        languageDir: getLanguageDir(state),
        localizedUrl: (intl, routeId) => getLocalizedUrl(state, intl, routeId),
        employeeId: getEmployeeId(state),
        LowesCenterId: getLowesCenterId(),
        isMkToolsCenter: getIsMkToolsCenter(state),
        isSpanishSalesOrg: isSpanishSalesOrg(state),
        userName: getUserName(state),
        naturalStoneViewingPermissions: getNaturalStoneViewingPermissions(state),
        isBetweenCenters: (centerId) => isBetweenCenters(centerId, state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeCenter: (centerId, urlCallback) => {
            dispatch({ type: 'CHANGE_CENTER_BY_USER', center: centerId })
            dispatch(changeCenter(centerId, urlCallback))
            dispatch({ type: 'REMEMBERED_CENTER' })
            dispatch(fetchProductsDiscounts())
        },
        setEmployeeId: employeeId => Promise.resolve(dispatch({ type: 'SET_EMPLOYEE_ID', employeeId }))
    }
}

const CentersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CentersV2)

export default CentersContainer
