import React from 'react'
import { injectIntl } from 'react-intl'
import Select from 'react-select'
import { dispatchPushURL } from '../store/ui/actions'
import { connect } from 'react-redux'
import { byProductId } from '../store/products/reducers'

const mapDispatchToProps = dispatch => {
    return {
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}

const mapStateToProps = state => {
    return {
        byProductId: productId => byProductId(state, productId)
    }
}

class FiltersProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            classActiveBool: false
        }
    }

    customSelect = () => {
        this.setState({ classActiveBool: !this.state.classActiveBool })
    }

    render() {
        const { alternatives, outlet, productId, redirect, intl, selectHeading } = this.props

        if (!alternatives) return null
        if (alternatives.length === 0) return null

        let alternativesRemoveBlank = Object.fromEntries(Object.entries(alternatives).filter(([i, v]) => i !== ''))

        let selected = null
        if (this.props.value && this.props.alternatives[this.props.value]) {
            selected = {
                value: this.props.alternatives[this.props.value],
                label: this.props.translateValue ? this.props.intl.formatMessage({ id: this.props.value }) : this.props.value
            }
        }

        if (!selected) {
            selected = Object.entries(alternativesRemoveBlank).flatMap(alternative => {
                return alternative[1] === productId
                    ? {
                          value: alternative[1],
                          label: this.props.translateValue ? this.props.intl.formatMessage({ id: alternative[0] }) : alternative[0]
                      }
                    : []
            })
            selected = selected[0]
        }

        let options = Object.entries(alternativesRemoveBlank).map(alternative => {
            return {
                value: alternative[1],
                label: this.props.translateValue ? this.props.intl.formatMessage({ id: alternative[0] }) : alternative[0]
            }
        })

        return (
            <div>
                <span className="select-heading-product">{selectHeading}</span>
                <div>
                    <Select
                        isSearchable={false}
                        onChange={selectOption => {
                            redirect(
                                intl
                                    .formatMessage({ id: 'ROUTE_PRODUCT_DETAIL' })
                                    .replace(':id', selectOption.value)
                                    .replace(':outlet?', outlet ? 'outlet' : '')
                            )
                        }}
                        value={selected}
                        options={options}
                        clearable={false}
                        className="select_filter_products"
                    />
                </div>
            </div>
        )
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FiltersProduct))
