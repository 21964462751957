import React from 'react'
import Translation from '../../global/Translation'

import { NoHits, PageSizeSelector, Select, Pagination, SortingSelector } from 'searchkit'
import { injectIntl, defineMessages } from 'react-intl'
import FormatCSVModal from '../../global/FormatCSVModal'
import { ReactComponent as IconTruck } from '../../../assets/img/icons/icon-catalog-shipping-truck.svg'
import { ReactComponent as IconSlabs } from '../../../assets/img/icons/icon-catalog-slabs.svg'
import { ReactComponent as IconDownload } from '../../../assets/img/icons/icon-catalog-download.svg'
import { ReactComponent as IconInfo } from '../../../assets/img/icons/icon-catalog-info.svg'
import { ReactComponent as IconWarning } from '../../../assets/img/icons/icon-catalog-warning.svg'
import OutletPnFromOtherCentersWalkthrough from '../../walkthroughs/outlet-pn-from-other-centers/OutletPnFromOtherCentersWalkthrough'
import Modal from '../../global/Modal'
import DisplayNewIndicator from '../rectangleResult/displays/DisplayNewIndicator'
import DisplayUpcomingIndicator from '../rectangleResult/displays/DisplayUpcomingIndicator'
import DisplayOutletIndicator from '../rectangleResult/displays/DisplayOutletIndicator'
import DisplayDiscontinued from '../rectangleResult/displays/DisplayDiscontinued'
import DisplayTechnology from '../rectangleResult/displays/DisplayTechnology'
import NaturalStoneIndicator from '../../cart-line/indicators/NaturalStoneIndicator'
import DisplayBlackIndicator from '../rectangleResult/displays/DisplayBlackIndicator'
import axios from 'axios'
import CustomProductIndicator from '../../cart-line/indicators/CustomProductIndicator'
import DisplayHomeDepotIndicator from '../rectangleResult/displays/DisplayHomeDepotIndicator'

defineMessages({
    displayName: {
        id: 'displayName',
        description: 'displayName',
        defaultMessage: 'Product Name'
    },
    csv: {
        id: 'csv',
        description: 'csv',
        defaultMessage: 'csv'
    },
    subtype_standard: {
        id: 'subtype_standard',
        description: 'subtype_standard',
        defaultMessage: 'Standard'
    },
    subtype_raw: {
        id: 'subtype_raw',
        description: 'subtype_raw',
        defaultMessage: 'Raw'
    },
    subtype_optimma: {
        id: 'subtype_optimma',
        description: 'subtype_optimma',
        defaultMessage: 'Optimma'
    },
    subtype_jumbo: {
        id: 'subtype_jumbo',
        description: 'subtype_jumbo',
        defaultMessage: 'Jumbo'
    },
    2.0: {
        id: '2.0',
        description: '2.0',
        defaultMessage: '2.0'
    },
    1.2: {
        id: '1.2',
        description: '1.2',
        defaultMessage: '1.2'
    },
    3.0: {
        id: '3.0',
        description: '3.0',
        defaultMessage: '3.0'
    },
    '306X141': {
        id: '306X141',
        description: '306X141',
        defaultMessage: '306X141'
    },
    thank_you_very_much_would_you_like_to_add_anything_else: {
        id: 'thank_you_very_much_would_you_like_to_add_anything_else',
        description: 'Thank you very much, would you like to add anything else?',
        defaultMessage: 'Thank you very much, would you like to add anything else?'
    },
    no_results_found_for_: {
        id: 'no_results_found_for_',
        description: 'No results found for {query}',
        defaultMessage: 'No results found for {query}'
    },
    collection: {
        id: 'collection',
        description: 'Collection',
        defaultMessage: 'Collection'
    },
    tone: {
        id: 'tone',
        description: 'Tone',
        defaultMessage: 'Tone'
    }
})

class ResultsV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showCSVFormat: false,
            showLegendOfLabels: false,
            isExitingLegendOfLabels: false
        }

        this.modalLegendOfLabelsRef = React.createRef()
        this.handleModalLegendOfLabelsClick = this.handleModalLegendOfLabelsClick.bind(this)
    }

    resize = () => {
        let pageScope = 4
        if (window.innerWidth < 768) {
            pageScope = 1
        }
        this.setState({ pageScope })
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
        this.resize()
        document.addEventListener('mousedown', this.handleModalLegendOfLabelsClick)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
        document.removeEventListener('mousedown', this.handleModalLegendOfLabelsClick)
    }

    customSelect = () => {
        this.setState({ classActiveBool: !this.state.classActiveBool })
    }

    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        if (!format) {
            return
        }

        const { intl } = this.props
        const fieldsSlabs = [
            'brand',
            'subtypeName',
            'productId',
            'colorName',
            'finish',
            'thickness',
            'stock_m2',
            'stock_ft2',
            'stock',
            'stock_outlet_m2',
            'stock_outlet_ft2',
            'stock_outlet',
            'outletQuality'
        ]
        const fieldsMktools = [
            'productId',
            'displayName',
            'brand',
            'webHeading',
            'type',
            'subtypeName',
            'subtype1Name',
            'subtype2Name',
            'colorName',
            'finishFamily',
            'thickness',
            'stock',

            'stock_outlet',
            'outletQuality'
        ]
        const fields = this.props.isMkToolsCenter ? fieldsMktools : fieldsSlabs
        const header = fields.map(field => {
            return intl.formatMessage({ id: `${field}` })
        })

        const FileDownload = require('js-file-download')
        axios
            .get(process.env.REACT_APP_API_HOST + `/catalog/csv?format=${format}&fields=${encodeURIComponent(fields.join(','))}&header=${encodeURIComponent(header.join(','))}`, {
                headers: { Authorization: `Bearer ${this.props.token}` },
                'Content-Type': 'text/plain',
                responseType: 'blob'
            })
            .then(response => {
                FileDownload(response.data, `products.${format}`)
            })
    }

    handleModalLegendOfLabelsClick(event) {
        // Verificar si el clic está fuera del modal
        if (this.modalLegendOfLabelsRef.current && !this.modalLegendOfLabelsRef.current.contains(event.target)) {
            this.handleCloseLegendOfLabels()
        }
    }

    handleOpenLegendOfLabels() {
        this.setState({ showLegendOfLabels: !this.state.showLegendOfLabels })
    }

    handleCloseLegendOfLabels() {
        this.setState({ isExitingLegendOfLabels: true })

        setTimeout(() => {
            this.setState({ showLegendOfLabels: false, isExitingLegendOfLabels: false })
        }, 300) // Debe coincidir con la duración de la animación en CSS
    }

    render() {
        const sortingSelector = (
            <SortingSelector
                options={[
                    {
                        label: this.props.intl.formatMessage({
                            id: 'stock_high_to_low'
                        }),
                        field: this.props.outlet ? 'stock_outlet' : 'stock',
                        order: 'desc',
                        defaultOption: !this.props.isIkeaCenter
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'stock_low_to_high'
                        }),
                        field: this.props.outlet ? 'stock_outlet' : 'stock',
                        order: 'asc',
                        defaultOption: false
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'newest_arrivals'
                        }),
                        field: 'creationDate',
                        order: 'desc',
                        defaultOption: this.props.isIkeaCenter
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'oldest_materials'
                        }),
                        field: 'creationDate',
                        order: 'asc',
                        defaultOption: false
                    }
                ]}
            />
        )

        const slabLocation = ['tabla'].includes(this.props.currentLocation.section)
        const outletLocation = ['outlet'].includes(this.props.currentLocation.section)

        return (
            <div className="results">
                <div className={`results__header__options ${slabLocation || outletLocation ? 'justify-content-between' : 'justify-content-end'}`}>
                    {slabLocation && this.props.naturalStoneViewingPermissions.canViewBrazil && (
                        <div id="pn_outlet_hq_button" className="pn__switch__tabs">
                            <button
                                className={`pn__switch__tabs--current-center ${!this.props.statePnHQ && !this.props.statePnBrazil ? 'active-current-center' : ''}`}
                                onClick={() => {
                                    this.props.setPnHQ(false)
                                    this.props.setPnBrazil(false)
                                }}
                            >
                                <IconSlabs />
                                <span>{this.props.centerData && this.props.centerData.name}</span>
                            </button>
                            <button
                                className={`pn__switch__tabs--pn ${this.props.statePnHQ && !this.props.statePnBrazil ? 'active-pn' : ''}`}
                                onClick={() => {
                                    this.props.setPnHQ(true)
                                    this.props.setPnBrazil(false)
                                }}
                            >
                                <IconTruck />
                                <Translation id="natural_stone_hq" defaultMessage="Natural stone Spain" />
                            </button>
                            <button
                                className={`pn__switch__tabs--pn ${this.props.statePnBrazil && !this.props.statePnHQ ? 'active-pn' : ''}`}
                                onClick={() => {
                                    this.props.setPnBrazil(true)
                                    this.props.setPnHQ(false)
                                }}
                            >
                                <IconTruck />
                                <Translation id="natural_stone_brazil" defaultMessage="Natural stone Brazil" />
                            </button>
                        </div>
                    )}

                    {slabLocation && this.props.naturalStoneViewingPermissions.canViewHQ && !this.props.naturalStoneViewingPermissions.canViewBrazil && (
                        <div id="pn_outlet_hq_button" className="pn__switch__tabs">
                            <button
                                className={`pn__switch__tabs--current-center ${this.props.statePnHQ ? '' : 'active-current-center'}`}
                                onClick={() => {
                                    this.props.setPnHQ(false)
                                }}
                            >
                                <IconSlabs />
                                <span>{this.props.centerData && this.props.centerData.name}</span>
                            </button>
                            <button
                                className={`pn__switch__tabs--pn ${this.props.statePnHQ ? 'active-pn' : ''}`}
                                onClick={() => {
                                    this.props.setPnHQ(true)
                                }}
                            >
                                <IconTruck />
                                <Translation id="natural_stone_hq" defaultMessage="Natural stone Spain" />
                            </button>
                        </div>
                    )}

                    {outletLocation && this.props.naturalStoneViewingPermissions.canViewHQ && (
                        <div id="pn_outlet_hq_button" className="pn__switch__tabs">
                            <button className={`pn__switch__tabs--current-center ${this.props.statePnHQ ? '' : 'active-current-center'}`} onClick={() => this.props.setPnHQ(false)}>
                                <IconSlabs />
                                <span>{this.props.centerData && this.props.centerData.name}</span>
                            </button>
                            <button className={`pn__switch__tabs--pn ${this.props.statePnHQ ? 'active-pn' : ''}`} onClick={() => this.props.setPnHQ(true)}>
                                <IconTruck />
                                <Translation id="outlet_in_hq" defaultMessage="Outlet Spain" />
                            </button>
                        </div>
                    )}

                    <OutletPnFromOtherCentersWalkthrough key={this.props.skKey} />

                    <div className="options__filters">
                        {!this.props.hideOptions && (
                            <React.Fragment>
                                <div className="options__filters--count">
                                    <span className="showing">
                                        <Translation id="showing" defaultMessage="Showing" />
                                    </span>
                                    &nbsp;
                                    <span className="count-text">{this.props.totalResult}</span>
                                    <span>
                                        &nbsp;
                                        <Translation id="results" defaultMessage="Results" />
                                    </span>
                                </div>

                                {this.props.token && !this.props.isLowesCenter && (
                                    <div onClick={this.customSelect}>
                                        <div className={this.state.classActiveBool ? 'select-excel-v2 open-excel-v2' : 'select-excel-v2'} gtm-label="ec_plp_descarga">
                                            <button className="options__filters--icon">
                                                <IconDownload />
                                            </button>

                                            <div className="custom-options-excel-v2">
                                                <button data-value="xls" onClick={() => this.closeFormatCSVModal('xls')}>
                                                    {this.props.intl.formatMessage({ id: 'excel' })}
                                                </button>
                                                <button data-value="csv" onClick={() => this.closeFormatCSVModal('csv')}>
                                                    {this.props.intl.formatMessage({ id: 'csv' })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!this.props.isLowesCenter && !this.props.isIkeaCenter && (
                                    <div className="catalog__sorting__selector">
                                        <div className="order-by">
                                            <span>
                                                <Translation id="order_by" defaultMessage="Order by" />
                                            </span>
                                        </div>
                                        <div className="order-input" gtm-label="ec_plp_orden_listado">
                                            {sortingSelector}
                                        </div>
                                    </div>
                                )}
                                {!this.props.isLowesCenter && !this.props.isIkeaCenter && !this.props.isMkToolsCenter && (
                                    <div>
                                        <button onClick={() => this.handleOpenLegendOfLabels()} className="btn-view-labels-info">
                                            <Translation id="view_labels_info" defaultMessage="View labels info" />
                                        </button>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                {this.props.naturalStoneViewingPermissions.canViewHQ && this.props.statePnHQ && (
                    <div className="banner__pn__outlet">
                        <div>
                            <IconInfo />
                        </div>
                        <div className="banner__pn__outlet--content">
                            <div>
                                <span className="main-text">
                                    <Translation
                                        id="you_are_viewing_exclusively_natural_stone_materials"
                                        defaultMessage="You are viewing exclusively natural stone materials from center Spain. Please note the following conditions to add them to your cart:"
                                    />
                                </span>
                            </div>
                            <div className="content-row">
                                <div>
                                    <IconTruck />
                                </div>
                                <div>
                                    <span>
                                        <Translation
                                            id="delivery_times_may_be_longer_than_the_delivery"
                                            defaultMessage="Delivery times may be longer than the delivery times of your usual Center."
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="content-row">
                                <div>
                                    <IconWarning />
                                </div>
                                <div>
                                    <span>
                                        <Translation
                                            id="the_total_width_of_all_the_natural_stone_slabs_you_buy_from_HQ"
                                            defaultMessage="The TOTAL thickness of all natural stone slabs purchased from the Spanish center must be at least: 12 cm. Example: 4 slabs of 3 cm = 12 cm."
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.props.naturalStoneViewingPermissions.canViewBrazil && this.props.statePnBrazil && (
                    <div className="banner__pn__outlet">
                        <div>
                            <IconInfo />
                        </div>
                        <div className="banner__pn__outlet--content">
                            <div>
                                <span className="main-text">
                                    <Translation
                                        id="you_are_viewing_exclusively_natural_stone_materials_brazil"
                                        defaultMessage="You are viewing exclusively natural stone materials from Brazil. Please note the following conditions to add them to your cart:"
                                    />
                                </span>
                            </div>
                            <div className="content-row">
                                <div>
                                    <IconTruck />
                                </div>
                                <div>
                                    <span>
                                        <Translation
                                            id="delivery_times_may_be_longer_than_the_delivery"
                                            defaultMessage="Delivery times may be longer than the delivery times of your usual Center."
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="content-row">
                                <div>
                                    <IconWarning />
                                </div>
                                <div>
                                    <span>
                                        <Translation id="the_purchase_is_only_allowed_per_bundle" defaultMessage="The purchase is only allowed per bundle." />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={`results-list text-center text-sm-left ${this.props.isLowesCenter ? 'lowes-list' : ''}`}>
                    {this.props.hits}
                    <NoHits
                        translations={{
                            'NoHits.NoResultsFound': this.props.intl.formatMessage({ id: 'no_results_found_for_' }),
                            'NoHits.SearchWithoutFilters': this.props.intl.formatMessage({ id: 'search_for__without_filters' })
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        columnGap: '24px',
                        justifyContent: 'center',
                        alignItems: 'baseline'
                    }}
                >
                    {!this.props.hidePagination && (
                        <div className="results-pagination" data-cy="catalog__pagination_catalog" onClick={() => window.scrollTo(0, 160)}>
                            <Pagination showNumbers={true} pageScope={this.state.pageScope} />
                        </div>
                    )}
                    <div>{!this.props.hideOptions && <PageSizeSelector className="sk-select-v2" options={[24, 50, 100, 500]} listComponent={Select} />}</div>
                </div>

                {this.state.showCSVFormat && <FormatCSVModal onClose={format => this.closeFormatCSVModal(format)} token={() => this.props.token}></FormatCSVModal>}

                {this.state.showLegendOfLabels && (
                    <Modal
                        title={<Translation id="legend_of_labels" defaultMessage="Legend of labels" />}
                        onClose={() => this.handleCloseLegendOfLabels()}
                        showFooter={false}
                        customClass={`modal-legend-of-labels ${this.state.isExitingLegendOfLabels ? 'exit' : 'enter'}`}
                        ref={this.modalLegendOfLabelsRef}
                    >
                        {/* Technology */}
                        <div className="legend__card">
                            <h5 className="legend__card--title">
                                <Translation id="technology" defaultMessage="Technology" />:
                            </h5>
                            <div className="legend__card--content">
                                <div>
                                    <DisplayTechnology subBrand={'xm'} desktopWidth={'104px'} desktopHeight={'34px'} withBorder={true} />
                                    <p><Translation id="silestone_xm_is_our_designer_mineral_surface_with_a_maximum_content" defaultMessage="Silestone®XM is our designer mineral surface with a maximum content of 10% crystalline silica." /></p>
                                </div>
                                <div>
                                    <DisplayTechnology technology={'hybriq'} desktopWidth={'96px'} desktopHeight={'34px'} withBorder={true} />
                                    <p><Translation id="all_products_with_the_hybriq_label_have_a_maximum" defaultMessage="All products with the HYBRIQ® label have a maximum crystalline silica content of 40%." /></p>
                                </div>
                                <div>
                                    <DisplayTechnology technology={'ukiyo'} desktopWidth={'82px'} desktopHeight={'34px'} withBorder={true} />
                                    <p>
                                        <Translation id="silestone_ukiyo_offers_full_continuity_of_veining_throughout_its_thickness_with_vertical_grooving" defaultMessage="Silestone UKIYO offers full continuity of veining throughout its thickness, with vertical grooving that exposes color integration and material depth for advanced design solutions." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayTechnology technology={'optimma'} desktopWidth={'98px'} desktopHeight={'34px'} withBorder={true} />
                                    <p>
                                        <Translation id="optimma_2600_x_1000_the_most_versatile_format_for_interior_cladding_in_dekton" defaultMessage="Optimma 2600 X 1000 mm, the most versatile format for interior cladding in Dekton®." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayTechnology technology={'grip+'} desktopWidth={'96px'} desktopHeight={'34px'} withBorder={true} />
                                    <p>
                                        <Translation id="dekton_grip_plus_is_the_anti_slip_treatment_for_those_areas_that_by_regulation_require_a_superior_grip" defaultMessage="Dekton® Grip+ is the anti-slip treatment for those areas that by regulation require a superior grip, and is not recommended for areas where it is not necessary." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayTechnology technology={'nboost'} desktopWidth={'94px'} desktopHeight={'34px'} withBorder={true} />
                                    <p>
                                        <Translation id="silestone_n_noost_is_an_advanced_silestone_technological_process_that_further_enhances_the_excellent_technical_and_aesthetic_performance_of_the_worktop" defaultMessage="Silestone® N-Boost is an advanced Silestone® technological process that further enhances the excellent technical and aesthetic performance of the worktop." />
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Discontinued */}
                        <div className="legend__card">
                            <h5 className="legend__card--title">
                                <Translation id="discontinued" defaultMessage="Discontinued" />:
                            </h5>
                            <div className="legend__card--content">
                                <div>
                                    <DisplayDiscontinued discontinued={'LD'} displayInLine={true} />
                                    <p>
                                        <Translation id="this_material_has_been_discontinued_and_will_no_longer_be_produced_the_product_will_remain_available_for_purchase_while_supplies_last" defaultMessage="This material has been discontinued and will no longer be produced. The product will remain available for purchase while supplies last." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayDiscontinued discontinued={'DF'} displayInLine={true} />
                                    <p>
                                        <Translation id="this_material_is_discontinued_due_to_reformulation_so_it_will_no_longer_be_produced" defaultMessage="This material is discontinued due to reformulation, so it will no longer be produced. It will be available while supplies last and you will be able to find it in another format." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayDiscontinued discontinued={'DD'} displayInLine={true} />
                                    <p>
                                        <Translation id="this_material_is_discontinued_by_format_so_it_will_no_longer_be_produced" defaultMessage="This material is discontinued by format, so it will no longer be produced. It will be available while stocks last and you will be able to find it in another format." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayDiscontinued discontinued={'DA'} displayInLine={true} />
                                    <p>
                                        <Translation id="this_material_is_discontinued_by_finish_so_it_will_no_longer_be_produced" defaultMessage="This material is discontinued by finish, so it will no longer be produced. It will be available while stocks last and you will be able to find it with another finish." />
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Outlet */}
                        <div className="legend__card">
                        <h5 className="legend__card--title">
                                <Translation id="outlet" defaultMessage="Outlet" />:
                            </h5>
                            <div className="legend__card--content">
                                <div>
                                    <DisplayOutletIndicator outletQuality={'X'} displayInLine={true} />
                                    <p>
                                        <Translation id="discontinued_material_with_the_highest_discount_applied" defaultMessage="Discontinued material with the highest discount applied. Availability limited to current stock." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayOutletIndicator outletQuality={'O'} displayInLine={true} />
                                    <p>
                                        <Translation id="discontinued_material_with_intermediate_discount_applied_availability_limited_to_current_stock" defaultMessage="Discontinued material with intermediate discount applied. Availability limited to current stock." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayOutletIndicator outletQuality={'V'} displayInLine={true} />
                                    <p>
                                        <Translation id="discontinued_material_with_discount_applied_availability_limited_to_current_stock" defaultMessage="Discontinued material with discount applied. Availability limited to current stock." />
                                    </p>
                                </div>
                                <div>
                                    <NaturalStoneIndicator idMessage={'outlet_in_hq'} defaultMessage="Outlet Spain" />
                                    <p><Translation id="this_product_is_available_at_our_spain_center" defaultMessage="This product is available at our Spain Center." /></p>
                                </div>
                            </div>
                        </div>

                        {/* Price */}
                        <div className="legend__card">
                            <h5 className="legend__card--title">
                                <Translation id="price" defaultMessage="Price" />:
                            </h5>
                            <div className="legend__card--content">
                                <div>
                                    <DisplayBlackIndicator value={'-10%'} />
                                    <p>
                                        <Translation id="discount_applied_to_the_final_price_of_the_product" defaultMessage="Discount applied to the final price of the product." />
                                    </p>
                                </div>
                                <div>
                                    <DisplayBlackIndicator value={this.props.intl.formatMessage({ id: 'new_price' })} />
                                    <p><Translation id="displays_the_exclusive_reduced_price_for_this_product" defaultMessage="Displays the exclusive reduced price for this product." /></p>
                                </div>
                            </div>
                        </div>

                        {/* Novedades */}
                        <div className="legend__card">
                            <h5 className="legend__card--title">
                                <Translation id="NEWS" defaultMessage="News" />
                            </h5>
                            <div className="legend__card--content">
                                <div>
                                    <DisplayUpcomingIndicator />
                                    <p>
                                        <Translation id="this_product_will_be_available_within_15_days" defaultMessage="This product will be available within 15 days. You can add it to your cart and checkout, the product will be shipped when available." />
                                    </p>
                                </div>
                                <div>   
                                    <DisplayNewIndicator />
                                    <p>
                                        <Translation id="recently_launched_belonging_to_our_latest_collection" defaultMessage="Recently launched, belonging to our latest collection." />
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Natural stone */}
                        <div className="legend__card">
                            <h5 className="legend__card--title">
                                <Translation id="natural_stone" defaultMessage="Natural Stone" />:
                            </h5>
                            <div className="legend__card--content" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flexWrap: 'wrap' }}>
                                        <NaturalStoneIndicator idMessage={'natural_stone_hq'} defaultMessage="Natural stone Spain" isCheckout={true} />
                                        <NaturalStoneIndicator idMessage={'natural_stone_brazil'} defaultMessage="Natural stone Brazil" isCheckout={true} />
                                    </div>
                                    <p>
                                        <Translation id="natural_stone_material_that_is_available_from_the_central_warehouse_in_spain_or_brazil" defaultMessage="Natural stone material that is available from the central warehouse in Spain or Brazil." />
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Others */}
                        <div className="legend__card">
                            <h5 className="legend__card--title">
                                <Translation id="OTHERS" defaultMessage="Others" />:
                            </h5>
                            <div className="legend__card--content">
                                {this.props.isUsaOrCanada && (
                                    <div>
                                        <DisplayHomeDepotIndicator />
                                        <p>
                                            <Translation id="this_product_is_only_available_for_certain_purchases_and_or_customers_with_a_pre_established_relationship_with_home_depot" defaultMessage="This product is only available for certain purchases and/or customers with a pre-established relationship with Home Depot." />
                                        </p>
                                    </div>
                                )}
                                <div>
                                    <CustomProductIndicator customWidth={"54px"}/>
                                    <p>
                                        <Translation id="tailor_made_product_subject_to_special_conditions" defaultMessage="Tailor-made product. Subject to special conditions." />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default injectIntl(ResultsV2)
