import React, { useEffect, useState } from 'react'
import FiltersRow from "./FiltersRow";
import Modal from "../Modal";
import Button from "../Button";
import Translation from '../Translation';

/**
 *
 * @param props
 *  title : String,
 *  filters : [
 *      {
 *          filters : [
 *              {
 *                  label : String,
 *                  defaultValue : Boolean
 *              },
 *              ...,
 *              { ... }
 *          ],
 *          label : String,
 *          multiselect : Boolean
 *      },
 *      ...,
 *      { ... }
 *  ]
 * @returns {JSX.Element}
 * @constructor
 */
const FiltersModal = props => {

    const [showFilters, setShowFilters] = useState(false)
    // const [filters, setFilters] = useState({})
    const [selections, setSelections] = useState({})

    const initFilters = () => {
        //console.log('FILTERS ', props.filters)

        setSelections(Object.entries(props.filters).reduce(
            (p, [k, v]) => ({
                ...p, [k]: Object.entries(v.filters).reduce((_p, [_k, _v]) => ({..._p, [_k]: _v.isSelected}), {})
            }), {})
        )
    }

    useEffect(() => {
        initFilters()
    }, [props.filters])

    const onSelect = (key, currentSelections) => {
        const _selections = Object.assign({}, selections)
        _selections[key] = currentSelections
        setSelections(_selections)
    }

    const onChange = () => {
        Object.entries(props.filters).forEach(([k, v]) => {
            Object.entries(v.filters).forEach(([_k, _v]) => {
                _v.isSelected = !!selections[k][_k]

                if (_v.custom) {
                    _v.mapper.prevOutput = Object.assign({}, _v.mapper.output)
                    _v.value = Object.assign({}, _v.mapper.output)
                }
            })
        })

        props.onChange(Object.assign({}, props.filters))
        setShowFilters(false)
    }

    const closeWithoutApply = () => {
        Object.values(props.filters).forEach(f => Object.values(f.filters).forEach(_f => {
            if (_f.custom && _f.mapper && _f.mapper.prevOutput) {
                _f.mapper.output = Object.assign({}, _f.mapper.prevOutput)
            }
        }))
        setShowFilters(false)
    }

    const onClean = () => {
        props.onClean()
        setShowFilters(false)
    }

    return (
        <React.Fragment>

            <Button onClick={() => {
                    initFilters()
                    setShowFilters(true)
                }}
                    icon="filter"
                    gtmLabel={props.gtmLabel}
            >
                <span><Translation id="more_filters" defaultMessage="More filters" /></span>
            </Button>

            {
                showFilters && (
                    <Modal title={props.title}
                           onClose={closeWithoutApply}
                           customClass={props.customClassName}
                           showFooter={false}
                           gtmLabel={props.gtmLabelModal}
                    >
                        <div className="filter__body__content">
                            {
                                Object.entries(props.filters).map(([key, _filter]) => (
                                    <FiltersRow filters={_filter}
                                                label={_filter.label}
                                                multiselect={_filter.multiselect}
                                                key={key}
                                                onSelect={selections => onSelect(key, selections)}
                                    />
                                ))
                            }
                            <div className="filter__body__content--footer">
                                <Button onClick={onClean} customClass="clean-filters">
                                    <span><Translation id="clean_filters" defaultMessage="Clean filters" /></span>
                                </Button>
                                <Button onClick={onChange} inverted gtmLabel={props.gtmLabelApplyFilters}>
                                    <span><Translation id="apply_filters" defaultMessage="Apply filters" /></span>
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )
            }

        </React.Fragment>

    )




}

export default FiltersModal