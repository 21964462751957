import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import Modal from '../global/Modal'
import confirm from '../global/confirm'
import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'
import ChannelV4 from './ChannelV4'
import SalesForceIdV3 from './SalesForceIdV3'
import ProjectIdV3 from './ProjectIdV3'
import UseV3 from './UseV3'
import TypologyV3 from './TypologyV3'
import LineSegmentationV2 from '../cart-line/LineSegmentationV2'

const confirmDeleteSegmentation = (currentFavourite, deletePreferredSegmentation, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM' })).then(
        confirm => {
            return deletePreferredSegmentation(currentFavourite)
        },
        cancel => {
            return Promise.reject()
        }
    )
}
class SegmentationV3 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            saveSegmentationPromptOpen: false,
            active: null,
            excuse: null,
            expandSaved: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.segmentatingProductId !== this.props.segmentatingProductId) {
            this.setState({ product: this.props.getProductById(this.props.segmentatingProductId) })
        }

        if (
            prevState.active &&
            (this.props.businessSegment != prevProps.businessSegment ||
                this.props.channel != prevProps.channel ||
                this.props.subchannel != prevProps.subchannel ||
                this.props.salesForceId != prevProps.salesForceId ||
                this.props.salesForceName != prevProps.salesForceName ||
                this.props.projectId != prevProps.projectId ||
                this.props.projectName != prevProps.projectName ||
                this.props.subchannelProgram != prevProps.subchannelProgram ||
                this.props.typology != prevProps.typology ||
                this.props.useName != prevProps.useName)
        ) {
            this.setState({ active: null })
        }
    }

    clearSegmentation = () => {
        this.setState({ active: null })
        this.props.clearSegmentation()
    }

    deleteSegmentation = async currentFavourite => {
        confirmDeleteSegmentation(currentFavourite, this.props.deletePreferredSegmentation, this.props.intl).then(() => {
            if (currentFavourite === this.state.active) this.setState({ active: null })
        })
    }

    toggleSegmentation = name => {
        const activeSegmentation = this.state.active === name ? null : name
        if (activeSegmentation) {
            this.loadSegmentation(name)
        } else {
            this.clearSegmentation()
        }
        this.setState({ active: activeSegmentation })
    }

    loadSegmentation = name => {
        this.props.loadPreferredSegmentation(name)
        this.setState({ active: name })
    }

    saveSegmentation = name => {
        this.props.saveSegmentationPreferred(name)
        this.setState({ saveSegmentationPromptOpen: false, active: name })
    }

    //En el SegmtationV3 no existe this.poInput. Si llega a existir, entonces restaurar esté código.
    // handleSubmit() {
    //     const { callback } = this.props
    //     if (this.poInput) {
    //         const po = this.poInput.value.split(';')
    //         let poTrue = po.filter(function (poItem) {
    //             return poItem !== ''
    //         })
    //         this.props.setPO(poTrue)
    //         this.poInput.value = ''
    //     }
    //     this.props.onSubmitSegmentation(callback)
    // }

    handleNextStep() {
        if (this.props.subchannelProgram && this.props.evidencesType) {
            this.props.onNextStep()
        }
        else {
            this.props.onSubmitSegmentation(this.props.callback)
        }
    }

    componentDidMount() {
        this.props.fetchSegmentation()
    }

    canAddMaterial() {
        if (this.props.isExclusiveHomeDepot) {
            if (!this.props.validHomeDepotSegmentation) {
                if (!this.state.excuse) this.setState({ excuse: 'EXCLUSIVE_HOME_DEPOT' })
                return false
            }
        }

        if (this.state.excuse) this.setState({ excuse: null })

        return true
    }

    isFormFullFilled = () => {
        let salesForceIsValid = this.props.sfOptions && Object.keys(this.props.sfOptions).length > 0 ? this.props.salesforceId !== null : true
        if (this.props.channel === 'B0') {
            salesForceIsValid = true
        }
        const isNexusChannel = typeof this.props.channel === 'string' && this.props.channel.startsWith('nexus')

        const needsProjectId = (
            (
                (this.props.channel === 'BD' || this.props.channel === 'BB')
                && this.props.salesforceId
                && this.props.sfOptions[this.props.salesforceId]
                && Object.keys(this.props.sfOptions[this.props.salesforceId].projectOptions).length > 0
            ) || (
                this.props.channel === 'B0' && Object.keys(this.props.projectOptions).length > 0
            ) || (
                this.props.channel === 'C0' && Object.keys(this.props.projectOptions).length > 0 && !this.props.isOptionalBuilderProjectCenter
            ) || isNexusChannel
        )

        const needsUseAndEnvironment = (
            (!this.props.use || !this.props.typology) && isNexusChannel
        )

        // const needsUse = this.props.isAdmin || !this.props.use
        if (needsUseAndEnvironment) return false

        const formIsFullfilled = this.props.channel === 'A0' || (this.props.channel && salesForceIsValid && (needsProjectId ? this.props.projectId : true))

        return formIsFullfilled
    }

    scrollDown = () => {
        const element = document.getElementById('seg-content')
        if (element) {
            element.scrollTop = element.scrollHeight
        }
    }

    render() {
        const isInAreaPersonal = !!window.location.pathname.match(/segmentation/gi)
        const { salesforceId, channel } = this.props

        const submitButton =
            this.isFormFullFilled() && this.canAddMaterial() && !window.location.href.includes('personal') ? (
                <Button 
                    onClick={() => this.handleNextStep()} 
                    inverted
                    dataQas="global_segmentation-add-to-cart-button" 
                    icon={this.props.subchannelProgram && this.props.evidencesType ? "fal fa-chevron-right" : "fa fa-shopping-cart"}
                    iconEnd={true}
                >
                    {this.props.subchannelProgram && this.props.evidencesType ? (
                        <Translation id="next" defaultMessage="Next" />
                    ) : (
                        <Translation id="add_to_cart" defaultMessage="Add to cart" />
                    )}
                </Button>
            ) : (
                <Button 
                    disabled
                    inverted
                    icon={this.props.subchannelProgram && this.props.evidencesType ? "fal fa-chevron-right" : "fa fa-shopping-cart"}
                    iconEnd={true}
                >
                    {this.props.subchannelProgram && this.props.evidencesType ? (
                        <Translation id="next" defaultMessage="Next" />
                    ) : (
                        <Translation id="add_to_cart" defaultMessage="Add to cart" />
                    )}
                </Button>
            )

        return (
            <div>
                <div>
                {(this.props.isSaving) && <Spinner isLoading={this.props.isSaving} />}             
                {this.state.excuse && <Alerts alert={this.state.excuse} status="warning" />}
                    <div id="seg-content">
                        {this.props.preferredSegmentation && this.props.preferredSegmentation.length > 0 && (
                            <div className="segmentation__saved-segmentations-container">
                                <div className="segmentation__saved_dropdown-title"  onClick={() => this.setState({ expandSaved: !this.state.expandSaved })}>
                                    <div>
                                        <h6>
                                            <Translation id="saved_segmentations" defaultMessage="Saved segmentations" />
                                        </h6>
                                    </div>
                                    <div>
                                        {this.state.expandSaved ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
                                    </div>
                                </div>
                                <div className="saved-segmentations" style={{ display: this.state.expandSaved ? 'block' : 'none' }}>
                                    {this.props.preferredSegmentation.map(option => (
                                        <div
                                            onClick={selectedOption => this.toggleSegmentation(option.name)}
                                            key={option.name}
                                            className={`saved-segmentation-item ${option.name === this.state.active ? 'active-segmentation-v4' : ''}`}
                                        >
                                            <div className="save-segmentation-name">
                                                <span>{option.name}</span>
                                            </div>
                                            <div className="save-segmentation-option">
                                                <LineSegmentationV2 line={{ ...option, segmentation: option }} showAsList hideSpans />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div>
                            <div className="mt-3">
                                <ChannelV4 intl={this.props.intl} />
                            </div>
                            <div>
                                {channel != null && channel !== 'B0' && channel !== 'HE' && (
                                    <div onClick={this.scrollDown}>
                                        <SalesForceIdV3 intl={this.props.intl} />
                                    </div>
                                )}
                                {(channel === 'C0' ||
                                    channel === 'BB' ||
                                    channel === 'BD' ||
                                    channel === 'B0' ||
                                    channel === 'HE' ||
                                    channel === 'DA' ||
                                    channel === 'HO' ||
                                    channel === 'nexus_retail' ||
                                    channel === 'nexus_non_retail') && (
                                    <div onClick={this.scrollDown}>
                                        <ProjectIdV3 intl={this.props.intl} />
                                    </div>
                                )}
                            </div>
                            {this.props.viewFullSegmentationV3 && (
                                <div className="row p-0 m-0" style={{ gap: '16px' }}>
                                    <div className="col p-0 m-0" onClick={this.scrollDown}>
                                        <TypologyV3 intl={this.props.intl} product={this.state.product} />
                                    </div>
                                    <div className="col p-0 m-0" onClick={this.scrollDown}>
                                        <UseV3 intl={this.props.intl} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                <footer>
                    <div>
                        {this.isFormFullFilled() ? (
                            <Button onClick={
                                this.state.active
                                    ? () => this.saveSegmentation(this.state.active)
                                    : () => {
                                            this.setState({ saveSegmentationPromptOpen: true })
                                        }
                                }
                            >
                                <Translation id="save" defaultMessage="Save" />
                            </Button>
                        ) : (
                            <Button disabled>
                                <Translation id="save" defaultMessage="Save" />
                            </Button>
                        )}
                    </div>
                    <div>{submitButton}</div>
                </footer>
                {this.state.saveSegmentationPromptOpen && (
                    <Modal
                        title={<Translation id="segmentation_prompt_title" defaultMessage="Saving segmentation" />}
                        onClose={() =>
                            this.setState({
                                saveSegmentationPromptOpen: false
                            })
                        }
                        footer={
                            <Button onClick={() => this.saveSegmentation(this.inputSegmentation.value)} inverted size="medium">
                                <Translation id="save" defaultMessage="Save" />
                            </Button>
                        }
                    >
                        <p>
                            <Translation id="segmentation_prompt_body" defaultMessage="Please choose a unique name for this configuration" />
                        </p>
                        <div className="form-group">
                            <b>
                                <small>
                                    <Translation id="name" defaultMessage="Name" />
                                </small>
                            </b>
                            <input
                                ref={ref => (this.inputSegmentation = ref)}
                                type="text"
                                className="form-control"
                                data-cy="modal_segmentation__input_name_save_segmentation"
                                placeholder={this.props.intl.formatMessage({
                                    id: 'name'
                                })}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}
export default injectIntl(SegmentationV3)
