import { getMinRequiredDate, getCheckoutStepOneButtonNextDisabled } from './../../store/checkout/reducers';
import { connect } from 'react-redux'
import CartLine from './CartLine'
import {
    removeCartLine,
    changeCartLineQty,
    changeCartLineSegmentation,
    changeCartLineComment,
    saveCartLineComment,
    addCartLineNotSegmentation,
    unsetLineDeliveryDate,
    setAllLinesDeliveryDatesCts
} from '../../store/cart/actions'
import {dispatchPushURL, openBundleContent, showSegmentationModal} from '../../store/ui/actions'
import { getBusinesSegmentOptions, getUseOptions, getChannelOptionsV3, getTypologyOptionsV3, getHasSegmentationV3 } from '../../store/segmentation/reducers'
import { getShippingMethod, getIsMkToolsCenter, getIsIkeaCenter, getIsMktoolsUsaCenter, getPricesGroup, isDisabledPreferedDeliveryDatePicker, getIsEndiCenter, getIsLowesCenter, getSelectedCenter, isUsaOrCanada } from '../../store/centers/reducers'
import { loadThisSegmentation } from '../../store/segmentation/actions'
import {
    getQtyProductsCart,
    getLinesPrice,
    getCalculatingLinesPrice,
    getDeliveryDateControlKey,
    getLinesSlaDate,
    getIsLoadingLinesSlaDate,
    getLinesDeliveryDates,
    isMallCartForContainer,
    isDefaultDeliveryDate,
    hideSlaDatesForCts,
    linesSlaDateVisibility
} from '../../store/cart/reducers'
import { getImageBackground, toogleFavourite } from '../../store/products/actions'
import {
    getUserPriceList,
    getPriceListAtSelectedCenter,
    getUserType,
    getNaturalStoneViewingPermissions
} from '../../store/login/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { canViewPricesPhase2, canViewPricesPhase3, isFavouriteById } from '../../store/products/reducers'
import { getLanguage } from '../../store/ui/reducers'
import {getAdaptedFormat, getCtsMeasures} from "../../helpers";
import {setLineDeliveryDate} from "../../store/cart/actions";
import {getProductMinStock, getSelectedProduct} from "../../store/landing/reducers";
import {setLastSelectedProduct} from "../../store/selected-objects/actions";
import { getShippingAddresses } from '../../store/shipping-addresses/reducers';

const mapStateToProps = state => {
    return {
        selectedCenter: getSelectedCenter(state),
        minDeliveryDate: getMinRequiredDate(state),
        linesDeliveryDates: getLinesDeliveryDates(state),
        deliveryDateControlKey: getDeliveryDateControlKey(state),
        businesSegmentOptions: getBusinesSegmentOptions(state),
        useOptions: getUseOptions(state),
        userPreferences: getProfileConfiguration(state),
        shippingMethod: getShippingMethod(state),
        segmentationV3: getHasSegmentationV3(state),
        channelOptionsV3: getChannelOptionsV3(state),
        typologyOptionsV3: getTypologyOptionsV3(state),
        qtyProductCart: getQtyProductsCart(state),
        priceGroup: getPricesGroup(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isMktoolsUsaCenter: getIsMktoolsUsaCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        userPriceList: getUserPriceList(state),
        priceList: getPriceListAtSelectedCenter(state),
        linesPrice: getLinesPrice(state),
        calculatingLinesPrice: getCalculatingLinesPrice(state),
        canViewPricesPhase2: canViewPricesPhase2(state),
        canViewPricesPhase3: canViewPricesPhase3(state),
        lang: getLanguage(state),
        mallMinOrder: (productId) => getProductMinStock(state, productId),
        selectedProduct: getSelectedProduct(state),
        getCtsMeasure: (cutToSize) => getCtsMeasures(cutToSize, state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        userType: getUserType(state),
        isEndiCenter: getIsEndiCenter(state),
        isFavouriteById: (productId) => isFavouriteById(state, productId),
        linesSlaDate: getLinesSlaDate(state),
        isLoadingLinesSlaDate: getIsLoadingLinesSlaDate(state),
        shippingAddresses: getShippingAddresses(state),
        isMallCartForContainer: isMallCartForContainer(state),
        isDefaultDeliveryDate: isDefaultDeliveryDate(state),
        hideSlaDatesForCts: hideSlaDatesForCts(state),
        linesSlaDateVisibility: linesSlaDateVisibility(state),
        naturalStoneViewingPermissions: getNaturalStoneViewingPermissions(state),
        isCheckoutStepOneButtonNextDisabled: getCheckoutStepOneButtonNextDisabled(state),
        isDisabledPreferedDeliveryDatePicker: isDisabledPreferedDeliveryDatePicker(state),
        isUsaOrCanada: isUsaOrCanada(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDelete: lineId => {
            dispatch(removeCartLine(lineId))
        },
        onToggleFavourite: productId => {
            dispatch(toogleFavourite(productId))
        },
        changeCartLineQty: (lineId, qty) => {
            dispatch(changeCartLineQty(lineId, qty))
        },
        openBundleContent: product => {
            dispatch(openBundleContent(product))
        },
        loadThisSegmentation: seg => {
            dispatch(loadThisSegmentation(seg))
        },
        showSegmentationModal: (mode, callback, productId) => {
            dispatch(showSegmentationModal(mode, callback, productId))
        },
        changeCartLineSegmentation: lineId => {
            dispatch(changeCartLineSegmentation(lineId))
        },
        onOpenBundleContent: product => {
            dispatch(openBundleContent(product))
        },
        getImageBackground: product => dispatch(getImageBackground(product)),
        saveCartLineComment: (lineId, comment) => dispatch(saveCartLineComment(lineId, comment)),

        changeCartLineComment: (lineId, comment) => dispatch(changeCartLineComment(lineId, comment)),

        addCartLine: (productId, qty) => dispatch(addCartLineNotSegmentation(productId, qty)),
        setLineDeliveryDate: (lineId, date) => dispatch(setLineDeliveryDate(lineId, date)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        setSelectedProductId: productId => dispatch({ type: 'SET_SELECTED_MALL_PRODUCT', productId }),
        setIsCommingFromMall: (isCommingFromMall) => dispatch({type: 'SET_IS_COMMING_FROM_MALL', isCommingFromMall}),
        setLastSelectedProduct: (product, extra) => {
            product.extra = {...extra, ...product.extra}
            dispatch(setLastSelectedProduct(product))
        },
        unsetLineDeliveryDate: (lineId) => dispatch(unsetLineDeliveryDate(lineId)),
        setAllLinesDeliveryDatesCts: (deliveryDateCts, slaDateState) => dispatch(setAllLinesDeliveryDatesCts(deliveryDateCts, slaDateState))
    }
}

const CartLineContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartLine)

export default CartLineContainer
