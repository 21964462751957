import React from 'react'
import Translation from '../../global/Translation'
import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'
import mallLarge from '../../../assets/img/logos/mall-large.jpg'
//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'
import DisplayStock from './displays/DisplayStock'
import DisplayPrice from './displays/DisplayPrice'
import LocalizedLink from '../../global/LocalizedLink'
import { connect } from 'react-redux'
import { getAdaptedFormat, getReplaceDotWithComma } from '../../../helpers'
import DisplayNaturalStoneOrigin from './displays/DisplayNaturalStoneOrigin'

class DetailsDefaultV2TemporalFix extends React.Component {
    state = { showHelp: false }
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    render() {
        const { product, canViewThresholdMax, intl } = this.props

        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div style={{ display: 'inline' }}>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }
        const watchingOutlet = this.props.outlet ? true : false
        let stock
        if (watchingOutlet) {
            stock = product.stock_outlet
        } else {
            stock = product.stock
        }

        return (
            <DisplayProductInfo mallPromotionSection={this.props.mallPromotionSection}>
                <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ columnGap: '16px', rowGap: 0 }}>
                    <div>
                        <DisplayBrand brand={product.brand} colorSerieDesc={product.colorSerieDesc} />
                    </div>
                    <div className="d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                        {product.fromMall && (
                            <div>
                                <img style={{ width: '38px' }} src={mallLarge} alt="logo mall" />
                            </div>
                        )}
                    </div>
                </div>

                <div className="product-color-name">
                    <ProductDisplayName product={product} />
                </div>
                {!this.props.mallPromotionSection && (
                    <div className="product-info-details_productid">
                        <div>
                            <DisplayGroupDtails>
                                <DisplayDetail class="productId" value={product.productId} label={<Translation id="material_id" defaultMessage="Material ID" />} />
                            </DisplayGroupDtails>
                        </div>
                    </div>
                )}

                {!this.props.notDescriptionProduct && !this.props.mallPromotionSection && (
                    <React.Fragment>
                        <div className="product-info-details__content">
                            <DisplayGroupDtails>
                                <DisplayDetail
                                    class="measure-unit format"
                                    value={this.props.adaptedFormat(product.format, { upper: true, showUnits: true })}
                                    label={<Translation id="format" defaultMessage="Format" />}
                                />
                            </DisplayGroupDtails>
                            <DisplayGroupDtails>
                                <DisplayDetail class="subtype" value={product.subtypeName} label={<Translation id="subtype" defaultMessage="Subtype" />} />
                            </DisplayGroupDtails>
                            <DisplayGroupDtails>
                                <DisplayDetail
                                    class="thickness"
                                    value={this.props.replaceDotWithComma(product.thickness, intl.locale)}
                                    label={<Translation id="thickness" defaultMessage="Thickness" />}
                                />
                                {product.thickness && <span className="thickness-measure">cm</span>}
                            </DisplayGroupDtails>
                            <DisplayGroupDtails>
                                <DisplayDetail class="finish" value={product.finish} label={<Translation id="finish" defaultMessage="Finish" />} />
                            </DisplayGroupDtails>

                            <DisplayGroupDtails>
                                <div className="product_detail">
                                    <div className="text">
                                        <span className="bold-text">
                                            <Translation id="MAL" defaultMessage="MAL" />
                                        </span>
                                        :&nbsp;
                                        {product.zaddon === 'MAL' ? <Translation id="yes" defaultMessage="Yes" /> : <Translation id="no" defaultMessage="No" />}
                                    </div>
                                </div>
                            </DisplayGroupDtails>

                            <DisplayNaturalStoneOrigin product={product} />
                        </div>
                        <DisplayStock
                            value={stock}
                            measure={quantity}
                            surface={this.getUnitBy()}
                            product={this.props.product}
                            landingMall={this.props.landingMall}
                            watchingOutlet={this.props.outlet}
                            canViewThresholdMax={canViewThresholdMax}
                        />
                    </React.Fragment>
                )}

                {this.props.landingMall && (
                    <React.Fragment>
                        <div className="product-info-details__content">
                            <DisplayGroupDtails>
                                <DisplayDetail
                                    class="finish minimum"
                                    value={this.props.landingMall.minimumOrder || '1'}
                                    label={<Translation id="minimun_order" defaultMessage="Minimun Order" />}
                                />
                            </DisplayGroupDtails>
                        </div>
                        
                        <div class="box-buttons-lots">
                            {(this.props.landingMall.stock > 0 || this.props.landingMall.data.stock) > 0 &&
                                this.props.landingMall.data.type === 'TABLA' &&
                                (this.props.landingMall.data.lotViewAvailable === undefined || this.props.landingMall.data.lotViewAvailable ? (
                                    <div className="buttons-lots">
                                        <LocalizedLink routeId="ROUTE_LOTS" >
                                            <div className="lots">
                                                <Translation id="view_lots" defaultMessage="View lots" />
                                            </div>
                                        </LocalizedLink>
                                    </div>
                                ) : (
                                    <div className="buttons-lots">
                                        <React.Fragment>
                                            <Translation id="lots" defaultMessage="Lots" /> 
                                        </React.Fragment>
                                    </div>
                                ))}
                        </div>
                    </React.Fragment>
                )}

                <DisplayGroupDtails>
                    <DisplayPrice product={this.props.product} outlet={this.props.outlet} />
                </DisplayGroupDtails>

                {this.props.mallPromotionSection && (
                    <React.Fragment>
                        <div className="promotion-progress-bar">
                            <span
                                style={{
                                    width: `${
                                        this.props.product.stock > 0
                                            ? //(this.props.product.stock_mall / this.props.product.stock) * 100
                                              100
                                            : 0
                                    }%`,
                                    backgroundColor: `rgb(54, 165, 150)`
                                }}
                            ></span>
                        </div>

                        <DisplayGroupDtails>
                            <DisplayStock value={stock} measure={quantity} surface={this.getUnitBy()} product={this.props.product} />
                        </DisplayGroupDtails>
                    </React.Fragment>
                )}
            </DisplayProductInfo>
        )
    }
}

const mapStateToProps = state => {
    return {
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state)
    }
}

export default connect(mapStateToProps, null)(DetailsDefaultV2TemporalFix)
