import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Spinner from '../global/Spinner'
import ProfileContainer from '../profile/ProfileContainer'
import Button from '../global/Button'
import RecurringOrderDetails from './RecurringOrderDetails'
import moment from 'moment'
import CentersContainer from '../centers/CentersContainer'
import LocalizedLink from '../global/LocalizedLink'
import $ from 'jquery'
import InputNumber from '../global/InputNumber'
import DatePicker from 'react-datepicker'
import debounce from 'lodash/debounce'
import ProductDisplayName from '../global/ProductDisplayName'
import LineSegmentationRecurring from '../cart-line/LineSegmentationRecurring'
import ProductImage from '../catalog/ProductImage'
import ReactTooltip from 'react-tooltip'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayDiscontinued from '../catalog/rectangleResult/displays/DisplayDiscontinued'
import { isNewIndicator, isUpcomingIndicator, canDisplayDiscontinued } from '../../store/products/reducers'
import DisplayUpcomingIndicator from '../catalog/rectangleResult/displays/DisplayUpcomingIndicator'
import DisplayNewIndicator from '../catalog/rectangleResult/displays/DisplayNewIndicator'

const DEFAULT_PAGE_SIZE = 5
const DEFAULT_PAGE_OPTION = { label: '2 rows', value: 2 }

class RecurringOrders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRecurringdOrder: null,
            showRecurringOrderDetails: false,
            selectedIndex: 0,
            currentPageSize: DEFAULT_PAGE_SIZE,
            currentValue: DEFAULT_PAGE_OPTION,
            dateFrom: moment().subtract(3, 'month'),
            dateTo: moment(),
            recurringOrdersPaginateFilter: [],
            pagesCount: 0,
            search: null
        }
    }

    componentDidMount() {
        this.fetchRecurringOrders()
    }
    getMin(line) {
        return line.product.min
    }
    getLimit(line) {
        if (line.extra && line.extra.mall) {
            return this.getLimitMall(line)
        }
        if (line.product.discontinued) {
            if (this.props.isMktoolsUsaCenter && this.getLimitMktool(line) < this.getLimitDiscontinued(line)) return this.getLimitMktool(line)
            else return this.getLimitDiscontinued(line)
        } else if (this.props.isMktoolsUsaCenter) return this.getLimitMktool(line)
        else if (line.product.isMktool) return this.getLimitMktool(line)
        else if (line.extra && line.extra.outlet) return this.getLimitOutlet(line)
        else return null
    }
    getLimitMktool(line) {
        let result = line.qty
        if (line.product.isMktool) {
            if (this.props.isMktoolsUsaCenter && (line.product.max || line.product.max === 0)) result = line.qty + line.product.max
            else result = result + 1000
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    getLimitOutlet(line) {
        let result = line.qty
        if (line.product.stock_outlet) {
            result = result + parseInt(line.product.stock_outlet, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    getLimitDiscontinued(line) {
        let result = line.qty
        if (line.product.stock + line.product.stock_outlet) {
            result = result + parseInt(line.product.stock + line.product.stock_outlet, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    getLimitMall(line) {
        let result = line.qty
        if (line.extra && line.extra.mall && line.extra.outlet && line.product.stock_outlet > 0) {
            result = result + parseInt(line.product.stock_outlet, 10)
        } else {
            result = result + parseInt(line.product.stock, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }

    fetchRecurringOrders() {
        this.props.fetchRecurringOrders(moment(this.state.dateFrom).format('YYYY-MM-DD')).then(() => {
            this.setState({
                pagesCount: Math.ceil(this.props.recurringOrders.length / this.state.currentPageSize),
                // recurringOrdersPaginateFilter: this.props.recurringOrders ? this.props.recurringOrders.slice(this.state.selectedIndex * this.state.currentPageSize, this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize) : []
                recurringOrdersPaginateFilter: this.props.recurringOrders ? this.props.recurringOrders : []
            })
        })
    }

    fetchRecurringOrdersByDate() {
        this.props.fetchRecurringOrders(moment(this.state.dateFrom).format('YYYY-MM-DD'), moment(this.state.dateTo).format('YYYY-MM-DD'), this.state.search).then(() => {
            this.setState({
                pagesCount: Math.ceil(this.props.recurringOrders.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: this.props.recurringOrders
                    ? this.props.recurringOrders.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        })
    }

    fetchRecurringOrdersPaginate(page) {
        this.props.fetchRecurringOrders(moment(this.state.dateFrom).format('YYYY-MM-DD'), moment(this.state.dateTo).format('YYYY-MM-DD'), this.state.search, page).then(() => {
            this.setState({
                pagesCount: Math.ceil(this.props.recurringOrders.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: this.props.recurringOrders
                    ? this.props.recurringOrders.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        })
    }

    fetchRecurringOrdersSearch = debounce(() => {
        this.props.fetchRecurringOrders(moment(this.state.dateFrom).format('YYYY-MM-DD'), moment(this.state.dateTo).format('YYYY-MM-DD'), this.state.search).then(() => {
            this.setState({
                pagesCount: Math.ceil(this.props.recurringOrders.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: this.props.recurringOrders
                    ? this.props.recurringOrders.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        })
    }, 500)

    showHiddenTr(id) {
        if ($('.' + id).hasClass('hidden-column-v2')) {
            $('.' + id).removeClass('hidden-column-v2')
            $('#pAll_' + id).css('display', 'none')
            $('#pNotAll_' + id).css('display', 'block')
        } else {
            $('.' + id).addClass('hidden-column-v2')
            $('#pAll_' + id).css('display', 'block')
            $('#pNotAll_' + id).css('display', 'none')
        }
    }

    filterDate() {
        if (this.state.dateFrom && !this.state.dateTo) {
            let recurringOrdersFilter = this.props.recurringOrders.filter(recurringOrder => {
                let dateRecurringOrder = new Date(recurringOrder.createdAt)
                let dateFrom = this.state.dateFrom ? new Date(this.state.dateFrom) : null
                if (this.state.dateFrom && dateRecurringOrder >= dateFrom) {
                    return recurringOrder
                }
            })
            this.setState({
                pagesCount: Math.ceil(recurringOrdersFilter.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: recurringOrdersFilter
                    ? recurringOrdersFilter.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        } else if (!this.state.dateFrom && this.state.dateTo) {
            let recurringOrdersFilter = this.props.recurringOrders.filter(recurringOrder => {
                let dateRecurringOrder = new Date(recurringOrder.createdAt)
                let dateTo = this.state.dateTo ? new Date(this.state.dateTo) : null
                if (this.state.dateTo && dateRecurringOrder <= dateTo) {
                    return recurringOrder
                }
            })
            this.setState({
                pagesCount: Math.ceil(recurringOrdersFilter.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: recurringOrdersFilter
                    ? recurringOrdersFilter.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        } else if (this.state.dateFrom && this.state.dateTo) {
            let recurringOrdersFilter = this.props.recurringOrders.filter(recurringOrder => {
                let dateRecurringOrder = new Date(recurringOrder.createdAt)
                let dateFrom = this.state.dateFrom ? new Date(this.state.dateFrom) : null
                let dateTo = this.state.dateTo ? new Date(this.state.dateTo) : null
                if (this.state.dateFrom && dateRecurringOrder >= dateFrom) {
                    if (this.state.dateTo && dateRecurringOrder <= dateTo) {
                        return recurringOrder
                    }
                }
            })
            this.setState({
                pagesCount: Math.ceil(recurringOrdersFilter.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: recurringOrdersFilter
                    ? recurringOrdersFilter.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        } else {
            this.setState({
                pagesCount: Math.ceil(this.props.recurringOrders.length / this.state.currentPageSize),
                recurringOrdersPaginateFilter: this.props.recurringOrders
                    ? this.props.recurringOrders.slice(
                          this.state.selectedIndex * this.state.currentPageSize,
                          this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                      )
                    : []
            })
        }
    }

    filterOrder(value) {
        let recurringOrdersFilter = this.props.recurringOrders.filter(recurringOrder => {
            let exist = 0
            for (let line of recurringOrder.lines) {
                if (
                    line.productName
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) !== -1 ||
                    line.productId.toString().indexOf(value) !== -1
                ) {
                    exist++
                }
            }
            if (recurringOrder.id.toString().indexOf(value) !== -1 || exist !== 0) {
                return recurringOrder
            }
        })
        this.setState({
            pagesCount: Math.ceil(recurringOrdersFilter.length / this.state.currentPageSize),
            recurringOrdersPaginateFilter: recurringOrdersFilter
                ? recurringOrdersFilter.slice(
                      this.state.selectedIndex * this.state.currentPageSize,
                      this.state.selectedIndex * this.state.currentPageSize + this.state.currentPageSize
                  )
                : []
        })
    }

    render() {
        const { showRecurringOrderDetails, closeModalRecurringOrderDetails } = this.props
        let image = null

        let pagination = []
        let newPagination = []
        if (this.state.recurringOrdersPaginateFilter && this.state.recurringOrdersPaginateFilter.length !== 0) {
            let ii = this.state.recurringOrdersPaginateFilter[0].currentPage >= 10 ? this.state.recurringOrdersPaginateFilter[0].currentPage - 5 : 1
            for (let i = ii; i < this.state.recurringOrdersPaginateFilter[0].lastPage; i++) {
                if (i === 10) {
                    break
                }
                pagination.push(
                    <li
                        className={i === this.state.recurringOrdersPaginateFilter[0].currentPage ? 'page-item active' : 'page-item'}
                        onClick={() => this.fetchRecurringOrdersPaginate(i)}
                    >
                        <a role="button" className="page-link">
                            {i}
                        </a>
                    </li>
                )
            }

            if (pagination.length > 4) {
                if (this.state.recurringOrdersPaginateFilter[0].currentPage !== 1 && this.state.recurringOrdersPaginateFilter[0].currentPage !== pagination.length) {
                    newPagination = [
                        pagination[this.state.recurringOrdersPaginateFilter[0].currentPage - 2],
                        pagination[this.state.recurringOrdersPaginateFilter[0].currentPage - 1],
                        pagination[this.state.recurringOrdersPaginateFilter[0].currentPage],
                        <li>
                            <span role="button" className="page-link">
                                ...
                            </span>
                        </li>,
                        pagination[pagination.length - 1]
                    ]
                } else {
                    if (this.state.recurringOrdersPaginateFilter[0].currentPage === 1) {
                        newPagination = [
                            pagination[0],
                            pagination[1],
                            pagination[2],
                            <li>
                                <span role="button" className="page-link">
                                    ...
                                </span>
                            </li>,
                            pagination[pagination.length - 1]
                        ]
                    }
                    if (this.state.recurringOrdersPaginateFilter[0].currentPage === pagination.length) {
                        newPagination = [
                            pagination[0],
                            <li>
                                <span role="button" className="page-link">
                                    ...
                                </span>
                            </li>,
                            pagination[pagination.length - 2],
                            pagination[pagination.length - 1]
                        ]
                    }
                }
            }
        }
        return (
            <div className="box-profile row">
                <div className="profile-container-box col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>
                <div className="col-12 col-xl-9">
                    <div className="profile-form">
                        {this.props.isLoading && <Spinner isLoading={this.props.isLoading} />}
                        <div
                            className="header"
                            style={{ justifyContent: 'space-between', display: 'flex', backgroundColor: 'white', padding: '1em', flexWrap: 'wrap', alignItems: 'center' }}
                        >
                            <div id="filter_center" className="location">
                                <span style={{ fontWeight: 'bold' }}>
                                    <Translation id="center" defaultMessage="Center" />
                                </span>
                                <div className="box-select-center" style={{ marginTop: '0.7rem', marginBottom: '0.5rem' }}>
                                    <CentersContainer keepHere reloadPage={true} onChangeCenter={value => window.location.reload()} />
                                </div>
                            </div>
                            <div className="date">
                                <div className="filter_date">
                                    <div>
                                        <div id="filter_date_from" className="location">
                                            <span style={{ fontWeight: 'bold' }}>
                                                <Translation id="from" defaultMessage="From" />
                                            </span>
                                            <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <DatePicker
                                                    selected={this.state.dateFrom}
                                                    onChange={selectedOption => {
                                                        this.setState(
                                                            {
                                                                dateFrom: selectedOption
                                                            },
                                                            () => {
                                                                this.fetchRecurringOrdersByDate()
                                                            }
                                                        )
                                                    }}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onKeyDown={e => e.preventDefault()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div id="filter_date_to" className="location">
                                            <span style={{ fontWeight: 'bold' }}>
                                                <Translation id="to" defaultMessage="To" />
                                            </span>
                                            <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <DatePicker
                                                    selected={this.state.dateTo}
                                                    onChange={selectedOption => {
                                                        this.setState(
                                                            {
                                                                dateTo: selectedOption
                                                            },
                                                            () => {
                                                                this.fetchRecurringOrdersByDate()
                                                            }
                                                        )
                                                    }}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onKeyDown={e => e.preventDefault()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="search" style={{ display: 'grid', alignContent: 'end', maxWidth: 'inherit' }}>
                                <div id="filter_order" className="location">
                                    <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                        <input
                                            type="text"
                                            placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                            style={{
                                                width: '100%',
                                                borderRadius: '6px',
                                                padding: '0.4rem',
                                                border: '2px solid #E5E5E5'
                                            }}
                                            onKeyUp={e => {
                                                this.setState(
                                                    {
                                                        search: e.target.value
                                                    },
                                                    () => {
                                                        this.fetchRecurringOrdersSearch()
                                                    }
                                                )
                                            }}
                                        />
                                        <i className={`fal fa-search`}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.recurringOrdersPaginateFilter && this.state.recurringOrdersPaginateFilter.length > 0 ? (
                            <div style={{ marginTop: '1rem' }}>
                                {this.state.recurringOrdersPaginateFilter.map(value => {
                                    let date = moment(value.createdAt).format('LL')
                                    return (
                                        <div id="recurring_profile" gtm-id-pedido-recurrente={value.id} className="d-flex flex-column v2 recurring_profile">
                                            <div className="d-flex title" style={{ borderBottom: '1px solid #EBEBEB', fontWeight: '600', paddingBottom: '0.2em' }}>
                                                <div>
                                                    <Translation id="order_placed" defaultMessage="Order placed" />
                                                    {': ' + date}
                                                </div>
                                                <div>
                                                    <Translation id="order_quantity" defaultMessage="Order quantity" />
                                                    {`: ${value.lines.length}`}
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                {value.lines.map((line, index) => {
                                                    line.brand = line.product ? line.product.brand : ''
                                                    line.businessSegment = line.segmentation.businessSegment
                                                    line.channel = line.segmentation.channel
                                                    line.displayName = line.product ? line.product.productName : ''
                                                    line.productId = line.product ? line.product.productId : ''
                                                    line.subchannel = line.segmentation.subchannel
                                                    line.thumb = line.product ? line.product.thumb : ''
                                                    line.typology = line.segmentation.typology
                                                    line.use = line.segmentation.use
                                                    line.lineId = index + 1
                                                    line.orderId = value.id
                                                    let qtyToAdd = null
                                                    if (line.extra && line.extra.type === 'custom_sample') {
                                                        qtyToAdd = 6
                                                    } else if (line.cutToSize) {
                                                        qtyToAdd = 1
                                                    } else if (line.product && (line.product.typeId === '01' || line.product.typeId === 'NEWS')) {
                                                        qtyToAdd = 1
                                                    } else {
                                                        qtyToAdd = line.product.minQty[this.props.shippingMethod]
                                                    }
                                                    return (
                                                        <div className="desktop" style={{ marginBottom: '1em' }}>
                                                            <div className="desktop-version">
                                                                <div
                                                                    className={`d-flex align-items-center ${line.lineId > 3 ? 'hidden-column-v2 ' + line.orderId : ''}`}
                                                                    style={{ gap: '16px', height: '120px' }}
                                                                >
                                                                    <div className="line-item" style={{ height: '100%' }}>
                                                                        <div className="img">
                                                                            <div className="indicators" style={{ maxWidth: '100%' }}>
                                                                                {canDisplayDiscontinued(line.product.webHeading, line.product.discontinued) && (
                                                                                    <DisplayDiscontinued discontinued={line.product.discontinued} isCheckout={true} />
                                                                                )}
                                                                                {line.extra && line.extra.outlet && (
                                                                                    <DisplayOutletIndicator outletQuality={line.extra.outlet} isCheckout={true} />
                                                                                )}
                                                                                {isNewIndicator(line.product.creationDate) && <DisplayUpcomingIndicator />}
                                                                                {isUpcomingIndicator(line.product.creationDate) && <DisplayNewIndicator />}
                                                                            </div>
                                                                            {line.cutToSize && line.cutToSize.thumb && <img alt="" src={line.cutToSize.thumb} />}
                                                                            {((line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) ||
                                                                                (line.extra && line.extra.type === 'brochures')) && (
                                                                                <img alt="" src={this.props.getImageBackground(line.product)} />
                                                                            )}
                                                                            {!(line.cutToSize && line.cutToSize.thumb) &&
                                                                                !(
                                                                                    (line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) ||
                                                                                    (line.extra && line.extra.type === 'brochures')
                                                                                ) && (
                                                                                    <LocalizedLink
                                                                                        className="cart_line__link_image"
                                                                                        routeId="ROUTE_PRODUCT_DETAIL"
                                                                                        params={{ id: line.product.productId }}
                                                                                    >
                                                                                        <ProductImage product={line.product} customClass="recurring_orders_image" />
                                                                                    </LocalizedLink>
                                                                                )}
                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', gap: 0 }}>
                                                                            <div>
                                                                                <span className="title">
                                                                                    {line.cutToSize ? line.cutToSize.colorName : <ProductDisplayName product={line.product} />}
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{ fontSize: '10px' }}>{line.productId}</span>
                                                                                {line.product.zaddon === 'MAL' && (
                                                                                    <span>
                                                                                        {' '}
                                                                                        (<Translation id="MAL" />)
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: 1, height: '100%' }}>
                                                                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                                                                            <span style={{ fontSize: '14px' }}>
                                                                                <span style={{ fontWeight: 'bold', display: 'block', fontSize: '14px' }}>
                                                                                    <Translation id="segmentation" defaultMessage="Segmentation" />
                                                                                </span>
                                                                                <LineSegmentationRecurring line={line} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: 1, height: '100%' }}>
                                                                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                                                                            <span style={{ fontSize: '14px' }}>
                                                                                <span style={{ fontWeight: 'bold', display: 'block', fontSize: '14px' }}>
                                                                                    <Translation id="stock" defaultMessage="Stock" />
                                                                                </span>
                                                                                {line.product.stock === 0 ? (
                                                                                    <React.Fragment>
                                                                                        <span
                                                                                            style={{ margin: 0 }}
                                                                                            data-tip={this.props.intl.formatMessage({
                                                                                                id: 'material_is_currently_out_of_stock',
                                                                                                defaultMessage:
                                                                                                    'This material is currently out of stock but you can add it to your cart. Delivery date may be affected.'
                                                                                            })}
                                                                                        >
                                                                                            <i style={{ color: 'black' }} className="icon far visible fa-info-circle"></i>{' '}
                                                                                            {line.product.stock}{' '}
                                                                                            <Translation
                                                                                                id="tables_available_in_stock"
                                                                                                defaultMessage="Tables available in stock"
                                                                                            />
                                                                                        </span>
                                                                                        <ReactTooltip />
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        {line.product.stock}{' '}
                                                                                        <Translation id="tables_available_in_stock" defaultMessage="Tables available in stock" />
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: 1, height: '100%' }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
                                                                            <div>
                                                                                <InputNumber
                                                                                    value={line.qty}
                                                                                    change={qty => {
                                                                                        line.qty = qty
                                                                                    }}
                                                                                    size="small"
                                                                                    step={this.props.isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                                                                    limit={this.getLimit(line)}
                                                                                    min={this.getMin(line)}
                                                                                    decimals={this.props.isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                                                                />
                                                                            </div>
                                                                            <Button onClick={() => this.props.addCartLine(line.productId, line.qty)} gtm-label="ec_añadir_carrito">
                                                                                <i className="icon far visible fa-shopping-cart"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="responsive">
                                                                <div className="img">
                                                                    <div className="indicators" style={{ maxWidth: '100px' }}>
                                                                        {canDisplayDiscontinued(line.product.webHeading, line.product.discontinued) && (
                                                                            <DisplayDiscontinued discontinued={line.product.discontinued} isCheckout={true} />
                                                                        )}
                                                                        {line.extra && line.extra.outlet && (
                                                                            <DisplayOutletIndicator
                                                                                outletQuality={line.extra.outlet}
                                                                                outletDeviated={
                                                                                    this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null
                                                                                }
                                                                                isCheckout={true}
                                                                            />
                                                                        )}
                                                                        {isNewIndicator(line.product.creationDate) && <DisplayUpcomingIndicator />}
                                                                        {isUpcomingIndicator(line.product.creationDate) && <DisplayNewIndicator />}
                                                                    </div>
                                                                    {line.cutToSize && line.cutToSize.thumb && <img alt="" src={line.cutToSize.thumb} />}
                                                                    {((line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) ||
                                                                        (line.extra && line.extra.type === 'brochures')) && (
                                                                        <img alt="" src={this.props.getImageBackground(line.product)} />
                                                                    )}
                                                                    {!(line.cutToSize && line.cutToSize.thumb) &&
                                                                        !(
                                                                            (line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) ||
                                                                            (line.extra && line.extra.type === 'brochures')
                                                                        ) && (
                                                                            <LocalizedLink
                                                                                className="cart_line__link_image"
                                                                                routeId="ROUTE_PRODUCT_DETAIL"
                                                                                params={{ id: line.product.productId }}
                                                                            >
                                                                                <ProductImage product={line.product} customClass="recurring_orders_image" />
                                                                            </LocalizedLink>
                                                                        )}
                                                                </div>
                                                                <div className="texts">
                                                                    <span className="title">
                                                                        <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                                                                            <Translation id="material" defaultMessage="Material" />:{' '}
                                                                        </span>
                                                                        {line.cutToSize ? line.cutToSize.colorName : <ProductDisplayName product={line.product} />}
                                                                    </span>
                                                                    <br></br>
                                                                    <span>
                                                                        <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                                                                            <Translation id="segmentation" defaultMessage="Segmentation" />:
                                                                        </span>
                                                                        <LineSegmentationRecurring line={line} />
                                                                    </span>
                                                                    <br></br>
                                                                    <span>
                                                                        <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                                                                            <Translation id="stock" defaultMessage="Stock" />:{' '}
                                                                        </span>
                                                                        {line.product.stock === 0 ? (
                                                                            <React.Fragment>
                                                                                <span
                                                                                    style={{ margin: 0 }}
                                                                                    data-tip="Actualmente no hay stock de este material pero puede añadirlo al carrito. La fecha de entrega podrá verse afectada."
                                                                                >
                                                                                    <i style={{ color: 'black' }} className="icon far visible fa-info-circle"></i>{' '}
                                                                                    {line.product.stock}{' '}
                                                                                    <Translation id="tables_available_in_stock" defaultMessage="Tables available in stock" />
                                                                                </span>
                                                                                <ReactTooltip />
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {line.product.stock}{' '}
                                                                                <Translation id="tables_available_in_stock" defaultMessage="Tables available in stock" />
                                                                            </React.Fragment>
                                                                        )}
                                                                    </span>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }} className="inputs">
                                                                        <div>
                                                                            <InputNumber
                                                                                value={line.qty}
                                                                                change={qty => {
                                                                                    line.qty = qty
                                                                                }}
                                                                                size="small"
                                                                                step={this.props.isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                                                                limit={this.getLimit(line)}
                                                                                min={this.getMin(line)}
                                                                                decimals={this.props.isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                                                            />
                                                                        </div>
                                                                        <button
                                                                            style={{ marginLeft: '1rem' }}
                                                                            className="bt"
                                                                            onClick={() => this.props.addCartLine(line.productId, line.qty)}
                                                                            gtm-label="ec_añadir_carrito"
                                                                        >
                                                                            <i className="icon fal visible fa-shopping-cart"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="bottom-btns">
                                                {value.lines.length > 3 ? (
                                                    <p
                                                        id={'pAll_' + value.id}
                                                        ref={p => {
                                                            this.pAll = p
                                                        }}
                                                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.showHiddenTr(value.id)
                                                        }}
                                                    >
                                                        <Translation id="see_full_order" defaultMessage="See full order" /> ({value.lines.length})
                                                        <i
                                                            className="fal fa-angle-down"
                                                            style={{
                                                                marginLeft: '0.3rem',
                                                                fontSize: '1.5rem',
                                                                position: 'absolute'
                                                            }}
                                                        ></i>
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                                {value.lines.length > 3 ? (
                                                    <p
                                                        id={'pNotAll_' + value.id}
                                                        ref={p => {
                                                            this.pNotAll = p
                                                        }}
                                                        style={{
                                                            fontWeight: 'bold',
                                                            display: 'none',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            this.showHiddenTr(value.id)
                                                        }}
                                                    >
                                                        <Translation id="see_less" defaultMessage="See Less" />
                                                        <i
                                                            className="fal fa-angle-up"
                                                            style={{
                                                                marginLeft: '0.3rem',
                                                                fontSize: '1.5rem',
                                                                position: 'absolute'
                                                            }}
                                                        ></i>
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="d-flex justify-content-end" style={{ gap: '16px' }}>
                                                    <LocalizedLink routeId="ROUTE_CHECKOUT">
                                                        <button
                                                            className="bt"
                                                            style={{ padding: '7px 32px' }}
                                                            onClick={() => {
                                                                this.props.add2CartRecurringOrderQty(value)
                                                            }}
                                                            gtm-label="ec_comprar_ahora"
                                                        >
                                                            <Translation id="buy_now" defaultMessage="Buy now" />
                                                        </button>
                                                    </LocalizedLink>
                                                    <button
                                                        className="bt bt-inverted"
                                                        style={{ padding: '7px 32px' }}
                                                        onClick={() => this.props.add2CartRecurringOrderQty(value)}
                                                        gtm-label="añadir_pedido_completo"
                                                    >
                                                        <i className="icon far visible fa-shopping-cart"></i>
                                                        <Translation id="add_full_order" defaultMessage="Add full order" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="paginator d-flex flex-grow-1 justify-content-center">
                                    <ul className="pagination">
                                        <li
                                            className={this.state.recurringOrdersPaginateFilter[0].currentPage === 1 ? 'page-item disabled' : 'page-item'}
                                            onClick={() => this.fetchRecurringOrdersPaginate(this.state.recurringOrdersPaginateFilter[0].currentPage - 1)}
                                        >
                                            <a className="page-link page-nav">
                                                <span>
                                                    <i className="fas fa-chevron-left mr-3"></i>
                                                    <span className="move-btn">
                                                        <Translation id="previous" defaultMessage="Previous" />
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        {newPagination.length > 0 && newPagination}
                                        {newPagination.length === 0 && pagination}
                                        <li
                                            className={
                                                this.state.recurringOrdersPaginateFilter[0].currentPage === this.state.recurringOrdersPaginateFilter[0].lastPage
                                                    ? 'page-item disabled'
                                                    : 'page-item'
                                            }
                                            onClick={() => this.fetchRecurringOrdersPaginate(this.state.recurringOrdersPaginateFilter[0].currentPage + 1)}
                                        >
                                            <a className="page-link page-nav">
                                                <span>
                                                    <span className="move-btn">
                                                        <Translation id="next" defaultMessage="Next" />
                                                    </span>
                                                    <i className="fas fa-chevron-right ml-3"></i>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="row last-colors">
                                <div className="col-12">
                                    <div className="container">
                                        <section id="section-products-favourite" className="no-padding">
                                            <h4>
                                                <Translation id="no_recurring_orders_in_current_center" defaultMessage="No recurring orders in current center" />
                                            </h4>
                                            <p>
                                                <Translation id="select_another_center_or_keep_buying" defaultMessage="Select another center or keep buying" />
                                            </p>
                                            <div className="row">
                                                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                                                    <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: '' }}>
                                                        <Button inverted>
                                                            <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                                        </Button>
                                                    </LocalizedLink>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showRecurringOrderDetails && <RecurringOrderDetails onClose={() => closeModalRecurringOrderDetails()} />}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(RecurringOrders)
