import React, { Component } from 'react'

class TextInput extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.isFocused) {
            this.focus()
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isFocused && this.props.isFocused) {
            this.focus()
        }
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus()
        }
    }

    render() {
        const { type = 'text', className = '', placeholder, name = '', ...props } = this.props

        return (
            <input
                {...props}
                type={type}
                className={`global__input__component form-control ${className}`}
                ref={this.inputRef}
                placeholder={placeholder}
                name={name}
            />
        )
    }
}

export default TextInput
