import React from 'react'
import Translation from '../../../global/Translation'

export default class DisplayNewIndicator extends React.Component {
    render() {
        const styles = {
            backgroundColor: '#3897DC',
            color: '#FFFFFF',
            padding: '4px 8px',
            fontSize: '10px',
            fontWeight: '500',
            zIndex: 1,
            display: 'inline-block',
            lineHeight: '16px'
        }

        return (
            <div style={styles}>
                <Translation id="new" defaultMessage="New" />
            </div>
        )
    }
}
