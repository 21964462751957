import React from 'react'
import customProductIcon from '../../../assets/img/logos/custom-product-icon.svg'

const CustomProductIndicator = ({ customWidth = '48px', customHeight = 'auto' }) => {
    // Dynamic styles based on props
    const defaultStyles = {
        container: {
            display: 'inline-block',
            width: customWidth,
            height: customHeight,
        },
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
    };

    return (
        <div style={defaultStyles.container}>
            <img src={customProductIcon} alt="Custom" style={defaultStyles.img} />
        </div>
    )
}

export default CustomProductIndicator;
