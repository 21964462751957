import React from 'react'
import DisplayOutletIndicator from '../displays/DisplayOutletIndicator'
import DisplayNewIndicator from '../displays/DisplayNewIndicator'
import DisplayUpcomingIndicator from '../displays/DisplayUpcomingIndicator'
import Button from '../../../global/Button'
import DisplaySoldOut from '../displays/DisplaySoldOut'
import DisplayDiscontinued from '../displays/DisplayDiscontinued'
import { v4 as uuidv4 } from 'uuid'
import { isNewIndicator, isUpcomingIndicator } from '../../../../store/products/reducers'

const MallProductIndicators = props => {
    const getIndicators = () => {
        const indicators = props.indicators ? props.indicators : []

        if (
            (props.onZoomClick && props.product.detailImage && props.product.detailImage !== 'null') ||
            (props.onZoomClick && props.product.imgLarge && props.product.imgLarge !== 'null')
        )
            indicators.push(<Button className="slide-card__zoom" icon="expand" size="medium" inverted onClick={() => props.onZoomClick(props.product)} />)

        if (props.product.realStock === 0) {
            indicators.push(<DisplaySoldOut key={`mall-sold-out-ind_${props.product.mallProductId}`} />)
        } else {
            if (props.canDisplayDiscontinued()) {
                indicators.push(
                    <React.Fragment key={uuidv4()}>
                        <DisplayDiscontinued discontinued={props.product.discontinued} />
                    </React.Fragment>
                )
            }

            if (props.product.outlet) {
                indicators.push(
                    <DisplayOutletIndicator
                        key={`mall-outlet-ind_${props.product.mallProductId}`}
                        outletQuality={props.product.outletQuality}
                        outletDeviated={props.isAdmin || props.impersonatedBy ? props.product.outletDeviated : null}
                    />
                )
            }
        }

        return indicators
    }

    const getSphereIndicators = () => {
        const sphereIndicators = props.sphereIndicators ? props.sphereIndicators : []

        if (isNewIndicator(props.product.creationDate)) sphereIndicators.push(<DisplayNewIndicator key={uuidv4()} />)
        if (isUpcomingIndicator(props.product.creationDate)) sphereIndicators.push(<DisplayUpcomingIndicator key={uuidv4()} />)

        return sphereIndicators
    }

    return props.product ? (
        <div className="indicators" style={{ maxWidth: '100%' }}>
            {[...getIndicators(), ...getSphereIndicators()].map((indicator, index) => (
                <div key={index} style={{ zIndex: 1, lineHeight: 0 }}>
                    {indicator}
                </div>
            ))}
        </div>
    ) : null
}

export default MallProductIndicators
