import { getSelectedCenter, getShippingMethod, getCenterName } from './store/centers/reducers'
import {
    getUserType,
    getImpersonatedBy,
    getUserPriceList,
    getCommonParams,
    getPriceListAtSelectedCenter,
    hasAiRecommendations,
    getDataFromSalesforce,
    getNaturalStoneViewingPermissions
} from './store/login/reducers'
import { byProductId, getProductsFavouritesByIds } from './store/products/reducers'
import { byLineId, getLines, getLinePricePhase2ByLineId, totalSelector, totalCoin } from './store/cart/reducers'
import { LOCATION_CHANGE } from 'react-router-redux/reducer'
import React from 'react'
import {CtsIdsByElaboration} from "./components/cut-to-size/CutToSizeMaps";
import getNumberFormat from "./components/getNumberFormat";

/**
 * Check if the product isCosentinoGlobal
 * @param {number} selectedCenter 
 * @param {number} productCenter 
 * @returns {boolean}
 */
const isCosentinoGlobal = (selectedCenter, productCenter) => {
    if (selectedCenter != 1100 && productCenter == 1100) return true
    return false
}

const checkLoginType = (userType, impersonatedBy) => {
    if (impersonatedBy) {
        return 'Internal - Supplant'
    }
    if ((userType === 'CUSTOMER' && !impersonatedBy) || userType === 'SHOP') {
        return 'Client - Client'
    }
    if (userType === 'ADMIN') {
        return 'Internal - Direct'
    } else {
        return 'Visitant'
    }
}

export const cancelOrder = (reservationId, deliveryDate, orderStatus) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeguimientoAcciones',
            accionSeguimiento: 'cancelarPedido',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: ''
        })
        global.dataLayer.push({
            event: 'ga4wwwSeguimientoAcciones',
            accionSeguimiento: 'cancelarPedido',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: ''
        })
    }
}
export const editOrder = (reservationId, deliveryDate, orderStatus, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeguimientoAcciones',
            accionSeguimiento: 'editarPedido',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: ''
        })
        global.dataLayer.push({
            event: 'ga4wwwSeguimientoAcciones',
            accionSeguimiento: 'editarPedido',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: ''
        })
    }
}
export const viewOrderDetails = (reservationId, deliveryDate, orderStatus, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeguimientoAcciones',
            accionSeguimiento: 'detallePedido',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
        global.dataLayer.push({
            event: 'ga4wwwSeguimientoAcciones',
            accionSeguimiento: 'detallePedido',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
    }
}
export const createRecurringOrder = (reservationId, deliveryDate, orderStatus, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeguimientoAcciones',
            accionSeguimiento: 'pedidoRecurrente',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
        global.dataLayer.push({
            event: 'ga4wwwSeguimientoAcciones',
            accionSeguimiento: 'pedidoRecurrente',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
    }
}
export const viewInvoice = (reservationId, deliveryDate, orderStatus, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeguimientoAcciones',
            accionSeguimiento: 'verFactura',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
        global.dataLayer.push({
            event: 'ga4wwwSeguimientoAcciones',
            accionSeguimiento: 'verFactura',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
    }
}
export const printOrder = (reservationId, deliveryDate, orderStatus, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeguimientoAcciones',
            accionSeguimiento: 'imprimir',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
        global.dataLayer.push({
            event: 'ga4wwwSeguimientoAcciones',
            accionSeguimiento: 'imprimir',
            idPedido: reservationId,
            fechaEntrega: deliveryDate,
            estado: orderStatus,
            incidencias: '',
            ...commonParams
        })
    }
}
export const changeCenter = (centerId, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true'){
        global.dataLayer.push({
            event: 'wwwSeleccionarCentro',
            nombre_centro: centerId,
            ...commonParams
        })
        global.dataLayer.push({
            event: 'ga4wwwSeleccionarCentro',
            nombre_centro: centerId,
            ...commonParams
        })
    }
}

export const sendCatalogImpressions = (results, list, userPriceList, userMaterialsPriceList, commonParams, sessionCrossType, selectedCenter) => {
    const impressions = []
    const impressionsGA4 = [];
    if (results) {
        results.forEach((result, index) => {
            if (result) {
                const product = result._source || result // Searckit context products or our products
                const productHq = isCosentinoGlobal(selectedCenter, product.centerId)
                const finalPrice = getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams)
                //Universal Analytics
                impressions.push({
                    name: product.displayName,
                    id: product.productId,
                    price: finalPrice,
                    brand: product.brand,
                    category: product.type,
                    variant: product.finish,
                    dimension1: product.subtype,
                    dimension2: product.thickness,
                    dimension3: product.format,
                    dimension4: product.centerId,
                    dimension18: sessionCrossType,
                    dimension19: list,
                    list: list,
                    position: index,
                    product_HQ: productHq
                })
                //GA4 Analytics
                impressionsGA4.push({
                    item_name: product.displayName,
                    item_id: product.productId,
                    price: finalPrice,
                    item_brand: product.brand,
                    item_category: product.type,
                    item_category2: product.subtype,
                    item_category3: product.thickness,
                    item_category4: product.format,
                    item_variant: product.finish,
                    item_list_id: list,
                    index: index,
                    cross_type_product: sessionCrossType,
                    cross_type_list: list,
                    product_HQ: productHq
                })
            }
        })

        if (process.env.REACT_APP_ANALYTICS === 'true'){

            //Universal Analytics
            if (impressions) {
                global.dataLayer.push({
                    event: 'wwwProductImpressions',
                    ecommerce: {
                        currencyCode: 'EUR', //REVIEW
                        impressions
                    },
                    ...commonParams
                })
            }
            //GA4 Analytics
            if(impressionsGA4) {
                const ecommerceObject = {
                    currency: 'EUR',
                    items: impressionsGA4
                }
                pushDataLayerGA4('ga4wwwProductImpressions', ecommerceObject, commonParams)
            }
        }
    }
}

export const sendMallImpressions = (products, userPriceList, userMaterialsPriceList, commonParams, sessionCrossType) => {
    const impressions = []
    const impressionsGA4 = []
    if (products) {
        products.forEach((product, index) => {
            if (product) {
                //Universal Analytics
                impressions.push({
                    name: product.displayName,
                    id: product.productId,
                    price: getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams),
                    brand: product.brand,
                    category: product.type,
                    variant: product.finish,
                    dimension1: product.subtype,
                    dimension2: product.thickness,
                    dimension3: product.format,
                    dimension4: product.centerId,
                    dimension18: sessionCrossType,
                    dimension19: 'MALL',

                    list: 'MALL',
                    position: index
                })
                //GA4 Analytics
                impressionsGA4.push({
                    item_name: product.displayName,
                    item_id: product.productId,
                    price: getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams),
                    item_brand: product.brand,
                    item_category: product.type,
                    item_category2: product.subtype,
                    item_category3: product.thickness,
                    item_category4: product.format,
                    item_variant: product.finish,
                    item_list_id: 'MALL',
                    index: index,
                    cross_type_product: sessionCrossType,
                    cross_type_list: 'MALL',
                })
            }
        })

        if (process.env.REACT_APP_ANALYTICS === 'true'){
            //Universal Analytics
            if (impressions) {
                global.dataLayer.push({
                    event: 'wwwProductImpressions',
                    ecommerce: {
                        currencyCode: 'EUR', //REVIEW
                        impressions
                    },
                    ...commonParams
                })
            }
            //GA4 Analytics
            if(impressionsGA4){
                const ecommerceObject = {
                    currency: 'EUR',
                    items: impressionsGA4
                }
                pushDataLayerGA4('ga4wwwProductImpressions', ecommerceObject, commonParams)
            }
        }

    }
}

export const sendProductDetail = (product, center, userType, shippingMethod, userPriceList = null, userMaterialsPriceList, commonParams, sessionCrossType) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        //Universal Analytics
        const productLayer = {
            name: product.displayName,
            id: product.productId,
            price: getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams),
            brand: product.brand,
            category: product.type,
            variant: product.finish,
            dimension1: product.subtype,
            dimension2: product.thickness,
            dimension3: product.format,
            dimension4: product.centerId,
            dimension18: sessionCrossType,
            product_HQ: false
        }

        if (product.extra) {
            if (product.extra.analyticsTag) {
                productLayer.list = product.extra.analyticsTag
                productLayer.dimension19 = product.extra.analyticsTag
            }
            if (product.extra.variant) {
                productLayer.dimension20 = product.extra.variant
            }
            if (product.extra.crossType) productLayer.cross_type_product = product.extra.crossType
            if (product.extra.isCosentinoGlobal) productLayer.product_HQ = product.extra.isCosentinoGlobal
        }

        global.dataLayer.push({
            event: 'wwwProductView',
            ecommerce: {
                detail: {
                    products: [productLayer]
                }
            },
            ...commonParams
        })

        //GA4 Analytics
        const productLayerGA4 = {
            item_name: product.displayName,
            item_id: product.productId,
            price: getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams),
            item_brand: product.brand,
            item_category: product.type,
            item_category2: product.subtype,
            item_category3: product.thickness,
            item_category4: product.format,
            item_variant: product.finish,
            cross_type_product: sessionCrossType,
            product_HQ: false
        }

        if (product.extra) {
            if (product.extra.analyticsTag) {
                productLayerGA4.item_list_id = product.extra.analyticsTag
                productLayerGA4.item_list_name = product.extra.analyticsTag
                productLayerGA4.cross_type_list = product.extra.analyticsTag
            }
            if (product.extra.variant) {
                productLayerGA4.cross_variant = product.extra.variant
            }
            if (product.extra.crossType) productLayer.cross_type_product = product.extra.crossType
            if (product.extra.isCosentinoGlobal) productLayerGA4.product_HQ = product.extra.isCosentinoGlobal
        }

        pushDataLayerGA4('ga4wwwProductView', {
            items: [productLayerGA4]
        }, commonParams)
    }
}

export const applyDiscount = (discount, priceBefore) => {
    let priceAfter = priceBefore

    //CAUTION: THIS METHOD WORKS BOTH WITH PROMOTION OR PRICECONDITIONS
    if (discount.condition === 'A') {
        priceAfter = discount.price
        return priceAfter
    }
    if (discount.condition === 'B') {
        priceAfter = priceBefore - (priceBefore * discount.price) / 100
        return priceAfter
    }

    if (discount.coin === '%') {
        if (discount.condition === 'ZEFP' || discount.condition === 'ZEFN') {
            //esto es un extra
            priceAfter = priceBefore + (priceBefore * discount.price) / 100
        } else {
            // esto es un discount
            priceAfter = priceBefore - (priceBefore * discount.price) / 100
        }
    } else {
        if (discount.condition === 'ZEFP' || discount.condition === 'ZEFN') {
            //esto es un extra
            priceAfter = priceBefore + discount.price
        } else {
            // esto es un discount
            priceAfter = priceBefore - discount.price
        }
    }

    return priceAfter
}
export const getFinalPrice = (product, userPriceList, userMaterialsPriceList, commonParams) => {
    // console.log('por que doy null')
    // console.log('por que doy null, hola')

    // console.log(product)
    // console.log(userPriceList)
    if (!product.price && !userPriceList) return 0
    let finalPrice = calculatePrice(product, userPriceList, userMaterialsPriceList)
    // console.log('precio base')
    // console.log(finalPrice)

    // console.log('lista del usuario')
    // console.log(userPriceList)
    let discounts = getDiscounts(product, userPriceList, userMaterialsPriceList)
    discounts.forEach(discount => {
        finalPrice = applyDiscount(discount, finalPrice)
    })
    // console.log(discounts)
    // console.log('precio con descuentos')
    // console.log(finalPrice)
    // console.log(commonParams)
    let lang = commonParams && commonParams.idioma ? commonParams.idioma : 'en-US'
    let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    if(product.type == 'TABLA' && product.surface){
        // console.log(finalPrice,'before calculate')
        //send total price of slab instead of price by m2
        // console.log(product.surface,'surface')
        finalPrice = finalPrice * parseInt(product.surface,10)
        // console.log(finalPrice, 'after calculate')
    }
    finalPrice = numberFormat.format(finalPrice)
    // console.log(numberFormat)

    return finalPrice
}

export const getDiscounts = (product, userPriceList, userMaterialsPriceList) => {
    let priceCondition = {
        ZT00: { condition: 'ZT00', operator: '-', description: 'BASE_PRICE' },
        ZEFP: { condition: 'ZEFP', operator: '+', description: 'FINISH_EXTRA_PERCENT' },
        ZDPR: { condition: 'ZDPR', operator: '-', description: 'PRODUCT_DISCOUNT' },
        ZDCU: { condition: 'ZDCU', operator: '-', description: 'CUSTOMER_DISCOUNT' },
        ZDLO: { condition: 'ZDLO', operator: '-', description: 'LOGISTIC_DISCOUNT' },
        B: { condition: 'B', operator: '-', description: 'PERCENTUAL_DISCOUNT_PROMOTION' },
        ZEFN: { condition: 'ZEFN', operator: '+', description: 'FINISH_EXTRA' },
        A: { condition: 'A', operator: '', description: 'FIXED_PRICE_PROMOTION' },
        ZDWB: { condition: 'ZDWB', operator: '-', description: 'ECOSENTINO_DISCOUNT' },
        ZDPP: { condition: 'ZDPP', operator: '-', description: 'PROMT_PAYMENT_DISCOUNT' }
    }
    let priceConditionOrder = Object.keys(priceCondition)

    let discounts = []

    //prepara descuentos de material
    // console.log('antes de descuentos')
    // console.log(userPriceList)
    // console.log(product.price)
    // console.log(product.centerId)
    product.price &&
        product.price[product.centerId] &&
        product.price[product.centerId].forEach(priceCondition => {
            // let discount = this.normalizeDiscount(priceCondition)
            // if (discount) discounts.push(discount)
            // console.log('foreach discount')
            // console.log(priceCondition.salesOrg)
            // console.log(userPriceList.salesOrg)
            if (userPriceList && userPriceList.length > 0 && priceCondition.salesOrg === userPriceList[0].salesOrg) {
                let discount = normalizeDiscount(priceCondition, userPriceList, product)
                if (discount) discounts.push(discount)
            }
        })

    //prepara descuentos de cliente
    // console.log('despues de descuentos')
    // console.log(discounts)
    userMaterialsPriceList &&
        userMaterialsPriceList[product.productId] &&
        userMaterialsPriceList[product.productId].forEach(priceCondition => {
            let discount = normalizeDiscount(priceCondition, userPriceList, product)
            if (discount) {
                //if theres a discount with same condition , we keep the user condition, cause its more specific
                let foundAt = discounts.map(discount => discount.condition).indexOf(discount.condition)
                if (foundAt > -1) {
                    discounts.splice(foundAt, 1, discount)
                } else {
                    if (discount) discounts.push(discount)
                }
            }
        })

    discounts = discounts.sort((a, b) => {
        return priceConditionOrder.indexOf(a.condition) - priceConditionOrder.indexOf(b.condition)
    })
    return discounts
}
export const normalizeDiscount = (priceCondition, userPriceList, product) => {
    let m2toft2Ratio = 10.7639

    if (priceCondition.condition === 'ZT00' || priceCondition.condition === 'ZVFG') {
        return null
    }

    if (!userPriceList[0] || !getCurrentZT00(product, userPriceList[0].priceList)) return null
    // console.log('normalizando')
    // console.log(priceCondition.priceList)
    // console.log(userPriceList[0].priceList)
    // console.log(getCurrentZT00(product, userPriceList[0].priceList))
    if (priceCondition.priceList === userPriceList[0].priceList) {
        if (getCurrentZT00(product, userPriceList[0].priceList).measure === priceCondition.measure || priceCondition.coin === '%') {
            return priceCondition
        } else {
            if (getCurrentZT00(product, userPriceList[0].priceList).measure === 'M2' && priceCondition.measure === 'PI2') {
                priceCondition.price = priceCondition.price * m2toft2Ratio
                return priceCondition
            } else if (getCurrentZT00(product, userPriceList[0].priceList).measure === 'PI2' && priceCondition.measure === 'M2') {
                priceCondition.price = priceCondition.price / m2toft2Ratio
                return priceCondition
            }
        }
    }
    return null
}
export const getCurrentZT00 = (product, userPriceList) => {
    // console.log('current zt00')
    // console.log(product)
    // console.log(userPriceList)
    return (
        product.price &&
        product.price[product.centerId] &&
        product.price[product.centerId].filter(priceCondition => priceCondition.condition === 'ZT00' && priceCondition.priceList === userPriceList)[0]
    )
}
export const calculatePrice = (product, userPriceList, userMaterialsPriceList) => {
    let basePrice = undefined

    // console.log('calculando')
    // console.log(userPriceList)
    // console.log(product)
    // console.log(userMaterialsPriceList)
    if (!userPriceList || !userPriceList[0] || !userPriceList[0].priceList) return basePrice
    let price =
        product.price &&
        product.price[product.centerId] &&
        product.price[product.centerId].filter(condition => {
            return condition.condition === 'ZT00' && condition.priceList == userPriceList[0].priceList
        })
    basePrice = price && price[0] ? price[0].price : 0

    ///TODO:FIX FINAL PRICE

    return basePrice
}
export const productClick = (product, index, list = 'CATALOG', userPriceList, userMaterialsPriceList, commonParams, sessionCrossType, selectedCenter) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        //Universal Analytics
        const productLayer = {
            name: product.displayName,
            id: product.productId,
            price: getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams),
            brand: product.brand,
            category: product.type,
            variant: product.finish,
            dimension1: product.subtype,
            dimension2: product.thickness,
            dimension3: product.format,
            dimension4: product.centerId,
            dimension18: sessionCrossType,
            dimension19: list,
            list: list,
            position: index,
            product_HQ: false
        }

        if (product.extra) {
            if (product.extra.crossType) productLayer.cross_type_product = product.extra.crossType
            if (product.extra.variant) productLayer.dimension20 = product.extra.variant
            if (product.extra.isCosentinoGlobal) productLayer.product_HQ = product.extra.isCosentinoGlobal
        }

        global.dataLayer.push({
            event: 'wwwProductClick',
            ecommerce: {
                click: {
                    actionField: {list: list},
                    products: [productLayer]
                }
            },
            ...commonParams
        })

        //GA4 Analytics
        const productLayerGA4 = {
            item_name: product.displayName,
            item_id: product.productId,
            price: getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams),
            item_brand: product.brand,
            item_category: product.type,
            item_category2: product.subtype,
            item_category3: product.thickness,
            item_category4: product.format,
            item_variant: product.finish,
            item_list_id: list,
            index: index,
            cross_type_product: sessionCrossType,
            cross_type_list: list,
            product_HQ: false
        }

        if (product.extra) {
            if (product.extra.crossType) productLayerGA4.cross_type_product = product.extra.crossType
            if (product.extra.variant) productLayerGA4.cross_variant = product.extra.variant
            if (product.extra.isCosentinoGlobal) productLayerGA4.product_HQ = product.extra.isCosentinoGlobal
        }

        const ecommerceObject = {
            item_list_id: list,
            items: [productLayerGA4]
        }

        pushDataLayerGA4('ga4wwwProductClick', ecommerceObject, commonParams)
    }
}
export const sendAddToCart = (product, quantity, shippingMethod, username, userPriceList, userMaterialsPriceList, channelName, commonParams, extra, selectedCenter) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {

        //Universal Analytics
        try {
            const price = getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams)
            const lineProduct = {
                product: product,
                productId: product.productId,
                qty: quantity
            }
            const productLayer = getProductLayer(lineProduct, price, selectedCenter, extra)

            global.dataLayer.push({
                event: 'wwwAddToCart',
                ecommerce: {
                    currencyCode: 'EUR', //REVIEW
                    add: {
                        products: [productLayer]
                    }
                },
                ...commonParams
            })
        } catch (e) {
            console.log('wwwAddToCart ERROR:', e)
        }

        //GA4 Analytics
        try {
            const price = getFinalPrice(product, userPriceList, userMaterialsPriceList, commonParams)
            const lineProduct = {
                product: product,
                productId: product.productId,
                qty: quantity
            }
            const productLayer = getProductLayerGA4(lineProduct, price, selectedCenter, extra)

            const ecommerceObject = {
                currency: 'EUR',
                items: [productLayer]
            }

            pushDataLayerGA4('ga4wwwAddToCart', ecommerceObject, commonParams)

        } catch (e) {
            console.log('ga4wwwAddToCart ERROR:', e)
        }
    }
}
export const sendRemoveFromCart = (line, qty, shippingMethod, username, commonParams, lines = null, center) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        //Universal Analytics
        try {
            let items;
            if(lines){
                items = lines.map((line) => {
                    return getProductLayer(line, null, center)
                })
            } else if (line) {
                line.qty = qty
                const productLayer = getProductLayer(line, null, center)
                productLayer.item_category5 = line.product.channelName
                items = [productLayer]
            }

            global.dataLayer.push({
                event: 'wwwRemoveFromCart',
                ecommerce: {
                    currencyCode: 'EUR', //REVIEW
                    remove: {
                        products: items
                    }
                },
                ...commonParams
            })
        } catch (e) {
            console.log('wwwRemoveFromCart ERROR:', e)
        }

        //GA4 Analytics
        try {
            let items;
            if(lines){
                items = lines.map((line) => {
                    return getProductLayerGA4(line, null, center)
                })
            } else{
                const productLayer = getProductLayerGA4(line, null, center)
                productLayer.item_category5 = line.product.channelName
                items = [productLayer]
            }

            const ecommerceObject = {
                items,
                currency: 'EUR'
            }

            pushDataLayerGA4('ga4wwwRemoveFromCart', ecommerceObject, commonParams)
        } catch (e) {
            console.log('ga4wwwRemoveFromCart ERROR:', e)
        }
    }

}

const getProductLayer = (line, price, center, extra) => {
    const productLayer = {
        name: line.product.displayName,
        id: line.productId,
        quantity: line.qty,
        brand: line.product.brand,
        category: line.product.type,
        variant: !!line.cutToSize ? line.cutToSize.finish : line.product.finish,
        dimension1: line.product.subtype,
        dimension2: !!line.cutToSize ? line.cutToSize.thickness : line.product.thickness,
        dimension3: !!line.cutToSize ? `${line.cutToSize.width}x${line.cutToSize.height}`  : line.product.format,
        dimension4: line.product.centerId,
        product_HQ: isCosentinoGlobal(center, line.product.centerId)
    }
    if (!!price){
        productLayer.price = price
    }
    if (!!line.cutToSize || line.product.isCutToSize) productLayer.dimension16 = "corte a medida"

    const list = (!!line.extra && line.extra.analyticsTag) || (!!extra && extra.analyticsTag)
    if (list) {
        productLayer.list = list
        productLayer.dimension19 = list
    }

    const variant = (!!line.extra && line.extra.variant) || (!!extra && extra.variant)
    if (variant) {
        productLayer.dimension20 = variant
    }

    if (!!line.extra && line.extra.crossType) productLayer.cross_type_product = line.extra.crossType
    else if (!!extra && extra.crossType) productLayer.cross_type_product = extra.crossType
    if (!!extra && extra.sessionCrossType) productLayer.dimension18 = extra.sessionCrossType
    if (!!center) productLayer.dimension4 = center

    return productLayer
}

const getProductLayerGA4 = (line, price, center, extra) => {
    const productLayer = {
        item_name: line.product.displayName,
        item_id: line.productId,
        quantity: line.qty,
        item_brand: line.product.brand,
        item_category: line.product.type,
        item_category2: line.product.subtype,
        item_category3: !!line.cutToSize ? line.cutToSize.thickness : line.product.thickness,
        item_category4: !!line.cutToSize ? `${line.cutToSize.width}x${line.cutToSize.height}`  : line.product.format,
        item_variant: !!line.cutToSize ? line.cutToSize.finish : line.product.finish,
        product_HQ: isCosentinoGlobal(center, line.product.centerId)
    }
    if (!!price){
        productLayer.price = price
    }else{

    }
    if (!!line.cutToSize || line.product.isCutToSize) productLayer.item_category5 = "corte a medida"
    if (!!line.extra && line.extra.crossType) productLayer.cross_type_product = line.extra.crossType
    else if (!!extra && extra.crossType) productLayer.cross_type_product = extra.crossType

    const list = (!!line.extra && line.extra.analyticsTag) || (!!extra && extra.analyticsTag)
    if (list) {
        productLayer.item_list_id = list
        productLayer.cross_type_list = list
    }

    const variant = (!!line.extra && line.extra.variant) || (!!extra && extra.variant)
    if (variant) {
        productLayer.cross_variant = variant
    }

    return productLayer
}

export const sendCheckoutStep1Review = (center, userType, shippingMethod, username, userPriceList, userMaterialsPriceList, lines, commonParams, sessionCrossType) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        //Universal Analytics
        const products = lines.map(line => {
            const price = getFinalPrice(line.product, userPriceList, userMaterialsPriceList, commonParams)
            return getProductLayer(line, price, center, {sessionCrossType:sessionCrossType})
        })
        global.dataLayer.push({
            event: 'wwwCheckout',
            ecommerce: {
                checkout: {
                    actionField: {step: 1},
                    products
                }
            },
            ...commonParams
        })

        //GA4 Analytics
        const productsGA4 = lines.map(line => {
            const price = getFinalPrice(line.product, userPriceList, userMaterialsPriceList, commonParams)
            return getProductLayerGA4(line, price, center, {sessionCrossType:sessionCrossType})
        })

        const ecommerceObject = {
            items: productsGA4
        }

        if (process.env.REACT_APP_ANALYTICS === 'true') {
            pushDataLayerGA4('ga4wwwCheckout', ecommerceObject, commonParams)
        }
    }
}

export const sendCheckoutOk = (center, userType, shippingMethod, username, reservationCode, lines, userPriceList, userMaterialsPriceList, total, currencyCode, commonParams, sessionCrossType) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        let shippingNumber = {
            CONTAINER: 1,
            FULL_TRUCK: 2,
            SINGLE_SLAB: 3,
            DIRECT_TRUCK: 4,
            MKTOOLS: 5,
            CUT_TO_SIZE: 6,
            LOWES_WALL_SAMPLES: 7,
            LOWES_CUSTOMER_SAMPLES: 8,
            IKEA: 9
        }

        //Universal Analytics
        const products = lines.map(line => {
            let priceDivided = 0
            if(line.pricePhase2 > 0){
                priceDivided = line.pricePhase2 / line.qty
            }else{
                priceDivided = getFinalPrice(line.product, userPriceList, userMaterialsPriceList, commonParams)
            }

            return getProductLayer(line, priceDivided, center, {sessionCrossType:sessionCrossType})
        })

        global.dataLayer.push({
            event: 'wwwPurchase',
            ecommerce: {
                currencyCode: currencyCode,
                purchase: {
                    actionField: {
                        id: reservationCode,
                        affiliation: center,
                        shipping: shippingNumber[shippingMethod],
                        coupon: undefined, //REVIEW:
                        tax: undefined, //REVIEW:
                        revenue: total //REVIEW:
                    },
                    products
                }
            },
            ...commonParams
        })

        //GA4 Analytics
        const productsGA4 = lines.map(line => {
            let priceDivided = 0
            if(line.pricePhase2 > 0){
                priceDivided = line.pricePhase2 / line.qty
            }else{
                priceDivided = getFinalPrice(line.product, userPriceList, userMaterialsPriceList, commonParams)
            }

            return getProductLayerGA4(line, priceDivided, center, {sessionCrossType:sessionCrossType})
        })

        const ecommerceObject = {
            items: productsGA4,
            currency: currencyCode,
            transaction_id: reservationCode,
            shipping_tier: shippingNumber[shippingMethod],
            value: total
        }

        pushDataLayerGA4('ga4wwwPurchase', ecommerceObject, commonParams)
    }
}
export const sendSearchProduct = (term, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        //Universal Analytics
        global.dataLayer.push({
            event: 'wwwSearch',
            ecommerce: {
                search: [
                    {
                        event: term
                    }
                ]
            }
        })

        //GA4 Analytics
        pushDataLayerGA4('ga4wwwSearch', {search_term: term}, commonParams)
    }
}

export const setLocation = (dispatch, pageType, section, subsection, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        const object = {
            page_section: section,
            page_subsection: subsection,
            page_type_ec: pageType
        }


        global.dataLayer.push({
            event: 'www_ecosentino_pageview',
            page: object
        })

        global.dataLayer.push({ ecommerce: null });
        global.dataLayer.push({
            event: 'ga4www_ecosentino_pageview',
            page: object,
            ...commonParams
        })
    }

    dispatch({type: 'SET_LOCATION', pageType, section, subsection})
}

export const downloadLots = (type, commonParams) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        global.dataLayer.push({
            event: 'wwwDescargarLote',
            tipo_descarga: type,
            ...commonParams
        })
        global.dataLayer.push({
            event: 'ga4wwwDescargarLote',
            tipo_descarga: type,
            ...commonParams
        })
    }
}

export const showCrossSellingModal = (lines, priceList, userMaterialsPriceList, list, commonParams, sessionCrossType, selectedCenter) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        const articles = lines.single 
        const articlesLink = lines.multi 

        if (articles) {
            //Universal Analytics
            const impressions = articles.map((item, index) => {
                return {
                    name: item.displayName,
                    id: item.productId,
                    price: getFinalPrice(item.productId, priceList, userMaterialsPriceList, commonParams),
                    brand: item.brand,
                    category: item.type,
                    variant: item.finish,
                    dimension1: item.subtype,
                    dimension2: item.thickness,
                    dimension3: item.format,
                    dimension4: item.centerId,
                    dimension18: sessionCrossType,
                    dimension19: list,
                    list: list,
                    cross_type_product: !!lines.fromRecommendation ? 'dinamico' : 'estatico',
                    position: index,
                    product_HQ: isCosentinoGlobal(selectedCenter, item.centerId)
                }            
            })

            global.dataLayer.push({
                event: 'wwwProductImpressions',
                ecommerce: {
                    currencyCode: 'EUR', //REVIEW
                    impressions: impressions                  
                },
                ...commonParams
            })

            //GA4 Analytics
            const impressionsGA4 = articles.map((item, index) => {
                return {
                    item_name: item.displayName,
                    item_id: item.productId,
                    price: getFinalPrice(item.productId, priceList, userMaterialsPriceList, commonParams),
                    item_brand: item.brand,
                    item_category: item.type,
                    item_category2: item.subtype,
                    item_category3: item.thickness,
                    item_category4: item.format,
                    item_variant: item.finish,
                    item_list_id: list,
                    index: index,
                    cross_type_product: !!lines.fromRecommendation ? 'dinamico' : 'estatico',
                    cross_type_list: list,
                    product_HQ: isCosentinoGlobal(selectedCenter, item.centerId)
                }
            })

            const ecommerceObject = {
                currency: 'EUR',
                items: impressionsGA4
            }

            pushDataLayerGA4('ga4wwwProductImpressions', ecommerceObject, commonParams)
        }

        if (articlesLink) {
            global.dataLayer.push({
                event: 'cross_selling',
                eventCategory: 'modal',
                eventAction: 'gama_' + articlesLink.type,
                eventLabel: 'view'
            })
        }
    }
}

export const showCrossSellingSlider = (lines, priceList, userMaterialsPriceList, list, commonParams, sessionCrossType, selectedCenter) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {

        if (lines) {
            //Universal Analytics
            const impressions = lines.map((line, index) => {
                const item = line.product
                return {
                    name: item.displayName,
                    id: item.productId,
                    price: getFinalPrice(item.productId, priceList, userMaterialsPriceList, commonParams),
                    brand: item.brand,
                    category: item.type,
                    variant: item.finish,
                    dimension1: item.subtype,
                    dimension2: item.thickness,
                    dimension3: item.format,
                    dimension4: item.centerId,
                    dimension18: sessionCrossType,
                    dimension19: list,
                    list: list,
                    cross_type_product: !!line.fromRecommendation ? 'dinamico' : 'estatico',
                    position: index,
                    product_HQ: isCosentinoGlobal(selectedCenter, item.centerId)
                }
            })

            global.dataLayer.push({
                event: 'wwwProductImpressions',
                ecommerce: {
                    currencyCode: 'EUR', //REVIEW
                    impressions: impressions
                },
                ...commonParams
            })

            //GA4 Analytics
            const impressionsGA4 = lines.map((line, index) => {
                const item = line.product
                return {
                    item_name: item.displayName,
                    item_id: item.productId,
                    price: getFinalPrice(item.productId, priceList, userMaterialsPriceList, commonParams),
                    item_brand: item.brand,
                    item_category: item.type,
                    item_category2: item.subtype,
                    item_category3: item.thickness,
                    item_category4: item.format,
                    item_variant: item.finish,
                    item_list_id: list,
                    index: index,
                    cross_type_product: !!lines.fromRecommendation ? 'dinamico' : 'estatico',
                    cross_type_list: list,
                    product_HQ: isCosentinoGlobal(selectedCenter, item.centerId)
                }
            })

            const ecommerceObject = {
                currency: 'EUR',
                items: impressionsGA4
            }

            pushDataLayerGA4('ga4wwwProductImpressions', ecommerceObject, commonParams)
        }
    }
}

export const clickOnCrossSellingLink = (type) => {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
        global.dataLayer.push({
            event: 'cross_selling',
            eventCategory: 'modal',
            eventAction: 'gama_' + type,
            eventLabel: 'click'
        })
        global.dataLayer.push({
            event: 'ga4cross_selling',
            eventCategory: 'modal',
            eventAction: 'gama_' + type,
            eventLabel: 'click'
        })
    }
}

const pushDataLayerGA4 = (name, ecommerceObject, commonParams) => {
    global.dataLayer.push({ ecommerce: null });
    global.dataLayer.push({
        event: name,
        ecommerce: ecommerceObject,
        ...commonParams
    })
}


const analyticsEvents = ({ dispatch, getState }) => next => action => {
    const center = getSelectedCenter(getState())
    const userType = checkLoginType(getUserType(getState()), getImpersonatedBy(getState()))
    const shippingMethod = getShippingMethod(getState())
    const priceList = getPriceListAtSelectedCenter(getState())
    const userMaterialsPriceList = getUserPriceList(getState())
    const username = undefined
    const deliveryDate = undefined //REVIEW:
    const orderStatus = undefined //REVIEW:
    let commonParams = getCommonParams(getState())
    const lines = getLines(getState())
    const crossType = hasAiRecommendations(getState()) ? 'dinamico' : 'estatico'

    let hasSalesforceAccess = getDataFromSalesforce(getState())
    let hasWeAccess = 0
    if (hasSalesforceAccess && hasSalesforceAccess !== 'no_data_found_on_sf') {
        hasWeAccess = 1
    }

    /** @type {boolean} */
    const canViewHQ = getNaturalStoneViewingPermissions(getState()).canViewHQ
    const canViewOutletAndPnInHQ = canViewHQ
    
    switch (action.type) {
        case LOCATION_CHANGE:
            return next(action)
        // case 'SIMPLE_LOGIN_SUCCESS':
        // case 'SIMPLE_IMPERSONATE_SUCCESS':
        //     const decoded = jwtDecode(action.token)
        //     sendLoginType(decoded.type, getImpersonatedBy(getState()))
        //     return next(action)

        // case 'SUBMIT_CHECKOUT_REQUEST':
        //     sendCheckoutStep2Reservation(center, userType, shippingMethod, username)
        //     return next(action)

        // NEW EVENTS
        // case 'REJECT_ORDER_SUCCESS':
        //     cancelOrder(action.reservationId, deliveryDate, orderStatus, commonParams)
        //     return next(action)
        // case 'FETCH_ORDER_TO_EDIT_SUCCESS':
        //     editOrder(action.reservationId, action.order.preferredDeliveryDate, action.order.statusText, commonParams)
        //     return next(action)
        // case 'FETCH_ORDER_DETAILS_SUCCESS':
        //     viewOrderDetails(action.order.reservationId, action.order.preferredDeliveryDate, action.order.statusText, commonParams)
        //     return next(action)
        case 'ADD2CART_RECURRING_ORDERS_DETAILS_SUCCESS':
            createRecurringOrder(action.reservationId, deliveryDate, orderStatus, commonParams)
            return next(action)
        case 'CHANGE_CENTER_BY_USER':
            changeCenter(action.center, commonParams)
            return next(action)
        case 'PRODUCT_CLICK':
            productClick(action.product, action.index, action.list, priceList, userMaterialsPriceList, commonParams, crossType, center)
            return next(action)
        case 'CHECKOUT_VISIT':
            sendCheckoutStep1Review(center, userType, shippingMethod, username, priceList, userMaterialsPriceList, lines, commonParams, crossType)
            return next(action)
        case 'SUBMIT_CHECKOUT_SUCCESS':
            const reservationCode = action.code
            let linesWithPrice = []
            let total = totalSelector(getState())
            // console.log('Total selector: ' + total)
            if (isNaN(total)) {
                total = 0
            }
            // console.log('Total selector after check NaN: ' + total)
            let currencyCode = totalCoin(getState())
            linesWithPrice = lines.map(line => {
                let pricePhase2 = getLinePricePhase2ByLineId(getState(), line.lineId)
                line.pricePhase2 = pricePhase2 ? pricePhase2 : 0
                return line
            })

            sendCheckoutOk(center, userType, shippingMethod, username, reservationCode, linesWithPrice, priceList, userMaterialsPriceList, total, currencyCode, commonParams, crossType)
            return next(action)
        case 'ADD_CART_LINE_SUCCESS':

            let addedProduct;

            if (!!action.cutToSize) {
                addedProduct = {
                    isCutToSize: true,
                    displayName: action.cutToSize.colorName,
                    productId: CtsIdsByElaboration(action.cutToSize.elaboration, action.cutToSize.brand),
                    price: null,
                    brand: action.cutToSize.brand,
                    type: 'TABLA',
                    quantity: action.qty,
                    finish: action.cutToSize.finish,
                    subtype: action.cutToSize.subtype,
                    thickness: action.cutToSize.thickness,
                    format: `${action.cutToSize.width}x${action.cutToSize.height}`,
                    centerId: center,
                }
            } else {
                addedProduct = byProductId(getState(), action.productId)
            }

            const extra = {...action.extra, sessionCrossType: crossType}


            sendAddToCart(
                addedProduct,
                action.qty,
                shippingMethod,
                username,
                priceList,
                userMaterialsPriceList,
                action.segmentation.channelName,
                commonParams,
                extra,
                center
            )

            return next(action)

        case 'ADD_CART_BULK_LOTS_SUCCESS':
            addedProduct = byProductId(getState(), action.productId)
            const _extra = action.lines[0].extra
            const quantity = action.lines.reduce((acc, line) => acc + line.quantity, 0)


            sendAddToCart(
                addedProduct,
                quantity,
                shippingMethod,
                username,
                priceList,
                userMaterialsPriceList,
                action.segmentation.channelName,
                commonParams,
                _extra,
                center
            )
            return next(action)

        case 'CHANGE_CART_LINE_ADD_QTY':
            if (action.line.product !== null) {
                sendAddToCart(action.line.product, action.diffQty, shippingMethod, username, priceList, userMaterialsPriceList, action.line.segmentation.channelName, commonParams, {sessionCrossType: crossType})
            }
            return next(action)
        case 'CHANGE_CART_LINE_REMOVE_QTY':
            if (action.line.product !== null) {
                sendRemoveFromCart(action.line, action.diffQty, shippingMethod, username, commonParams, null, center)
            }
            return next(action)
        case 'REMOVE_CART_LINE':
            const line = byLineId(getState(), action.lineId)
            //const product = getProductLayer(line, null, center)
            if (line !== null) {
                sendRemoveFromCart(line, line.qty, shippingMethod, username, commonParams, null, center)
            }
            return next(action)
        case 'EMPTY_CART_BY_USER':
            //const products = lines.map(l => getProductLayer(l, null, center))
            sendRemoveFromCart(null, null, shippingMethod, username, commonParams, lines, center)
            return next(action)

        case 'PRODUCT_DETAIL_VISIT':
            sendProductDetail(action.product, center, userType, shippingMethod, priceList, userMaterialsPriceList, commonParams, crossType)
            return next(action)

        case 'SHOW_CROSS_SELLING_MODAL':
            showCrossSellingModal(action.lines, priceList, userMaterialsPriceList, 'crossselling-popupcarrito', commonParams, crossType, center)
            return next(action)

        case 'SHOW_CROSS_SELLING_SLIDER':
            showCrossSellingSlider(action.products, priceList, userMaterialsPriceList, action.tag, commonParams, crossType, center)
            return next(action)

        case 'CLICK_CROSS_SELLING_LINK':
            clickOnCrossSellingLink(action.articleType)
            return next(action)

        case 'LOGIN_PROCESS_FINISH':
        case 'SIMPLE_IMPERSONATE_RESUCCESS':
        case 'FETCH_CENTER_PREFERENCES_SUCCESS':
        case 'FETCH_SHIPPING_ADDRESSES_SUCCESS':
        case 'MOUNT_APP':
            try {
                if (process.env.REACT_APP_ANALYTICS === 'true') {
                    global.dataLayerCosentino.cross_type = crossType
                    global.dataLayerCosentino.has_we_access = hasWeAccess
                    global.dataLayerCosentino.user_order_HQ = canViewOutletAndPnInHQ
                }
            } catch (e) {
                console.log(e)
            }

            return next(action)

        case 'SEND_SEARCH_PRODUCT':
            sendSearchProduct(action.term, commonParams)
            return next(action)

        case 'CHANGE_ANALYTICS_URL':
            setLocation(dispatch, action.pageType, action.section, action.subsection, commonParams)
            return next(action)

        case 'SELECT_VIRTUAL_VISIT':
            if (process.env.REACT_APP_ANALYTICS === 'true') {
                pushDataLayerGA4('ga4wwwVirtualVisit', action.values, commonParams)
            }

        default:
            return next(action)
    }
}
export default analyticsEvents
