import { push } from 'react-router-redux'
import { logout } from './login/actions'
import axios from 'axios'
import { setError } from './error/actions'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import * as hardcode from './hardCode'
import { dispatchPushURL } from './ui/actions'
import FileDownload from "js-file-download";

dayjs.extend(timezone)
const ApiLocation = process.env.REACT_APP_API_HOST
axios.defaults.headers.common['Content-Type'] = 'application/json'

export const login = (username, password, locale) => {
    let loginData = {
        login: username,
        password: password,
        lang: locale
    }

    return axios.post(ApiLocation + '/login', loginData)
}

export const mallCheckLogin = (username, token) => {
    return axios.get(ApiLocation + `/api/mall/user/check/${username}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const loginSSO = (username, email, locale) => {
    let loginData = {
        login: username,
        lang: locale,
        email
    }

    return axios.post(ApiLocation + '/loginSSO', loginData)
}

export const loginJT = (username, email, locale) => {
    let loginData = {
        login: username,
        lang: locale,
        email
    }

    return axios.post(ApiLocation + '/loginJT', loginData)
}

export const loginAD = (email, locale, azureToken) => {
    let loginData = {
        lang: locale,
        email,
        azureToken
    }

    return axios.post(ApiLocation + '/loginAD', loginData)
}

export const loginShops = (username, password, locale) => {
    let loginData = {
        login: username,
        password: password,
        lang: locale
    }

    return axios.post(ApiLocation + '/loginShops', loginData)
}

export const fetchInfoUserLowes = token => {
    return axios.get(ApiLocation + '/users/lowes-store-data', {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const recoverPassword = (username, email) => {
    let loginData = {
        login: username,
        email: email
    }

    return axios.post(ApiLocation + '/users/remember-password', loginData)
}
export const createAccount = (username, email) => {
    let loginData = {
        login: username,
        email: email
    }

    return axios.post(ApiLocation + '/users', loginData)
}
export const addEmailCenter = (user, email, token) => {
    let data = {
        userAdmin: user,
        email: email
    }

    return axios.post(ApiLocation + '/users-center', data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const sendFeedback = (score, comments, banner, lang, token) => {
    let data = {
        score: score,
        comments: comments,
        banner: banner,
        lang: lang
    }

    return axios.post(ApiLocation + '/user-feedback', data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const hasFeedback = (banner, token) => {
    let data = {
        banner: banner,
    }

    return axios.post(ApiLocation + '/user-has-feedback', data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const impersonate = (login, token, locale) => {
    let impersonateData = { login: login, lang: locale }
    return axios.post(ApiLocation + `/impersonate`, impersonateData, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const getCart = (centerId, storeId = null, employeeId = null, locale, token) => {
    return axios.get(ApiLocation + `/cart/${centerId}?lang=${locale}&${cartKey(storeId, employeeId)}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const genericAddCartLine = (
    centerId,
    productId,
    qty = null,
    lotId = null,
    bundleId = null,
    token,
    segmentation,
    locale,
    extra,
    cutToSize,
    storeId = null,
    employeeId = null,
    supplierCenterId,
    shippingMethod,
    position
) => {
    const formData = new FormData()

    if (segmentation.segmentationEvidences) {
        segmentation.segmentationEvidences.uploadedFiles.forEach(file => {
            formData.append('segmentationEvidenceFiles[]', file)
        })
        formData.append('segmentationEvidencesPos', JSON.stringify(segmentation.segmentationEvidences.purchaseOrders))
    }

    delete segmentation.segmentationEvidences

    formData.append('segmentation', JSON.stringify(segmentation))
    formData.append('productId',productId)
    formData.append('qty',qty)
    formData.append('bundleId',bundleId)
    formData.append('lotId',lotId)
    formData.append('extra', JSON.stringify(extra))
    formData.append('supplierCenterId',supplierCenterId)
    formData.append('shippingMethod',shippingMethod)
    formData.append('position',position)
    if (cutToSize && cutToSize.files) {
        const fileValues = Object.entries(cutToSize.files)
        cutToSize.files = []
        fileValues.forEach(([type,file]) => {
            const key = 'cutToSizefile_' + type
            formData.append(key, file)
            cutToSize.files.push(key)
        })
    }
    formData.append('cutToSize', JSON.stringify(cutToSize))

    // let lineData = {
    //     segmentation,
    //     productId,
    //     qty,
    //     bundleId,
    //     lotId,
    //     cutToSize,
    //     extra: extra,
    //     supplierCenterId,
    //     shippingMethod
    // }
    return axios.post(ApiLocation + `/cart/${centerId}/lines?lang=${locale}&${cartKey(storeId, employeeId)}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const addCartBulkLots = (
    centerId,
    selectedLots,
    token,
    segmentation,
    locale,
    employeeId,
    shippingMethod
) => {
    const formData = new FormData()
    formData.append('segmentation', JSON.stringify(segmentation))
    formData.append('shippingMethod',shippingMethod)

    selectedLots.forEach(l => {
        formData.append('extra[]', JSON.stringify(l.extra))
        formData.append('productId[]', l.productId)
        formData.append('qty[]', l.quantity)
        formData.append('lotId[]', !l.bundleId ? l.lotId : null)
        formData.append('bundleId[]', l.bundleId)
        formData.append('supplierCenterId[]', l.supplierCenterId)
    })

    return axios.post(ApiLocation + `/cart/${centerId}/bulk-lots-lines?lang=${locale}&${cartKey(null, employeeId)}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
    })
}


export const changeCartLineSegmentation = (lineId, newSegmentation, centerId, token, storeId, employeeId) => {
    let segmentation = { segmentation: newSegmentation }
    return axios.patch(ApiLocation + `/cart/${centerId}/lines/${lineId}/segmentation?${cartKey(storeId, employeeId)}`, segmentation, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const changeCartLineQty = (lineId, newQty, centerId, token, storeId = null, employeeId = null) => {
    let body = { qty: newQty }

    return axios.patch(ApiLocation + `/cart/${centerId}/lines/${lineId}?${cartKey(storeId, employeeId)}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const checkLinesPrice = (centerId, token, storeId = null, employeeId = null, shippingMethod, voucher = undefined) => {
    let body = { shippingType: shippingMethod, voucher }
    return axios.post(ApiLocation + `/cart/${centerId}/lines/prices?${cartKey(storeId, employeeId)}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const checkPricePhase2ByProduct = (productId, centerId, token, outletType) => {
    let body = { outletType }
    return axios.post(ApiLocation + `/catalog/${centerId}/product-price/${productId}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const changeCartLineComment = (lineId, comment, centerId, token, storeId = null, employeeId = null) => {
    let body = { comment: comment }

    return axios.patch(ApiLocation + `/cart/${centerId}/lines/${lineId}/comment?${cartKey(storeId, employeeId)}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const removeCartLine = (lineId, centerId, token, storeId = null, employeeId = null) => {
    return axios.delete(ApiLocation + `/cart/${centerId}/lines/${lineId}?${cartKey(storeId, employeeId)}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const emptyCart = (centerId, token, storeId = null, employeeId = null) => {
    return axios.delete(ApiLocation + `/cart/${centerId}?${cartKey(storeId, employeeId)}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const submitCheckout = (centerId, token, checkoutData, storeId = null, employeeId = null) => {
    return axios.post(ApiLocation + `/cart/${centerId}/checkout?${cartKey(storeId, employeeId)}`, checkoutData, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
}
export const fetchSegmentation = (centerId, token, locale) => {
    return axios.get(ApiLocation + `/cart/${centerId}/segmentation?lang=${locale}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductById = (centerId, productId, locale) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products/${productId}?lang=${locale}`)
}
export const fetchSimilarProduct = (centerId, colorId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products/${colorId}/similar`)
}
export const fetchCrossSelling = (centerId, productId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products/${productId}/cross-selling`)
}
export const fetchCrossSellingCheckOut = (token, centerId, productIds) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/discover-products?products=${productIds}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchCartSimilarProducts = (token, centerId, productIds, quantities) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/cart-similar-products?products=${productIds}&quantities=${quantities}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchCartComplementaryProducts = (token, centerId, productIds, quantities) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/cart-complementary-products?products=${productIds}&quantities=${quantities}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchCartCrossSelling = (token, centerId, productId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products/${productId}/cart-cross-selling`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchAlternatives = (centerId, productId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products/${productId}/alternatives`)
}
export const fetchProducts = (centerId, productIds = []) => {
    // console.log('FETCHING PRODUCTS')
    return axios.get(ApiLocation + `/catalog/${centerId}/products`, {
        params: {
            productIds
        }
    })
}
export const fetchMallProducts = (landingId, centerIds = [], productIds = [], portfolioMode = 0) => {
    return axios.get(ApiLocation + `/catalog/mall-products`, {
        params: {
            landingId,
            centerIds,
            productIds,
            portfolioMode
        }
    })
}
export const fetchLastProductsBought = (token, centerId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/last-products-bought`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductsForYou = (token, centerId) => {
    try {
        return axios.get(ApiLocation + `/catalog/${centerId}/products-for-you`, {
            headers: { Authorization: `Bearer ${token}` }
        })
    } catch (error) {
        console.log(error)
    }
   
}
export const fetchProductsSimilarColors = (token, centerId, productId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products-similar-colors/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const possibleScaleaToSensa = (productId, centerId, token) => {
    return axios.get(ApiLocation + `/cart/check-convert-scalea-to-sensa/${productId}/${centerId}`, { headers: { Authorization: `Bearer ${token}` } })
}
export const transformScaleaToSensa = (centerId, lineIds, token) => {
    return axios.patch(ApiLocation + `/cart/${centerId}/lines/convert-scalea-to-sensa`, { lineIds }, { headers: { Authorization: `Bearer ${token}` } })
}
export const transformSkuToLots = (centerId, token) => {
    return axios.patch(ApiLocation + `/cart/${centerId}/lines/convert-sku-to-lots`, {}, { headers: { Authorization: `Bearer ${token}` } })
}
export const fetchLot = (centerId, lotId, productId, token) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/lot/${lotId}/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchLots = (productId, centerId, quarryBlock, shade, quality, location, outlet, token) => {
    let filters = []
    if (quarryBlock) filters.push(`quarryBlock=${quarryBlock}`)
    if (shade) filters.push(`shade=${shade}`)
    if (quality) filters.push(`quality=${quality}`)
    if (location) filters.push(`location=${location}`)
    if (outlet) filters.push(`outlet=true`)

    const filtersQuery = filters.join('&')
    return axios.get(ApiLocation + `/catalog/${centerId}/lots/${productId}?${filtersQuery}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchLotsImages = lotIds => {
    return axios.post(ApiLocation + `/catalog/lots-images`, { lots: lotIds })
}

export const fetchUsersToImpersonate = token => {
    return axios.get(ApiLocation + '/customers', {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchUsersToImpersonateByCenter = (centerId, token) => {
    return axios.get(ApiLocation + '/customersByCenter/' + centerId, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchKpis = (token, filters) => {
    return axios.post(ApiLocation + '/kpis', filters, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchKpisOpenOrders = (token, page, filters) => {
    return axios.post(ApiLocation + `/kpis/open-orders?page=${page}`, filters, { headers: { Authorization: `Bearer ${token}` } })
}
export const fetchKpisBackOrders = (token, page, filters) => {
    return axios.post(ApiLocation + `/kpis/back-orders?page=${page}`, filters, { headers: { Authorization: `Bearer ${token}` } })
}

export const fetchShippingAddresses = (token, centerId, locale) => {
    return axios.get(ApiLocation + `/cart/${centerId}/shipping-addresses?locale=${locale}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchDeliveryDetails = (deliveryId, token) => {
    return axios.get(ApiLocation + `/kpis/deliveries/${deliveryId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchReservations = (token, unassigned) => {
    const location = unassigned ? `/kitchen/reservations/unassigned` : `/kitchen/reservations`

    return axios.get(ApiLocation + location, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchCustomers = token => {
    return axios.get(ApiLocation + `/kitchen/customers`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const rejectReservation = (token, reservationId) => {
    return axios.post(ApiLocation + `/kitchen/reservations/${reservationId}/reject`, null, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const assignCustomer = (token, shippingAddressId, reservationId, preferredDeliveryDate, po, comments) => {
    let shippingAddressData = {
        shippingAddressId,
        preferredDeliveryDate,
        po,
        comments
    }

    return axios.post(ApiLocation + `/kitchen/reservations/${reservationId}/assign`, shippingAddressData, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchSpecialNames = (token, center) => {
    return axios.get(ApiLocation + `/catalog/${center}/custom-product-descriptions`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchUserPriceList = token => {
    return axios.get(ApiLocation + `/catalog/user-price-list`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchRefreshUserPriceList = (token, centerId, productIds) => {
    const body = { productIds }
    return axios.post(ApiLocation + `/catalog/${centerId}/refresh-products-prices`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchUserPriceListByUser = (token, userId) => {
    return axios.post(
        ApiLocation + `/catalog/user-price-list-by-user`,
        { userId },
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const fetchTransferOrders = (token, center) => {
    const location = `/users/transfer-orders`
    const body = { centerId: center }
    return axios.post(ApiLocation + location, body, { headers: { Authorization: `Bearer ${token}` } })
}
export const fetchOrders = (token, open, locale, centerId, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search) => {
    const location = open ? `/users/orders/open` : `/users/orders`
    const body = { centerId, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search }
    return axios.post(ApiLocation + location + `?lang=${locale}`, body, { headers: { Authorization: `Bearer ${token}` } })
}
export const fetchOrdersPaginate = (token, open, locale, centerId, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search, page) => {
    const location = open ? `/users/orders/open` : `/users/orders`
    const body = { centerId, filterTypeDate, startDate, endDate, status, shippingType, supplierCenter, search, page }
    return axios.post(ApiLocation + location + `?lang=${locale}`, body, { headers: { Authorization: `Bearer ${token}` } })
}

export const fetchOrderDeliveriesPaginate = (token, status, startDate, endDate, search, locale, type, page) => {
    const location = `/users/deliveries`
    const body = { status, startDate, endDate, search, locale, type, page }
    return axios.post(ApiLocation + location + `?page=${page}`, body, { headers: { Authorization: `Bearer ${token}` } })
}

export const fetchOrderDeliveriesById = (token, deliveryId) => {
    const location = `/users/delivery/${deliveryId}`
    return axios.get(ApiLocation + location, { headers: { Authorization: `Bearer ${token}` } })
}

export const fetchOffers = (token, open, locale, centerId, startDate, endDate) => {
    const location = open ? `/users/offers/open` : `/users/offers`
    const body = { centerId, startDate, endDate }
    return axios.post(ApiLocation + location + `?lang=${locale}`, body, { headers: { Authorization: `Bearer ${token}` } })
}
export const fetchOrderToEdit = (token, reservationId, locale) => {
    let body = { lang: locale }
    return axios.post(ApiLocation + `/users/order/${reservationId}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const saveOrderToEdit = (token, order, orderId, reservationId, locale) => {
    let body = { modifications: order, orderId, reservationId }
    return axios.put(ApiLocation + `/users/order/${orderId}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchOrderDetails = (token, reservationId, locale) => {
    return axios.get(ApiLocation + `/users/order/${reservationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchPackingList = (token, reservationId, locale) => {
    return axios.get(ApiLocation + `/users/order/${reservationId}/packing-list`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchOfferOverview = (token, reservationId, locale) => {
    return axios.get(ApiLocation + `/users/offer/${reservationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const rejectOrder = (token, reservationId, orderId, locale) => {
    let body = {
        lang: locale,
        reservationId
    }
    return axios.delete(ApiLocation + `/users/order/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: body
    })
}
export const getFileByUrl = (url, token) => {
    return axios.get(url,
        {
        headers: {Authorization: `Bearer ${token}`},
        responseType: 'blob'
    })
}
export const fetchRecurringOrders = (token, centerId, dateFrom = null, dateTo = null, search = null, page = null) => {
    const location = `/users/previous-carts/${centerId}`
    const params = new URLSearchParams([['dateFrom', dateFrom],['dateTo', dateTo],['search', search],['page', page]]);
    return axios.get(ApiLocation + location, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params
    })
}
export const fetchRecurringOrdersDetails = (token, centerId, previousCartId) => {
    return axios.get(ApiLocation + `/users/previous-carts/${centerId}/detail/${previousCartId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const add2cartRecurringOrders = (token, centerId, id, storeId, employeeId, locale) => {
    const location = `/users/previous-carts/${centerId}/add-2-cart/${id}?lang=${locale}&${cartKey(storeId, employeeId)}`
    return axios.get(ApiLocation + location, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}
export const add2cartRecurringOrdersQty = (token, centerId, values, storeId, employeeId, locale) => {
    let data = []
    for(let value of values.lines){
        data.push({
            id: value.productId,
            qty: value.qty
        })
    }
    const params = new URLSearchParams({data: JSON.stringify(data)});
    const location = `/users/previous-carts/${centerId}/add-2-cart/${values.id}?lang=${locale}&${cartKey(storeId, employeeId)}`
    return axios.get(ApiLocation + location, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params
    })
}
export const fetchDirectSlabAccess = (reservationId = null, invoiceId = null, token, locale) => {
    let body = {
        reservationId,
        invoiceId
    }
    return axios.post(ApiLocation + `/users/direct-slab-access?lang=${locale}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const saveProfileConfiguration = (token, profileConfigData, selectedCenterId) => {
    const body = Object.assign({}, profileConfigData, { selectedCenterId })
    return axios.put(ApiLocation + `/users/preferences`, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
}
export const setUserEmailAAD = (token, email) => {
    const body = { email }
    return axios.put(ApiLocation + `/users/email-active-directory`, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
}
export const changePassword = (token, password) => {
    let body = {
        password
    }
    return axios.post(ApiLocation + `/users/change-password`, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
}
export const savePromotionConfiguration = (token, profileConfigData) => {
    const body = Object.assign({}, profileConfigData)
    return axios.put(ApiLocation + `/users/preferences/promotion`, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
}
export const submitWhatsappOptin = (token, profileConfigData) => {
    const body = Object.assign({}, profileConfigData)
    return axios.put(ApiLocation + `/users/preferences/whatsapp`, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
}
export const unsubscribeNewsletter = tokenOnlyLogin => {
    const body = Object.assign({})
    return axios.put(ApiLocation + `/users/preferences/unsubscribe-newsletter/${tokenOnlyLogin}`, body, {
        headers: { 'Content-Type': 'application/json' }
    })
}
export const resubscribeNewsletter = tokenOnlyLogin => {
    const body = Object.assign({})
    return axios.put(ApiLocation + `/users/preferences/resubscribe-newsletter/${tokenOnlyLogin}`, body, {
        headers: { 'Content-Type': 'application/json' }
    })
}
export const fetchPromotionConfiguration = token => {
    return axios.get(ApiLocation + `/users/preferences/promotion`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchWhatsappConfiguration = token => {
    return axios.get(ApiLocation + `/users/preferences/whatsapp`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const mandatoryChangePassword = (login, currentPassword, newPassword) => {
    let body = {
        login,
        currentPassword,
        newPassword
    }
    return axios.post(ApiLocation + `/users/mandatory-change-password`, body, {
        headers: { 'Content-Type': 'application/json' }
    })
}
export const fetchProfileConfiguration = (token, selectedCenterId) => {
    let body = {
        selectedCenterId
    }
    return axios.post(ApiLocation + `/users/preferences`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchCenterPreferences = (token, centerId) => {
    return axios.get(ApiLocation + `/center/${centerId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const deleteSegmentationPreferred = (currentFavourite, token, center) => {
    let segmentationData = {
        segmentation: {
            name: currentFavourite
        }
    }
    return axios.delete(ApiLocation + `/cart/${center}/segmentation`, {
        headers: { Authorization: `Bearer ${token}` },
        data: segmentationData
    })
}
export const saveSegmentationPreferred = (token, centerId, segmentationData) => axios.post(ApiLocation + `/cart/${centerId}/segmentation`, segmentationData, { headers: { Authorization: `Bearer ${token}` } })

export const fetchColorsCutToSize = (brand, lang, token) => {
    return axios.post(ApiLocation + `/cut-to-size/search`, {
        brand: brand,
        lang: lang
    }, {headers: { Authorization: `Bearer ${token}` }})
}
export const fetchSamplesCutToSize = (brand, lang, token) => {
    //HARDCODE: [api] cuttosize samples
    return Promise.resolve({ data: hardcode.samplesCutToSize })
}
export const fetchFurnituresCutToSize = (brand, lang, token) => {
    //HARDCODE: [api] cuttosizse furnitures
    return Promise.resolve({ data: hardcode.furnituresCutToSize })
}
export const fetchColorsMktoolsAttr = token => {
    return axios.get(ApiLocation + `/catalog/fetch-mktools-colors`)
}
export const saveColorsMktoolsAttr = (token, colors) => {
    let data = { colors: colors }
    return axios.post(ApiLocation + `/catalog/save-mktools-colors`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductsFavourite = (token, centerId) => {
    return axios.get(ApiLocation + `/users/favourite-products/${centerId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductsDiscounts = (token, centerId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products/discounts`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const addProductsFavourite = (token, centerId, productId) => {
    let data = { products: [productId] }
    return axios.post(ApiLocation + `/users/favourite-products/${centerId}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const removeProductsFavourite = (token, centerId, productId) => {
    let data = { products: [productId] }
    return axios.delete(ApiLocation + `/users/favourite-products/${centerId}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: data
    })
}
export const sendSuggestion = (token, data) => {
    return axios.post(ApiLocation + `/users/suggestions-mailbox`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchBrochuresLowes = () => {
    //HARDCODE: [api] Lowes brochures
    return Promise.resolve({ data: { brochures: hardcode.brochuresLowes } })
}

export const fetchMarkets = token => {
    return axios.get(ApiLocation + '/api/mall/sales-organization/markets', {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const fetchCountries = (token, salesOrg) => {
    return axios.post(
        ApiLocation + '/api/mall/sales-organization/countries',
        {
            salesOrg: salesOrg
        },
        {
            headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
        }
    )
}

export const fetchCenters = (token, salesOrg) => {
    return axios.post(
        ApiLocation + '/api/mall/sales-organization/centers',
        {
            salesOrg: salesOrg
        },
        {
            headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
        }
    )
}

export const fetchLangs = token => {
    return axios.get(ApiLocation + '/api/mall/langs', {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const fetchTemplates = token => {
    return axios.get(ApiLocation + '/api/mall/templates', {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const fetchLandings = token => {
    return axios.get(ApiLocation + '/api/mall/landings', {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const fetchUserLandings = (token, language, selectedCenter) => {
    return axios.get(ApiLocation + '/api/mall/landings/user/' + selectedCenter + '/' + language, {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}
export const fetchLandingsQuery = (token, query) => {
    return axios.post(
        ApiLocation + '/api/mall/landings',
        null,
        {
            params: {
                search_string: query,
                skip: 0,
                limit: 99
            }
        },
        { headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() } }
    )
}

export const fetchLanding = token => {
    return axios.get(ApiLocation + '/api/mall/landing', {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}
export const createLanding = (token, landing) => {
    // for (var pair of landing.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]);
    // }
    return axios({
        method: 'post',
        url: ApiLocation + '/api/mall/landing',
        data: landing,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'X-Timezone': dayjs.tz.guess() }
    })
}
export const createLandingTranslated = (token, landing) => {
    // for (var pair of landing.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]);
    // }
    return axios({
        method: 'post',
        url: ApiLocation + '/api/mall/landing/translated',
        data: landing,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'X-Timezone': dayjs.tz.guess() }
    })
}

export const updateLanding = (token, landing) => {
    return axios({
        method: 'post',
        url: ApiLocation + '/api/mall/landing/update',
        data: landing,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'X-Timezone': dayjs.tz.guess() }
    })
}

export const updateLandingTranslated = (token, landing) => {
    return axios({
        method: 'post',
        url: ApiLocation + '/api/mall/landing/update/translated',
        data: landing,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', 'X-Timezone': dayjs.tz.guess() }
    })
}

export const fetchLandingbyId = (token, id, iso) => {
    return axios.get(ApiLocation + `/api/mall/landing/${iso}/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-Timezone': dayjs.tz.guess()
        }
    })
}

export const getLandingSlug = (token, data) => {
    return axios.post(ApiLocation + `/api/mall/landing/slug`, data, {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const getLandingBySlug = (token, slug, language, selectedCenter) => {
    return axios.get(ApiLocation + `/api/mall/landing/user/${selectedCenter}/${language}/${slug}`, {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const getPreviewLandingBySlug = (token, slug, language) => {
    return axios.get(ApiLocation + `/api/mall/landing/preview/${language}/${slug}`, {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const    getMallLots = (token, centers, productId, landingCommonId, landingProductId, lang) => {
    return axios.post(
        ApiLocation + '/api/mall/mall-lots',
        {centers, productId, landingCommonId, landingProductId, lang},
        {
            headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
        }
    )
}

export const deleteLanding = (token, id, iso) => {
    return axios.delete(ApiLocation + `/api/mall/landing/${iso}/${id}`, {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const deleteLandingImage = (token, id, type) => {
    return axios.delete(ApiLocation + `/api/mall/landing/image/${type}/${id}`, {
        headers: { Authorization: `Bearer ${token}`, 'X-Timezone': dayjs.tz.guess() }
    })
}

export const fetchProductsLowes = token => {
    return axios.get(ApiLocation + '/lowes/colors', {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductsLowes2 = () => {
    //HARDCODE: productLowes
    return Promise.resolve({ data: { products: hardcode.productsLowes2 } })
}
export const sendCheckoutLowes = cart => {
    //MOCK: Lowes checkout
    return Promise.resolve({ data: { idConfirmation: 'A confirmation email will be sent' } })
}
export const saveTemplateLowes = (token, template) => {
    const body = {
        name: template.name,
        plan: JSON.stringify(template.samples),
        stores: template.stores,
        screenshot: template.screenshot
    }
    return axios.post(ApiLocation + `/lowes/plans`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const updateTemplateLowes = (token, template) => {
    const body = {
        name: template.name,
        screenshot: template.screenshot,
        plan: JSON.stringify(template.samples),
        stores: template.stores
    }

    return axios.put(ApiLocation + `/lowes/plans/${template.id}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const deleteTemplateLowes = (token, templateId) => {
    return axios.delete(ApiLocation + `/lowes/plans/${templateId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const enableTemplateLowes = (token, templateId) => {
    return axios.get(ApiLocation + `/lowes/plans/${templateId}/enable`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const disableTemplateLowes = (token, templateId) => {
    return axios.get(ApiLocation + `/lowes/plans/${templateId}/disable`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const editTemplateName = (token, templateId, name) => {
    return axios.post(
        ApiLocation + `/lowes/plans/${templateId}/rename`,
        { name: name },
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const updateStore = (token, storeId, templatesId) => {
    let body = { plans: templatesId }
    return axios.put(ApiLocation + `/lowes/stores/${storeId}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchUsersShopperLowes = token => {
    return axios.get(ApiLocation + `/lowes/stores`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchTemplatesLowes = token => {
    return axios.get(ApiLocation + `/lowes/plans`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const askLocation = token => {
    return axios.get(ApiLocation + `/helper/visitor-land`)
}
export const fetchMaintenance = token => {
    return axios.get(ApiLocation + `/helper/maintenance`)
}
//MOCK: sendFormRegister
export const sendFormRegister = (email, user, pass) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (user !== 'error') {
                resolve({ data: { msg: 'your account has been created', status: 200 } })
            } else {
                reject({ data: { msg: 'error', status: 500 } })
            }
        }, 2000)
    })
}
//MOCK: sendFormNewRegister
export const sendFormNewRegister = (email, name, lastName, storeNumber, pass) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (name !== 'error') {
                resolve({ data: { msg: 'your account has been created', status: 200 } })
            } else {
                reject({ data: { msg: 'error', status: 500 } })
            }
        }, 2000)
    })
}
export const saveProductsMktToMax = (token, products) => {
    let data = { products: products }
    return axios.post(ApiLocation + `/catalog/mktools-edit`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductsMktToMax = token => {
    return axios.get(ApiLocation + `/catalog/mktools-edit`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchProductsDeviatedAdmin = token => {
    return axios.get(ApiLocation + `/catalog/products-deviated-admin`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const saveProductsDeviatedAdmin = (token, descriptions) => {
    let data = { descriptions: descriptions }
    return axios.post(ApiLocation + `/catalog/save-products-deviated-admin`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchDeviatedDescriptions = (token, centerId) => {
    return axios.get(ApiLocation + `/catalog/products-deviated-descriptions?centerId=${centerId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchAllLowesOrders = token => {
    return axios.get(ApiLocation + `/lowes/orders`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchAllLowesLinesOrders = (token, page, pageSize, query, startDate, endDate) => {
    const body = { startDate, endDate }
    return axios.post(ApiLocation + `/lowes/lines-orders?page=${page}&pageSize=${pageSize}&query=${query}&startDate=${startDate}&endDate=${endDate}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchLowesOrderDetails = (reservationId, token) => {
    return axios.get(ApiLocation + `/lowes/orders/${reservationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const sendTranslation = (termId, translation, language) => {
    let transJson = [{ term: termId, translation: { content: translation } }]
    transJson = encodeURIComponent(JSON.stringify(transJson))
    const body = 'api_token=' + process.env.REACT_APP_POEDITOR_API_KEY + '&id=' + process.env.REACT_APP_PROJECT_ID + '&data=' + transJson + '&language=' + language
    return axios.post(process.env.REACT_APP_POEDITOR_API + '/languages/update', body)
}
export const getTranslation = language => {
    const data2 = 'api_token=' + process.env.REACT_APP_POEDITOR_API_KEY + '&id=' + process.env.REACT_APP_PROJECT_ID + '&language=' + language
    return axios.post(process.env.REACT_APP_POEDITOR_API + '/terms/list', data2)
}
export const fetchInfoEnv = () => {
    return axios.get(ApiLocation + `/infoenv`)
}
export const fetchChangelogByProject = (token, onlyNews) => {
    return axios.get(ApiLocation + `/changelog-items-by-project-and-functionality${onlyNews ? '?news=WHATS_NEW' : ''}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const toJson = response => {
    return response.json()
}

export const checkVoucher = (token, centerId, voucher, lang) => {
    return axios.post(
        ApiLocation + `/cart/${centerId}/validate-voucher`,
        {
            voucher,
            lang
        },
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const removeVoucher = (token, centerId, voucher, lang) => {
    return axios.post(
        ApiLocation + `/cart/${centerId}/remove-voucher`,
        {
            voucher,
            lang
        },
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const noShowMoreSubscribeModal = token => {
    return axios.post(
        ApiLocation + `/promotions/subscribe/no-show-more`,
        {},
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const noShowMoreWhatsappModal = token => {
    return axios.post(
        ApiLocation + `/users/preferences/whatsapp/no-show-more`,
        {},
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const getPromotionByCenterId = (token, centerId) => {
    return axios.get(ApiLocation + `/promotions/${centerId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendTemplatesSelected = (token, data) => {
    return axios.post(ApiLocation + `/promotions/templates`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const getPromotionsList = (token, lang, page = null, perPage = null, filters = null) => {
    const data = { lang, page, perPage, filters }
    return axios.post(ApiLocation + `/promotions/promotions-list`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const getPromotionToEdit = (token, promotionId) => {
    return axios.get(ApiLocation + `/promotions/to-edit/${promotionId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchCustomersOfArea = (token, market, countries, centers) => {
    const data = { market, countries, centers }
    return axios.post(ApiLocation + `/promotions/customers-of-area`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const sendCustomersSelected = (token, promotionId, customers) => {
    const data = { promotionId, customers }
    return axios.post(ApiLocation + `/promotions/set-promotion-customers`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const sendMaterialsSelected = (token, promotionId, materials, materialsWithOutletSelected) => {
    const data = { promotionId, materials, materialsWithOutletSelected }
    return axios.post(ApiLocation + `/promotions/set-materials`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const sendMaterialsToRequestDiscount = (token, centerId, promotionId, materials, lang) => {
    const data = { centerId, promotionId, materials, lang }
    return axios.post(ApiLocation + `/promotions/set-discount-materials`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const getBanners = (token, pageSelected, search, type) => {
    const data = { page: pageSelected, search, type}
    return axios.post(ApiLocation + `/promotions/banners`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const getPromotionData = (token, promotionId, centerId, lang) => {
    return axios.get(ApiLocation + `/promotions/data/${promotionId}?centerId=${centerId}&lang=${lang}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendPreviewPromotionEmail = (token, promotionId, email, userSelected, centerId, lang) => {
    const data = { promotionId, email, userSelected, centerId, lang }
    return axios.post(ApiLocation + `/promotions/send-email-promotion-preview`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const enablePromotion = (token, promotionId) => {
    return axios.post(
        ApiLocation + `/promotions/enable-promotion`,
        { promotionId },
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}
export const disablePromotion = (token, promotionId) => {
    return axios.post(
        ApiLocation + `/promotions/disable-promotion`,
        { promotionId },
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}

export const getAllProductOfTheLastQueryMultiCenterByUser = token => {
    return axios.post(
        ApiLocation + `/promotions/get-products-last-query`,
        {},
        {
            headers: { Authorization: `Bearer ${token}` }
        }
    )
}

export const getIsCampaignAdmin = token => {
    return axios.get(ApiLocation + `/users/is-campaign-admin`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const errorHandler = (dispatch, error) => {
    if (!error) {
        dispatch(setError('NETWORK_ERROR'))
        dispatch(dispatchPushURL('/error'))
    } else {
        if (error.status === 500) {
            dispatch(dispatchPushURL('/error'))
            localStorage.clear()
            dispatch(logout(true))
        } else if (error.status === 400) {
        } else if (error.status === 401) {
            dispatch(logout(true))
            localStorage.clear()
            dispatch({ type: 'LOGIN_REQUIRED', payload: { statusText: error.data.err || error.response } })
        } else if (error.status === 404) {
        } else if (error.status === 503 && error.data.err === 'MAINTENANCE_MODE_ON') {
            dispatch({ type: 'RESET_UI' })
            dispatch({ type: 'MAINTENANCE_MODE_ON' })
        } else {
            dispatch(push('/error'))
        }
        //400 Gentile
        //404 url not found
    }
}

export const cartKey = (storeId, employeeId) => {
    let cartKey = []
    if (storeId) cartKey.push(`storeId=${storeId}`)
    if (employeeId) cartKey.push(`employeeId=${employeeId}`)

    const cartKeyQuery = cartKey.join('&')
    return cartKeyQuery
}
export const checkStatus = response => {
    if (!response.ok) {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }

    return response.json()
}

export const sendPromotionHeader = (token, data) => {
    return axios.post(ApiLocation + `/promotions/header`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const uploadPromotionFile = (token, formData) => {
    return axios.post(ApiLocation + `/promotions/upload-banner`, formData, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const sendBanners = (token, data) => {
    return axios.post(ApiLocation + `/promotions/set-banners`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const promotionFetchMarkets = token => {
    return axios.get(ApiLocation + `/promotions/markets`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const promotionFetchCountries = (token, data) => {
    return axios.post(ApiLocation + '/promotions/countries', data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const promotionFetchCenters = (token, data) => {
    return axios.post(ApiLocation + '/promotions/centers', data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendPromotionArea = (token, data) => {
    return axios.post(ApiLocation + `/promotions/area`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const rejectQuote = (token, reservationId) => {
    // const response = {response : {}}
    // response.response.data = {'err' : 'ddddd'}
    // return Promise.reject(response)

    return axios.post(ApiLocation + `/users/reject-quote/${reservationId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const acceptQuote = (token, reservationId) => {
    // const response = {response : {}}
    // response.response.data = {'err' : 'ddddd'}
    // return Promise.reject(response)

    return axios.post(ApiLocation + `/users/accept-quote/${reservationId}`, {},{
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchAdvertisements = (selectedCenter) => {
    return axios.get(ApiLocation + `/advertisements/get/${selectedCenter}`, {})
}

export const getDataToResolveDiscount = (token, promotionId, centers) => {
    return axios.post(ApiLocation + `/promotions/to-resolve-discount/${promotionId}`, {centers}, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchDiscountMaterialsData = (token, promotionId) => {
    return axios.get(ApiLocation + `/promotions/get-discount-materials-data/${promotionId}/csv`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export const fetchDiscountCustomersData = (token, promotionId) => {
    return axios.get(ApiLocation + `/promotions/get-discount-customers-data/${promotionId}/csv`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const checkPromotionDiscount = (token, promotionId, vistexId) => {
    return axios.get(ApiLocation + `/promotions/check-discount/${promotionId}/${vistexId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendApproveCampaignDiscount = (token, promotionId, vistex_ids, comments, lang) => {
    const data = {vistex_ids, comments, lang}
    return axios.post(ApiLocation + `/promotions/approve-campaign-discounts/${promotionId}`, data,{
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendDenyCampaignDiscount = (token, promotionId, reason, lang) => {
    const data = {reason, lang}
    return axios.post(ApiLocation + `/promotions/deny-campaign-discounts/${promotionId}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendCampaignDiscountStatus = (token, promotionId, status) => {
    return axios.get(ApiLocation + `/promotions/set-campaign-discounts/${promotionId}/${status}`,  {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const sendClonePromotion = (token, promotionId) => {
    return axios.post(ApiLocation + `/promotions/clone-promotion/${promotionId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const downloadImage = (token, url, name) => {
    const data = {url, name}
    return axios.post(ApiLocation + '/users/download-image', data,
        {
            headers: {Authorization: `Bearer ${token}`},
            responseType: 'blob'
        }).then((response) => {
            FileDownload(response.data, name);
        })
}

export const fetchPackagingImages = (token, orderId) => {
    return axios.get(ApiLocation + `/users/order/${orderId}/packaging-images`,
    { headers: {Authorization: `Bearer ${token}`}
    })
}

export const fetchLastVisitedProducts = (token, centerId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/last-visited-products`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchBoughtTogetherProducts = (token, centerId, productId) => {
    return axios.get(ApiLocation + `/catalog/${centerId}/products-bought-together/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const postVisitedProduct = (token, centerId, productId, outlet) => {
    return axios.put(ApiLocation + `/catalog/${centerId}/visited-product/${productId}`, {outlet}, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

/**
 * Send / post the walkthrough data after finishing the walkthrough
 * @param {string} email 
 * @param {string} lang 
 * @param {string} type 
 * @param {boolean} noShowMore 
 * @param {object} token 
 */
export const sendWalkthrough = (email, lang, type, noShowMore, token) => {
    const data = {
        email: email,
        lang: lang,
        type: type,
        no_show_more: noShowMore
    }

    return axios.post(ApiLocation + '/user-walkthrough', data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

/**
 * Fetch / get the user walkthrough data
 * @param {object} token 
 */
export const fetchHasWalkthrough = token => {
    return axios.get(ApiLocation + `/user-has-walkthrough`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

/**
 * Post corresponding data to set the sla dates.
 * @param {(string|int)} centerId
 * @param {string} shippingMethod
 * @param {object} token
 */
export const setLinesSlaDate = (centerId, shippingMethod, selectedShippingAddrId, selectedShippingCenterId, token) => {
    let body = {
        shippingMethod: shippingMethod,
        shippingAddressId: selectedShippingAddrId,
        shippingCenterId: selectedShippingCenterId
    }

    return axios.post(ApiLocation + `/cart/${centerId}/lines/sla-dates`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const changeCartLineCutToSize = (lineId, cutToSize, centerId, token, storeId = null, employeeId = null) => {
    let body = { cutToSize: cutToSize }

    return axios.patch(ApiLocation + `/cart/${centerId}/lines/${lineId}/cut-to-size?${cartKey(storeId, employeeId)}`, body, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export const fetchVirtualVisitSalesforceId = (userId, token) => {
    return axios.get(ApiLocation + `/cart/vv-salesforce-ids/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

