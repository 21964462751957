import { connect } from 'react-redux'
import { addCartLineWithouthSeg } from '../../../store/cart/actions'
import StockResult from './StockResult'
import { getShippingMethod } from '../../../store/centers/reducers'
import { getUserType } from '../../../store/login/reducers'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getImageBackground } from '../../../store/products/actions'

const mapStateToProps = (state, ownProps) => {
    return {
        shippingMethod: getShippingMethod(state),
        userType: getUserType(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (productId, qty, extra) => {
            dispatch(addCartLineWithouthSeg(productId, qty, extra))
        },
        getImageBackground: product => dispatch(getImageBackground(product))
    }
}

const StockResultContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(StockResult)
    )
)

export default StockResultContainer
