import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getSelectedCenter } from '../../../../store/centers/reducers'
import { capitalizeFirstLetter } from '../../../../helpers'

const mapStateToProps = (state) => {
    return {
        selectedCenter: getSelectedCenter(state),
    }
}

class DisplayBrand extends React.Component {
    render() {
        const { brand, span, selectedCenter, style, colorSerieDesc } = this.props

        if (!brand) return ''
        if (span)
            return (
                <span>
                    {selectedCenter == 7130 && brand == 'DEKTON'
                        ? 'DKTN'
                        : capitalizeFirstLetter(brand)}
                    &reg;
                    {colorSerieDesc && ` - ${capitalizeFirstLetter(colorSerieDesc)}`}
                </span>
            )

        return (
            <div className="product-brand" style={style}>
                {selectedCenter == 7130 && brand == 'DEKTON'
                    ? 'DKTN'
                    : brand.toLowerCase() == 'services'
                    ? 'worktop'
                    : capitalizeFirstLetter(brand)}{' '}
                &reg;
                {colorSerieDesc && ` - ${capitalizeFirstLetter(colorSerieDesc)}`}
            </div>
        )
    }
}

export default injectIntl(connect(mapStateToProps, null)(DisplayBrand))
