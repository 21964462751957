import React from "react"
import Select from 'react-select'
import { injectIntl } from "react-intl"
import { capitalizeFirstLetter } from "../../../helpers"

const CatalogFilter = props => {
    const { intl, configurableModels, uniqueColors, uniqueBrands, uniqueTypes } = props

    const typeOptions = Object.keys(uniqueTypes).map(key => {
        if (key === 'washbasins') return { label: intl.formatMessage({ id: 'subtype_washbasin' }), value: key }
        else return { label: intl.formatMessage({ id: 'subtype_shower_tray' }), value: key }
    })

    const modelOptions = configurableModels && configurableModels.map(item => {
        return { value: item.model, label: capitalizeFirstLetter(item.model) }
    })

    const brandOptions = Object.entries(uniqueBrands).map(([key, value]) => {
        return { label: capitalizeFirstLetter(value), value: key }
    })

    const colorOptions = Object.entries(uniqueColors).map(([key, value]) => {
        return { value: key, label: capitalizeFirstLetter(value) }
    }).sort((a, b) => a.label.localeCompare(b.label))

    return (
        <div className="config__bath__filter">
            <div className="config__bath__filter--select">
                <div className="flex-block">
                    <Select value={props.selectedOptionType}
                            onChange={props.handleChangeType}
                            options={typeOptions}
                            placeholder={intl.formatMessage({ id: 'type' })}
                            className={'custom__select'}
                    />
                    <Select value={props.selectedOptionModel}
                            onChange={props.handleChangeModel}
                            options={modelOptions}
                            placeholder={intl.formatMessage({ id: 'Modelo' })}
                            className={'custom__select'}
                    />
                    <Select value={props.selectedOptionBrand}
                            onChange={props.handleChangeBrand}
                            options={brandOptions}
                            placeholder={intl.formatMessage({ id: 'brand' })}
                            className={'custom__select'}
                    />
                    <Select value={props.selectedOptionColor}
                            onChange={props.handleChangeColor}
                            options={colorOptions}
                            placeholder={intl.formatMessage({ id: 'color' })}
                            className={'custom__select'}
                    />
                </div>
            </div>
        </div>
    )
}

export default injectIntl(CatalogFilter)