import React from 'react'
import {injectIntl, defineMessages} from "react-intl";
import {connect} from "react-redux";
import ConfigCutToSizePlanAcceptance from "../components/cut-to-size/configurable-baths/ConfigCutToSizePlanAcceptance";
import {addCartLineCutToSize, changeCartLineCutToSize} from "../store/cart/actions";
import { getConfigurableInProgress, getConfigurableModifiedList, getConfigurableDrawingList } from '../store/cut-to-size/reducers'
import { setIsConfigurablePlanAcceptanceScreen } from '../store/ui/actions';
import { addConfigurablePlanAcceptanceChecked, fetchConfigurableDrawing } from '../store/cut-to-size/actions';
import { getLocalizedUrl } from '../store/ui/reducers';
import {getCmOrInches} from "../helpers";
import { getLines } from '../store/cart/reducers'

const ConfigCutToSizePlanAcceptanceScreen = props => <ConfigCutToSizePlanAcceptance {...props} />

const mapDispatchToProps = (dispatch, ownprops) => {
    return {
        addCartLine: (qty, cts) => dispatch(addCartLineCutToSize(qty, cts)),
        setIsConfigurablePlanAcceptanceScreen: isConfigurablePlanAcceptanceScreen => {
            dispatch(setIsConfigurablePlanAcceptanceScreen(isConfigurablePlanAcceptanceScreen))
        },
        addConfigurablePlanAcceptanceChecked: (refcode, checked) => dispatch(addConfigurablePlanAcceptanceChecked(refcode, checked)),
        changeCartLineCutToSize: (lineId, cutToSize) => dispatch(changeCartLineCutToSize(lineId, cutToSize)),
        fetchConfigurableDrawing: (referenceCode) => dispatch(fetchConfigurableDrawing(referenceCode))
    }
}
const mapStateToProps = (state, ownprops) => {
    return {
        configurableInProgress: getConfigurableInProgress(state),
        model: ownprops.match.params.model,
        refcode: ownprops.match.params.refcode,
        type: ownprops.match.params.type,
        fabricator: ownprops.match.params.fabricator,
        localizedUrl: (routeId) => getLocalizedUrl(state, ownprops.intl, routeId),
        units: getCmOrInches(state),
        checkoutLines: getLines(state),
        configurableModifiedList: getConfigurableModifiedList(state),
        configurableDrawingList: getConfigurableDrawingList(state)
    }
}

defineMessages({
    leave_page_plan_acceptance_confirmation_message: {
        id: 'leave_page_plan_acceptance_confirmation_message',
        description: 'leave_page_plan_acceptance_confirmation_message',
        defaultMessage: 'Are you sure you want to leave without accepting the plan?'
    },
    leave_page_plan_acceptance_advise_message: {
        id: 'leave_page_plan_acceptance_advise_message',
        description: 'leave_page_plan_acceptance_advise_message',
        defaultMessage: 'By clicking on “Exit without accepting plan” you will lose the product configuration and will have to make a new configuration.'
    },
    leave_page_plan_acceptance_textok_message: {
        id: 'leave_page_plan_acceptance_textok_message',
        description: 'leave_page_plan_acceptance_textok_message',
        defaultMessage: 'Exit without accepting plan'
    }
})

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConfigCutToSizePlanAcceptanceScreen)
)