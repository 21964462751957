import React from 'react'
import Translation from '../../global/Translation'

import LocalizedLink from '../../global/LocalizedLink'
import { injectIntl } from 'react-intl'

import iconoTemporalAcabado from '../../../assets/img/acabado.png'
import ProductDisplayName from '../../global/ProductDisplayName'
import withRouter from 'react-router-dom/withRouter'

class CircleResult extends React.Component {
    constructor(props) {
        super(props)
        const source = props.result !== undefined ? props.result._source : props.product
        this.product = source
    }

    render() {
        const goToProduct = () => {
            this.props.setLastSelectedProduct(this.product)
        }

        const promoCenter = this.props.location.search.indexOf('promo=true') > -1 ? 7500 : null
        const thickness = this.product.thickness ? (
            <div className="thickness">
                <div className="icon">
                    <div style={{ height: `${this.product.thickness}px` }} />
                </div>
                <div className="text">{this.product.thickness.replace('.', ',')} cm</div>
            </div>
        ) : null

        const finish = this.product.finish2 ? (
            <div className="finish">
                <div className="icon">
                    <img alt="" src={iconoTemporalAcabado} />
                </div>
                <div className="text">{this.props.intl.formatMessage({ id: this.product.finish2 })}</div>
            </div>
        ) : null

        return (
            <div className="result" data-cy="catalog__product_circle_result" data-key={this.product.productId} data-color={this.product.colorId} type={this.product.type}>
                <LocalizedLink
                    routeId="ROUTE_PRODUCT_DETAIL"
                    params={{ id: this.product.productId, outlet: this.props.outlet ? 'outlet' : '' }}
                    queryString={this.props.location.search.indexOf('promo=true') > -1 ? '?promo=true' : undefined}
                    onClick={goToProduct}
                >
                    <div className="product-image-container">
                        <div className="product-image" style={{ backgroundImage: 'url("' + this.product.thumb + '")' }} />
                    </div>
                </LocalizedLink>
                <div className="product-info">
                    <div className="product-brand">{this.product.brand.toLowerCase()} &reg;</div>
                    <div className="product-color-name">
                        <ProductDisplayName product={this.product} />
                    </div>
                    <div className="product-info-details">
                        {thickness}
                        {finish}
                    </div>
                </div>
                <div className="product-actions">
                    <div className="action-buttons">
                        <span
                            className="action-button bt-add-to-cart"
                            onClick={() => {
                                this.props.addCartLine(this.product.productId, 1, promoCenter)
                            }}
                        >
                            <i className="fal fa-shopping-cart" />
                        </span>
                        <LocalizedLink
                            routeId="ROUTE_PRODUCT_DETAIL"
                            queryString={this.props.location.search.indexOf('promo=true') > -1 ? '?promo=true' : undefined}
                            params={{ id: this.product.productId, outlet: this.props.outlet ? 'outlet' : '' }}
                            className="action-button bt-view-detail"
                            onClick={goToProduct}
                        >
                            <i className="fal fa-eye" />
                        </LocalizedLink>
                    </div>
                    <LocalizedLink
                        routeId="ROUTE_PRODUCT_DETAIL"
                        queryString={this.props.location.search.indexOf('promo=true') > -1 ? '?promo=true' : undefined}
                        params={{
                            id: this.product.productId,
                            outlet: this.props.outlet ? 'outlet' : ''
                        }}
                        className="bt-more-info"
                        onClick={goToProduct}
                    >
                        <Translation id="more_info" defaultMessage="+ Information" />
                    </LocalizedLink>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(CircleResult))
