import React, { useState, useEffect } from "react"
import gripImg from "../../../../assets/img/logos/grip.svg"
import gripPlusImg from "../../../../assets/img/logos/grip-plus.svg"
import hybriqImg from "../../../../assets/img/logos/hybriq.svg"
import nboostImg from "../../../../assets/img/logos/nboost.svg"
import ukiyoImg from "../../../../assets/img/logos/ukiyo.svg"
import optimmaImg from "../../../../assets/img/logos/optimma-logo.svg"
import silestonexmImg from "../../../../assets/img/logos/silestone-xm.svg"

const technologyData = {
    grip: { img: gripImg, alt: "GRIP Technology", defaultWidth: "74px", defaultHeight: "24px" },
    "grip+": { img: gripPlusImg, alt: "GRIP+ Technology", defaultWidth: "74px", defaultHeight: "24px" },
    hybriq: { img: hybriqImg, alt: "Hybriq Technology", defaultWidth: "63px", defaultHeight: "24px" },
    nboost: { img: nboostImg, alt: "NBOOST Technology", defaultWidth: "74px", defaultHeight: "24px" },
    ukiyo: { img: ukiyoImg, alt: "Ukiyo Technology", defaultWidth: "63px", defaultHeight: "24px" },
    optimma: { img: optimmaImg, alt: "Optimma Technology", defaultWidth: "63px", defaultHeight: "24px" },
    silestonexm: { img: silestonexmImg, alt: "Silestone XM Technology", defaultWidth: "63px", defaultHeight: "24px" },
}

const DisplayTechnology = ({
    technology,
    subBrand,
    backgroundColor = "#FFFFFF",
    className,
    desktopWidth,
    desktopHeight,
    showTechnologies = true,
    isCheckout = false,
    withBorder = false
}) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

    // Update the state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768)
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    // Verify if the technology should be displayed
    if (!showTechnologies) {
        return null
    }

    let techKey = technology && typeof technology === "string" ? technology.toLowerCase() : null
    let techInfo

    if (subBrand && typeof subBrand === "string" && subBrand.toUpperCase() === "XM") {
        techKey = "silestonexm"
    }

    if (techKey && technologyData[techKey]) {
        techInfo = technologyData[techKey]
    }

    if (!techInfo) return null

    // Adjusted dimensions and padding for checkout
    const checkoutStyles = {
        width: "50px",
        height: "16px",
        padding: "2px 4px",
    }

    // General styles
    const styles = {
        generalStyles: {
            display: "inline-block",
            backgroundColor: backgroundColor,
            padding: isCheckout ? checkoutStyles.padding : "6px 8px",
            lineHeight: "0",
            border: withBorder ? '1px solid #cacaca' : '',
            width: isDesktop
                ? desktopWidth || (isCheckout ? checkoutStyles.width : techInfo.defaultWidth)
                : isCheckout
                ? checkoutStyles.width
                : techInfo.defaultWidth,
            height: isDesktop
                ? desktopHeight || (isCheckout ? checkoutStyles.height : techInfo.defaultHeight)
                : isCheckout
                ? checkoutStyles.height
                : techInfo.defaultHeight,
        },
        imgStyles: {
            width: "100%",
            height: "100%",
        },
    }

    return (
        <div className={className} style={styles.generalStyles}>
            <img src={techInfo.img} alt={techInfo.alt} style={styles.imgStyles} />
        </div>
    )
}

export default DisplayTechnology
