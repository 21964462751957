import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import { showingSegmentationModal } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import SegmentationContainer from './SegmentationContainer'
import { getSegmentationCurrentStep } from '../../store/segmentation/reducers'

const mapStateToProps = state => {
    return {
        show: showingSegmentationModal(state),
        segmentationCurrentStep: getSegmentationCurrentStep(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeSegmentationModal())
        }
    }
}

class SegmentationModal extends React.Component {
    render() {
        const { show, onClose, segmentationCurrentStep } = this.props

        if (!show) return (
            <React.Fragment/>
        )

        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id="modal-segmentation-container" className="modal-segmentation container segmentation" style={{ display: show ? 'block' : 'none' }}>
                    <header>
                        <div className="d-flex align-items-center justify-content-between pb-3">
                            <div>
                                <h4 className="m-0">
                                    {segmentationCurrentStep === 1 ? (
                                        <Translation id="choose_segmentation" defaultMessage="Choose segmentation" />
                                    ) : (
                                        <Translation id="attach_supporting_documents" defaultMessage="Attach supporting documents" />
                                    )}
                                </h4>
                            </div>
                            <div>
                                <div className="bt-close" onClick={onClose}>
                                    <i className="fal fa-times" />
                                </div>
                            </div>
                        </div>
                    </header>
                    <div>
                        <SegmentationContainer modal />
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SegmentationModal)
)
