import {productLogic} from "./ProductLogic";

export const mallProductLogic = (mallProps, baseLogicFunction) => {
    const getExtras = () => {
        const extras = {
            ...mallProps.product.extra,
            mall: true,
            landing_id: mallProps.product.landingId,
            isCosentinoGlobal: false,
            isCosentinoBrazil: false
        }
        if(mallProps.sapLandingId){
            extras.sap_landing_id = mallProps.sapLandingId
        }
        if (mallProps.product.lots || (mallProps.product.productLots && mallProps.product.productLots.lots))
            extras.lots = true
        if (mallProps.product.outletQuality)    extras.outlet = mallProps.product.outletQuality
        if (mallProps.sectionFlag)              extras.sectionFlag = mallProps.sectionFlag
        if (mallProps.list)                     extras.analyticsTag = mallProps.list
        if (mallProps.crossType)                extras.crossType = mallProps.crossType

        return extras
    }

    const getAdditionalInfo = () => {
        if (mallProps.product.additionalInfo) {
            try {
                const transAdditionalInfo = JSON.parse(mallProps.additionalInfo)
                return transAdditionalInfo[mallProps.intl.locale] ? transAdditionalInfo[mallProps.intl.locale] : transAdditionalInfo[transAdditionalInfo.default]
            } catch(e) {}
        }
        return null
    }

    mallProps.product.additionalInfo = getAdditionalInfo()

    const minimumOrder = Math.min(mallProps.product.minimumOrder, mallProps.product.realStock)
    const getMin = () => minimumOrder > 1 ? minimumOrder : 1
    const getLimit = () => mallProps.product.realStock

    const mallBaseLogicFunction = (props) => {
        return {
            ...baseLogicFunction(props),
            getMin,
            getExtras,
            getLimit
        }
    }

    const props = productLogic(mallProps, mallBaseLogicFunction)
    const state = props.state


    const addToCart = () => {
        console.log('MALL ADDTOCART:' ,mallProps.product)
        if (props.needsSegmentation) {
            props.addCartLine(
                mallProps.product.productId,
                state.qtyToAdd,
                getExtras(),
                null,
                mallProps.product.finish === 'AND' && ['DEKTON', 'DKTN'].includes(mallProps.product.brand),
                mallProps.product.centerId
            )
        } else {
            props.addCartLineWithouthSeg(mallProps.product.productId, state.qtyToAdd, {
                type: props.type
            })
        }

    }

    const goToProductCard = (_product) => {
        _product.extra = getExtras()


        console.log('EXTRAS', _product.extra)
        if (props.indexOfList !== undefined) props.clickDetail(_product, props.list, props.indexOfList)
    }

    const showLots = () => {
        // if(mallProps.list && mallProps.list === 'MALL')
        //     return false;
        if(mallProps.product.productLots){
            return props.showLots()
        }
        return false
    }

    return {
        ...props,
        minimumOrder,
        addToCart,
        goToProductCard,
        showLots,
        getExtras
    }
}
