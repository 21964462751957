import React from "react"
import { capitalizeFirstLetter } from "../../../helpers"
import { ReactComponent as TresdIcon } from '../../../assets/img/icons/tresd-icon.svg'
import LocalizedLink from "../../global/LocalizedLink";
import Translation from "../../global/Translation";

const ConfigBathCard = props => {
    const { data } = props

    const brands = Object.keys(data.fullConfig.brands)
    
    return (
        <div className="config__bath__card">
            {/* Image */}
            <div className="config__bath__card--img">
                <img src={data.defaultImageModelUrl} alt={capitalizeFirstLetter(data.model)} />
            </div>

            {/* Info */}
            <div className="config__bath__card--info">
                <div className="brand">
                    {brands.sort().map(brand => {
                        return <span key={brand}>{capitalizeFirstLetter(brand) + ' ® '}</span>
                    })}
                </div>
                <div className="name">
                    <span>{capitalizeFirstLetter(data.model)}</span>
                </div>
            </div>

            <div className="x-divider"></div>

            {/* Price */}
            <div className="config__bath__card--price">
                <div>{/* <span>Desde:&nbsp;{data.price}</span> */}</div>
            </div>

            {/* Config btn */}
            <div className="config__bath__card--btn">
                <LocalizedLink routeId="ROUTE_CONFIG_CUT_TO_SIZE" params={{ type: data.type, model: data.model.toLowerCase().replace(' ', '_'), refcode: '', fabricator: props.fabricator }}>
                    <Translation id="custom_configuration" defaultMessage="Custom configuration" /> <TresdIcon />
                </LocalizedLink>
            </div>
        </div>
    )
}

export default ConfigBathCard